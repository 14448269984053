import React from "react";

export const UniverseAddCompaniesSearchResultsTable = (props) => {
    let divStyle = {
        width: '800px', 
        overflowY: 'auto', 
        border: '1px solid black',
        display: 'block'
    }
    if (!props.onlySearch) {
        divStyle['height'] = '200px';
    }
    

    return (
        <div className="company-table" style={{maxHeight: '300px'}}>
        <table>
            <thead>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            checked={props.selectAll}
                            onChange={() => {props.handleSelectAll()}}
                        />
                    </th>
                    <th>Company Name</th>    
                    <th>LinkedIn</th>
                    <th>Signal Score</th>
                    <th>Employee Count</th>
                    <th>Public Profiles</th>
                </tr>
            </thead>
            <tbody>
                {props.searchDataItems.map((item, index) => (
                    <tr key={item.index}>
                        <td>
                            <input
                                type="checkbox"
                                checked={props.selectedRows[index] || false}
                                onChange={() => {props.handleSelect(index, item)} }
                            />
                        </td>
                        
                        <td>
                            {item.name ? item.name : 'N/A'}
                        </td>

                        <td>
                            <a href={item.linkedin_url} target="_blank" rel="noopener noreferrer">
                                {item.linkedin_url}
                            </a>
                        </td>

                        <td>{item.signal_score ? parseFloat(item.signal_score * 100).toFixed(1)+'%' : 'N/A'}</td>
                        <td>{item.employees_count ? item.employees_count : 'N/A'}</td>
                        <td>{item.num_valid_public_profiles ? item.num_valid_public_profiles : 'N/A'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};
