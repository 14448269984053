import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";


class RecentCompaniesStore {
    loading = false;
    companies = null;
    error = null;
    unAuthorized = false;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchRecentCompanies(uuid) {
        // check if data already exists
        // if (this.companies && uuid in this.companies && this.companies[uuid] !== undefined) {
        //     console.log(`RecentCompaniesStore: Data already exists`);
        //     return;
        // }
        
        this.loading = true;

        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_GET_RECENT_EVENTS_ENDPOINT;

            console.log(`RecentCompaniesStore: Fetching recent companies list from ${apiUrl}...`);

            let data = {
                "account_uuid": uuid, //'admin@telemetryllc.com',
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            
            if (response.status === 200 && response.data.data.events.length !== 0) {
                const events = response.data.data.events;
                console.log(`RecentCompaniesStore: Fetched companies list from ${apiUrl}, ${events.length} records`);
                
                let recentCompanies = [];
                for (let i = 0; i < events.length; i++) {
                    recentCompanies.push({
                        "company_id": events[i].company_id,
                        "company_name": events[i].company_name,
                    });
                }

                // set data in store
                if (this.companies === null) {
                    this.companies = {};
                }
                this.companies[uuid] = recentCompanies;
                this.unAuthorized = false;
            }

        }
        catch(err) {
            console.log(err);
            if(err.response !== undefined && err.response.status === 401) {
                this.error = 'Unauthorized';
                this.unAuthorized = true;
            }
            else {
                this.error = "Oops! Something went wrong. Please try again later."
            }
        }
        finally {
            this.loading = false;
        }

    }
}

export const recentCompaniesStore = new RecentCompaniesStore();
