import { PageLayout } from "../../components/common/page-layout"
import BizDevReportKf from "../../components/report/bizdev_report_kf";

export const BizDevReportKfPage = () => {
    return (
        <PageLayout>
            <BizDevReportKf />
        </PageLayout>
    );
};
