import ForgotPasswordComponent from "../../components/auth/forgot-password";
import { PageLayout } from "../../components/common/page-layout"

export const ForgotPasswordPage = () => {
    return (
        <PageLayout>
            <ForgotPasswordComponent/>
        </PageLayout>
    );
};
