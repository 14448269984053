import React from "react";
import { PageLayout } from "../../components/common/page-layout";

export const NotFoundPage = () => {
  return (
    <PageLayout>
      <div>
        <h1 id="page-title">
          Not Found
        </h1>
      </div>
    </PageLayout>
  );
};
