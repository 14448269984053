import { PageLayout } from "../../components/common/page-layout"
import BizDevReport from "../../components/report/bizdev_report";

export const BizDevReportPage = () => {
    return (
        <PageLayout>
            <BizDevReport />
        </PageLayout>
    );
};
