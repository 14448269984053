import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";


class UniverseSummaryDataStore {
    loading = false;
    isUnAuthorized = false;
    data = {};
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchUniverseSummaryData(universeId, accessToken, forceRefresh = false) {

        // check if data already exists
        if (!forceRefresh && this.data[universeId] && this.data[universeId].length > 0) {
            console.log(`UniverseSummaryDataStore: Data already exists for universe ${universeId}`);
            return;
        }

        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_UNIVERSE_SUMMARY_ENDPOINT;

        console.log(`UniverseSummaryDataStore: Fetching universe summary data from ${apiUrl}...`);
        let data = {
            "universe_id": `${universeId}`,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            // withCredentials: true,
            decompress: true,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }

        try {
            const response = await axios.post(apiUrl, data, config);
            if (response.status === 401 || response.status === 403) {
                this.isUnAuthorized = true;
                return;
            }

            if (response.status === 200 && response.data.data.universe_summary.length !== 0) {
                const jsonData = JSON.parse(response.data.data.universe_summary);
                console.log(`UniverseSummaryDataStore: Fetched universe summary data from ${apiUrl}, ${jsonData.length} records`);
                this.data[universeId] = jsonData;
                this.loading = false;
            }

            if (userAccountDataStore.user !== null) {
                // track universe 2x2 event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId: null,
                    eventType: 'universe_summary',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_UNIVERSE_SUMMARY_ENDPOINT,
                        "dataset": null,
                        "universe_id": universeId,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
            
        }
        catch (error) {
            if (error.response  && error.response.status === 401 || error.response === 403) {
                this.isUnAuthorized = true;
            } 
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    }
}

export const universeSummaryDataStore = new UniverseSummaryDataStore();
