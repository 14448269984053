import _ from 'lodash';
import './App.css';
import { NotFoundPage } from './pages/common/not-found-page';
import { LoginPage } from './pages/auth/login_page';
import { CompanyGrowthPage } from './pages/company/company_growth_page';
import { CompanyTeamGrowthPage } from './pages/company/company_team_growth_page';
import { Routes, Route } from 'react-router-dom';
import { CompanyTalentPage } from './pages/company/company_talent_page';
import { UniverseChartPage } from './pages/universe/universe_page';
import { CompanyTenurePage } from './pages/company/company_tenure_page';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getApiServrUrl, getGoogleAnalyticsId } from './utils/utils';
import { CompanySearchPage } from './pages/company/company_search_page';
import { CompanySearchAndCreateUniversePage } from './pages/company/company_search_and_create_universe_page';
import { RegisterUserPage } from './pages/auth/register_user_page';
import { VerifyEmailPage } from './pages/auth/verify_email_page';
import { HomePage } from './pages/common/home_page';
import { ForgotPasswordPage } from './pages/auth/forgot-password-page';
import { ResetPasswordPage } from './pages/auth/reset-password-page';
import { BugReportPage } from './pages/common/bug_report_page';
import { UniverseEditPage } from './pages/universe/universe_edit_page';
import { BizDevReportPage } from './pages/report/bizdev_report_page';
import { BizDevReportKfPage } from './pages/report/bizdev_report_kf_page';
import { CompanyTalentRetentionPage } from './pages/company/company_talent_retention_page';
import { DigitalBenchmarkReportPage } from './pages/report/digital_benchmark_page';
import { HealthCheckReportPage } from './pages/report/health_check_report_page';
import { DigitalBenchmarkReportKFPage } from './pages/report/digital_benchmark_kf_page';
import { StandardBenchmarkReportKFPage } from './pages/report/standard_benchmark_kf_page';
import { StandardBenchmarkReportPage } from './pages/report/standard_benchmark_page';
import { TalentMgmtReportPage } from './pages/report/talent_mgmt_report_page';
import { TalentMgmtReportKFPage } from './pages/report/talent_mgmt_report_kf_page';
import { CompanyTalentRetentionPlusPage } from './pages/company/company_talent_retention_plus_page';
import { CompanyTopTitlesPage } from './pages/company/company_top_titles_page';


function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        // Assuming gtag is available as a global function
        if (typeof window.gtag === 'function') {
			const gAnalyticsId = getGoogleAnalyticsId();
            window.gtag('config', gAnalyticsId, {
                'page_path': location.pathname + location.search
            });
        }
    }, [location]);
}

function App() {
	usePageViews();

	return (
		<div>
			<Routes>
				<Route exact path="/" element={<HomePage />} />
				<Route path="/home" element={<HomePage />} />
				<Route path="/report-bug" element={<BugReportPage />} />
				<Route path="/report/preview" element={<BizDevReportPage />} />
				<Route path="report/kf/preview" element={<BizDevReportKfPage />} />
				<Route path="/report/digital-bm/kf/preview" element={<DigitalBenchmarkReportKFPage />} />
				<Route path="/report/digital-bm/preview" element={<DigitalBenchmarkReportPage />} />
				<Route path="/report/standard-bm/kf/preview" element={<StandardBenchmarkReportKFPage />} />
				<Route path="/report/standard-bm/preview" element={<StandardBenchmarkReportPage />} />
				<Route path="/report/health-check/preview" element={<HealthCheckReportPage />} />
				<Route path="/report/talent-mgmt/preview" element={<TalentMgmtReportPage />} />
				<Route path="/report/talent-mgmt/kf/preview" element={<TalentMgmtReportKFPage />} />
				<Route path="*" element={<NotFoundPage />} />

				<Route path='/register' element={<RegisterUserPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/verify-email" element={<VerifyEmailPage />} />
				<Route path="/forgot-password" element={<ForgotPasswordPage />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />

				<Route path="/universe/summary" element={<UniverseChartPage />} />
				<Route path="/universe/company/search" element={<CompanySearchAndCreateUniversePage />} />
				<Route path="/universe/edit" element={<UniverseEditPage />} />

				<Route path="/company/search" element={<CompanySearchAndCreateUniversePage />} />
				<Route path="/company/growth" element={<CompanyGrowthPage />} />
				<Route path="/company/team/growth" element={<CompanyTeamGrowthPage />} />
				<Route path="/company/talent" element={<CompanyTalentPage />} />
				<Route path="/company/talent/retention" element={<CompanyTalentRetentionPage />} />
				<Route path="/company/talent/retention/plus" element={<CompanyTalentRetentionPlusPage />} />
				<Route path="/company/tenure" element={<CompanyTenurePage />} />
				<Route path="/company/top-titles" element={<CompanyTopTitlesPage />} />
				
			</Routes>
		</div>
	);
}

export default App;