import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";


class ApiEventsStore {
    error = null;
    unAuthorized = false;

    constructor() {
        makeAutoObservable(this);
    }

    async trackApiEvents(uuid, event) {
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_TRACK_API_EVENTS_ENDPOINT;

            console.log(`ApiEventsStore: Tracking api events with ${apiUrl}...`);

            let data = {
                "account_uuid": uuid,
                "company_id": event.companyId || null,
                "event_type": event.eventType,
                "event_data": event.eventData,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);

        }
        catch(err) {
            console.log(err);
            if(err.response !== undefined && err.response.status === 401) {
                this.error = 'Unauthorized';
                this.unAuthorized = true;
            }
            else {
                this.error = "Oops! Something went wrong. Please try again later."
            }
        }

    }
}

export const apiEventsStore = new ApiEventsStore();
