import { toJS } from "mobx";
import { useEffect, useMemo, useState } from "react";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils.js";
import { standardBenchmarkReportDataStore } from "../../../stores/standard_benchmark_report_data_store";


const TargetToPeerDeptAvgCohortRetentionChart = ({requestData, deptType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const NUM_HIRED_THRESHOLD = 5;

    const title = deptType === null ? `Target-to-Peer Set Comparison: Dept Employee Avg Cohort Retention` : `Target-to-Peer Set Comparison: ${deptType} Employee Avg Cohort Retention`;
    const divId = deptType === null ? 'dept-cohort-retention-target-peer-chart' : `dept-cohort-retention-target-peer-chart-${deptType}`;
    const chartContainerId = deptType === null ? 'dept-cohort-retention-target-peer-chart-container' : `dept-cohort-retention-target-peer-chart-container-${deptType}`;


    const uniqueByKeys = (data, key1, key2, key3) => {
        const uniqueData = data.map(item => ({
            [key1]: item[key1],
            [key2]: item[key2],
            [key3]: item[key3],
        }))
        .reduce((acc, current) => {
            if (!acc.some(item => item[key1] === current[key1] && item[key2] === current[key2] && item[key3] === current[key3])) {
            acc.push(current);
            }
            return acc;
        }, []);
        
        return uniqueData;
    };

    const getHeatmapColor = (percentage) => {
        if (percentage === undefined)
            return 'white';
        // You would define your own logic here to return a color based on the value
        const value = parseFloat(percentage.replace('%', ''));
        // if (value >= 80) return '#2CC0DD';
        // if (value >= 60) return '#25A2BB';
        // if (value >= 40) return '#1F8599';
        // if (value >= 20) return '#186777';
        if (value >= 80) return '#3cdfff';
        if (value >= 60) return '#63e5ff';
        if (value >= 40) return '#8aecff';
        if (value >= 20) return '#b1f2ff';
        return '#d8f9ff'; // Less than 20%
    };

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchRetentionHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset, deptType);
            const retentionHistoryData = standardBenchmarkReportDataStore.retentionHistoryData[targetCompanyId];

            if (retentionHistoryData !== null) {
                setData(toJS(retentionHistoryData));
                console.log('retentionHistoryData', toJS(retentionHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('retentionHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawCohortRetentionChart = (data) => {
            const uniqueData = uniqueByKeys(data, 'official_name', 'company_exp_tenure_years_normalized', 'pct_retained_avg');
            // Extract unique company names
            const categories = [...new Set(data.map(item => item.official_name))];

            // Prepare the series data
            const seriesData = [];
            categories.forEach(category => {
                const dataForCompany = data.filter(item => item.official_name === category && item.company_exp_tenure_years_normalized === '0');
                // calculate the average number of people
                const avgNumPeople = dataForCompany.reduce((acc, current) => acc + current.num_people, 0) / dataForCompany.length;
                if (avgNumPeople < NUM_HIRED_THRESHOLD) {
                    return;
                }

                const dataForCategory = uniqueData.filter(d => d.official_name === category);
                const seriesItems = [];
                dataForCategory.forEach(item => {
                    if (item.company_exp_tenure_years_normalized !== 'current') {
                        seriesItems.push([parseFloat(item.company_exp_tenure_years_normalized), parseFloat((item.pct_retained_avg * 100).toFixed(0))]);
                    }
                });
                if (category === targetCompanyName) {
                    seriesData.push({
                        name: category,
                        data: seriesItems,
                        type: 'spline',
                        color: 'black',
                        lineWidth: 4,
                    });
                }
                else {
                    seriesData.push({
                        name: category,
                        data: seriesItems,
                        type: 'spline',
                    });
                }
            });

            Highcharts.chart(chartContainerId, {
                chart: {
                    zoomType: 'xy',
                    type: 'spline',
                    height: '25%',
                },
                title: {
                    text: 'Cohort Retention',
                    style: {
                        color: '#fff',
                    },
                },
                xAxis: {
                    title : {
                        text: 'Years',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    labels: {
                        style: {
                            fontSize: '20px',
                        },
                    },
                    tickInterval: 1,
                },
                yAxis: {
                    title: {
                        text:  'Retention Rate',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    labels: {
                        format: '{value}%',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    max: 100,
                },
                legend: {
                    enabled: true,
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    floating: false,
                },
                
                series: seriesData,
    
                credits: {
                    enabled: false
                },
                tooltip: { 
                    formatter: function() {
                        var series = this.series.chart.series;
                        var result = '';
                        result += '<b><span style="color:' +
                                    this.point.color + '">\u25CF</span> ' +
                                    this.series.name + '</b>: <b>' + this.point.y +
                                    '%</b><br/>';
                     
                        series.forEach(function(s) {
                            if (s !== this.series) {
                                result += '<span style="color:' +
                                        s.points[this.point.index].color +
                                        '">\u25CF</span><span style="color: #636363"> ' +
                                        s.name + ': ' + s.points[this.point.index].y +
                                        '%</span><br/>';
                            }
                        }, this);
                    
                        return result;
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        lineWidth: 2,
                    },
                },
            });
        };


        if (data !== null) {
            let filteredData = [];
            let uniqueData = [];
            if (deptType === null) {
                filteredData = data.filter(item => item.derived_department_group === 'All');
            }
            else {
                // API returns the specific digital transformation type data
                filteredData = data;
            }
            console.log('filteredData', filteredData);
            drawCohortRetentionChart(filteredData);

        }

    }, [data]);

    const tableContent = useMemo(() => {
        if (!data || data.length === 0) return null;
        const filteredData = data.filter(item => item.derived_department_group === 'All');
        const uniqueData = uniqueByKeys(filteredData, 'official_name', 'company_exp_tenure_years_normalized', 'pct_retained_avg');

        // Get unique company names and experience years
        const companies = [...new Set(uniqueData.map(item => item.official_name))];
        const expYears = [...new Set(uniqueData.map(item => item.company_exp_tenure_years_normalized))].sort((a, b) => {
            if (a === 'current') return 1;
            if (b === 'current') return -1;
            return parseFloat(a) - parseFloat(b);
        });
        // remove current from expYears
        expYears.pop();

        // Create a map for easy data lookup
        const dataMap = new Map(uniqueData.map(item => [
            `${item.official_name}-${item.company_exp_tenure_years_normalized}`,
            item.pct_retained_avg*100
        ]));

        // Create a map for avg num_people per company
        const avgNumPeopleMap = {};
        companies.forEach(company => {
            const dataForCompany = filteredData.filter(item => item.official_name === company && item.company_exp_tenure_years_normalized === '0');
            // calculate the average number of people
            const avgNumPeople = dataForCompany.reduce((acc, current) => acc + current.num_people, 0) / dataForCompany.length;
            avgNumPeopleMap[company] = Math.round(parseFloat(avgNumPeople));
        });

        return (
            <table className="company-table">
                <thead>
                <tr>
                    <th>Company</th>
                    <th>Avg Num Hired Per Year</th>
                    {expYears.map(year => (
                    <th key={year}>{year}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {companies.map(company => (
                    <tr key={company}>
                    <td>{company}</td>
                    <td>{putCommasInNumber(avgNumPeopleMap[company])}</td>
                    {expYears.map(year => {
                        if (avgNumPeopleMap[company] < NUM_HIRED_THRESHOLD) {
                            return <td key={`${company}-${year}`}>-</td>;
                        }

                        const value = dataMap.get(`${company}-${year}`);
                        return (
                            <td style={{backgroundColor: getHeatmapColor(`${dataMap.get(`${company}-${year}`)?.toFixed(0)}%`)}} key={`${company}-${year}`}>
                                {value ? `${value.toFixed(0)}%` : '-'}
                            </td>
                        );
                    })}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }, [data]);


    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting retention data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <div id={divId}>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={chartContainerId} />
                        </div>
                        <br/>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            {tableContent}
                        </div>
                    </div>
                    <p> *Note: Retention data not shown when the Avg Num Hired per Year is less than 5.* </p>
                </>
                )
            }
        </div>
    );

};

export default TargetToPeerDeptAvgCohortRetentionChart;
