import React, { useState, useEffect } from 'react';
import { apiEventsStore } from '../../stores/api_events_store';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';
import { toJS } from 'mobx';


const BugReportForm = () => {
    const [description, setDescription] = useState('');
    const [timestamp, setTimestamp] = useState('');

    // fetch url from local storage
    const url = localStorage.getItem("reportBugUrl");

    useEffect(() => {
        // set UTC timestamp
        const date = new Date();
        const timestamp = date.toLocaleString('en-US', { timeZone: 'UTC' });
        setTimestamp(timestamp);
        
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // find company id from url
        let companyId = null;
        // check if ? is in url
        if (url.includes('?')) {
            
            const urlParams = url.split('?');
            // find company_id in url params
            const urlParamsArray = urlParams[1].split('&');
            const companyIdParam = urlParamsArray.find(param => param.includes('company_id'));
            companyId = companyIdParam.split('=')[1];
        }

        const report = { description, url, timestamp };
        // Send `report` to the server or handle it as needed
        console.log('Bug Report:', report);

        // track bug report event
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Bug Report", {
                url,
                companyId,
                user: toJS(userAccountDataStore.user), 
            });
            amplitudeActions.track("Bug Report", {
                url,
                companyId,
                user: toJS(userAccountDataStore.user), 
            });
            apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                companyId,
                eventType: 'bug_report',
                eventData: {
                    ...report
                },
            });
        }

        // clear local storage
        localStorage.removeItem("reportBugUrl");

        // clear form
        setDescription('');

        // display success message
        alert('Thank you for reporting this bug! We will look into it as soon as possible.');

        // redirect to home page after 2 seconds
        setTimeout(() => {
            window.location.href = '/';
        }, 2000);
    };

    return (
        <form onSubmit={handleSubmit} className="bug-report-form">
            <div className="report-info">
                <p><strong>URL:</strong> {url}</p>
                <p><strong>Timestamp:</strong> {timestamp}</p>
            </div>
            <textarea 
                className="description-field"
                value={description} 
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the bug here"
            />
            <button type="submit" className="submit-button">Report Bug</button>
        </form>
    );
};

export default BugReportForm;
