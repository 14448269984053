

import VerfiyEmailComponent from "../../components/auth/verify-email";
import { PageLayout } from "../../components/common/page-layout"

export const VerifyEmailPage = () => {
    return (
        <PageLayout>
            <VerfiyEmailComponent/>
        </PageLayout>
    );
};