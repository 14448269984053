import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';

import { useSearchParams, useLocation, NavLink, useNavigate } from 'react-router-dom';
import { customSort, getFriendlyMetricName, getGoogleAnalyticsId, getLastDateOfMonth, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { benchmarkLineColors, lineColors, lineStyles } from '../../utils/constants';
import { DownloadButton } from '../common/download_button';
import Select from 'react-select';

import { observer } from "mobx-react";
import { teamsGrowthDataStore } from '../../stores/teams_growth_data_store';
import { dataVersionDateStore } from '../../stores/data_version_date_store';
import { metadataStore } from '../../stores/metadata_store';
import LoadingWithText from '../common/loading_text';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { collectionsStore } from '../../stores/collections_store';
import { toJS } from 'mobx';
import Dropdown from '../common/collections_dropdown';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';


const CompanyTeamGrowthChartComponent = observer(() => {
    const [teamsGrowthData, setTeamsGrowthData] = useState(null);

    const [geo1Values, setGeo1Values] = useState([]);
    const [deptValues, setDeptValues] = useState([]);
    const [seniorityValues, setSeniorityValues] = useState([]);
    
    const [selectedRegions, setSelectedRegions] = useState([{value: 'All', label: 'All'}]);
    const [selectedDepartments, setSelectedDepartments] = useState([{value: 'Sales', label: 'Sales'}]);
    const [selectedSeniorities, setSelectedSeniorities] = useState([{value: 'All', label: 'All'}]);
    const [collections, setCollections] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [deptDistDataDate, setDeptDistDataDate] = useState('');
    const [seniorityDistDataDate, setSeniorityDistDataDate] = useState('');
    const [geo1DistDataDate, setGeo1DistDataDate] = useState('');
    const viewOptions = [
        { id: 1, value: 'Region', label: 'Region' },
        { id: 2, value: 'Team', label: 'Team' },
        { id: 3, value: 'Seniority', label: 'Seniority' },
    ];
    const [selectedView, setSelectedView] = useState({ id: 2, value: 'Team', label: 'Team' });

    const [showAll, setShowAll] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showStacked, setShowStacked] = useState(false);
    const [showHiringForGrowthChart, setShowHiringForGrowthChart] = useState(false);
    const [showAttritionForGrowthChart, setShowAttritionForGrowthChart] = useState(false);
    const [showNetGrowthForGrowthChart, setShowNetGrowthForGrowthChart] = useState(true);
    const [showGrowthProdForGrowthChart, setShowGrowthProdForGrowthChart] = useState(false);
    const [showHiringForGenderChart, setShowHiringForGenderChart] = useState(false);
    const [showAttritionForGenderChart, setShowAttritionForGenderChart] = useState(false);
    const [showCompanyPercentageForGenderChart, setShowCompanyPercentageForGenderChart] = useState(true);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [companyName, setCompanyName] = useState('');

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;
    
    let dataVersionDate = location.state && location.state.dataVersionDate;

    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`; 
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top-titles?company_id=${companyId}`;
    
    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
    }

    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
    }

    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;
        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };

    const getMetaData = async() => {
        // await both promises in parallel
        await Promise.all([
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ]);

        setCompanyName(metadataStore.data[companyId].companyName);
        if (metadataStore.data[companyId].publicProfileCount !== undefined) {
            setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
        }
        dataVersionDate = dataVersionDateStore.dataVersionDate;

        setDeptDistDataDate(dataVersionDate);
        setSeniorityDistDataDate(dataVersionDate);
        setGeo1DistDataDate(dataVersionDate);

        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            await collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId);
            setCollections(toJS(collectionsStore.collections[companyId]));
        };
        
        setIsMetaDataLoading(false);
    };

    const getTeamsGrowthDataDefault = async() => {
        try {
            if (teamsGrowthDataStore.data[companyId] && teamsGrowthDataStore.data[companyId].length > 0) {
                const jsonData = toJS(teamsGrowthDataStore.data[companyId]);

                let geo1Values = [...new Set(jsonData.map((item) => item.geo1))];
                let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
            
                let deptValues = [... new Set(jsonData.map((item) => item.department))];
                let sortedDeptValues = customSort(deptValues, ['All', 'Sales', 'Product']);
                
                let seniorityValues = [... new Set(jsonData.map((item) => item.seniority))];
                let sortedSeniorityOrder = [
                    "All",
                    "Individual Contributor",
                    "Advisor / Board",
                    "Executive",
                    "Leadership",
                    "Management",
                    "Student / Intern",
                    "Unsure"
                ];
                let sortedSeniorityValues = customSort(seniorityValues, sortedSeniorityOrder);

                // default view is set to Department so remove 'All' from the list
                sortedDeptValues = sortedDeptValues.filter(dept => dept !== 'All');
                
                setDeptValues(sortedDeptValues);
                setSeniorityValues(sortedSeniorityValues);
                setGeo1Values(sortedGeo1Values);
                setTeamsGrowthData(jsonData);
            }
            else {
                let loadDefault = true;
                await teamsGrowthDataStore.fetchTeamsGrowthData(companyId, accessToken, loadDefault, dataset);
                const jsonData = teamsGrowthDataStore.defaultData[companyId];
                setTeamsGrowthData(jsonData);
            }
            
            
        } catch (err) {
            console.log(err);
        }
        setIsPageLoading(false);
    };

    const handleDataFetched = (data, error) => {
        if( teamsGrowthDataStore.data && teamsGrowthDataStore.data[companyId] !== undefined) {
            const jsonData = toJS(teamsGrowthDataStore.data[companyId]);

            let geo1Values = [...new Set(jsonData.map((item) => item.geo1))];
            let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
        
            let deptValues = [... new Set(jsonData.map((item) => item.department))];
            let sortedDeptValues = customSort(deptValues, ['All', 'Sales', 'Product']);
            
            let seniorityValues = [... new Set(jsonData.map((item) => item.seniority))];
            let sortedSeniorityOrder = [
                "All",
                "Individual Contributor",
                "Advisor / Board",
                "Executive",
                "Leadership",
                "Management",
                "Student / Intern",
                "Unsure"
            ];
            let sortedSeniorityValues = customSort(seniorityValues, sortedSeniorityOrder);

            // default view is set to Department so remove 'All' from the list
            sortedDeptValues = sortedDeptValues.filter(dept => dept !== 'All');
            
            setDeptValues(sortedDeptValues);
            setSeniorityValues(sortedSeniorityValues);
            setGeo1Values(sortedGeo1Values);
            setTeamsGrowthData(jsonData);
    }
    };   

    const handleDeptChangeWithMultiSelect = (selectedOptions) => {
        if (selectedOptions === null || selectedOptions.length === 0) {
            console.warn('Atleast one department must be selected');
            return;
        }

        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedDepartments(selectedOptions);
        } else {
            setSelectedDepartments([selectedOptions]);
        }
    };

    const handleSeniorityChangeWithMultiSelect = (selectedOptions) => {
        if (selectedOptions === null || selectedOptions.length === 0) {
            console.warn('Atleast one seniority must be selected');
            return;
        }

        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedSeniorities(selectedOptions);
        } else {
            setSelectedSeniorities([selectedOptions]);
        }
    };

    const handleGeo1ChangeWithMultiSelect = (selectedOptions) => {
        if (selectedOptions === null || selectedOptions.length === 0) {
            console.warn('Atleast one region must be selected');
            return;
        }

        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedRegions(selectedOptions);
        } else {
            setSelectedRegions([selectedOptions]);
        }
    };


    const handleViewChange = (option) => {
        setSelectedView(option);

        if(option.value === 'Team') {
            const deptValuesWithoutAll = deptValues.filter(dept => dept !== 'All');
            setDeptValues(deptValuesWithoutAll);
            setSelectedDepartments([{value: 'Sales', label: 'Sales'}]);

            if (seniorityValues.includes('All') === false) {
                setSeniorityValues(['All', ...seniorityValues]);
            }
            setSelectedSeniorities([{value: 'All', label: 'All'}]);

            if (geo1Values.includes('All') === false) {
                setGeo1Values(['All', ...geo1Values]);
            }
            setSelectedRegions([{value: 'All', label: 'All'}]);
        }
        else if(option.value === 'Seniority') {
            const seniorityValuesWithoutAll = seniorityValues.filter(seniority => seniority !== 'All');
            setSeniorityValues(seniorityValuesWithoutAll);
            setSelectedSeniorities([{value: 'Individual Contributor', label: 'Individual Contributor'}]);

            if (deptValues.includes('All') === false) {
                setDeptValues(['All', ...deptValues]);
            }
            setSelectedDepartments([{value: 'All', label: 'All'}]);

            if (geo1Values.includes('All') === false) {
                setGeo1Values(['All', ...geo1Values]);
            }
            setSelectedRegions([{value: 'All', label: 'All'}]);
        }
        else if(option.value === 'Region') {
            const geoValuesWithoutAll = geo1Values.filter(geo => geo !== 'All');
            setGeo1Values(geoValuesWithoutAll);
            setSelectedRegions([{value: 'North America', label: 'North America'}]);

            if (deptValues.includes('All') === false) {
                setDeptValues(['All', ...deptValues]);
            }
            setSelectedDepartments([{value: 'All', label: 'All'}]);

            if (seniorityValues.includes('All') === false) {
                setSeniorityValues(['All', ...seniorityValues]);
            }
            setSelectedSeniorities([{value: 'All', label: 'All'}]);
        }

    };

    //////////////////////////////
    // Headcount By Department Line Chart //
    function getHeadcountByDepartmentOptions() {
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        let filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority) && item.department !== 'All'; // Filtering out 'All' here
        });

        const deptDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            if (deptDataMap.hasOwnProperty(filteredTeamsDepartmentData[i].department)) {
                const data = [filteredTeamsDepartmentData[i].the_date, filteredTeamsDepartmentData[i].total_headcount];
                deptDataMap[filteredTeamsDepartmentData[i].department].push(data);
                continue;
            }
            deptDataMap[filteredTeamsDepartmentData[i].department] = [];
            const data = [filteredTeamsDepartmentData[i].the_date, filteredTeamsDepartmentData[i].total_headcount];
            deptDataMap[filteredTeamsDepartmentData[i].department].push(data);
        };

        // const legendOrder = ['All', 'Sales', 'Product'];
        const legendOrder = ['All', 'Sales', 'Marketing', 'Product', 'Engineering', 'Consulting', 'Operations', 'Finance', 'Human Resources', 'Administrative', 'Information Technology', 'Customer Success & Support', 'Other'];
        legendOrder.reverse();

        // Sort based on legendOrder, then alphabetically, and 'Unsure' is always last
        const sortedDeptDataMap = Object.entries(deptDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Unsure') return 1;
                if (keyB === 'Unsure') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        for (let key in sortedDeptDataMap) {
            dataSeries.push({
                name: key,
                data: sortedDeptDataMap[key],
                type: 'column',
                color: lineColors[key],
                pointWidth:20,
                stacking: showStacked === true ? 'percent' : 'normal',
                opacity: (selectedDepartmentValues.length ===1 && selectedDepartmentValues[0] === "All") ? 1 : selectedDepartmentValues.includes(key) ? 1 : 0.8,
            });
        };

		return {
			chart: {
				zoomType: 'xy',
                //type: 'column',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Total Headcount by Department (Public Profiles)' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: showStacked ? 'Department Distribution' : 'Total Headcount (Public Profiles)'
				},
                labels: {
                    formatter: function() {
                        if (showStacked) {
                            return this.value + '%';
                        }
                        else {
                            if (this.value >= 1000) {
                                return (this.value/1000) + 'k';
                            }
                            else if ((this.value >= 1000000)) {
                                return (this.value/1000000) + 'M';
                            }
                        }
                    },
                },
			},
			tooltip: {
                pointFormatter: function() {
                    if (showStacked) {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                    }
                    else {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                    }
                },
                shared: true,
            },
			series: dataSeries,
            legend: {
                enabled: true,
                reversed: true,
            },
			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};
		
	};

    //////////////////////////////
    // Headcount By Seniority Line Chart //
    function getHeadcountBySeniorityOptions() {
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        let filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && item.seniority !== 'All'; // Filtering out 'All' here
        });

        const seniorityDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            if (seniorityDataMap.hasOwnProperty(filteredTeamsSeniorityData[i].seniority)) {
                const data = [filteredTeamsSeniorityData[i].the_date, filteredTeamsSeniorityData[i].total_headcount];
                seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(data);
                continue;
            }
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = [];
            const data = [filteredTeamsSeniorityData[i].the_date, filteredTeamsSeniorityData[i].total_headcount];
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(data);
        };

        const legendOrder = [
            "Advisor / Board",
            "Executive",
            "Leadership",
            "Management",
            "Individual Contributor",
            "Student / Intern",
            "Unsure"
        ];
        legendOrder.reverse();
        
        const sortedSeniorityDataMaps = Object.entries(seniorityDataMap)
            .sort(([keyA], [keyB]) => {
                const indexA = legendOrder.indexOf(keyA);
                const indexB = legendOrder.indexOf(keyB);
                return indexA - indexB;
            })
            .map(([key, value]) => ({
                name: key,
                data: value,
                type: 'column',
                color: lineColors[key],
                pointWidth:20,
                stacking: showStacked === true ? 'percent' : 'normal',
                opacity: (selectedSeniorityValues.length === 1 && selectedSeniorityValues[0] === "All") ? 1 : selectedSeniorityValues.includes(key) ? 1 : 0.7,
            }));
        
        dataSeries.push(...sortedSeniorityDataMaps);

        return {
			chart: {
				zoomType: 'xy',
                // type: 'column',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Total Headcount by Seniority (Public Profiles)' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: showStacked ? 'Seniority Distribution' : 'Total Headcount (Public Profiles)'
				},
                labels: {
                    formatter: function() {
                        if (showStacked) {
                            return this.value + '%';
                        }
                        else {
                            if (this.value >= 1000) {
                                return (this.value/1000) + 'k';
                            }
                            else if ((this.value >= 1000000)) {
                                return (this.value/1000000) + 'M';
                            }
                        }
                    },
                },
			},
            tooltip: {
                pointFormatter: function() {
                    if (showStacked) {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                    }
                    else {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                    }
                },
                shared: true,
            },
			series: dataSeries,
            legend: {
                enabled: true,
                reversed: true,
            },
			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
    };

    //////////////////////////////
    // Headcount By Geo Line Chart //
    function getHeadcountByGeoOptions () {
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        let filteredTeamsGeoData = teamsGrowthData.filter((item) => {
            return item.geo1 !== 'All' && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority); // Filtering out 'All' here
        });

        const geoDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsGeoData.length; i++) {
            if (geoDataMap.hasOwnProperty(filteredTeamsGeoData[i].geo1)) {
                const data = [filteredTeamsGeoData[i].the_date, filteredTeamsGeoData[i].total_headcount];
                geoDataMap[filteredTeamsGeoData[i].geo1].push(data);
                continue;
            }
            geoDataMap[filteredTeamsGeoData[i].geo1] = [];
            const data = [filteredTeamsGeoData[i].the_date, filteredTeamsGeoData[i].total_headcount];
            geoDataMap[filteredTeamsGeoData[i].geo1].push(data);
        };

        const legendOrder = [ "All", "North America", "Europe, Middle East & Africa", "Asia/Pacific", "Latin America", "Other", "N/A" ];
        legendOrder.reverse();
        
        const sortedGeoDataMap = Object.entries(geoDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'N/A') return 1;
                if (keyB === 'N/A') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .map(([key, value]) => ({
                name: key,
                data: value,
                type: 'column',
                color: lineColors[key],
                pointWidth:20,
                stacking: showStacked === true ? 'percent' : 'normal',
                opacity: (selectedRegionValues.length === 1 && selectedRegionValues[0] === "All") ? 1 : selectedRegionValues.includes(key) ? 1 : 0.7,
            }));
        
        dataSeries.push(...sortedGeoDataMap);

        return {
			chart: {
				zoomType: 'xy',
                //type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Total Headcount by Region (Public Profiles)' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: showStacked ? 'Region Distribution' : 'Total Headcount (Public Profiles)'
				},
                labels: {
                    formatter: function() {
                        if (showStacked) {
                            return this.value + '%';
                        }
                        else {
                            if (this.value >= 1000) {
                                return (this.value/1000) + 'k';
                            }
                            else if ((this.value >= 1000000)) {
                                return (this.value/1000000) + 'M';
                            }
                        }
                    },
                },
			},
            tooltip: {
                pointFormatter: function() {
                    if (showStacked) {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                    }
                    else {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                    }
                },
                shared: true,
            },

			series: dataSeries,
            legend: {
                enabled: true,
                reversed: true,
            },
			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
    };

    //////////////////////////////
    // Department Distribution Pie Chart //
    function getDeptPieChartOptions() {
        let date = new Date(deptDistDataDate);
        let month = date.getUTCMonth();
        let year = date.getUTCFullYear();
        
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        let filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            let dateCondition = itemDate.getUTCMonth() === month && itemDate.getUTCFullYear() === year
            return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority) && dateCondition && item.department !== 'All'; // Filter out 'All' from the dataseries;
        });

        const deptDataMap = {};
        let totalHeadcount = 0;
        const dataSeries = [];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            totalHeadcount += filteredTeamsDepartmentData[i].total_headcount;
            deptDataMap[filteredTeamsDepartmentData[i].department] = filteredTeamsDepartmentData[i].total_headcount;
        };
        
        // Filter out 'All' from the dataseries
        for (let key in deptDataMap) {
            dataSeries.push({
                name: key,
                y: (deptDataMap[key] / totalHeadcount) * 100,
                color: lineColors[key],
                opacity: (selectedDepartmentValues.length === 1 && selectedDepartmentValues[0] === "All") ? 1 : selectedDepartmentValues.includes(key) ? 1: 0.8,
            });
        }

        // Sort the data series in descending order based on 'y' value, but keep "Unsure" at the end
        dataSeries.sort((a, b) => {
            // If a is "Unsure", it should come last - return 1
            if (a.name === "Unsure") return 1;
            // If b is "Unsure", it should come last - return -1
            if (b.name === "Unsure") return -1;
            // Otherwise, sort normally
            return b.y - a.y;
        });

        return {
            chart: {
                type: 'pie',
                // marginRight: 170,
            },
            title: {
                text: `Department Distribution ${date.toLocaleString('default', { month: 'long' })} ${year}`,
            },
            subtitle: {
				text: 'Click on the growth chart to the left to update distribution for any particular time',
				align: 'center'
			},
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '80%',
                    dataLabels: {
                        enabled: true,
                        distance: -40,  // Negative values pull the labels closer to the pie, positive values push them further away
                        formatter: function() {
                            // Check if the current point is in the top 5
                            if (this.point.index < 5) {
                                return this.point.name;
                            }
                        }
                        
                    },
                    // showInLegend: true,
                },
            },

            series: [{
                name: 'Headcount dist',
                colorByPoint: true,
                data: dataSeries,
            }],

            credits: {
				enabled: false
			},
        };

    };

    //////////////////////////////
    // Seniority Distribution Pie Chart //
    function getSeniorityPieChartOptions() {
        let date = new Date(seniorityDistDataDate);
        let month = date.getUTCMonth();
        let year = date.getUTCFullYear();

        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        let filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            let dateCondition = itemDate.getUTCMonth() === month && itemDate.getUTCFullYear() === year
            return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && dateCondition && item.seniority !== 'All'; // Filter out 'All' from the dataseries
        });

        const seniorityDataMap = {};
        let totalHeadcount = 0;
        const dataSeries = [];
        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            totalHeadcount += filteredTeamsSeniorityData[i].total_headcount;
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = filteredTeamsSeniorityData[i].total_headcount;
        };
        
        for (let key in seniorityDataMap) {
            dataSeries.push({
                name: key,
                y: (seniorityDataMap[key] / totalHeadcount) * 100,
                color: lineColors[key],
                opacity: (selectedSeniorityValues.length === 1 && selectedSeniorityValues[0] === "All") ? 1 : selectedSeniorityValues.includes(key) ? 1: 0.3,
            });
        }

        // Sort the data series in descending order based on 'y' value, but keep "Unsure" at the end
        dataSeries.sort((a, b) => {
            // If a is "Unsure", it should come last - return 1
            if (a.name === "Unsure") return 1;
            // If b is "Unsure", it should come last - return -1
            if (b.name === "Unsure") return -1;
            // Otherwise, sort normally
            return b.y - a.y;
        });
  
        return {
            chart: {
                type: 'pie'
            },
            title: {
                text: `Seniority Distribution ${date.toLocaleString('default', { month: 'long' })} ${year}`,
            },
            subtitle: {
				text: 'Click on the growth chart to the left to update distribution for any particular time',
				align: 'center'
			},
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '80%',
                    dataLabels: {
                        enabled: true,
                        distance: -50,  // Negative values pull the labels closer to the pie, positive values push them further away
                        formatter: function() {
                            // Check if the current point is in the top 5
                            if (this.point.index < 3) {
                                return this.point.name;
                            }
                        }
                        
                    },
                    // showInLegend: true,
                },
            },

            series: [{
                name: 'Headcount %',
                colorByPoint: true,
                data: dataSeries,
            }],

            credits: {
				enabled: false
			},
        };

    };

    //////////////////////////////
    // Geo Distribution Pie Chart //
    function getGeoPieChartOptions() {
        let date = new Date(geo1DistDataDate);
        let month = date.getUTCMonth();
        let year = date.getUTCFullYear();

        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        let filteredTeamsGeo1Data = teamsGrowthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            let dateCondition = itemDate.getUTCMonth() === month && itemDate.getUTCFullYear() === year
            return selectedSeniorityValues.includes(item.seniority) && selectedDepartmentValues.includes(item.department) && dateCondition && item.geo1 !== 'All'; // Filter out 'All' from the dataseries
        });

        const geo1DataMap = {};
        let totalHeadcount = 0;
        const dataSeries = [];
        for (let i=0; i<filteredTeamsGeo1Data.length; i++) {
            totalHeadcount += filteredTeamsGeo1Data[i].total_headcount;
            geo1DataMap[filteredTeamsGeo1Data[i].geo1] = filteredTeamsGeo1Data[i].total_headcount;
        };
        
        for (let key in geo1DataMap) {
            dataSeries.push({
                name: key,
                y: (geo1DataMap[key] / totalHeadcount) * 100,
                color: lineColors[key],
                opacity: (selectedRegionValues.length === 1 && selectedRegionValues[0] === "All") ? 1 : selectedRegionValues.includes(key) ? 1: 0.3,
            });
        }

        // Sort the data series in descending order based on 'y' value, but keep "Unsure" at the end
        dataSeries.sort((a, b) => {
            return b.y - a.y;
        });
  
        return {
            chart: {
                type: 'pie'
            },
            title: {
                text: `Region Distribution ${date.toLocaleString('default', { month: 'long' })} ${year}`,
            },
            subtitle: {
				text: 'Click on the growth chart to the left to update distribution for any particular time',
				align: 'center'
			},
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '80%',
                    dataLabels: {
                        enabled: true,
                        distance: -50,  // Negative values pull the labels closer to the pie, positive values push them further away
                        formatter: function() {
                            // Check if the current point is in the top 5
                            if (this.point.index < 3) {
                                return this.point.name;
                            }
                        }
                        
                    },
                    // showInLegend: true,
                },
            },

            series: [{
                name: 'Headcount %',
                colorByPoint: true,
                data: dataSeries,
            }],

            credits: {
				enabled: false
			},
        };

    };

    //////////////////////////////
    // Team Growth By Seniority Line Chart //
    function getTeamGrowthBySeniorityOptions() { 
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        const filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            // if (selectedSeniorityValues.length === 1 && selectedSeniorityValues[0] === 'All') {
            //     return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department);
            // }
            return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority) && selectedDepartmentValues.includes(item.department);
        });

        if (showAll) {
            // add metrics for 'All'
            filteredTeamsSeniorityData.push(...teamsGrowthData.filter((item) => {
                return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && item.seniority == 'All';
            }));
        }

        const seniorityDataMap = {};
        const dataSeries = [];

        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            const teamData = filteredTeamsSeniorityData[i];
            if (seniorityDataMap.hasOwnProperty(filteredTeamsSeniorityData[i].seniority)) {
                let dataItem = {
                    the_date: teamData.the_date,
                };
                if (showHiringForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_addition_rate: parseFloat((teamData.ltm_addition_rate * 100).toFixed(1)),
                    };
                }
                if (showAttritionForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_attrition_rate: parseFloat((teamData.ltm_attrition_rate * 100).toFixed(1)),
                    };
                }
                if (showNetGrowthForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_net_hc_growth: parseFloat((teamData.ltm_net_hc_growth * 100).toFixed(1)),
                    };
                }
                if (showGrowthProdForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_growth_productivity: parseFloat((teamData.ltm_growth_productivity * 100).toFixed(1)),
                    };
                }

                seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(dataItem);
                continue;
            }
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = [];
            let dataItem = {
                the_date: teamData.the_date,
            };
            if (showHiringForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_addition_rate: parseFloat((teamData.ltm_addition_rate * 100).toFixed(1)),
                };
            }
            if (showAttritionForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_attrition_rate: parseFloat((teamData.ltm_attrition_rate * 100).toFixed(1)),
                };
            }
            if (showNetGrowthForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_net_hc_growth: parseFloat((teamData.ltm_net_hc_growth * 100).toFixed(1)),
                };
            }
            if (showGrowthProdForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_growth_productivity: parseFloat((teamData.ltm_growth_productivity * 100).toFixed(1)),
                };
            }

            seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(dataItem);
        };

        const legendOrder = [
            "All",
            "Individual Contributor",
            "Advisor / Board",
            "Executive",
            "Leadership",
            "Management",
            "Student / Intern",
            "Unsure"
        ];

        let metricData = [];
        if (showHiringForGrowthChart) {
            metricData = [...metricData, 'ltm_addition_rate'];
        }
        if (showAttritionForGrowthChart) {
            metricData = [...metricData, 'ltm_attrition_rate'];
        }
        if (showNetGrowthForGrowthChart) {
            metricData = [...metricData, 'ltm_net_hc_growth'];
        }
        if (showGrowthProdForGrowthChart) {
            metricData = [...metricData, 'ltm_growth_productivity'];
        }

        const sortedSeniorityDataMaps = Object.entries(seniorityDataMap)
            .sort(([keyA], [keyB]) => {
                const indexA = legendOrder.indexOf(keyA);
                const indexB = legendOrder.indexOf(keyB);
                return indexA - indexB;
            })
            .reduce((acc, [key, value]) => {
                metricData.forEach(metric => {
                    // Define the conditions for default visibility
                    const isVisibleSeniority = (key === 'All' || key === 'Individual Contributor' || selectedSeniorityValues.includes(key));
                    const isVisible = (
                        (metric === 'ltm_net_hc_growth' && isVisibleSeniority && showNetGrowthForGrowthChart) ||
                        (metric === 'ltm_avg_net_hc_growth_benchmark' && showIndustry) ||
                        (metric === 'ltm_addition_rate' && isVisibleSeniority && showHiringForGrowthChart) ||
                        (metric === 'ltm_avg_addition_rate_benchmark' && showIndustry) ||
                        (metric === 'ltm_attrition_rate' && isVisibleSeniority && showAttritionForGrowthChart) ||
                        (metric === 'ltm_avg_attrition_rate_benchmark' && showIndustry) ||
                        (metric === 'ltm_growth_productivity' && isVisibleSeniority && showGrowthProdForGrowthChart) ||
                        (metric === 'ltm_avg_growth_productivity_benchmark' && showIndustry)
                    );
                    acc.push({
                        name: `${getFriendlyMetricName(metric)}: ${key}`,
                        data: value.map(item => [new Date(item.the_date).getTime(), item[metric]]),
                        type: 'spline',
                        color: (showIndustry && metric.includes('_avg_')) ? benchmarkLineColors[key] : lineColors[key],
                        dashStyle: lineStyles[getFriendlyMetricName(metric)],
                        visible: isVisible
                    });
                });
                return acc;
            }, []);

        dataSeries.push(...sortedSeniorityDataMaps);

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Team Growth By Seniority',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
            yAxis: {
				labels: {
					format: '{value}%'
				},
                title:{
                    text: 'Rate (Last 12 Months)'
                }
			},

            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setSeniorityDistDataDate(dateStr);
                            }
                        }
                    },
                    events: {
                        // afterAnimate: function() {
                        //     if (showIndustry) {
                        //         // this view has no benchmark data
                        //         let chart = this.chart;
                        //         chart.renderer.text('Benchmark data not available', 500, 25)
                        //         .css({
                        //             fontSize: '14px',
                        //             color: 'gray',
                        //         })
                        //         .add();
                        //     }
                        // }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};

    };

    //////////////////////////////
    // Team Growth By Department Line Chart //
    function getTeamGrowthByDepartmentOptions() { 
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        const filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            // if (selectedDepartmentValues.length === 1 && selectedDepartmentValues[0] === 'All') {
            //     return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority);
            // }
            return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
        });

        if(showAll) {
            // add metrics for 'All'
            filteredTeamsDepartmentData.push(...teamsGrowthData.filter((item) => {
                return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority) && item.department == 'All';
            }));
        }

        const deptDataMap = {};
        const dataSeries = [];
        const displayBenchmark = (selectedRegionValues.length === 1 && selectedRegionValues[0] === 'All') && (selectedSeniorityValues.length === 1 && selectedSeniorityValues[0] === 'All');

        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            const teamData = filteredTeamsDepartmentData[i];
            if (deptDataMap.hasOwnProperty(teamData.department)) {
                let dataItem = {
                    the_date: teamData.the_date,
                };
                if (showHiringForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_addition_rate: parseFloat((teamData.ltm_addition_rate * 100).toFixed(1)),
                    };
                }
                if (showAttritionForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_attrition_rate: parseFloat((teamData.ltm_attrition_rate * 100).toFixed(1)),
                    };
                }
                if (showNetGrowthForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_net_hc_growth: parseFloat((teamData.ltm_net_hc_growth * 100).toFixed(1)),
                    };
                }
                if (showGrowthProdForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_growth_productivity: parseFloat((teamData.ltm_growth_productivity * 100).toFixed(1)),
                    };
                }

                if (showIndustry && displayBenchmark) {
                    // add metrics for industry benhcmark
                    dataItem = {
                        ...dataItem,
                        ltm_avg_net_hc_growth_benchmark: parseFloat((teamData.avg_net_hc_growth_prev_12_mo_benchmark * 100).toFixed(1)),
                        ltm_avg_growth_productivity_benchmark: parseFloat((teamData.avg_growth_prod_prev_12_mo_benchmark * 100).toFixed(1)),
                        ltm_avg_addition_rate_benchmark: parseFloat((teamData.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(1)),
                        ltm_avg_attrition_rate_benchmark: parseFloat((teamData.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(1)),
                    };
                }

                deptDataMap[teamData.department].push(dataItem);

                continue;
            }
            deptDataMap[teamData.department] = [];
            let dataItem = {
                the_date: teamData.the_date,
            };
            if (showHiringForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_addition_rate: parseFloat((teamData.ltm_addition_rate * 100).toFixed(1)),
                };
            }
            if (showAttritionForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_attrition_rate: parseFloat((teamData.ltm_attrition_rate * 100).toFixed(1)),
                };
            }
            if (showNetGrowthForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_net_hc_growth: parseFloat((teamData.ltm_net_hc_growth * 100).toFixed(1)),
                };
            }
            if (showGrowthProdForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_growth_productivity: parseFloat((teamData.ltm_growth_productivity * 100).toFixed(1)),
                };
            }

            if (showIndustry && displayBenchmark) {
                // add metrics for industry benhcmark
                dataItem = {
                    ...dataItem,
                    ltm_avg_net_hc_growth_benchmark: parseFloat((teamData.avg_net_hc_growth_prev_12_mo_benchmark * 100).toFixed(1)),
                    ltm_avg_growth_productivity_benchmark: parseFloat((teamData.avg_growth_prod_prev_12_mo_benchmark * 100).toFixed(1)),
                    ltm_avg_addition_rate_benchmark: parseFloat((teamData.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(1)),
                    ltm_avg_attrition_rate_benchmark: parseFloat((teamData.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(1)),
                };
            }

            deptDataMap[teamData.department].push(dataItem);
        };
        
        console.log(deptDataMap);
        const legendOrder = ['All', 'Sales', 'Product'];
    
        // Sort based on legendOrder, then alphabetically, and 'Unsure' is always last
        const sortedDeptDataMap = Object.entries(deptDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Unsure') return 1;
                if (keyB === 'Unsure') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    
        let metricData = [];
        if (showHiringForGrowthChart) {
            metricData = [...metricData, 'ltm_addition_rate'];
            if (showIndustry) {
                metricData = [...metricData, 'ltm_avg_addition_rate_benchmark'];
            }
        }
        if (showAttritionForGrowthChart) {
            metricData = [...metricData, 'ltm_attrition_rate'];
            if (showIndustry) {
                metricData = [...metricData, 'ltm_avg_attrition_rate_benchmark'];
            }
        }
        if (showNetGrowthForGrowthChart) {
            metricData = [...metricData, 'ltm_net_hc_growth'];
            if (showIndustry) {
                metricData = [...metricData, 'ltm_avg_net_hc_growth_benchmark'];
            }
        }
        if (showGrowthProdForGrowthChart) {
            metricData = [...metricData, 'ltm_growth_productivity'];
            if (showIndustry) {
                metricData = [...metricData, 'ltm_avg_growth_productivity_benchmark'];
            }
        }

        for (let key in sortedDeptDataMap) {
            metricData.forEach(metric => {
                // Define the conditions for default visibility
                const isVisibleDept = (key === 'All' || key === 'Sales' || selectedDepartmentValues.includes(key));
                const isVisible = (
                    (metric === 'ltm_net_hc_growth' && isVisibleDept && showNetGrowthForGrowthChart) ||
                    (metric === 'ltm_avg_net_hc_growth_benchmark' && showIndustry) ||
                    (metric === 'ltm_addition_rate' && isVisibleDept && showHiringForGrowthChart) ||
                    (metric === 'ltm_avg_addition_rate_benchmark' && showIndustry) ||
                    (metric === 'ltm_attrition_rate' && isVisibleDept && showAttritionForGrowthChart) ||
                    (metric === 'ltm_avg_attrition_rate_benchmark' && showIndustry) ||
                    (metric === 'ltm_growth_productivity' && isVisibleDept && showGrowthProdForGrowthChart) ||
                    (metric === 'ltm_avg_growth_productivity_benchmark' && showIndustry)
                );
                // const isVisible = (metric === 'ltm_growth_productivity' && isVisibleDept);
                dataSeries.push({
                    name: `${getFriendlyMetricName(metric)}: ${key}`,
                    data: sortedDeptDataMap[key].map((item) => [new Date(item.the_date).getTime(), item[metric]]),
                    type: 'spline',
                    color: (showIndustry && metric.includes('_avg_')) ? benchmarkLineColors[key] : lineColors[key],
                    dashStyle: lineStyles[getFriendlyMetricName(metric)],
                    visible: isVisible,
                });
            });            
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Team Growth By Department',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				labels: {
					format: '{value}%'
				},
                title:{
                    text: 'Rate (Last 12 Months)'
                }
			},

            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },

			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setDeptDistDataDate(dateStr);
                            }
                        }
                    },
                    events: {
                        // afterAnimate: function() {
                        //     if (!displayBenchmark) {
                        //         let chart = this.chart;
                        //         chart.renderer.text('Benchmark data not available', 500, 25)
                        //         .css({
                        //             fontSize: '14px',
                        //             color: 'gray',
                        //         })
                        //         .add();
                        //     }
                        // }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};

    };

    //////////////////////////////
    // Team Growth By Geo Line Chart //
    function getTeamGrowthByGeoOptions() {
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        const filteredTeamsGeoData = teamsGrowthData.filter((item) => {
            // if (selectedRegionValues.length === 1 && selectedRegionValues[0] === 'All') {
            //     return selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
            // }
            return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
        });

        if(showAll) {
            // add metrics for 'All'
            filteredTeamsGeoData.push(...teamsGrowthData.filter((item) => {
                return item.geo1 === 'All' && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
            }));
        }
        
        const geoDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsGeoData.length; i++) {
            const geoData = filteredTeamsGeoData[i];
            if (geoDataMap.hasOwnProperty(geoData.geo1)) {
                let dataItem = {
                    the_date: geoData.the_date,
                };
                if (showHiringForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_addition_rate: parseFloat((geoData.ltm_addition_rate * 100).toFixed(1)),
                    };
                }
                if (showAttritionForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_attrition_rate: parseFloat((geoData.ltm_attrition_rate * 100).toFixed(1)),
                    };
                }
                if (showNetGrowthForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_net_hc_growth: parseFloat((geoData.ltm_net_hc_growth * 100).toFixed(1)),
                    };
                }
                if (showGrowthProdForGrowthChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_growth_productivity: parseFloat((geoData.ltm_growth_productivity * 100).toFixed(1)),
                    };
                }

                geoDataMap[geoData.geo1].push(dataItem);

                continue;
            }
            geoDataMap[geoData.geo1] = [];
            let dataItem = {
                the_date: geoData.the_date,
            };
            if (showHiringForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_addition_rate: parseFloat((geoData.ltm_addition_rate * 100).toFixed(1)),
                };
            }
            if (showAttritionForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_attrition_rate: parseFloat((geoData.ltm_attrition_rate * 100).toFixed(1)),
                };
            }
            if (showNetGrowthForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_net_hc_growth: parseFloat((geoData.ltm_net_hc_growth * 100).toFixed(1)),
                };
            }
            if (showGrowthProdForGrowthChart) {
                dataItem = {
                    ...dataItem,
                    ltm_growth_productivity: parseFloat((geoData.ltm_growth_productivity * 100).toFixed(1)),
                };
            }

            geoDataMap[geoData.geo1].push(dataItem);
        };

        const legendOrder = ['All', 'North America', 'Europe, Middle East & Africa'];
    
        // Sort based on legendOrder, then alphabetically, and 'N/A' is always last
        const sortedGeoDataMap = Object.entries(geoDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'N/A') return 1;
                if (keyB === 'N/A') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        let metricData = [];
        if (showHiringForGrowthChart) {
            metricData = [...metricData, 'ltm_addition_rate'];
        }
        if (showAttritionForGrowthChart) {
            metricData = [...metricData, 'ltm_attrition_rate'];
        }
        if (showNetGrowthForGrowthChart) {
            metricData = [...metricData, 'ltm_net_hc_growth'];
        }
        if (showGrowthProdForGrowthChart) {
            metricData = [...metricData, 'ltm_growth_productivity'];
        }
        
        for (let key in sortedGeoDataMap) {
            metricData.forEach(metric => {
                // Define the conditions for default visibility
                const isVisibleGeo = (key === 'All' || key === 'North America' || selectedRegionValues.includes(key));
                const isVisible = (
                    (metric === 'ltm_net_hc_growth' && isVisibleGeo && showNetGrowthForGrowthChart) ||
                    (metric === 'ltm_avg_net_hc_growth_benchmark' && showIndustry) ||
                    (metric === 'ltm_addition_rate' && isVisibleGeo && showHiringForGrowthChart) ||
                    (metric === 'ltm_avg_addition_rate_benchmark' && showIndustry) ||
                    (metric === 'ltm_attrition_rate' && isVisibleGeo && showAttritionForGrowthChart) ||
                    (metric === 'ltm_avg_attrition_rate_benchmark' && showIndustry) ||
                    (metric === 'ltm_growth_productivity' && isVisibleGeo && showGrowthProdForGrowthChart) ||
                    (metric === 'ltm_avg_growth_productivity_benchmark' && showIndustry)
                );
                dataSeries.push({
                    name: `${getFriendlyMetricName(metric)}: ${key}`,
                    data: sortedGeoDataMap[key].map((item) => [new Date(item.the_date).getTime(), item[metric]]),
                    type: 'spline',
                    color: (showIndustry && metric.includes('_avg_')) ? benchmarkLineColors[key] : lineColors[key],
                    dashStyle: lineStyles[getFriendlyMetricName(metric)],
                    visible: isVisible
                });
            });            
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Team Growth By Region',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
            yAxis: {
				labels: {
					format: '{value}%'
				},
                title:{
                    text: 'Rate (Last 12 Months)'
                }
			},

            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setGeo1DistDataDate(dateStr);
                            }
                        }
                    },
                    events: {
                        afterAnimate: function() {
                            // if (showIndustry) {
                            //     // this view has no benchmark data
                            //     let chart = this.chart;
                            //     chart.renderer.text('Benchmark data not available', 500, 25)
                            //     .css({
                            //         fontSize: '14px',
                            //         color: 'gray',
                            //     })
                            //     .add();
                            // }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    //////////////////////////////
    // Gender Probability By Seniority Line Chart //
    function getGenderProbabilityBySeniorityOptions() { 
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        const filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            if (selectedSeniorityValues.length === 1 && selectedSeniorityValues[0] === 'All') {
                return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department);
            }
            return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority) && selectedDepartmentValues.includes(item.department);
        });

        if(showAll) {
            // add metrics for 'All'
            filteredTeamsSeniorityData.push(...teamsGrowthData.filter((item) => {
                return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && item.seniority == 'All';
            }));
        }

        const seniorityDataMap = {};
        const dataSeries = [];

        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            const teamData = filteredTeamsSeniorityData[i];
            if (seniorityDataMap.hasOwnProperty(filteredTeamsSeniorityData[i].seniority)) {
                let dataItem = {
                    the_date: teamData.the_date,
                }
                if (showHiringForGenderChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_addition_rate_female: parseFloat((teamData.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                    }
                }
                if (showAttritionForGenderChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_attrition_rate_female: parseFloat((teamData.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                    }
                }
                if (showCompanyPercentageForGenderChart) {  
                    dataItem = {
                        ...dataItem,
                        ltm_company_pct_female: parseFloat((teamData.company_hc_total_avg_f_prob * 100).toFixed(1)),
                    }   
                }
                seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(dataItem);
                continue;
            }
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = [];
            let dataItem = {
                the_date: teamData.the_date,
            }
            if (showHiringForGenderChart) {
                dataItem = {
                    ...dataItem,
                    ltm_addition_rate_female: parseFloat((teamData.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                }
            }
            if (showAttritionForGenderChart) {
                dataItem = {
                    ...dataItem,
                    ltm_attrition_rate_female: parseFloat((teamData.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                }
            }
            if (showCompanyPercentageForGenderChart) {  
                dataItem = {
                    ...dataItem,
                    ltm_company_pct_female: parseFloat((teamData.company_hc_total_avg_f_prob * 100).toFixed(1)),
                }   
            }
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(dataItem);
        };



        const legendOrder = [
            "All",
            "Individual Contributor",
            "Advisor / Board",
            "Executive",
            "Leadership",
            "Management",
            "Student / Intern",
            "Unsure"
        ];

        let metricData = [];
        if (showHiringForGenderChart) {
            metricData = [...metricData, 'ltm_addition_rate_female'];
        }
        if (showAttritionForGenderChart) {
            metricData = [...metricData, 'ltm_attrition_rate_female'];
        }
        if (showCompanyPercentageForGenderChart) {
            metricData = [...metricData, 'ltm_company_pct_female'];
        }

        const sortedSeniorityDataMaps = Object.entries(seniorityDataMap)
            .sort(([keyA], [keyB]) => {
                const indexA = legendOrder.indexOf(keyA);
                const indexB = legendOrder.indexOf(keyB);
                return indexA - indexB;
            })
            .reduce((acc, [key, value]) => {
                metricData.forEach(metric => {
                // Define the conditions for default visibility
                    const isVisibleSeniority = (key === 'All' || key === 'Individual Contributor' || selectedSeniorityValues.includes(key));
                    const isVisible = (
                        (metric === 'ltm_company_pct_female' && isVisibleSeniority && showCompanyPercentageForGenderChart) ||
                        (metric === 'ltm_addition_rate_female' && isVisibleSeniority && showHiringForGenderChart) ||
                        (metric === 'ltm_attrition_rate_female' && isVisibleSeniority && showAttritionForGenderChart)
                    );
                    acc.push({
                        name: `${getFriendlyMetricName(metric)}: ${key}`,
                        data: value.map(item => [new Date(item.the_date).getTime(), item[metric]]),
                        type: 'spline',
                        color: lineColors[key],
                        dashStyle: lineStyles[getFriendlyMetricName(metric)],
                        visible: isVisible
                    });
                });
                return acc;
            }, []);

        dataSeries.push(...sortedSeniorityDataMaps);

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Gender Probability By Seniority',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
            yAxis: {
				labels: {
					format: '{value}%'
				},
                title: {
                    text: 'Percentage Female'
                },
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
			
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setSeniorityDistDataDate(dateStr);
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};

    };

    //////////////////////////////
    // Gender Probability by Department Line Chart //
    function getGenderProbabilityByDepartmentOptions() {
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        const filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            if (selectedDepartmentValues.length === 1 && selectedDepartmentValues[0] === 'All') {
                return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority);
            }
            return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
        });

        if(showAll) {
            // add metrics for 'All'
            filteredTeamsDepartmentData.push(...teamsGrowthData.filter((item) => {
                return selectedRegionValues.includes(item.geo1) && selectedSeniorityValues.includes(item.seniority) && item.department == 'All';
            }));
        }
        
        const deptDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            const teamData = filteredTeamsDepartmentData[i];
            if (deptDataMap.hasOwnProperty(teamData.department)) {
                let dataItem = {
                    the_date: teamData.the_date,
                }
                if (showHiringForGenderChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_addition_rate_female: parseFloat((teamData.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                    }
                }
                if (showAttritionForGenderChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_attrition_rate_female: parseFloat((teamData.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                    }
                }
                if (showCompanyPercentageForGenderChart) {  
                    dataItem = {
                        ...dataItem,
                        ltm_company_pct_female: parseFloat((teamData.company_hc_total_avg_f_prob * 100).toFixed(1)),
                    }   
                }
                deptDataMap[teamData.department].push(dataItem);
                continue;
            }
            deptDataMap[teamData.department] = [];
            let dataItem = {
                the_date: teamData.the_date,
            }
            if (showHiringForGenderChart) {
                dataItem = {
                    ...dataItem,
                    ltm_addition_rate_female: parseFloat((teamData.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                }
            }
            if (showAttritionForGenderChart) {
                dataItem = {
                    ...dataItem,
                    ltm_attrition_rate_female: parseFloat((teamData.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                }
            }
            if (showCompanyPercentageForGenderChart) {  
                dataItem = {
                    ...dataItem,
                    ltm_company_pct_female: parseFloat((teamData.company_hc_total_avg_f_prob * 100).toFixed(1)),
                }   
            }
            deptDataMap[teamData.department].push(dataItem);
        };
    
        const legendOrder = ['All', 'Sales', 'Product'];
    
        // Sort based on legendOrder, then alphabetically, and 'Unsure' is always last
        const sortedDeptDataMap = Object.entries(deptDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Unsure') return 1;
                if (keyB === 'Unsure') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        let metricData = [];
        if (showHiringForGenderChart) {
            metricData = [...metricData, 'ltm_addition_rate_female'];
        }
        if (showAttritionForGenderChart) {
            metricData = [...metricData, 'ltm_attrition_rate_female'];
        }
        if (showCompanyPercentageForGenderChart) {
            metricData = [...metricData, 'ltm_company_pct_female'];
        }

        for (let key in sortedDeptDataMap) {
            metricData.forEach(metric => {
                // Define the conditions for default visibility
                const isVisibleDept = (key === 'All' || key === 'Sales' || selectedDepartmentValues.includes(key));
                const isVisible = (
                    (metric === 'ltm_company_pct_female' && isVisibleDept && showCompanyPercentageForGenderChart) ||
                    (metric === 'ltm_addition_rate_female' && isVisibleDept && showHiringForGenderChart) ||
                    (metric === 'ltm_attrition_rate_female' && isVisibleDept && showAttritionForGenderChart)
                );
                dataSeries.push({
                    name: `${getFriendlyMetricName(metric)}: ${key}`,
                    data: sortedDeptDataMap[key].map((item) => [new Date(item.the_date).getTime(), item[metric]]),
                    type: 'spline',
                    color: lineColors[key],
                    dashStyle: lineStyles[getFriendlyMetricName(metric)],
                    visible: isVisible
                });
            });            
        }
            
        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Gender Probability By Department',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				labels: {
					format: '{value}%'
				},
                title: {
                    text: 'Percentage Female'
                },
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setDeptDistDataDate(dateStr);
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    //////////////////////////////
    // Gender Probability By Geo Line Chart //
    function getGenderProbabilityByGeoOptions() {
        let selectedRegionValues = selectedRegions.map(region => region.value);
        let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = selectedSeniorities.map(seniority => seniority.value);
        const filteredTeamsGeoData = teamsGrowthData.filter((item) => {
            if (selectedRegionValues.length === 1 && selectedRegionValues[0] === 'All') {
                return selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
            }
            return selectedRegionValues.includes(item.geo1) && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
        });


        if(showAll) {
            // add metrics for 'All'
            filteredTeamsGeoData.push(...teamsGrowthData.filter((item) => {
                return item.geo1 === 'All' && selectedDepartmentValues.includes(item.department) && selectedSeniorityValues.includes(item.seniority);
            }));
        }
        
        const geoDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsGeoData.length; i++) {
            const geoData = filteredTeamsGeoData[i];
            if (geoDataMap.hasOwnProperty(geoData.geo1)) {
                let dataItem = {
                    the_date: geoData.the_date,
                }
                if (showHiringForGenderChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_addition_rate_female: parseFloat((geoData.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                    }
                }
                if (showAttritionForGenderChart) {
                    dataItem = {
                        ...dataItem,
                        ltm_attrition_rate_female: parseFloat((geoData.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                    }
                }
                if (showCompanyPercentageForGenderChart) {  
                    dataItem = {
                        ...dataItem,
                        ltm_company_pct_female: parseFloat((geoData.company_hc_total_avg_f_prob * 100).toFixed(1)),
                    }   
                }
                geoDataMap[geoData.geo1].push(dataItem);
                continue;
            }
            geoDataMap[geoData.geo1] = [];
            let dataItem = {
                the_date: geoData.the_date,
            }
            if (showHiringForGenderChart) {
                dataItem = {
                    ...dataItem,
                    ltm_addition_rate_female: parseFloat((geoData.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                }
            }
            if (showAttritionForGenderChart) {
                dataItem = {
                    ...dataItem,
                    ltm_attrition_rate_female: parseFloat((geoData.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1)),
                }
            }
            if (showCompanyPercentageForGenderChart) {  
                dataItem = {
                    ...dataItem,
                    ltm_company_pct_female: parseFloat((geoData.company_hc_total_avg_f_prob * 100).toFixed(1)),
                }   
            }
            geoDataMap[geoData.geo1].push(dataItem);
        };

        const legendOrder = ['All', 'North America', 'Europe, Middle East & Africa'];
    
        // Sort based on legendOrder, then alphabetically, and 'N/A' is always last
        const sortedGeoDataMap = Object.entries(geoDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'N/A') return 1;
                if (keyB === 'N/A') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        let metricData = [];
        if (showHiringForGenderChart) {
            metricData = [...metricData, 'ltm_addition_rate_female'];
        }
        if (showAttritionForGenderChart) {
            metricData = [...metricData, 'ltm_attrition_rate_female'];
        }
        if (showCompanyPercentageForGenderChart) {
            metricData = [...metricData, 'ltm_company_pct_female'];
        }
        
        for (let key in sortedGeoDataMap) {
            metricData.forEach(metric => {
                // Define the conditions for default visibility
                const isVisibleGeo = (key === 'All' || key === 'North America' || selectedRegionValues.includes(key));
                const isVisible = (
                    (metric === 'ltm_company_pct_female' && isVisibleGeo && showCompanyPercentageForGenderChart) ||
                    (metric === 'ltm_addition_rate_female' && isVisibleGeo && showHiringForGenderChart) ||
                    (metric === 'ltm_attrition_rate_female' && isVisibleGeo && showAttritionForGenderChart)
                );
                dataSeries.push({
                    name: `${getFriendlyMetricName(metric)}: ${key}`,
                    data: sortedGeoDataMap[key].map((item) => [new Date(item.the_date).getTime(), item[metric]]),
                    type: 'spline',
                    color: lineColors[key],
                    dashStyle: lineStyles[getFriendlyMetricName(metric)],
                    visible: isVisible
                });
            });            
        }
            
        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: 435,
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Gender Probability By Region',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
            yAxis: {
				labels: {
					format: '{value}%'
				},
                title: {
                    text: 'Percentage Female'
                },
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    //////////////////////////////
    // Gender Probability Line Chart //
    function getGenderProbabilityOptions() {
        if (selectedView.value === 'Team') {
            return getGenderProbabilityByDepartmentOptions();
        }
        else if (selectedView.value === 'Seniority') {
            return getGenderProbabilityBySeniorityOptions();
        }
        else if (selectedView.value === 'Region') {
            return getGenderProbabilityByGeoOptions();
        }
        else {
            // default to department view
            return getGenderProbabilityByDepartmentOptions();
        }
    };

    //////////////////////////////
    // Team Growth Line Chart //
    function getTeamGrowthOptions() {
        if (selectedView.value === 'Team') {
            return getTeamGrowthByDepartmentOptions();
        }
        else if (selectedView.value === 'Seniority') {
            return getTeamGrowthBySeniorityOptions();
        }
        else if (selectedView.value === 'Region') {
            return getTeamGrowthByGeoOptions();
        }
        else {
            // default to department view
            return getTeamGrowthByDepartmentOptions();
        }
    };

    //////////////////////////////
    // Public Profiles Line Chart //
    function getPublicProfilesOptions() {
        if (selectedView.value === 'Team') {
            return getHeadcountByDepartmentOptions();
        }
        else if (selectedView.value === 'Seniority') {
            return getHeadcountBySeniorityOptions();
        }
        else if (selectedView.value === 'Region') {
            return getHeadcountByGeoOptions();
        }
        else {
            // default to department view
            return getHeadcountByDepartmentOptions();
        }
    };

    //////////////////////////////
    // Distribution Pie Chart //
    function getDistributionPieOptions() {
        if (selectedView.value=== 'Team') {
            return getDeptPieChartOptions();
        }
        else if (selectedView.value === 'Seniority') {
            return getSeniorityPieChartOptions();
        }
        else if (selectedView.value === 'Region') {
            return getGeoPieChartOptions();
        }
        else {
            // default to department view
            return getDeptPieChartOptions();
        }
    };


    const regionLabel = (selectedView.value === 'Region') ? 'Regions: ' : 'Region: ';
    const deptLabel = (selectedView.value === 'Team') ? 'Teams: ' : 'Team: ';
    const seniorityLabel = (selectedView.value === 'Seniority') ? 'Seniorities: ' : 'Seniority: ';


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
        if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
            setIsMetaDataLoading(true);
            getMetaData();
        }
    }, []);

    useEffect(() => {
        if (publicProfileCount !== 0) {
            setIsPageLoading(true);
            // getDataVersionDate();
            getTeamsGrowthDataDefault();
        }
    }, [publicProfileCount]);

    useEffect(() => {
        if (publicProfileCount !== 0) {
            let loadDefault = false;
            teamsGrowthDataStore.fetchTeamsGrowthData(companyId, accessToken, loadDefault, dataset, handleDataFetched);
        }
    }, [publicProfileCount]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0 
                && teamsGrowthData !== null
                && document.getElementById('team-growth-chart-container') !== null
                && document.getElementById('public-profiles-chart-container') !== null
                && document.getElementById('distribution-pie-chart-container') !== null
                && document.getElementById('gender-probability-chart-container') !== null
                ) 
            {
                // get team growth chart options
                let teamGrowthChartOptions = getTeamGrowthOptions();
                Highcharts.chart('team-growth-chart-container', teamGrowthChartOptions);

                // get public profiles chart options
                let publicProfilesChartOptions = getPublicProfilesOptions();
                Highcharts.chart('public-profiles-chart-container', publicProfilesChartOptions);

                // get distribution pie chart options
                let distributionPieChartOptions = getDistributionPieOptions();
                Highcharts.chart('distribution-pie-chart-container', distributionPieChartOptions);

                // get gender probability chart options
                let genderProbabilityChartOptions = getGenderProbabilityOptions();
                Highcharts.chart('gender-probability-chart-container', genderProbabilityChartOptions);
            }
        }
        catch(err) {
            console.log(err);
        }

    }, [teamsGrowthData, selectedView, selectedRegions, selectedSeniorities, selectedDepartments, showAll, showIndustry]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0 
                && teamsGrowthData !== null
                && document.getElementById('team-growth-chart-container') !== null
            ) 
            {
                let teamGrowthChartOptions = getTeamGrowthOptions();
                Highcharts.chart('team-growth-chart-container', teamGrowthChartOptions);
            }
        }
        catch(err) {
            console.log(err);
        }

    }, [showHiringForGrowthChart, showAttritionForGrowthChart, showNetGrowthForGrowthChart, showGrowthProdForGrowthChart]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0 
                && teamsGrowthData !== null
                && document.getElementById('gender-probability-chart-container') !== null
            ) 
            {
                let genderProbabilityChartOptions = getGenderProbabilityOptions();
                Highcharts.chart('gender-probability-chart-container', genderProbabilityChartOptions);
            }
        }
        catch(err) {
            console.log(err);
        }
    
    }, [showHiringForGenderChart, showAttritionForGenderChart, showCompanyPercentageForGenderChart]);


    useEffect(() => {
        try {
            if (publicProfileCount !== 0 
                && teamsGrowthData !== null
                && document.getElementById('public-profiles-chart-container') !== null
            ) {
                let publicProfilesChartOptions = getPublicProfilesOptions();
                Highcharts.chart('public-profiles-chart-container', publicProfilesChartOptions);
            }
        }
        catch(err) {
            console.log(err);
        }

    }, [showStacked]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0 
                && teamsGrowthData !== null
                && document.getElementById('distribution-pie-chart-container') !== null
            ) {
                // get distribution pie chart options
                let distributionPieChartOptions = getDistributionPieOptions();
                Highcharts.chart('distribution-pie-chart-container', distributionPieChartOptions);
            }
        }
        catch(err) {
            console.log(err);
        }
        
    }, [deptDistDataDate, seniorityDistDataDate, geo1DistDataDate]);

    const allText = (selectedView.value === 'Team' || selectedView.value === 'Seniority') ? 'Compare with overall company' : 'Compare with overall company';
    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    function handleTabClick(path) {
        let type = '';
        if (path.includes("company")) {
            type = "Company Growth";
        }
        else if (path.includes("talent")) {
            if (path.includes("retention")) {
                type = "Talent Retention";
            }
            else {
                type = "Talent Flow";
            }
        }
        else if (path.includes("tenure")) {
            type = "Tenure";
        }


        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Team Growth",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Team Growth",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
        }
        navigate(path);
    };

    if (metadataStore.isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if (publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={2000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={companyGrowthPath}>Company</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button active">
                            Teams
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={talentFlowPath}>Talent Flow</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        {
                            displayTalentRetention() && (
                                <div className="button">
                                    <a href={talentRetentionPath}>Talent Retention</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}}/>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}> Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        <div className="button">
                            <a href={tenurePath}>Tenure</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={topTitlesPath}> Top Titles </a>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                        {/* { universeId !== null && (
                            <NavLink 
                                style={{border: 'black 2px solid'}}
                                className='button'
                                to={universeSummaryPath}
                                state={{universeId: universeId, accessToken: accessToken}}
                                target="_blank" rel="noopener noreferrer"
                            >
                                View Universe
                            </NavLink>
                        )} */}
                        { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Team Growth: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <LoadingWithText texts={['Requesting data...', 'Setting up filters...', 'Finishing charts...']} interval={2000} />
                ) : (
                <div>
                    {   teamsGrowthDataStore.data && teamsGrowthDataStore.data[companyId] !== undefined && teamsGrowthDataStore.data[companyId].length > 0 
                    ? (
                        <div className="checkbox-group-container">
                            { viewOptions.map((viewOption) => (
                                <div key={viewOption.id} className="checkbox-container" >
                                    <input
                                        type="radio"
                                        id={viewOption.id}
                                        name="viewOption"
                                        // checked={selectedCheckbox === timeOption}
                                        defaultChecked={viewOption.label === "Team"}
                                        onChange={() => handleViewChange(viewOption)}
                                    />
                                    <label htmlFor={viewOption.id}>{viewOption.label + ' View'}</label>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <LoadingWithText texts={['Setting up different views...']} interval={2000} />
                    )
                }
                <br/>
                <div className='checkbox-group-container'> 
                    <div className='checkbox-container'>
                        <label htmlFor='regions'>{regionLabel}</label>
                        &nbsp;&nbsp;
                        <Select
                            isMulti={selectedView.value === 'Region'}
                            name="regions"
                            options={geo1Values.map((value) => ({value: value, label: value}))}
                            value={selectedRegions}
                            onChange={handleGeo1ChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='departments'>{deptLabel}</label>
                        &nbsp;&nbsp;
                        <Select
                            isMulti={selectedView.value === 'Team'}
                            name="departments"
                            options={deptValues.map((value) => ({value: value, label: value}))}
                            value={selectedDepartments}
                            onChange={handleDeptChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='seniorities'>{seniorityLabel}</label>
                        &nbsp;&nbsp;
                        <Select
                            isMulti={selectedView.value === 'Seniority'}
                            name="seniorities"
                            options={seniorityValues.map((value) => ({value: value, label: value}))}
                            value={selectedSeniorities}
                            onChange={handleSeniorityChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <br/>
                <div style={{textAlign: 'center'}}>
                    <label>
                        <input type="checkbox" checked={showAll} onChange={() => setShowAll(!showAll)} />
                        &nbsp;{allText}
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                        selectedView.value === "Team" &&
                        <div style={{display: 'inline-block'}}>
                        <label>
                            <input type="checkbox" checked={showIndustry} onChange={() => setShowIndustry(!showIndustry)} />
                            &nbsp;Compare with Industry Benchmark
                        </label>
                        </div>
                    }
                </div>
                <br/>
                <br/>
                {
                    showIndustry && selectedRegions.length === 1 && selectedRegions[0].value !== 'All'
                    ? (
                        <div style={{right: '450px', marginBottom: '50px', position: 'absolute'}}>
                            Select All Regions to view Benchmark data
                        </div>
                    )   
                    : null
                }
                <div className='bordered-box'>
                    <label>
                        <input type="checkbox" checked={showNetGrowthForGrowthChart} onChange={() => setShowNetGrowthForGrowthChart(!showNetGrowthForGrowthChart)} />
                        &nbsp;Net Headcount Growth
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showHiringForGrowthChart} onChange={() => setShowHiringForGrowthChart(!showHiringForGrowthChart)} />
                        &nbsp;Hiring Rate
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showAttritionForGrowthChart} onChange={() => setShowAttritionForGrowthChart(!showAttritionForGrowthChart)} />
                        &nbsp;Attrition Rate
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showGrowthProdForGrowthChart} onChange={() => setShowGrowthProdForGrowthChart(!showGrowthProdForGrowthChart)} />
                        &nbsp;Growth Efficiency
                    </label>
                </div>
                <div className='chart-container'>
                    <div className="chart-container-left-hc" id="team-growth-chart-container"></div>
                    <div className="chart-container-right-hc" id="distribution-pie-chart-container"></div>
                </div>
                <br/>
                <div className='bordered-box'>
                    <label>
                        <input type="checkbox" checked={showStacked} onChange={() => setShowStacked(!showStacked)} />
                        &nbsp;Show as 100% Stacked Bar
                    </label>
                </div>
                <div className='chart-container'>
                    <div className="chart-container-left-hc-1" id="public-profiles-chart-container"></div>
                </div>
                <br/>
                <div className='bordered-box'>
                    <label>
                        <input type="checkbox" checked={showHiringForGenderChart} onChange={() => setShowHiringForGenderChart(!showHiringForGenderChart)} />
                        &nbsp;New Hires
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showAttritionForGenderChart} onChange={() => setShowAttritionForGenderChart(!showAttritionForGenderChart)} />
                        &nbsp;Attrits
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showCompanyPercentageForGenderChart} onChange={() => setShowCompanyPercentageForGenderChart(!showCompanyPercentageForGenderChart)} />
                        &nbsp;Company Avg
                    </label>
                </div>
                <div className='chart-container'>
                    <div className="chart-container-left-hc-1" id="gender-probability-chart-container"></div>
                </div>
                
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    {   teamsGrowthDataStore.data && teamsGrowthDataStore.data[companyId] !== undefined && teamsGrowthDataStore.data[companyId].length > 0 && (
                        <DownloadButton 
                            data = {teamsGrowthData} 
                            companyName={companyName} 
                            buttonText = {'Download Teams Data'}
                            fileName = { `${companyName}_teams_data.csv` }
                            dataType={'teamsGrowth'}
                            accessToken={accessToken}
                        />
                    )}
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }
});

export default CompanyTeamGrowthChartComponent;
