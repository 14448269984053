import { PageLayout } from "../../components/common/page-layout"
import StandardBenchmarkReportKF from "../../components/report/standard_benchmark_report_kf";

export const StandardBenchmarkReportKFPage = () => {
    return (
        <PageLayout>
            <StandardBenchmarkReportKF />
        </PageLayout>
    );
};
