import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { palette1, palette2 } from '../utils';


const ChartThemeSwitcher = ({ targetChartIds = [] }) => {
    const [currentTheme, setCurrentTheme] = useState('palette1');

    const colorThemes = {
        palette1: ['#dbc9f8', '#b89ccc', '#cad0fc', '#9ba7df', '#6884d9', '#3563d0', '#75b9dc', '#3893b8', '#a7cacd', '#73a8ab', '#3a8083', '#0b4c4d'],
        palette2: ['#fce4b3', '#e99358', '#fcd9df', '#f1b1c1', '#c66c8a', '#a13a61', '#bd97ce', '#6e4c8f', '#93a4d7', '#436bad', '#88c0c0', '#5c7b70'],
    };

    const themeNames = {
        palette1: 'Color Palette 1',
        palette2: 'Color Palette 2',
    };

    useEffect(() => {
        // Set up a Highcharts event listener for chart render complete
        const handleChartLoad = (e) => {
            const chart = e.target;
            if (chart && chart.container) {
                const containerId = chart.renderTo.id;
                // check if there is a partial match between the targetChartIds and the containerId
                if(targetChartIds.some(id => containerId.includes(id))) {
                    if (currentTheme === 'palette1') {
                        chart.series.forEach((series, index) => {
                            series.update({
                                color: palette1[series.name]
                            }, false);
                        });
                    }
                    else if (currentTheme === 'palette2') {
                        chart.series.forEach((series, index) => {
                            series.update({
                                color: palette2[series.name]
                            }, false);
                        });
                    }
                    else {
                        chart.series.forEach((series, index) => {
                            series.update({
                                color: palette1[series.type]
                            }, false);
                        }); 
                    }

                    // if ((isDigital && !containerId.includes('-tenure-')) || (isBizDev && containerId.includes('-makeup-by-fx-'))) {
                    //     // check series type and use the
                    //     // hardcoded color theme for digital charts
                    //     if (currentTheme === 'palette1') {
                    //         chart.series.forEach((series, index) => {
                    //             series.update({
                    //                 color: palette1[series.name]
                    //             }, false);
                    //         });
                    //     }
                    //     else if (currentTheme === 'palette2') {
                    //         chart.series.forEach((series, index) => {
                    //             series.update({
                    //                 color: palette2[series.name]
                    //             }, false);
                    //         });
                    //     }
                    //     else {
                    //         chart.series.forEach((series, index) => {
                    //             series.update({
                    //                 color: palette1[series.type]
                    //             }, false);
                    //         }); 
                    //     }
                    // }
                    // else {
                    //     // if length of series is smaller than the length of the color theme,
                    //     // remove the colors from the start of the color theme
                    //     if (chart.series.length < colorThemes[currentTheme].length) {
                    //         const diff = colorThemes[currentTheme].length - chart.series.length;
                    //         chart.series.forEach((series, index) => {
                    //             series.update({
                    //                 color: colorThemes[currentTheme][index + diff]
                    //             }, false);
                    //         });
                    //     } else {
                    //         chart.series.forEach((series, index) => {
                    //             series.update({
                    //                 color: colorThemes[currentTheme][index % colorThemes[currentTheme].length]
                    //             }, false);
                    //         });
                    //     }
                    // }
                    chart.redraw();
                }
            }
        };

        // Add event listener for new charts
        Highcharts.addEvent(Highcharts.Chart, 'load', handleChartLoad);

        // Apply to any existing charts
        Highcharts.charts.forEach(chart => {
            if (chart && chart.container) {
                handleChartLoad({ target: chart });
            }
        });

        // Cleanup
        return () => {
            Highcharts.removeEvent(Highcharts.Chart, 'load', handleChartLoad);
        };
    }, [currentTheme, targetChartIds]);

    const ColorSwatch = ({ theme }) => (
        <div style={{ display: 'flex', gap: '4px', marginTop: '4px', justifyContent: 'center' }}>
            {colorThemes[theme].map((color, index) => (
                <div
                    key={index}
                    style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: color
                    }}
                />
            ))}
        </div>
    );

    return (
        <div style={{ 
            padding: '4px',
            border: '1px solid #e5e7eb',
            borderRadius: '8px',
            backgroundColor: 'white',
            width: '280px'
        }}>
            <select 
                value={currentTheme}
                onChange={(e) => setCurrentTheme(e.target.value)}
                style={{
                    width: '100%',
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #e5e7eb',
                    marginBottom: '4px'
                }}
            >
                {Object.entries(themeNames).map(([key, name]) => (
                    <option key={key} value={key}>{name}</option>
                ))}
            </select>
            <ColorSwatch theme={currentTheme} />
        </div>
    );
};

export default ChartThemeSwitcher;
