import React, { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';

const LoadingWithText = ({ texts, interval = 2000 }) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
                // Check if it's the last text
                if (currentTextIndex < texts.length - 1) {
                    setCurrentTextIndex((prevIndex) => prevIndex + 1);
                } else {
                    clearInterval(timer); // Stop the timer if it's the last text
                }
          }, interval);

        // Cleanup the interval on component unmount
        return () => clearInterval(timer);
    }, [texts, interval]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <BeatLoader color="#07849dff" />
            <p style={{ marginTop: '10px' }}>{texts[currentTextIndex]}</p>
        </div>
    );
};

export default LoadingWithText;
