import { toJS } from "mobx";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";

export const TopBanner = () => {
    const displayIcons = userAccountDataStore.user !== null && userAccountDataStore.user !== undefined

    const handleHomeIconClick = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Top Banner",
                type: "Home",
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Top Banner",
                type: "Home",
                user: toJS(userAccountDataStore.user),
            });
        }

        // navigate to home page
        window.open("/home", "_blank");
    };

    const handleSearchIconClick = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Top Banner",
                type: "Search",
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Top Banner",
                type: "Search",
                user: toJS(userAccountDataStore.user),
            });
        }

        // navigate to home page
        window.open("/company/search", "_blank");
    };

    const handleReportBug = () => {
        let url = window.location.href;
        // store url in local storage
        localStorage.setItem("reportBugUrl", url);
        window.open("/report-bug", "_blank");
    };

    const handleIconClick = () => {
        // open telemetry website
        window.open("https://telemetryllc.com", "_blank");
    };

    return (
        <div className="top-banner">
            <div className="left-icons">
                { displayIcons &&
                    <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={`${process.env.PUBLIC_URL}/home_ico.png`} onClick={handleHomeIconClick} style={{cursor: 'pointer'}}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    <img src={`${process.env.PUBLIC_URL}/search_ico.png`} onClick={handleSearchIconClick} style={{cursor: 'pointer'}}/>
                    </div>
                }
            </div>
            <div className="center-content">
                <img src={`${process.env.PUBLIC_URL}/telemetry_ico.png`} alt="Logo" style={{width: '128px', cursor: 'pointer'}} onClick={handleIconClick} />
                &nbsp;&nbsp;
                <img src={`${process.env.PUBLIC_URL}/beta_ico.png`} alt="Logo" className="logo" />
            </div>
            <div className="right-icons">
                { displayIcons &&
                    <div>
                    <img src={`${process.env.PUBLIC_URL}/bug_ico.png` } onClick={handleReportBug} style={{cursor: 'pointer'}}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                }
            </div>
      </div>
    )

};