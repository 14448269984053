import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Highcharts from 'highcharts';
import axios from 'axios';

import { customSort, getApiServrUrl, getLastDateOfMonth, getGoogleAnalyticsId, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { lineColors } from '../../utils/constants';
import { DownloadButton } from '../common/download_button';
import Select from 'react-select';

import { observer } from 'mobx-react';
import { metadataStore } from '../../stores/metadata_store';
import { standardBenchmarkReportDataStore } from '../../stores/standard_benchmark_report_data_store';
import { dataVersionDateStore } from '../../stores/data_version_date_store';
import LoadingWithText from '../common/loading_text';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import Dropdown from '../common/collections_dropdown';
import { collectionsStore } from '../../stores/collections_store';
import { toJS } from 'mobx';
import { apiEventsStore } from '../../stores/api_events_store';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';
import { ExpandableList } from './company_growth_hires_attrits_list';


const CompanyTopTitlesComponent = observer(() => {
    const [data, setData] = useState(null);
    const [collections, setCollections] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [isUnAuthorized, setIsUnAuthorized] = useState(false);
    const [connectivityError, setConnectivityError] = useState(false);
    const [dataVersionDate, setDataVersionDate] = useState(null);

    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;

    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`; 
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`;
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top_titles?company_id=${companyId}`;

    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
    }

    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
    }

    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;

        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };

    const getMetaData = async() => {
        // await both promises in parallel
        await Promise.all([
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ]);
        if (metadataStore.serverConnectionError) {
            // server connection error
            setConnectivityError(true);
            setIsMetaDataLoading(false);
        }
        else if (!metadataStore.isUnAuthorized) {
            setDataVersionDate(new Date(dataVersionDateStore.dataVersionDate));
            
            setCompanyName(metadataStore.data[companyId].companyName);
            if (metadataStore.data[companyId].publicProfileCount !== undefined) {
                setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
            }
        }
        setIsUnAuthorized(metadataStore.isUnAuthorized);
        setIsMetaDataLoading(false);
    };

    const getTopTitlesData = async() => {
        
        try {
            const companyList = [
                {
                    "linkedin_company_id": parseInt(companyId),
                    "name": companyName
                }
            ];
            const dataVersionDateFormat = new Date(dataVersionDate);
            const endDate =`${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;

            await standardBenchmarkReportDataStore.fetchTopTitlesForAllCompanies(companyId, companyList, endDate, dataset);
            const topTitlesData = standardBenchmarkReportDataStore.topTitlesData[companyId];

            setData(topTitlesData);
            
            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                await collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId);
                setCollections(toJS(collectionsStore.collections[companyId]));
            };
           
            // setIsUnAuthorized(growthDataStore.setUnAuthorized);
        } catch (err) {
            console.log(err);
            if (err.response !== undefined && (err.response.status == 401 || err.response.status == 403)) {
                setIsUnAuthorized(true);
            }
            
        }

        setIsPageLoading(false);
    };


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);


    useEffect(() => {
        try {
            if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
                setIsMetaDataLoading(true);
                getMetaData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                setIsPageLoading(true);
                getTopTitlesData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [publicProfileCount]);


    const getTop10Titles = (titles) => {
        const sortedEntries = Object.entries(titles)
            .sort((a, b) => b[1] - a[1]);
        
        const padded = [...sortedEntries, ...Array(10).fill(['-', 0])];
        
        return padded.slice(0, 10);
    };

    const TypesTable = ({ types, officialName, groupedData }) => {
        const categoryWidth = '17%'; // 100% / 6 columns
        
        // Add 'N/A' types only if there are fewer than 12 types
        const extendedTypes = [...types];
        if (extendedTypes.length < 6) {
            extendedTypes.push('N/A');
        }

        // Split into two tables of 6 columns each
        const firstTable = extendedTypes.slice(0, 6);

        const renderTable = (tableTypes) => {
            const countColumnWidth = '60px';  // Adjust this value as needed
        
            return (
                <table style={{
                    verticalAlign: 'top',
                    fontSize: '12px',
                    margin: '0px',
                    width: '100%',
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                    tableLayout: 'fixed'
                }} className="company-table">
                    <colgroup>
                        {tableTypes.map((_, index) => (
                            <React.Fragment key={index}>
                                <col style={{width: `calc(17% - ${countColumnWidth})`}} />
                                <col style={{width: countColumnWidth}} />
                            </React.Fragment>
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            {tableTypes.map(type => (
                                <th key={type} colSpan="2" style={{
                                    padding: '8px',
                                    borderBottom: '1px solid #ddd',
                                    borderRight: '1px solid #ddd',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}>
                                    {type === 'N/A' ? 'N/A' : type}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {tableTypes.map(type => (
                                <React.Fragment key={type}>
                                    <th style={{
                                        padding: '8px',
                                        borderBottom: '1px solid #ddd',
                                        borderRight: '1px solid #ddd',
                                        backgroundColor: '#f2f2f2',
                                        fontWeight: 'bold'
                                    }}>Title</th>
                                    <th style={{
                                        padding: '8px',
                                        borderBottom: '1px solid #ddd',
                                        borderRight: '1px solid #ddd',
                                        backgroundColor: '#f2f2f2',
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}>Count</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {tableTypes.map(type => {
                                    let titles = type === 'N/A' 
                                        ? [['-', '-']] 
                                        : getTop10Titles(groupedData[officialName][type]);
                                    return (
                                        <React.Fragment key={type}>
                                            <td style={{
                                                padding: '8px',
                                                borderBottom: '1px solid #ddd',
                                                borderRight: '1px solid #ddd',
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                minHeight: '40px',
                                                verticalAlign: 'top'
                                            }}>
                                                {titles[rowIndex] ? titles[rowIndex][0] : '-'}
                                            </td>
                                            <td style={{
                                                padding: '8px',
                                                borderBottom: '1px solid #ddd',
                                                borderRight: '1px solid #ddd',
                                                textAlign: 'center',
                                                verticalAlign: 'top',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {titles[rowIndex] && titles[rowIndex][1] !== '-' ? titles[rowIndex][1] : '-'}
                                            </td>
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        };

        return (
            <div style={{ paddingBottom: '2%'}}>
                {renderTable(firstTable)}
            </div>
        );
    };


    const tableContent = useMemo(() => {
        if (data === null) {
            return null;
        }
        const deptTypesOrder = ['Sales', 'Marketing', 'Product', 'Engineering', 'Consulting', 'Operations', 'Finance', 'Human Resources', 'Administrative', 'Information Technology', 'Customer Success & Support', 'Other'];
        
        const groupedData = data.reduce((acc, item) => {
            const { official_name, derived_department_group, title_clean, num_people } = item;
            
            if (!acc[official_name]) {
                acc[official_name] = Object.fromEntries(deptTypesOrder.map(type => [type, {}]));
            }
            
            if (!acc[official_name][derived_department_group][title_clean]) {
                acc[official_name][derived_department_group][title_clean] = 0;
            }
            
            acc[official_name][derived_department_group][title_clean] += num_people;
            
            return acc;
        }, {});

        return (
            <div>
                {Object.entries(groupedData).map(([officialName]) => (
                    <div key={officialName} style={{
                        marginTop: '5px',
                        marginBottom: '-20px',
                        paddingTop: '0px',
                        paddingBottom: '20px'
                    }} id={`appendix-${officialName}`}>
                        {/* <h2>Top Titles by Dept Function for {officialName}</h2> */}
                        <TypesTable 
                            types={deptTypesOrder.slice(0, 6)} 
                            officialName={officialName} 
                            groupedData={groupedData} 
                        />
                        <TypesTable 
                            types={deptTypesOrder.slice(6)} 
                            officialName={officialName} 
                            groupedData={groupedData} 
                        />
                    </div>
                ))}
            </div>
        );
    }, [data]);


    if (connectivityError) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. The server connection is taking too long. Please try again later.</p>
            </div>
        );
    }
    else if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if ( publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={2000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        let loadingTexts = ['Fetching company top titles...', 'Setting up tables...', 'Adding final touches...'];
        
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={companyGrowthPath}> Company </a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                           <a href={teamGrowthPath}>Teams</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={talentFlowPath}>Talent Flow</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        {
                            displayTalentRetention() && (
                                <div className="button">
                                    <a href={talentRetentionPath}>Talent Retention</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}}/>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}> Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        <div className="button">
                            <a href={tenurePath}>Tenure</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button active">
                            Top Titles
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                        {/* { universeId !== null && (
                            <NavLink 
                                style={{border: 'black 2px solid'}}
                                className='button'
                                to={universeSummaryPath}
                                state={{universeId: universeId, accessToken: accessToken}}
                                target="_blank" rel="noopener noreferrer"
                            >
                                View Universe
                            </NavLink>
                        )} */}
                        { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Company Top Titles by Department Function: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <LoadingWithText texts={loadingTexts} interval={2000} />
                ) : (
                <div>
                <div id="appendix">
                    <div style={{marginLeft: '5%'}} className="chart-container-left-dbm">
                        {tableContent}
                    </div>
                </div>
                
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data={data} 
                        companyName={companyName} 
                        buttonText={ 'Download Top Titles Data' }
                        fileName={ `${companyName}_company_top_titles_data.csv` }
                        dataType={'companyTopTitles'}
                        accessToken={accessToken}
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }   
});

export default CompanyTopTitlesComponent;
