import CompanyTenureComponent from "../../components/company/company_tenure_chart";
import { PageLayout } from "../../components/common/page-layout";

export const CompanyTenurePage = () => {
    return (
        <PageLayout>
            <CompanyTenureComponent/>
        </PageLayout>
    );
};
