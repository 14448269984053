import React, { useState, useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { standardBenchmarkReportDataStore } from '../../../stores/standard_benchmark_report_data_store';
import LoadingWithText from '../../common/loading_text';
import { DEPT_TYPES_ORDER } from "../utils.js";

const SeniorityByDeptPeerTable = ({ requestData, deptType = null }) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const title = deptType !== null 
        ? `Target-to-Peer Set Comparison: ${deptType} Employee Dispersion by Dept and Seniority as of Current Month` 
        : 'Target-to-Peer Set Comparison: Employee Dispersion by Dept and Seniority as of Current Month';
    const divId = deptType !== null ? `sen-by-dept-peer-table-${deptType}` : 'sen-by-dept-peer-table';

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchHeadcountHistoryByTeamSeniorityForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const multiCompanyData = standardBenchmarkReportDataStore.headcountHistoryByTeamSeniorityData[targetCompanyId];

            if (multiCompanyData !== null) {
                const filteredData = deptType !== null
                    ? multiCompanyData.filter(item => item.dept_group === deptType)
                    : multiCompanyData;

                setData(toJS(filteredData));
            } else {
                console.log('multiCompanyData is null');
            }
            setDataLoading(false);
        };

        getData();
    }, [companyList, endDate, dataset]);

    const splitCompanies = (companies) => {
        const chunks = [];
        // Pull first company out of the list
        const targetCompany = companies.shift();

        let remainingCompanies = [...companies];

        // If there are no remaining companies, return a chunk with just the target company
        if (remainingCompanies.length === 0) {
            chunks.push([targetCompany]);
            return chunks;
        }

        // Split remaining companies into chunks of 2
        while (remainingCompanies.length > 0) {
            const chunkSize = Math.min(2, remainingCompanies.length);
            // Create slice of remaining companies
            let newChunk = remainingCompanies.slice(0, chunkSize);
            // Add target company to the beginning of the slice
            newChunk.unshift(targetCompany);
            // Add slice to chunks
            chunks.push(newChunk);
            // chunks.push(remainingCompanies.slice(0, chunkSize));
            remainingCompanies = remainingCompanies.slice(chunkSize);
        }

        return chunks;
    };

    const processData = (data) => {
        const filteredData = data.filter(item => item.current_date_flag === 1);

        const companiesData = {};
        filteredData.forEach(item => {
            if (!companiesData[item.name]) {
                companiesData[item.name] = {};
            }

            const company = companiesData[item.name];
            const dept = item.derived_department_group;

            if (!company[dept]) {
                company[dept] = {
                    total: 0,
                    Executive: 0,
                    Leadership: 0,
                    Management: 0,
                    'Individual Contributor': 0,
                };
            }

            if (item.company_headcount_total) {
                company[dept][item.derived_seniority_group] += item.company_headcount_total;
                company[dept].total += item.company_headcount_total;
            }
        });

        Object.keys(companiesData).forEach(company => {
            Object.keys(companiesData[company]).forEach(dept => {
                const deptData = companiesData[company][dept];
                const total = deptData.total;

                if (total > 0) {
                    deptData.percentages = {
                        Executive: ((deptData.Executive / total) * 100).toFixed(1),
                        Leadership: ((deptData.Leadership / total) * 100).toFixed(1),
                        Management: ((deptData.Management / total) * 100).toFixed(1),
                        'Individual Contributor': ((deptData['Individual Contributor'] / total) * 100).toFixed(1),
                    };
                }
            });
        });

        return companiesData;
    };

    const renderTable = (companies, processedData, chunkIndex) => {
        const getPercentage = (company, dept, seniority) => {
            if (!processedData[company]?.[dept]?.percentages?.[seniority]) return '0';
            return `${processedData[company][dept].percentages[seniority]}%`;
        };

        return (
            <div key={chunkIndex}
                 id={`inner-${divId}-${chunkIndex}`}
                 style={{width: "100%"}}>
                <table 
                    className="company-table"
                    style={{
                            width: `${(companies.length + 1) * 25}%`, // Make the table responsive
                            tableLayout: "fixed", // Prevent column resizing
                        }}>
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}></th>
                            {companies.map(company => (
                                <th key={company}
                                    colSpan={4}                                
                                    style={{ width: "25%" }}>
                                    {company}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            <th style={{ width: "10%" }}>Departments</th>
                            {companies.map(company => (
                                <React.Fragment key={`${company}-headers`}>
                                    <th style={{ width: `${25 / 4}%`}} >E</th>
                                    <th style={{ width: `${25 / 4}%`}} >L</th>
                                    <th style={{ width: `${25 / 4}%`}} >M</th>
                                    <th style={{ width: `${25 / 4}%`}} >IC</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {DEPT_TYPES_ORDER
                            .filter(dept => companies.some(company => processedData[company]?.[dept]))
                            .map(dept => (
                                <tr key={dept}>
                                    <td style={{ width: "25%" }}>{dept}</td>
                                    {companies.map(company => (
                                        <React.Fragment key={`${company}-${dept}`}>
                                            <td style={{ width: `${25 / 4}%` }}>{getPercentage(company, dept, 'Executive')}</td>
                                            <td style={{ width: `${25 / 4}%` }}>{getPercentage(company, dept, 'Leadership')}</td>
                                            <td style={{ width: `${25 / 4}%` }}>{getPercentage(company, dept, 'Management')}</td>
                                            <td style={{ width: `${25 / 4}%` }}>{getPercentage(company, dept, 'Individual Contributor')}</td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
                <br/>
            </div>
        );
    };

    const tableContent = useMemo(() => {
        if (!data || Object.keys(data).length === 0) return null;

        const processedData = processData(data);
        const companies = Object.keys(processedData);
        const companyChunks = splitCompanies(companies);

        return companyChunks.map((chunk, index) => 
            renderTable(chunk, processedData, index)
        );

    }, [data]);

    return (
        <div>
            <div style={{ textAlign: 'left', marginLeft: '5%' }}>
                <h2>{title}</h2>
            </div>
            {dataLoading ? (
                <div>
                    <LoadingWithText
                        texts={['Requesting geo data...', 'Setting up table...', 'Wrapping things up...', 'Finished!']}
                        interval={5000}
                    />
                </div>
            ) : (
                <div id={divId}>
                    <div style={{ marginLeft: '2%', overflowX: 'auto' }} className="chart-container-left-dbm">
                        {tableContent}
                    </div>
                    <div>
                        <p>
                            E = Executive, L = Leadership, M = Management, IC = Individual Contributor
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SeniorityByDeptPeerTable;
