import React, { useState, useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { standardBenchmarkReportDataStore } from '../../../stores/standard_benchmark_report_data_store';
import LoadingWithText from '../../common/loading_text';


const GeoByDeptPeerTable = ({requestData, deptType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const title = deptType !== null ? `Target-to-Peer Set Comparison: ${deptType} Employee Dispersion by Geo as of Current Month` : 'Target-to-Peer Set Comparison: Employee Dispersion by Geo as of Current Month';
    const divId = deptType !== null ? `geo-by-dept-peer-table-${deptType}` : 'geo-by-dept-peer-table';

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchGeoByDeptTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const multiCompanyData = standardBenchmarkReportDataStore.geoByDeptData[targetCompanyId];

            if (multiCompanyData !== null) {
                let filteredData = [];
                if (deptType !== null) {
                    filteredData = multiCompanyData.filter(item => item.dept_group === deptType);
                }
                else {
                    filteredData = multiCompanyData;
                }
                setData(toJS(filteredData));
                console.log('multiCompanyData', toJS(multiCompanyData));
                setDataLoading(false);
            } else {
                console.log('multiCompanyData is null');
                setDataLoading(false);
            }
        };

        getData();
    }, [companyList, endDate, dataset]);

    const splitCompanies = (companies) => {
        const chunks = [];
        // Pull first company out of the list
        const targetCompany = companies.shift();

        let remainingCompanies = [...companies];

        // If there are no remaining companies, return a chunk with just the target company
        if (remainingCompanies.length === 0) {
            chunks.push([targetCompany]);
            return chunks;
        }

        // Split remaining companies into chunks of 2
        while (remainingCompanies.length > 0) {
            const chunkSize = Math.min(2, remainingCompanies.length);
            // Create slice of remaining companies
            let newChunk = remainingCompanies.slice(0, chunkSize);
            // Add target company to the beginning of the slice
            newChunk.unshift(targetCompany);
            // Add slice to chunks
            chunks.push(newChunk);
            // chunks.push(remainingCompanies.slice(0, chunkSize));
            remainingCompanies = remainingCompanies.slice(chunkSize);
        }

        return chunks;
    };

    const tableContent = useMemo(() => {
        if (!data || Object.keys(data).length === 0) return null;

        // Create ordered Map based on companyList
        const orderedCompanies = new Map();
        
        // First, initialize the Map with companyList order
        companyList.forEach(company => {
            orderedCompanies.set(company.name, {
                shortName: '', // Will be filled from data
                countries: {},
                totalHC: 0
            });
        });

        // Fill in the data while maintaining order
        Object.values(data).flat().forEach(item => {
            if (orderedCompanies.has(item.company_name)) {
                const companyData = orderedCompanies.get(item.company_name);
                
                // Set shorthand name if not set
                if (!companyData.shortName) {
                    companyData.shortName = item.company_shorthand_name;
                }

                if (!companyData.countries[item.geo3_group]) {
                    companyData.countries[item.geo3_group] = {
                        headcount: 0,
                        percentage: 0
                    };
                }
                
                companyData.countries[item.geo3_group].headcount += item.employee_count;
                companyData.countries[item.geo3_group].percentage += item.pct_of_overall;
                companyData.totalHC += item.employee_count;
            }
        });

        // Get first company's data to determine country order
        const [[firstCompanyName, firstCompany]] = orderedCompanies.entries();
        const sortedCountries = Object.entries(firstCompany.countries)
            .sort((a, b) => b[1].headcount - a[1].headcount)
            .map(([country]) => country);
        // move 'Other' country to the end
        const otherIndex = sortedCountries.indexOf('Other');
        if (otherIndex !== -1) {
            sortedCountries.push(sortedCountries.splice(otherIndex, 1)[0]);
        }
        

        const getBackgroundColor = (value) => {
            // if value is less than 0, return red
            if (value < 0) {
                return '#FF0000';
          }
          else {
            value = Math.round(value * 100);
            // if value is greater than 0, return shades of green depending on value
            if (value < 10) {
              return '#E0FFE0';
            }
            else if (value < 20) {
              return '#C0FFC0';
            }
            else if (value < 30) {
              return '#A0FFA0';
            }
            else if (value < 40) {
              return '#80FF80';
            }
            else if (value < 50) {
              return '#60FF60';
            }
            else if (value < 60) {
              return '#40FF40';
            }
            else if (value < 70) {
              return '#20FF20';
            }
            else {
              return '#00FF00';
            }
            
          }
        };

        const formatValue = (value, isPercentage) => {
            if (value === 0) return isPercentage ? '0' : '0';
            if (isPercentage) return `${Math.round(value * 100)}%`;
            return value.toLocaleString();
        };

        // Split companies into chunks
        const companyChunks = splitCompanies([...orderedCompanies.entries()]);

        return companyChunks.map((chunk, chunkIndex) => (
            <div key={chunkIndex}
                 id={`inner-${divId}-${chunkIndex}`}
                 style={{width: "100%"}}>
                <table className="company-table"
                       style={{
                           width: `${(chunk.length + 1) * 25}%`, // Dynamic width based on number of companies
                           tableLayout: 'fixed', // Fixed layout for consistent column widths
                           marginBottom: '20px'
                    }}>
                    <thead>
                        <tr>
                            <th style={{ width: '25%' }}></th>
                            {chunk.map(([companyName, company]) => (
                                <React.Fragment key={companyName}>
                                    <th colSpan="2"
                                        style={{ width: '25%' }}>
                                        {companyName}
                                    </th>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            <th style={{ width: '25%' }}>Countries</th>
                            {chunk.map(([companyName, company]) => (
                                <React.Fragment key={companyName}>
                                    <th style={{ backgroundColor: '#f0f0f0',
                                                 width: '12.5'}}>
                                        HC
                                    </th>
                                    <th style={{ backgroundColor: '#f0f0f0',
                                                 width: '12.5'}}>
                                        Pct
                                    </th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedCountries.map(country => (
                            <tr key={country}>
                                <td>{country}</td>
                                {chunk.map(([companyName, company]) => {
                                    const countryData = company.countries[country] || { headcount: 0, percentage: 0 };
                                    return (
                                        <React.Fragment key={`${companyName}-${country}`}>
                                            <td>
                                                {formatValue(countryData.headcount, false)}
                                            </td>
                                            <td 
                                                
                                                style={{ backgroundColor: getBackgroundColor(countryData.percentage) }}
                                            >
                                                {formatValue(countryData.percentage, true)}
                                            </td>
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>TOTAL</td>
                            {chunk.map(([companyName, company]) => {
                                const totalHC = company.totalHC;
                                const totalPct = Object.values(company.countries)
                                    .reduce((sum, country) => sum + country.percentage, 0);
                                return (
                                    <React.Fragment key={`total-${companyName}`}>
                                        <td>
                                            {formatValue(totalHC, false)}
                                        </td>
                                        <td>
                                            {formatValue(totalPct, true)}
                                        </td>
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                    </tfoot>
                </table>
            </div>
        ));
    }, [data, companyList]); // Added companyList to dependencies

    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2>{title}</h2>
            </div>
            {dataLoading ? (
                <div>
                    <LoadingWithText 
                        texts={['Requesting geo data...', 'Setting up table...', 'Wrapping things up...', 'Finished!']} 
                        interval={5000} 
                    />
                </div>
            ) : (
                <div id={divId}>
                    <div style={{marginLeft: '5%'}} className="chart-container-left-dbm">
                        {tableContent}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeoByDeptPeerTable;
