import { toJS } from "mobx";
import { useEffect, useMemo, useState } from "react";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { standardBenchmarkReportDataStore } from "../../../stores/standard_benchmark_report_data_store";


const CompanyAndCareerTenureChart = ({requestData, deptType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const title = deptType === null ? `${targetCompanyName}: Company and Career Tenure for Dept Roles (current month over the last 10 years)` : `${targetCompanyName}: Company and Career Tenure for ${deptType} Roles (current month over the last 10 years)`;
    const divId = deptType === null ? 'company-career-tenure-chart' : `company-career-tenure-chart-${deptType}`;
    const companyChartContainerId = deptType === null ? 'company-tenure-chart-container' : `company-tenure-chart-container-${deptType}`;
    const careerChartContainerId = deptType === null ? 'career-tenure-chart-container' : `career-tenure-chart-container-${deptType}`;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            let tenureHistoryData = [];
            if (deptType !== null) {
                await standardBenchmarkReportDataStore.fetchTenureHistoryByDeptTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                tenureHistoryData = standardBenchmarkReportDataStore.tenureHistoryByDeptTypeData[targetCompanyId];
            }
            else {
                await standardBenchmarkReportDataStore.fetchTenureHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                tenureHistoryData = standardBenchmarkReportDataStore.tenureHistoryData[targetCompanyId];
            }

            if (tenureHistoryData !== null) {
                setData(toJS(tenureHistoryData));
                console.log('tenureHistoryData', toJS(tenureHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('tenureHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {

        const drawCompanyTenureChart = (data) => {
            const categoryMap = {
                "pct_company_tenure_0_1": "0-1 year",
                "pct_company_tenure_1_3": "1-3 years",
                "pct_company_tenure_3_5": "3-5 years",
                "pct_company_tenure_5_10": "5-10 years",
                "pct_company_tenure_10_20": "10-20 years",
                "pct_company_tenure_20_plus": "20+ years",
            };

            const seriesData = [];

            //for each key in the categoryMap, create a series
            for (const key in categoryMap) {
                const seriesItems = [];
                data.forEach(item => {
                    seriesItems.push([item.the_date, Math.round(parseFloat(item[key]*100))]);
                });

                seriesData.push({
                    name: categoryMap[key],
                    data: seriesItems,
                    type: 'column',
                    stacking: 'percent',
                });
            }

            Highcharts.chart(companyChartContainerId, {
                chart: {
                    zoomType: 'xy',
                    //type: 'column',
                    height: '25%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null,
                },

                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    // set tick positions on the_date value of the data
                    tickPositions: data.map(item => item.the_date),
                    min: data && data.length > 1 ? data[0].the_date - 24 * 3600 * 1000 * 180 : null,
                    max: data && data.length > 1 ? data[data.length - 1].the_date + 24 * 3600 * 1000 * 180: null,
                    labels: {
                        style: {
                            fontSize: '20px',
                        },
                    },
                },
                
                yAxis: {
                    title: {
                        text: 'Pct Makeup by Company Tenure',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    labels: {
                        formatter: function() {
                            return this.value + '%';
                        },
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                legend: {
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    floating: false,
                },
                tooltip: {
                    pointFormatter: function() {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(0) + '%</b><br/>';
                    },
                    shared: true,
                },
                series: seriesData,
    
                credits: {
                    enabled: false
                },
    
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        lineWidth: 10,
                        pointPlacement: 'on',
                    },
                    column: {
                        stacking: 'percent',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                return this.y + '%';
                            },
                            inside: true,
                            
                        },
                    },
                },
            });

        };

        const drawCareerTenureChart = (data) => {
            const categoryMap = {
                "pct_career_tenure_0_1": "0-1 year",
                "pct_career_tenure_1_3": "1-3 years",
                "pct_career_tenure_3_5": "3-5 years",
                "pct_career_tenure_5_10": "5-10 years",
                "pct_career_tenure_10_20": "10-20 years",
                "pct_career_tenure_20_plus": "20+ years",
            };

            const seriesData = [];

            //for each key in the categoryMap, create a series
            for (const key in categoryMap) {
                const seriesItems = [];
                data.forEach(item => {
                    seriesItems.push([item.the_date, Math.round(parseFloat(item[key]*100))]);
                });

                seriesData.push({
                    name: categoryMap[key],
                    data: seriesItems,
                    type: 'column',
                    stacking: 'percent',
                });
            }

            Highcharts.chart(careerChartContainerId, {
                chart: {
                    zoomType: 'xy',
                    //type: 'column',
                    height: '25%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null,
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    // set tick positions on the_date value of the data
                    tickPositions: data.map(item => item.the_date),
                    min: data && data.length > 1 ? data[0].the_date - 24 * 3600 * 1000 * 180 : null,
                    max: data && data.length > 1 ? data[data.length - 1].the_date + 24 * 3600 * 1000 * 180 : null,
                    labels: {
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: 'Pct Makeup by Career Tenure',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    labels: {
                        formatter: function() {
                            return this.value + '%';
                        },
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                legend: {
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    floating: false,
                },
                tooltip: {
                    pointFormatter: function() {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(0) + '%</b><br/>';
                    },
                    shared: true,
                },
                series: seriesData,
    
                credits: {
                    enabled: false
                },
    
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        lineWidth: 10,
                        pointPlacement: 'on',
                    },
                    column: {
                        stacking: 'percent',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                return this.y + '%';
                            },
                            inside: true,
                            
                        },
                    },
                },
            });

        };

        if (data !== null) {
            let filteredData = data.filter((item) => item.linkedin_company_id === targetCompanyId);
            if (deptType !== null) {
                filteredData = filteredData.filter((item) => item.derived_department_group === deptType);
            }
            drawCompanyTenureChart(filteredData);
            drawCareerTenureChart(filteredData);
        }
    }, [data]);


    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting tenure data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <div id={divId}>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={companyChartContainerId} />
                        </div>
                        <br/>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={careerChartContainerId} />
                        </div>
                    </div>
                </>
                )
            }
        </div>
    );
};

export default CompanyAndCareerTenureChart;
