import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

import { useLocation, useSearchParams, NavLink, useNavigate } from "react-router-dom";
import { getLastDateOfMonth, getGoogleAnalyticsId, getPastDate, getNumberOfDaysBetweenDates, putCommasInNumber } from "../../utils/utils";
import { DownloadButton } from "../common/download_button";
import Select from 'react-select';

import { observer } from "mobx-react";
import { metadataStore } from "../../stores/metadata_store";
import { dataVersionDateStore } from "../../stores/data_version_date_store";
import LoadingWithText from "../common/loading_text";
import { toJS } from "mobx";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import { collectionsStore } from '../../stores/collections_store';
import Dropdown from '../common/collections_dropdown';
import { talentInflowAndOutflowDataStore } from "../../stores/talent_inflow_outflow_data_store";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";
import { lineColors } from "../../utils/constants";


const CompanyTalentRetentionComponent = observer(() => {
    const [talentFlowData, setTalentFlowData] = useState(null);
    const [talentFlowRetentionData, setTalentFlowRetentionData] = useState(null);
    const [talentFlowRetentionDataByTeams, setTalentFlowRetentionDataByTeams] = useState(null);
    const [talentFlowRetentionDataForInflow, setTalentFlowRetentionDataForInflow] = useState(null);
    const [topFiveInflowCompanyIds, setTopFiveInflowCompanyIds] = useState([]);
    const [topFiveInflowCompanyNames, setTopFiveInflowCompanyNames] = useState([]);
    const [companyNameToIdMap, setCompanyNameToIdMap] =  useState({});
    const [inflowCompanyOptions, setInflowCompanyOptions] = useState([]);
    const [companyAvgRetentionData, setCompanyAvgRetentionData] = useState(null);
    const [companyAvgQualityData, setCompanyAvgQualityData] = useState(null);
    const [companyAvgGenderData, setCompanyAvgGenderData] = useState(null);
    const [companyAvgAgeData, setCompanyAvgAgeData] = useState(null);
    const [inflowAvgRetentionData, setInflowAvgRetentionData] = useState(null);
    const [inflowAvgQualityData, setInflowAvgQualityData] = useState(null);
    const [inflowAvgGenderData, setInflowAvgGenderData] = useState(null);
    const [inflowAvgAgeData, setInflowAvgAgeData] = useState(null);
    const [currentAvgRetentionData, setCurrentAvgRetentionData] = useState(null);
    const [currentAvgQualityData, setCurrentAvgQualityData] = useState(null);
    const [currentAvgGenderData, setCurrentAvgGenderData] = useState(null);
    const [currentAvgAgeData, setCurrentAvgAgeData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [processedCohortRetentionData, setProcessedCohortRetentionData] = useState(null);
    const [processedCohortRetentionDataByTeams, setProcessedCohortRetentionDataByTeams] = useState(null);
    const [tenureMonths, setTenureMonths] = useState([1, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 9999]);
    const [retentionDataLoading, setRetentionDataLoading] = useState(false);
    const [selectedInflowCompanyName, setSelectedInflowCompanyName] = useState(null);
    const [selectedInflowCompanyId, setSelectedInflowCompanyId] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedCohortCompanyOne, setSelectedCohortCompanyOne] = useState(null);
    const [selectedCohortCompanyTwo, setSelectedCohortCompanyTwo] = useState(null);
    const [dateLabel, setDateLabel] = useState('');

    const [isRetentionDetailsVisible, setIsRetentionDetailsVisible] = useState(false);
    const [isRetentionByTeamVisible, setIsRetentionByTeamVisible] = useState(false);

    const [companyName, setCompanyName] = useState('');

    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [publicProfileCount, setPublicProfileCount] = useState(0);
   
    const geo1Values = ['North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific'];
    const deptValues = ['Sales', 'Product', 'Administrative', 'Consulting', 'Customer Success & Support', 'Engineering', 'Finance', 'Human Resources', 'Information Technology', 'Marketing', 'Operations', 'Other' ];
    const seniorityValues = ["Individual Contributor", "Advisor / Board", "Executive", "Leadership", "Management", "Student / Intern", "Unsure"];

    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedSeniorities, setSelectedSeniorities] = useState([]);
   
    const [dataVersionDate, setDataVersionDate] = useState(null);
    const [collections, setCollections] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;

    const monthsToYearsMapping = {
        1: 0,
        6: 0.5,
        12: 1,
        18: 1.5,
        24: 2,
        30: 2.5,
        36: 3,
        42: 3.5,
        48: 4,
        54: 4.5,
        60: 5
    };

    const viewOptions = [
        // { id: 1, value: 'Retention', label: 'Retention' },
        { id: 2, value: 'Quality Index', label: 'Quality Index' },
        { id: 3, value: 'Gender', label: 'Gender' },
        { id: 4, value: 'Age', label: 'Age' },
    ];
    const [selectedView, setSelectedView] = useState(null);

    // let dataVersionDate = location.state && location.state.dataVersionDate;

    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`;
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`;
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`;
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top-titles?company_id=${companyId}`;
    
    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
    }

    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
    }
    
    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;
        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }
    
    const handleDeptChangeWithMultiSelect = (selectedOptions) => {
        
        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedDepartments(selectedOptions);
        } else {
            setSelectedDepartments([selectedOptions]);
        }

       // update div style to be visible
       let refreshDataDiv = document.getElementById('refresh-data-text');
       refreshDataDiv.style.display = 'block';
    };

    const handleSeniorityChangeWithMultiSelect = (selectedOptions) => {
       
        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedSeniorities(selectedOptions);
        } else {
            setSelectedSeniorities([selectedOptions]);
        }

        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleGeo1ChangeWithMultiSelect = (selectedOptions) => {

        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedRegions(selectedOptions);
        } else {
            setSelectedRegions([selectedOptions]);
        }

        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleViewChange = (option) => {
        setSelectedView(option);
    }

    const handleRefreshClicked = () => {    
        setIsPageLoading(true);
        getTalentFlowAndRetentionData();
    };

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };


    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };


    const getMetaData = async() => {
        let promiseArray = [
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ];
        
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            promiseArray.push(collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId));
        }

        // await all promises in parallel
        await Promise.all(promiseArray);

        setCompanyName(metadataStore.data[companyId].companyName);
        if (metadataStore.data[companyId].publicProfileCount !== undefined) {
            setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
        }
        setDataVersionDate(toJS(dataVersionDateStore.dataVersionDate));

        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            setCollections(toJS(collectionsStore.collections[companyId]));
        }

        const startDate = new Date(dataVersionDateStore.dataVersionDate);
        startDate.setUTCFullYear(startDate.getUTCFullYear() - 10);
        const endDate = new Date(dataVersionDateStore.dataVersionDate);
        const startMonthInWords = startDate.toLocaleString('default', { month: 'short' });
        const endMonthInWords = endDate.toLocaleString('default', { month: 'short' });
        const startDateLabel = `${startMonthInWords} ${startDate.getUTCFullYear()}`
        const endDateLabel = `${endMonthInWords} ${endDate.getUTCFullYear()}`;
        setDateLabel(startDateLabel + ' - ' + endDateLabel);

        setIsMetaDataLoading(false);
    };
    
    const getTalentFlowAndRetentionData = async() => {
        try {
            const currentDate = new Date(dataVersionDate);
            // get date 10 years ago
            const tenYearsAgo = new Date(currentDate);
            tenYearsAgo.setUTCFullYear(tenYearsAgo.getUTCFullYear() - 10);

            const startDate = `${tenYearsAgo.getUTCFullYear()}-${currentDate.getUTCMonth() + 1}-01`;
            const endDate = `${currentDate.getUTCFullYear()}-${currentDate.getUTCMonth() + 1}-01`;

            const geo1 = selectedRegions.map((item) => item.value);
            const dept = selectedDepartments.map((item) => item.value);
            const seniority = selectedSeniorities.map((item) => item.value);

            const promises = [
                talentInflowAndOutflowDataStore.fetchTalentFlowData(companyId, accessToken, startDate, endDate, geo1, dept, seniority, dataset),
                talentInflowAndOutflowDataStore.fetchTalentFlowRetentionData(companyId, accessToken, geo1, dept, seniority, dataset),
                talentInflowAndOutflowDataStore.fetchTalentFlowRetentionDataByTeams(companyId, accessToken, dataset),
            ];
          
            await Promise.all(promises);
                
            let talentFlowData = toJS(talentInflowAndOutflowDataStore.data[companyId]);
            setTalentFlowData(talentFlowData);

            const inflowData = talentFlowData.filter((item) => item['dataset'] === 'inflow' && item['from_company_name'] !== 'Other' && item['from_company_name'] !== 'Private');
            inflowData.sort((a, b) => b['employee_count'] - a['employee_count']);
            const topFiveInflowCompanies = inflowData.slice(0, 5);
            const topFiveInflowCompanyIds = topFiveInflowCompanies.map((item) => item['from_company_id']);
            const topFiveInflowCompanyNames = topFiveInflowCompanies.map((item) => item['from_company_name']);

            // create options for dropdown with inflow companies
            const inflowCompanyOptions = [
                { id: 0, value: parseInt(companyId), label: `${companyName} (Target)` }
            ];
            const companyNameToIdMap = {};
            for (let i = 0; i < topFiveInflowCompanyNames.length; i++) {
                inflowCompanyOptions.push({ id: i+1, value: topFiveInflowCompanyIds[i], label: topFiveInflowCompanyNames[i] });
                companyNameToIdMap[topFiveInflowCompanyNames[i]] = topFiveInflowCompanyIds[i];
            };

            setSelectedCompanies(inflowData.map((item) => item['from_company_name']).slice(0,5));
            setTopFiveInflowCompanyIds(topFiveInflowCompanyIds);
            setTopFiveInflowCompanyNames(topFiveInflowCompanyNames);
            setSelectedInflowCompanyName(topFiveInflowCompanyNames[0]);
            setSelectedInflowCompanyId(topFiveInflowCompanyIds[0]);
            setInflowCompanyOptions(inflowCompanyOptions);
            setSelectedCohortCompanyOne(inflowCompanyOptions[0]);
            setSelectedCohortCompanyTwo(inflowCompanyOptions[1]);
            setCompanyNameToIdMap(companyNameToIdMap);
 
            const talentFlowRetentionData = toJS(talentInflowAndOutflowDataStore.retentionData[companyId]);
            setTalentFlowRetentionData(talentFlowRetentionData); 

            const talentFlowRetentionDataByTeams = toJS(talentInflowAndOutflowDataStore.retentionDataByTeams[companyId]);
            setTalentFlowRetentionDataByTeams(talentFlowRetentionDataByTeams);

            await talentInflowAndOutflowDataStore.fetchTalentFlowRetentionDataForTopInflowCompanies(companyId, topFiveInflowCompanyIds, accessToken, geo1, dept, seniority, dataset);
            const talentFlowRetentionDataForInflow = toJS(talentInflowAndOutflowDataStore.retentionDataForInflow[companyId]);
            setTalentFlowRetentionDataForInflow(talentFlowRetentionDataForInflow);

            const processedCohortRetentionData = getProcessedCohortRetentionData(talentFlowRetentionData, talentFlowRetentionDataForInflow, topFiveInflowCompanyIds);
            setProcessedCohortRetentionData(processedCohortRetentionData);

            const processedCohortRetentionDataByTeams = getProcessedCohortRetentionDataByTeams(talentFlowRetentionDataByTeams);
            setProcessedCohortRetentionDataByTeams(processedCohortRetentionDataByTeams);

            const months = [1, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60];
            const companyAvgRetentionData = [];
            const companyAvgQualityData = [];
            const companyAvgGenderData = [];
            const companyAvgAgeData = [];
            const currentAvgRetentionData = {};
            const currentAvgQualityData = {};
            const currentAvgGenderData = {};
            const currentAvgAgeData = {};
            const filteredCompanyAvgRetentionData = uniqueByTwoKeys(talentFlowRetentionData, 'company_exp_tenure_months_normalized', 'pct_retained_avg');
            const filteredCompanyAvgQualityData = uniqueByTwoKeys(talentFlowRetentionData, 'company_exp_tenure_months_normalized', 'alpha_net_hc_growth_prev_12_mo_avg');
            const filteredCompanyAvgGenderData = uniqueByTwoKeys(talentFlowRetentionData, 'company_exp_tenure_months_normalized', 'est_f_prob_avg');
            const filteredCompanyAvgAgeData = uniqueByTwoKeys(talentFlowRetentionData, 'company_exp_tenure_months_normalized', 'est_age_avg');
            for (let i=0; i<filteredCompanyAvgRetentionData.length; i++) {
                let avg = filteredCompanyAvgRetentionData[i]['pct_retained_avg'];
                if (months[i] in monthsToYearsMapping) {
                    companyAvgRetentionData.push([monthsToYearsMapping[months[i]], parseFloat((avg * 100).toFixed(0))]);
                }
                else {
                    currentAvgRetentionData[parseInt(companyId)] = parseFloat((avg * 100).toFixed(0));
                }
            }
            setCompanyAvgRetentionData(companyAvgRetentionData);

            for (let i=0; i<filteredCompanyAvgQualityData.length; i++) {
                let avg = filteredCompanyAvgQualityData[i]['alpha_net_hc_growth_prev_12_mo_avg'];
                if (months[i] in monthsToYearsMapping) {
                    companyAvgQualityData.push([monthsToYearsMapping[months[i]], parseFloat((avg * 100).toFixed(1))]);
                }
                else {
                    currentAvgQualityData[parseInt(companyId)] = parseFloat((avg * 100).toFixed(1));
                }
            }
            setCompanyAvgQualityData(companyAvgQualityData);

            for (let i=0; i<filteredCompanyAvgGenderData.length; i++) {
                let avg = filteredCompanyAvgGenderData[i]['est_f_prob_avg'];
                if (months[i] in monthsToYearsMapping) {
                    companyAvgGenderData.push([monthsToYearsMapping[months[i]], parseFloat((avg * 100).toFixed(0))]);
                }
                else {
                    currentAvgGenderData[parseInt(companyId)] = parseFloat((avg * 100).toFixed(0));
                }
            }
            setCompanyAvgGenderData(companyAvgGenderData);

            for (let i=0; i<filteredCompanyAvgAgeData.length; i++) {
                let avg = filteredCompanyAvgAgeData[i]['est_age_avg'];
                if (months[i] in monthsToYearsMapping) {
                    companyAvgAgeData.push([monthsToYearsMapping[months[i]], parseFloat((avg).toFixed(1))]);
                }
                else {
                    currentAvgAgeData[parseInt(companyId)] = parseFloat((avg).toFixed(1));
                }
            }
            setCompanyAvgAgeData(companyAvgAgeData);

            const inflowAvgRetentionData = {};
            const inflowAvgQualityData = {};
            const inflowAvgGenderData = {};
            const inflowAvgAgeData = {};
            for (let i=0; i <topFiveInflowCompanyIds.length; i++) {
                const inflowCompanyId = topFiveInflowCompanyIds[i];
                inflowAvgRetentionData[inflowCompanyId] = [];
                inflowAvgQualityData[inflowCompanyId] = [];
                inflowAvgGenderData[inflowCompanyId] = [];
                inflowAvgAgeData[inflowCompanyId] = [];
                const filteredData = talentFlowRetentionDataForInflow.filter((item) => item['from_company_id'] === inflowCompanyId);
                const filteredAvgRetentionDataForInflow = uniqueByTwoKeys(filteredData, 'company_exp_tenure_months_normalized', 'pct_retained_avg');
                const filteredAvgQualityDataForInflow = uniqueByTwoKeys(filteredData, 'company_exp_tenure_months_normalized', 'alpha_net_hc_growth_prev_12_mo_avg');
                const filteredAvgGenderDataForInflow = uniqueByTwoKeys(filteredData, 'company_exp_tenure_months_normalized', 'est_f_prob_avg');
                const filteredAvgAgeDataForInflow = uniqueByTwoKeys(filteredData, 'company_exp_tenure_months_normalized', 'est_age_avg');

                for (let j=0; j < filteredAvgRetentionDataForInflow.length; j++) {
                    let avg = filteredAvgRetentionDataForInflow[j]['pct_retained_avg'];
                    if (months[j] in monthsToYearsMapping) {
                        inflowAvgRetentionData[inflowCompanyId].push([monthsToYearsMapping[months[j]], parseFloat((avg * 100).toFixed(0))]);
                    }
                    else {
                        currentAvgRetentionData[inflowCompanyId] = parseFloat((avg * 100).toFixed(0));
                    
                    }
                }

                for (let j=0; j < filteredAvgQualityDataForInflow.length; j++) {
                    let avg = filteredAvgQualityDataForInflow[j]['alpha_net_hc_growth_prev_12_mo_avg'];
                    if (months[j] in monthsToYearsMapping) {
                        inflowAvgQualityData[inflowCompanyId].push([monthsToYearsMapping[months[j]], parseFloat((avg * 100).toFixed(1))]);
                    }
                    else {
                        currentAvgQualityData[inflowCompanyId] = parseFloat((avg * 100).toFixed(1));
                    
                    }
                }

                for (let j=0; j < filteredAvgGenderDataForInflow.length; j++) {
                    let avg = filteredAvgGenderDataForInflow[j]['est_f_prob_avg'];
                    if (months[j] in monthsToYearsMapping) {
                        inflowAvgGenderData[inflowCompanyId].push([monthsToYearsMapping[months[j]], parseFloat((avg * 100).toFixed(0))]);
                    }
                    else {
                        currentAvgGenderData[inflowCompanyId] = parseFloat((avg * 100).toFixed(0));
                    
                    }
                }

                for (let j=0; j < filteredAvgAgeDataForInflow.length; j++) {
                    let avg = filteredAvgAgeDataForInflow[j]['est_age_avg'];
                    if (months[j] in monthsToYearsMapping) {
                        inflowAvgAgeData[inflowCompanyId].push([monthsToYearsMapping[months[j]], parseFloat((avg).toFixed(1))]);
                    }
                    else {
                        currentAvgAgeData[inflowCompanyId] = parseFloat((avg).toFixed(1));
                    
                    }
                }
            }
            inflowAvgRetentionData[parseInt(companyId)] = companyAvgRetentionData;
            inflowAvgQualityData[parseInt(companyId)] = companyAvgQualityData;
            inflowAvgGenderData[parseInt(companyId)] = companyAvgGenderData;
            inflowAvgAgeData[parseInt(companyId)] = companyAvgAgeData;
            setInflowAvgRetentionData(inflowAvgRetentionData);
            setInflowAvgQualityData(inflowAvgQualityData);
            setInflowAvgGenderData(inflowAvgGenderData);
            setInflowAvgAgeData(inflowAvgAgeData);

            setCurrentAvgRetentionData(currentAvgRetentionData);
            setCurrentAvgQualityData(currentAvgQualityData);
            setCurrentAvgGenderData(currentAvgGenderData);
            setCurrentAvgAgeData(currentAvgAgeData);

            const tableData = getTableData(
                talentFlowRetentionData, 
                talentFlowRetentionDataForInflow, 
                companyAvgRetentionData, 
                inflowAvgRetentionData, 
                companyAvgQualityData,
                inflowAvgQualityData,
                companyAvgGenderData,
                inflowAvgGenderData,
                companyAvgAgeData,
                inflowAvgAgeData,
                topFiveInflowCompanyIds
            );
            setTableData(tableData);
            
            setSelectedView({id: 2, label: 'Quality Index', value: 'Quality Index'});

            setIsPageLoading(false);
        }
        catch (error) {
            console.log(error);
        }
    };


    function getTalentRetentionChartOptions () {
        const dataSeries = [];
        dataSeries.push(
            {
                type: 'spline',
                name: `${companyName} Overall Avg`,
                data: companyAvgRetentionData,
                color: 'black',
                dashStyle: 'Dash',
            },
        );

        // add items to dataSeries based on selectedCompanies
        selectedCompanies.slice(0,5).forEach(company => {
            dataSeries.push(
                {
                    type: 'spline',
                    name: `${company} Avg`,
                    data: inflowAvgRetentionData[companyNameToIdMap[company]],
                    //color: 'green',
                }
            );
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '30%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Avg Talent Retention',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				title : {
                    text: 'Years'
                },
                tickInterval: 1,
			},
			yAxis: {
				title: {
					text:  'Retention Rate'
				},
				labels: {
					format: '{value}%'
				},
                max: 100,
			},
			legend: {
				enabled: true,
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                // shared: true,
                // crosshairs: true, 
                formatter: function() {
                    var series = this.series.chart.series;
                    var result = '';
                    result += '<b><span style="color:' +
                                this.point.color + '">\u25CF</span> ' +
                                this.series.name + '</b>: <b>' + this.point.y +
                                '%</b><br/>';
                 
                    series.forEach(function(s) {
                        if (s !== this.series) {
                            result += '<span style="color:' +
                                    s.points[this.point.index].color +
                                    '">\u25CF</span><span style="color: #636363"> ' +
                                    s.name + ': ' + s.points[this.point.index].y +
                                    '%</span><br/>';
                        }
                    }, this);
                
                    return result;
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
        

    };

    function getTalentRetentionMetricChartOptions () {
        const dataSeries = [];
        if (selectedView.id === 1) {
            // Retention Data
            dataSeries.push(
                {
                    type: 'spline',
                    name: `${companyName} Overall Avg`,
                    data: companyAvgRetentionData,
                    color: 'black',
                    dashStyle: 'Dash',
                },
            );

            // add items to dataSeries based on selectedCompanies
            selectedCompanies.slice(0,5).forEach(company => {
                dataSeries.push(
                    {
                        type: 'spline',
                        name: `${company} Avg`,
                        data: inflowAvgRetentionData[companyNameToIdMap[company]],
                        //color: 'green',
                    }
                );
            });
        }
        else if (selectedView.id === 2) {
            // Quality data
            dataSeries.push(
                {
                    type: 'spline',
                    name: `${companyName} Overall Avg`,
                    data: companyAvgQualityData,
                    color: 'black',
                    dashStyle: 'Dash',
                }
            );

            // add items to dataSeries based on selectedCompanies
            selectedCompanies.slice(0,5).forEach(company => {
                dataSeries.push(
                    {
                        type: 'spline',
                        name: `${company} Avg`,
                        data: inflowAvgQualityData[companyNameToIdMap[company]],
                        //color: 'green',
                    }
                );
            });
        }
        else if (selectedView.id === 3) {
            // Gender data
            dataSeries.push(
                {
                    type: 'spline',
                    name: `${companyName} Overall Avg`,
                    data: companyAvgGenderData,
                    color: 'black',
                    dashStyle: 'Dash',
                }
            );

            // add items to dataSeries based on selectedCompanies
            selectedCompanies.slice(0,5).forEach(company => {
                dataSeries.push(
                    {
                        type: 'spline',
                        name: `${company} Avg`,
                        data: inflowAvgGenderData[companyNameToIdMap[company]],
                        //color: 'green',
                    }
                );
            });
        }
        else if (selectedView.id === 4) {
            // Age data
            dataSeries.push(
                {
                    type: 'spline',
                    name: `${companyName} Overall Avg`,
                    data: companyAvgAgeData,
                    color: 'black',
                    dashStyle: 'Dash',
                }
            );

            // add items to dataSeries based on selectedCompanies
            selectedCompanies.slice(0,5).forEach(company => {
                dataSeries.push(
                    {
                        type: 'spline',
                        name: `${company} Avg`,
                        data: inflowAvgAgeData[companyNameToIdMap[company]],
                        //color: 'green',
                    }
                );
            });
        }


        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '60%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: `Avg Talent Retention (${selectedView.label})`,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				title : {
                    text: 'Years'
                },
                tickInterval: 1,
			},
			yAxis: {
				title: {
					text:  selectedView.id === 1 ? 'Retention Rate' : selectedView.id === 2 ? 'Quality Index Score' : selectedView.id === 3 ? 'Gender Probability (Pct Female)' : 'Estimated Age'
				},
				labels: {
					format: selectedView.id === 4  || selectedView.id === 2 ? '{value}' : '{value}%'
				},
                max: selectedView.id === 1 ? 100 : null,
			},
			legend: {
				enabled: true,
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                formatter: function() {
                    var series = this.series.chart.series;
                    var result = '';
                    var pointY = this.y;
                    var suffix = selectedView.id === 4 ? ' Yrs' : selectedView.id === 2 ? '' : '%';
                    
                    // Format the main point
                    result += `<b><span style="color:${this.color}">\u25CF</span> ${this.series.name}</b>: <b>${pointY}${suffix}</b><br/>`;
                    
                    // Format other series points
                    series.forEach(function(s) {
                        if (s !== this.series) {
                            var point = s.points[this.point.index];
                            if (point && point.y !== undefined) {
                                result += `<span style="color:${point.color}">\u25CF</span><span style="color: #636363"> ${s.name}: ${point.y}${suffix}</span><br/>`;
                            }
                        }
                    }, this);
                    
                    return result;
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
        

    };

    function getTalentRetentionByTeamsChartOptions () {
        const data = processedCohortRetentionDataByTeams[companyId];
        const dataSeries = [];
        data.forEach((item) => {
            const dataList = [];
            // iterate over all keys and only select retention
            Object.keys(item).forEach((key) => {
                if (key !== 'dept' && key !== 'num_people' && key !== 'quality' && key !== 'gender' && key !== 'age') {
                    // Retention data
                    const retentionData = item[key];
                    // iterate over retentionData and select all but key === 9999
                    Object.keys(retentionData).forEach((k) => {
                        if (k !== '9999') {
                            dataList.push({
                                x: monthsToYearsMapping[parseInt(k)],
                                y: parseInt(retentionData[k].replace('%', '')),
                            });
                        }
                    });
                }
            });

            dataSeries.push({
                type: 'spline',
                name: `${item.dept} Avg`,
                data: dataList,
                color: lineColors[item.dept],
            });
        });

        dataSeries.push({
            type: 'spline',
            name: `Overall company Avg`,
            data: inflowAvgRetentionData[parseInt(companyId)],
            color: 'black',
            dashStyle: 'Dash',
        });


        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '70%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Avg Talent Retention',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				title : {
                    text: 'Years'
                },
                tickInterval: 1,
			},
			yAxis: {
				title: {
					text:  'Retention Rate'
				},
				labels: {
					format: '{value}%'
				},
                max: 100,
			},
			legend: {
				enabled: true,
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                // shared: false,
                // crosshairs: true,
                formatter: function() {
                    var series = this.series.chart.series;
                    var result = ''; 
                    result += '<b><span style="color:' +
                            this.point.color + '">\u25CF</span> ' +
                            this.series.name + '</b>: <b>' + this.point.y +
                            '%</b><br/>';

                    series.forEach(function(s) {
                        if (s !== this.series) {
                            result += '<span style="color:' +
                                s.points[this.point.index].color +
                                '">\u25CF</span><span style="color: #636363"> ' +
                                s.name + ': ' + s.points[this.point.index].y +
                                '%</span><br/>';
                        }
                    }, this);
                
                    return result;
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
        

    };  

    function getTalentRetentionByTeamsMetricChartOptions () {
        const data = processedCohortRetentionDataByTeams[companyId];
        const dataSeries = [];
        data.forEach((item) => {
            const dataList = [];
            // iterate over all keys except for dept, 9999, and num_people
            Object.keys(item).forEach((key) => {
                if (selectedView.id === 1) {
                    if (key !== 'dept' && key !== 'num_people' && key !== 'quality' && key !== 'gender' && key !== 'age') {
                        // Retention data
                        const retentionData = item[key];
                        // iterate over retentionData and select all but key === 9999
                        Object.keys(retentionData).forEach((k) => {
                            if (k !== '9999') {
                                dataList.push({
                                    x: monthsToYearsMapping[parseInt(k)],
                                    y: parseInt(retentionData[k].replace('%', '')),
                                });
                            }
                        });
                    }
                }
                else if (selectedView.id === 2) {
                    // Quality data
                    if (key !== 'dept' && key !== 'num_people' && key !== 'retention' && key !== 'gender' && key !== 'age') {
                        const qualityData = item[key];
                        // iterate over qualityData and select all but key === 9999
                        Object.keys(qualityData).forEach((k) => {
                            if (k !== '9999') {
                                dataList.push({
                                    x: monthsToYearsMapping[parseInt(k)],
                                    y: parseInt(qualityData[k].replace('%', '')),
                                });
                            }
                        });
                    }
                }
                else if (selectedView.id === 3) {
                    // Gender data
                    if (key !== 'dept' && key !== 'num_people' && key !== 'retention' && key !== 'quality' && key !== 'age') {
                        const genderData = item[key];
                        // iterate over genderData and select all but key === 9999
                        Object.keys(genderData).forEach((k) => {
                            if (k !== '9999') {
                                dataList.push({
                                    x: monthsToYearsMapping[parseInt(k)],
                                    y: parseInt(genderData[k].replace('%', '')),
                                });
                            }
                        });
                    }
                }
                else if (selectedView.id === 4) {
                    // Age data
                    if (key !== 'dept' && key !== 'num_people' && key !== 'retention' && key !== 'quality' && key !== 'gender') {
                        const ageData = item[key];
                        // iterate over ageData and select all but key === 9999
                        Object.keys(ageData).forEach((k) => {
                            if (k !== '9999') {
                                dataList.push({
                                    x: monthsToYearsMapping[parseInt(k)],
                                    y: parseInt(ageData[k]),
                                });
                            }
                        });
                    }
                }
            });

            dataSeries.push({
                type: 'spline',
                name: `${item.dept} Avg`,
                data: dataList,
                color: lineColors[item.dept],
            });
        });

        if (selectedView.id === 1) {
            dataSeries.push({
                type: 'spline',
                name: `Overall company Avg`,
                data: inflowAvgRetentionData[parseInt(companyId)],
                color: 'black',
                dashStyle: 'Dash',
            });
        }
        else if (selectedView.id === 2) {
            dataSeries.push({
                type: 'spline',
                name: `Overall company Avg`,
                data: inflowAvgQualityData[parseInt(companyId)],
                color: 'black',
                dashStyle: 'Dash',
            });
        }
        else if (selectedView.id === 3) {
            dataSeries.push({
                type: 'spline',
                name: `Overall company Avg`,
                data: inflowAvgGenderData[parseInt(companyId)],
                color: 'black',
                dashStyle: 'Dash',
            });
        }
        else if (selectedView.id === 4) {
            dataSeries.push({
                type: 'spline',
                name: `Overall company Avg`,
                data: inflowAvgAgeData[parseInt(companyId)],
                color: 'black',
                dashStyle: 'Dash',
            });
        }


        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '70%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: `Avg Talent Retention (${selectedView.label})`,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				title : {
                    text: 'Years'
                },
                tickInterval: 1,
			},
			yAxis: {
                title: {
					text:  selectedView.id === 1 ? 'Retention Rate' : selectedView.id === 2 ? 'Quality Index Score' : selectedView.id === 3 ? 'Gender Probability (Pct Female)' : 'Estimated Age'
				},
				labels: {
					format: selectedView.id === 4  || selectedView.id === 2 ? '{value}' : '{value}%'
				},
                max: selectedView.id === 1 ? 100 : null,
			},
			legend: {
				enabled: true,
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                // shared: false,
                // crosshairs: true,
                formatter: function() {
                    var series = this.series.chart.series;
                    var result = '';
                    if (selectedView.id === 4) {
                        result += '<b><span style="color:' +
                        this.point.color + '">\u25CF</span> ' +
                        this.series.name + '</b>: <b>' + this.point.y +
                        ' Yrs</b><br/>';
                    }
                    else if (selectedView.id === 2){
                        result += '<b><span style="color:' +
                        this.point.color + '">\u25CF</span> ' +
                        this.series.name + '</b>: <b>' + this.point.y +
                        '</b><br/>';
                    }
                    else {
                        result += '<b><span style="color:' +
                        this.point.color + '">\u25CF</span> ' +
                        this.series.name + '</b>: <b>' + this.point.y +
                        '%</b><br/>';
                    }
                
                    series.forEach(function(s) {
                        if (s !== this.series) {
                            if (selectedView.id === 4) {
                                result += '<span style="color:' +
                                    s.points[this.point.index].color +
                                    '">\u25CF</span><span style="color: #636363"> ' +
                                    s.name + ': ' + s.points[this.point.index].y +
                                    ' Yrs</span><br/>';
                            }
                            else if (selectedView.id === 2) {
                                result += '<span style="color:' +
                                    s.points[this.point.index].color +
                                    '">\u25CF</span><span style="color: #636363"> ' +
                                    s.name + ': ' + s.points[this.point.index].y +
                                    '</span><br/>';
                            }
                            else {
                                result += '<span style="color:' +
                                    s.points[this.point.index].color +
                                    '">\u25CF</span><span style="color: #636363"> ' +
                                    s.name + ': ' + s.points[this.point.index].y +
                                    '%</span><br/>';
                            
                            }
                        }
                    }, this);
                
                    return result;
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
        

    }; 

    const uniqueByTwoKeys = (data, key1, key2) => {
        const uniqueData = data.map(item => ({
            [key1]: item[key1],
            [key2]: item[key2],
        }))
        .reduce((acc, current) => {
            if (!acc.some(item => item[key1] === current[key1] && item[key2] === current[key2])) {
            acc.push(current);
            }
            return acc;
        }, []);
        
        return uniqueData;
    };
    
    const getAvgNumPeopleHiredPerYear = (data) => {
        let avgNumPeopleHiredPerYear = 0;
        const companyNumPeopleDataPerYear = data.filter((item) => item['company_exp_tenure_months_normalized'] === 1);
        const numPeopleHired = companyNumPeopleDataPerYear.map((item) => item['num_people']);
        let sum = 0;
        for (let j = 0; j < numPeopleHired.length; j++) {
            sum += numPeopleHired[j];
        }
        avgNumPeopleHiredPerYear = sum / numPeopleHired.length;

        return parseInt(avgNumPeopleHiredPerYear.toFixed(0));
    };

    const getTableData = (
        talentFlowRetentionData, 
        talentFlowRetentionDataForInflow, 
        companyAvgRetentionData, 
        inflowAvgRetentionData, 
        companyAvgQualityData,
        inflowAvgQualityData,
        companyAvgGenderData,
        inflowAvgGenderData,
        companyAvgAgeData,
        inflowAvgAgeData,
        topFiveInflowCompanyIds
    ) => {
        if (companyAvgRetentionData === null 
            || inflowAvgRetentionData === null 
            || companyAvgQualityData === null 
            || inflowAvgQualityData === null 
            || companyAvgGenderData === null 
            || inflowAvgGenderData === null 
            || companyAvgAgeData === null
            || inflowAvgAgeData === null
            || topFiveInflowCompanyIds.length === 0
        ) {
            return;
        }

        let tableData = [];
       
        const avgNumPeopleHiredForCompany = getAvgNumPeopleHiredPerYear(talentFlowRetentionData);
        const twoYearRetentionRate = companyAvgRetentionData[4] !== undefined ? `${companyAvgRetentionData[4][1]}%` : 'N/A';
        const fiveYearRetentionRate = companyAvgRetentionData[10] !== undefined ? `${companyAvgRetentionData[10][1]}%` : 'N/A';
        const twoYearQuality = companyAvgQualityData[4] !== undefined ? `${companyAvgQualityData[4][1]}` : 'N/A';
        const fiveYearQuality = companyAvgQualityData[10] !== undefined ? `${companyAvgQualityData[10][1]}` : 'N/A';
        const twoYearGender = companyAvgGenderData[4] !== undefined ? `${companyAvgGenderData[4][1]}%` : 'N/A';
        const fiveYearGender = companyAvgGenderData[10] !== undefined ? `${companyAvgGenderData[10][1]}%` : 'N/A';
        const twoYearAge = companyAvgAgeData[4] !== undefined ? `${companyAvgAgeData[4][1]}` : 'N/A';
        const fiveYearAge = companyAvgAgeData[10] !== undefined ? `${companyAvgAgeData[10][1]}` : 'N/A';
        tableData.push({
            companyName: `${companyName}`,
            avgNumPeopleHired: avgNumPeopleHiredForCompany,
            twoYearRetentionRate: twoYearRetentionRate,
            fiveYearRetentionRate: fiveYearRetentionRate,
            twoYearQuality: twoYearQuality,
            fiveYearQuality: fiveYearQuality,
            twoYearGender: twoYearGender,
            fiveYearGender: fiveYearGender,
            twoYearAge: twoYearAge,
            fiveYearAge: fiveYearAge,
        });
        
        for(let i=0; i<topFiveInflowCompanyIds.length; i++) {
            if (tableData.length === 6) {
                break;
            }
            const data = talentFlowRetentionDataForInflow.filter((item) => item['from_company_id'] === topFiveInflowCompanyIds[i]);
            if (data.length === 0) {
                continue;
            }
            const inflowCompanyName = data[0]['from_company_name'];
            
            const avgNumPeopleHired = getAvgNumPeopleHiredPerYear(data);
            const twoYearRetentionRate = inflowAvgRetentionData[topFiveInflowCompanyIds[i]][4] !== undefined ? `${inflowAvgRetentionData[topFiveInflowCompanyIds[i]][4][1]}%` : 'N/A';
            const fiveYearRetentionRate = inflowAvgRetentionData[topFiveInflowCompanyIds[i]][10] !== undefined ? `${inflowAvgRetentionData[topFiveInflowCompanyIds[i]][10][1]}%` : 'N/A';
            const twoYearQuality = inflowAvgQualityData[topFiveInflowCompanyIds[i]][4] !== undefined ? `${inflowAvgQualityData[topFiveInflowCompanyIds[i]][4][1]}` : 'N/A';
            const fiveYearQuality = inflowAvgQualityData[topFiveInflowCompanyIds[i]][10] !== undefined ? `${inflowAvgQualityData[topFiveInflowCompanyIds[i]][10][1]}` : 'N/A';
            const twoYearGender = inflowAvgGenderData[topFiveInflowCompanyIds[i]][4] !== undefined ? `${inflowAvgGenderData[topFiveInflowCompanyIds[i]][4][1]}%` : 'N/A';
            const fiveYearGender = inflowAvgGenderData[topFiveInflowCompanyIds[i]][10] !== undefined ? `${inflowAvgGenderData[topFiveInflowCompanyIds[i]][10][1]}%` : 'N/A';
            const twoYearAge = inflowAvgAgeData[topFiveInflowCompanyIds[i]][4] !== undefined ? `${inflowAvgAgeData[topFiveInflowCompanyIds[i]][4][1]}` : 'N/A';
            const fiveYearAge = inflowAvgAgeData[topFiveInflowCompanyIds[i]][10] !== undefined ? `${inflowAvgAgeData[topFiveInflowCompanyIds[i]][10][1]}` : 'N/A';
            tableData.push({
                companyName: `${inflowCompanyName}`,
                avgNumPeopleHired: avgNumPeopleHired,
                twoYearRetentionRate: twoYearRetentionRate,
                fiveYearRetentionRate: fiveYearRetentionRate,
                twoYearQuality: twoYearQuality,
                fiveYearQuality: fiveYearQuality,
                twoYearGender: twoYearGender,
                fiveYearGender: fiveYearGender,
                twoYearAge: twoYearAge,
                fiveYearAge: fiveYearAge,
            });
        }

        return tableData;
    };

    const getProcessedCompanyRetentionDataByTeams = (data) => {
        // Create a map where each key is a hired_year and the value is an object
        // with keys for each tenure month and values for retention.
        // Also store the num_people where company_exp_tenure_months_normalized is 1.
        const groupedByTeams = {};

        data.forEach((item) => {
            const { derived_department_group, company_exp_tenure_months_normalized, pct_retained_avg, alpha_net_hc_growth_prev_12_mo_avg, est_f_prob_avg, est_age_avg, avg_num_people } = item;
            
            if (!groupedByTeams[derived_department_group]) {
                groupedByTeams[derived_department_group] = {
                    retention: {}, // Initialize retention data
                    quality: {}, // Initialize quality data
                    gender: {}, // Initialize gender data
                    age: {}, // Initialize age data
                    // Do not initialize num_people here as we only want it for tenure = 1
                };
            }

            // Only store num_people for company_exp_tenure_months_normalized = 1
            if (company_exp_tenure_months_normalized === 1) {
                groupedByTeams[derived_department_group].num_people = avg_num_people;
            }

            // Store the retention data for each tenure month
            groupedByTeams[derived_department_group].retention[company_exp_tenure_months_normalized] = pct_retained_avg ? `${(pct_retained_avg * 100).toFixed(0)}%` : 'N/A';
            // Store the quality data for each tenure month
            groupedByTeams[derived_department_group].quality[company_exp_tenure_months_normalized] = alpha_net_hc_growth_prev_12_mo_avg ? `${(alpha_net_hc_growth_prev_12_mo_avg * 100).toFixed(1)}` : 'N/A';
            // Store the gender data for each tenure month
            groupedByTeams[derived_department_group].gender[company_exp_tenure_months_normalized] = est_f_prob_avg ? `${(est_f_prob_avg * 100).toFixed(0)}%` : 'N/A';
            // Store the age data for each tenure month
            groupedByTeams[derived_department_group].age[company_exp_tenure_months_normalized] = est_age_avg ? `${(est_age_avg).toFixed(1)}` : 'N/A';
        });

        // Now transform this map into an array of objects for rendering
        const processedData = Object.entries(groupedByTeams).map(([dept, data]) => {
            // Each entry in the array is an object with a key for the year, num_people, and each tenure month
            return {
                dept,
                num_people: data.num_people || 'N/A', // Use 'N/A' if num_people is undefined
                retention: data.retention,
                quality: data.quality,
                gender: data.gender,
                age: data.age
            };
        });

        // remove dept = 'Other' from the processedData and append it to the end
        const otherDeptIndex = processedData.findIndex((item) => item.dept === 'Other');
        const otherDeptData = processedData.splice(otherDeptIndex, 1);
        processedData.push(otherDeptData[0]);

        return processedData;
    };

    const getProcessedCompanyRetentionData = (data) => {
        // Create a map where each key is a hired_year and the value is an object
        // with keys for each tenure month and values for retention.
        // Also store the num_people where company_exp_tenure_months_normalized is 1.
        const groupedByYear = {};

        data.forEach((item) => {
            const { hired_year, company_exp_tenure_months_normalized, pct_retained, alpha_net_hc_growth_prev_12_mo, est_f_prob, est_age, num_people } = item;
            
            if (!groupedByYear[hired_year]) {
                groupedByYear[hired_year] = {
                    retention: {}, // Initialize retention data
                    quality: {}, // Initialize quality data
                    gender: {}, // Initialize gender data
                    age: {}, // Initialize age data
                    // Do not initialize num_people here as we only want it for tenure = 1
                };
            }

            // Only store num_people for company_exp_tenure_months_normalized = 1
            if (company_exp_tenure_months_normalized === 1) {
                groupedByYear[hired_year].num_people = num_people;
            }

            // Store the retention data for each tenure month
            groupedByYear[hired_year].retention[company_exp_tenure_months_normalized] = `${(pct_retained * 100).toFixed(0)}%`;
            // Store the quality data for each tenure month
            groupedByYear[hired_year].quality[company_exp_tenure_months_normalized] = `${(alpha_net_hc_growth_prev_12_mo * 100).toFixed(1)}`;
            // Store the gender data for each month
            groupedByYear[hired_year].gender[company_exp_tenure_months_normalized] = `${(est_f_prob * 100).toFixed(0)}%`;
            // Store the age data for each month
            groupedByYear[hired_year].age[company_exp_tenure_months_normalized] = `${(est_age).toFixed(1)}`;
        });

        // Now transform this map into an array of objects for rendering
        const processedData = Object.entries(groupedByYear).map(([year, data]) => {
            // Each entry in the array is an object with a key for the year, num_people, and each tenure month
            return {
                year,
                num_people: data.num_people || 'N/A', // Use 'N/A' if num_people is undefined
                retention: data.retention,
                quality: data.quality,
                gender: data.gender,
                age: data.age
            };
        });

        return processedData;
    };

    const getProcessedCohortRetentionData = (talentFlowRetentionData, talentFlowRetentionDataForInflow, topFiveInflowCompanyIds) => {
        const processedData = {};

        processedData[companyId] = getProcessedCompanyRetentionData(talentFlowRetentionData);
        topFiveInflowCompanyIds.forEach((inflowId) => {
            // filter based on inflowId
            const filteredInflowRetentionData = talentFlowRetentionDataForInflow.filter((item) => item['from_company_id'] === inflowId);
            processedData[inflowId] = getProcessedCompanyRetentionData(filteredInflowRetentionData);
        });

        return processedData;
    };
    
    const getProcessedCohortRetentionDataByTeams = (talentFlowRetentionDataByTeams) => {
        const processedData = {};
        processedData[companyId] = getProcessedCompanyRetentionDataByTeams(talentFlowRetentionDataByTeams);
        return processedData;
    };

    const handleInflowCompanyChange = (companyName) => {
        setSelectedInflowCompanyName(companyName);
        setSelectedInflowCompanyId(topFiveInflowCompanyIds[topFiveInflowCompanyNames.indexOf(companyName)]);
    };

    const handleCheckboxChange = (companyName, isChecked) => {
        if (isChecked) {
            setSelectedCompanies(prev => [...prev, companyName]);
        }
        else {
            setSelectedCompanies(prev => prev.filter(name => name !== companyName));
        }
    }

    const handleInflowCompanyChangeOne = (selectedOption) => {
        setSelectedCohortCompanyOne(selectedOption);
    };

    const handleInflowCompanyChangeTwo = (selectedOption) => {
        setSelectedCohortCompanyTwo(selectedOption);
    };


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
        if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
            setIsMetaDataLoading(true);
            getMetaData();
        }
    }, []);

    useEffect(() => {
        if (publicProfileCount !== 0) {
            setIsPageLoading(true);
            getTalentFlowAndRetentionData();
        }
    }, [publicProfileCount]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0
                && document.getElementById('talent-flow-retention-chart-container') !== null
                // && document.getElementById('talent-flow-retention-chart-container-1') !== null
                && dataVersionDate !== null
                && talentFlowRetentionData !== null
                && talentFlowRetentionDataForInflow !== null
                )
            {
                const retentionChartOptions = getTalentRetentionChartOptions();
                Highcharts.chart('talent-flow-retention-chart-container', retentionChartOptions);

                // const retentionMetricChartOptions = getTalentRetentionMetricChartOptions();
                // Highcharts.chart('talent-flow-retention-chart-container-1', retentionMetricChartOptions);

            }
        }
        catch (error) {
            console.log(error);
        }
    }, [talentFlowRetentionData, talentFlowRetentionDataForInflow, selectedCompanies]);


    // useEffect(() => {
    //     try {
    //         if (publicProfileCount !== 0
    //             && document.getElementById('talent-flow-retention-chart-container-1') !== null
    //             && dataVersionDate !== null
    //             && talentFlowRetentionData !== null
    //             && talentFlowRetentionDataForInflow !== null
    //             )
    //         {
    //             const retentionMetricChartOptions = getTalentRetentionMetricChartOptions();
    //             Highcharts.chart('talent-flow-retention-chart-container-1', retentionMetricChartOptions);
    //         }
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }, [selectedView]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0
                && document.getElementById('talent-flow-retention-by-teams-chart-container-1') !== null
                && dataVersionDate !== null
                && talentFlowRetentionData !== null
                && talentFlowRetentionDataForInflow !== null
                )
            {
                const retentionByTeamsMetricChartOptions = getTalentRetentionByTeamsMetricChartOptions();
                Highcharts.chart('talent-flow-retention-by-teams-chart-container-1', retentionByTeamsMetricChartOptions);

            }
        }
        catch (error) {
            console.log(error);
        }
    }, [selectedView]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0
                && document.getElementById('talent-flow-retention-by-teams-chart-container') !== null
                && document.getElementById('talent-flow-retention-by-teams-chart-container-1') !== null
                && dataVersionDate !== null
                && talentFlowRetentionData !== null
                && talentFlowRetentionDataForInflow !== null
                )
            {

                const retentionByTeamsChartOptions = getTalentRetentionByTeamsChartOptions();
                Highcharts.chart('talent-flow-retention-by-teams-chart-container', retentionByTeamsChartOptions);

                const retentionByTeamsMetricChartOptions = getTalentRetentionByTeamsMetricChartOptions();
                Highcharts.chart('talent-flow-retention-by-teams-chart-container-1', retentionByTeamsMetricChartOptions);

            }
        }
        catch (error) {
            console.log(error);
        }
    }, [isRetentionByTeamVisible]);


    useEffect(() => {
        try {
            if (publicProfileCount !== 0
                && document.getElementById('talent-flow-retention-by-teams-chart-container') !== null
                && document.getElementById('talent-flow-retention-by-teams-chart-container-1') !== null
                && dataVersionDate !== null
                && processedCohortRetentionDataByTeams !== null
                )
            {
                const retentionByTeamsChartOptions = getTalentRetentionByTeamsChartOptions();
                Highcharts.chart('talent-flow-retention-by-teams-chart-container', retentionByTeamsChartOptions);

                const retentionByTeamsMetricChartOptions = getTalentRetentionByTeamsMetricChartOptions();
                Highcharts.chart('talent-flow-retention-by-teams-chart-container-1', retentionByTeamsMetricChartOptions);
            }
        }
        catch (error) {
            console.log(error);
        }
    }, [processedCohortRetentionDataByTeams]);



    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    const getHeatmapColor = (percentage) => {
        if (percentage === undefined)
            return 'white';
        // You would define your own logic here to return a color based on the value
        const value = parseFloat(percentage.replace('%', ''));
        // if (value >= 80) return '#2CC0DD';
        // if (value >= 60) return '#25A2BB';
        // if (value >= 40) return '#1F8599';
        // if (value >= 20) return '#186777';
        if (value >= 80) return '#3cdfff';
        if (value >= 60) return '#63e5ff';
        if (value >= 40) return '#8aecff';
        if (value >= 20) return '#b1f2ff';
        return '#d8f9ff'; // Less than 20%
    };

    const getTalentUrl = (companyName) => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = url.searchParams;
        params.set('company_id', companyNameToIdMap[companyName]);
        const updatedUrl = url.toString();

        return updatedUrl;
    };

    function handleTabClick(path) {
        let type = '';
        if (path.includes("team")) {
            type = "Team Growth";
        }
        else if (path.includes("company")) {
            type = "Company Growth";
        }
        else if (path.includes("tenure")) {
            type = "Tenure";
        }
        else if (path.includes("talent")) {
            type = "Talent Flow"
        }


        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Talent Retention",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Talent Retention",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
        }
        navigate(path);
    };

    if (metadataStore.isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if (publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={8000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px '}} />
                        <div className="button">
                            <a href={companyGrowthPath}>Company</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={teamGrowthPath}>Teams</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={talentFlowPath}>Talent Flow</a>
                        </div>
                        <span style={{ margin: '5px '}} />
                        {
                            displayTalentRetention() && (
                                <div className="button active">
                                    Talent Retention
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}> Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        <div className="button">
                            <a href={tenurePath}>Tenure</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={topTitlesPath}> Top Titles </a>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                       { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Talent Retention: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <LoadingWithText 
                        texts={[
                            'Requesting data...', 
                            'Setting up filters...', 
                            'Creating talent retention chart...', 
                            'Creating retention cohort tables...',
                            'Adding final touches...'
                        ]}
                        interval={2500} 
                    />
                ) : (
                <div>
                {/* <div className="checkbox-group-container">
                    { viewOptions.map((viewOption) => (
                        <div key={viewOption.id} className="checkbox-container" >
                            <input
                                type="radio"
                                id={viewOption.id}
                                name="viewOption"
                                defaultChecked={viewOption.id === 2}
                                onChange={() => handleViewChange(viewOption)}
                            />
                            <label htmlFor={viewOption.id}>{viewOption.label}</label>
                        </div>
                    ))}
                </div> */}
                <br/>
                <div className='checkbox-group-container'> 
                    <div className='checkbox-container'>
                        <label htmlFor='regions'>Region:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="regions"
                            isMulti={true}
                            options={geo1Values.map((value) => ({value: value, label: value}))}
                            value={selectedRegions}
                            onChange={handleGeo1ChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='departments'>Team:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="departments"
                            isMulti={true}
                            options={deptValues.map((value) => ({value: value, label: value}))}
                            value={selectedDepartments}
                            onChange={handleDeptChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='seniorities'>Seniority:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="seniorities"
                            isMulti={true}
                            options={seniorityValues.map((value) => ({value: value, label: value}))}
                            value={selectedSeniorities}
                            onChange={handleSeniorityChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className='button' onClick={handleRefreshClicked}>Refresh Data</button>
                </div>
                <br/>
                <div id="refresh-data-text" style={{ alignContent: 'cemter', justifyContent: 'center', textAlign: 'center', display: 'none'}}>
                    Please hit Refresh Data to apply the new filters
                </div>
                <br/>
                <div>
                {
                    talentFlowRetentionData !== null && talentFlowRetentionDataForInflow !== null && talentFlowRetentionDataByTeams !== null
                    ? (
                        <div>
                            <div style={{textAlign: 'center', display: 'block', fontSize: '20px', fontWeight: 'bold'}}>
                                Retention Summary: {companyName} vs Inflows 
                            </div>
                            <br/>
                            <div className="chart-container">
                                <div className='chart-container-left-talent-retention-table' >
                                    <div id="talent-flow-retention-chart-container"></div>
                                </div>
                                {/* <div className='chart-container-right-talent-retention-table'>
                                    <div id="talent-flow-retention-chart-container-1"></div>
                                </div> */}
                            </div>
                            <div className= 'chart-container'>
                                <div className='chart-container-left-talent-retention-table'>
                                    { tableData && (
                                        <table className="company-table">
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Inflow Company</th>
                                                    <th>Hired per year</th>
                                                    <th colSpan={2}>Years retained</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Avg</th>
                                                    <th>2 Y</th>
                                                    <th>5 Y</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((company, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            name="companySelect"
                                                            value={company.companyName}
                                                            checked={selectedCompanies.includes(company.companyName)}
                                                            onChange={(e) => handleCheckboxChange(company.companyName, e.target.checked)}
                                                            disabled={index === 0} // Disable the first row's radio button
                                                        />
                                                    </td>
                                                    { index === 0 ? <td style={{backgroundColor: "lightgray"}}>{company.companyName} (Target)</td> : <td><a href={getTalentUrl(company.companyName)} target="_blank">{company.companyName}</a></td> }
                                                    { index === 0 ? <td style={{backgroundColor: "lightgray"}}>{putCommasInNumber(company.avgNumPeopleHired)}</td> : <td>{putCommasInNumber(company.avgNumPeopleHired)}</td> }
                                                    <td style={{backgroundColor: getHeatmapColor(company.twoYearRetentionRate), fontWeight: 'bold'}}>
                                                        {company.twoYearRetentionRate}
                                                    </td>
                                                    <td style={{backgroundColor: getHeatmapColor(company.fiveYearRetentionRate), fontWeight: 'bold'}}>
                                                        {company.fiveYearRetentionRate}
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        )
                                    }
                                </div>
                                {/* <div className='chart-container-right-talent-retention-table'>
                                    { tableData && selectedView && (
                                        <table className="company-table">
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Inflow Company</th>
                                                    <th>Hired per year</th>
                                                    <th colSpan={2}>Years retained</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Avg</th>
                                                    <th>2 Y</th>
                                                    <th>5 Y</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((company, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            name="companySelect"
                                                            value={company.companyName}
                                                            checked={selectedCompanies.includes(company.companyName)}
                                                            onChange={(e) => handleCheckboxChange(company.companyName, e.target.checked)}
                                                            disabled={index === 0} // Disable the first row's radio button
                                                        />
                                                    </td>
                                                    { index === 0 ? <td style={{backgroundColor: "lightgray"}}>{company.companyName} (Target)</td> : <td><a href={getTalentUrl(company.companyName)} target="_blank">{company.companyName}</a></td> }
                                                    { index === 0 ? <td style={{backgroundColor: "lightgray"}}>{putCommasInNumber(company.avgNumPeopleHired)}</td> : <td>{putCommasInNumber(company.avgNumPeopleHired)}</td> }
                                                    {
                                                        selectedView.id === 1 && 
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.twoYearRetentionRate), fontWeight: 'bold'}}>
                                                                {company.twoYearRetentionRate}
                                                            </td>
                                                        )
                                                    }
                                                    {   selectedView.id === 1 &&
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.fiveYearRetentionRate), fontWeight: 'bold'}}>
                                                                {company.fiveYearRetentionRate}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        selectedView.id === 2 && 
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.twoYearQuality), fontWeight: 'bold'}}>
                                                                {company.twoYearQuality}
                                                            </td>
                                                        )
                                                    }
                                                    {   selectedView.id === 2 &&
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.fiveYearQuality), fontWeight: 'bold'}}>
                                                                {company.fiveYearQuality}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        selectedView.id === 3 && 
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.twoYearGender), fontWeight: 'bold'}}>
                                                                {company.twoYearGender} F
                                                            </td>
                                                        )
                                                    }
                                                    {   selectedView.id === 3 &&
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.fiveYearGender), fontWeight: 'bold'}}>
                                                                {company.fiveYearGender} F
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        selectedView.id === 4 && 
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.twoYearAge), fontWeight: 'bold'}}>
                                                                {company.twoYearAge} yrs
                                                            </td>
                                                        )
                                                    }
                                                    {   selectedView.id === 4 &&
                                                        (
                                                            <td style={{backgroundColor: getHeatmapColor(company.fiveYearAge), fontWeight: 'bold'}}>
                                                                {company.fiveYearAge} yrs
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        )
                                    }
                                </div> */}
                            </div>
                            <hr className="dotted-line"></hr>
                            <div style={{textAlign: 'center', display: 'block', fontSize: '20px', fontWeight: 'bold'}} onClick={() => setIsRetentionDetailsVisible(!isRetentionDetailsVisible)}>
                                Retention Details {isRetentionDetailsVisible ? <FaChevronDown /> : <FaChevronRight />}  
                            </div>
                            <br/>
                            {
                                isRetentionDetailsVisible && 
                                (
                                <div>
                                {/* <div className='checkbox-group-container'> 
                                    <div className='checkbox-container'>
                                        <label htmlFor='companyOptions1'>Select Company:</label>
                                        &nbsp;&nbsp;
                                        <Select
                                            name="companyOptions1"
                                            options={inflowCompanyOptions}
                                            value={{value: selectedCohortCompanyOne.value, label: selectedCohortCompanyOne.label}}
                                            onChange={handleInflowCompanyChangeOne}
                                            
                                        />
                                    </div>
                                </div> */}
                                <div className="chart-container">
                                    <div className="chart-container-left-talent-retention-table-1">
                                        <h3 style={{textAlign: 'center', display: 'block'}}> {selectedCohortCompanyOne.label} Retention </h3>
                                        {
                                            processedCohortRetentionData && selectedView
                                            ? (
                                                <table className="company-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Hiring Year</th>
                                                            <th>Num People Hired</th>
                                                            <th colSpan={tenureMonths.length}>Years Retained</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th> {/* Empty cell under "Company Name" */}
                                                            <th></th> {/* Empty cell under "Num Hired" */}
                                                            {tenureMonths.map((month) => (
                                                                <th key={month}>
                                                                    {month === 9999 ? "Now" : monthsToYearsMapping[month] }
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            inflowAvgRetentionData[selectedCohortCompanyOne.value].length && (
                                                                <tr>
                                                                    <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                    <td></td>
                                                                    {
                                                                        inflowAvgRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                            <td 
                                                                                key={row[0]}
                                                                                style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                            >
                                                                                {row[1]+'%'}
                                                                            </td>
                                                                        ))
                                                                    }
                                                                    {
                                                                        currentAvgRetentionData[selectedCohortCompanyOne.value] !== undefined && (
                                                                            <td
                                                                                style={{backgroundColor: getHeatmapColor(currentAvgRetentionData[selectedCohortCompanyOne.value]+'%'), fontWeight: 'bold'}}
                                                                            >
                                                                                {currentAvgRetentionData[selectedCohortCompanyOne.value]+'%'}
                                                                            </td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            processedCohortRetentionData[selectedCohortCompanyOne.value].length > 0 
                                                            ? (
                                                                processedCohortRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                    <tr key={row.year}>
                                                                        <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                        <td>{putCommasInNumber(row.num_people)}</td>
                                                                        {tenureMonths.map((month) => (
                                                                            <td 
                                                                                key={`${row.year}-${month}`}
                                                                                style={{backgroundColor: getHeatmapColor(row.retention[month])}}
                                                                            >
                                                                                    {row.retention[month]}
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))
                                                            )
                                                            : (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data </td> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                            : (
                                                <div> No data </div>
                                            )
                                        }
                                    </div>
                                    {/* <div className="chart-container-right-talent-retention-table-1">
                                        <h3 style={{textAlign: 'center', display: 'block'}}> {selectedCohortCompanyOne.label} {selectedView.label} Retention</h3>
                                        {
                                            processedCohortRetentionData && selectedView
                                            ? (
                                                <table className="company-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Hiring Year</th>
                                                            <th>Num People Hired</th>
                                                            <th colSpan={tenureMonths.length}>Years Retained at {companyName} </th>
                                                        </tr>
                                                        <tr>
                                                        <th></th> 
                                                        <th></th> 
                                                            {tenureMonths.map((month) => (
                                                                <th key={month}>
                                                                    {month === 9999 ? "Now" : monthsToYearsMapping[month] }
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            selectedView.id === 1 
                                                            ? (
                                                                inflowAvgRetentionData[selectedCohortCompanyOne.value].length && (
                                                                    <tr>
                                                                        <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                        <td></td>
                                                                        {
                                                                            inflowAvgRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                                <td 
                                                                                    key={row[0]}
                                                                                    style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                >
                                                                                    {row[1]+'%'}
                                                                                </td>
                                                                            ))
                                                                        }
                                                                        {
                                                                            currentAvgRetentionData[selectedCohortCompanyOne.value] !== undefined && (
                                                                                <td
                                                                                    style={{backgroundColor: getHeatmapColor(currentAvgRetentionData[selectedCohortCompanyOne.value]+'%'), fontWeight: 'bold'}}
                                                                                >
                                                                                    {currentAvgRetentionData[selectedCohortCompanyOne.value]+'%'}
                                                                                </td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                )
                                                            )
                                                            : (
                                                                selectedView.id === 2 
                                                                ? (
                                                                    inflowAvgQualityData[selectedCohortCompanyOne.value].length && (
                                                                        <tr>
                                                                            <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                            <td></td>
                                                                            {
                                                                                inflowAvgQualityData[selectedCohortCompanyOne.value].map((row) => (
                                                                                    <td 
                                                                                        key={row[0]}
                                                                                        style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                    >
                                                                                        {row[1]}
                                                                                    </td>
                                                                                ))
                                                                            }
                                                                            {
                                                                                currentAvgQualityData[selectedCohortCompanyOne.value] !== undefined && (
                                                                                    <td
                                                                                        style={{backgroundColor: getHeatmapColor(currentAvgQualityData[selectedCohortCompanyOne.value]+'%'), fontWeight: 'bold'}}
                                                                                    >
                                                                                        {currentAvgQualityData[selectedCohortCompanyOne.value]}
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        </tr>
                                                                    )
                                                                )
                                                                : (
                                                                    selectedView.id == 3 
                                                                    ? (
                                                                        inflowAvgGenderData[selectedCohortCompanyOne.value].length && (
                                                                            <tr>
                                                                                <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                                <td></td>
                                                                                {
                                                                                    inflowAvgGenderData[selectedCohortCompanyOne.value].map((row) => (
                                                                                        <td 
                                                                                            key={row[0]}
                                                                                            style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                        >
                                                                                            {row[1]+'%'}
                                                                                        </td>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    currentAvgGenderData[selectedCohortCompanyOne.value] !== undefined && (
                                                                                        <td
                                                                                            style={{backgroundColor: getHeatmapColor(currentAvgGenderData[selectedCohortCompanyOne.value]+'%'), fontWeight: 'bold'}}
                                                                                        >
                                                                                            {currentAvgGenderData[selectedCohortCompanyOne.value]+'%'}
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    )
                                                                    : (
                                                                        selectedView.id === 4
                                                                        ? (
                                                                            inflowAvgAgeData[selectedCohortCompanyOne.value].length && (
                                                                                <tr>
                                                                                    <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                                    <td></td>
                                                                                    {
                                                                                        inflowAvgAgeData[selectedCohortCompanyOne.value].map((row) => (
                                                                                            <td 
                                                                                                key={row[0]}
                                                                                                style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                            >
                                                                                                {row[1]}
                                                                                            </td>
                                                                                        ))
                                                                                    }
                                                                                    {
                                                                                        currentAvgAgeData[selectedCohortCompanyOne.value] !== undefined && (
                                                                                            <td
                                                                                                style={{backgroundColor: getHeatmapColor(currentAvgAgeData[selectedCohortCompanyOne.value]+'%'), fontWeight: 'bold'}}
                                                                                            >
                                                                                                {currentAvgAgeData[selectedCohortCompanyOne.value]}
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        )
                                                                        : (
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>No data </td> 
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }
                                                        {
                                                            processedCohortRetentionData[selectedCohortCompanyOne.value].length > 0 
                                                            ? (
                                                                selectedView.id === 1 
                                                                ? (
                                                                    processedCohortRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                        <tr key={row.year}>
                                                                            <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                            <td>{putCommasInNumber(row.num_people)}</td>
                                                                            {tenureMonths.map((month) => (
                                                                                <td 
                                                                                    key={`${row.year}-${month}`}
                                                                                    style={{backgroundColor: getHeatmapColor(row.retention[month])}}
                                                                                >
                                                                                        {row.retention[month]}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                )
                                                                : (
                                                                    selectedView.id === 2 
                                                                    ? (
                                                                        processedCohortRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                            <tr key={row.year}>
                                                                                <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                                <td>{putCommasInNumber(row.num_people)}</td>
                                                                                {tenureMonths.map((month) => (
                                                                                    <td 
                                                                                        key={`${row.year}-${month}`}
                                                                                        style={{backgroundColor: getHeatmapColor(row.quality[month])}}
                                                                                    >
                                                                                            {row.quality[month]}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        ))
                                                                    )
                                                                    : (
                                                                        selectedView.id === 3 
                                                                        ? (
                                                                            processedCohortRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                                <tr key={row.year}>
                                                                                    <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                                    <td>{putCommasInNumber(row.num_people)}</td>
                                                                                    {tenureMonths.map((month) => (
                                                                                        <td 
                                                                                            key={`${row.year}-${month}`}
                                                                                            style={{backgroundColor: getHeatmapColor(row.gender[month])}}
                                                                                        >
                                                                                                {row.gender[month]}
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            ))
                                                                        )
                                                                        : (
                                                                            selectedView.id === 4 
                                                                            ? (
                                                                                processedCohortRetentionData[selectedCohortCompanyOne.value].map((row) => (
                                                                                    <tr key={row.year}>
                                                                                        <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                                        <td>{putCommasInNumber(row.num_people)}</td>
                                                                                        {tenureMonths.map((month) => (
                                                                                            <td 
                                                                                                key={`${row.year}-${month}`}
                                                                                                style={{backgroundColor: getHeatmapColor(row.age[month])}}
                                                                                            >
                                                                                                    {row.age[month]}
                                                                                            </td>
                                                                                        ))}
                                                                                    </tr>
                                                                                ))
                                                                            )
                                                                            : (
                                                                                <tr> 
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>No data </td> 
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                            : (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data </td> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div> No data </div>
                                            )
                                        }
                                    </div> */}
                                </div>
                                <br/>
                                <br/>
                                {/* <div className='checkbox-group-container'> 
                                    <div className='checkbox-container'>
                                        <label htmlFor='companyOptions2'>Select Company:</label>
                                        &nbsp;&nbsp;
                                        <Select
                                            name="companyOptions2"
                                            options={inflowCompanyOptions}
                                            value={{value: selectedCohortCompanyTwo.value, label: selectedCohortCompanyTwo.label}}
                                            onChange={handleInflowCompanyChangeTwo}
                                            
                                        />
                                    </div>
                                </div> */}
                                <div className="chart-container">
                                    {/* <div className="chart-container-left-talent-retention-table-1">
                                        <h3 style={{textAlign: 'center', display: 'block'}}> {selectedCohortCompanyTwo.label} Retention </h3>
                                        {
                                            processedCohortRetentionData && selectedView
                                            ? (
                                                <table className="company-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Hiring Year</th>
                                                            <th>Num People Hired</th>
                                                            <th colSpan={tenureMonths.length}>Years Retained</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th> 
                                                            <th></th> 
                                                            {tenureMonths.map((month) => (
                                                                <th key={month}>
                                                                    {month === 9999 ? "Now" : monthsToYearsMapping[month] }
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            inflowAvgRetentionData[selectedCohortCompanyTwo.value].length && (
                                                                <tr>
                                                                    <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                    <td></td>
                                                                    {
                                                                        inflowAvgRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                            <td 
                                                                                key={row[0]}
                                                                                style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                            >
                                                                                {row[1]+'%'}
                                                                            </td>
                                                                        ))
                                                                    }
                                                                    {
                                                                        currentAvgRetentionData[selectedCohortCompanyTwo.value] !== undefined && (
                                                                            <td
                                                                                style={{backgroundColor: getHeatmapColor(currentAvgRetentionData[selectedCohortCompanyTwo.value]+'%'), fontWeight: 'bold'}}
                                                                            >
                                                                                {currentAvgRetentionData[selectedCohortCompanyTwo.value]+'%'}
                                                                            </td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            processedCohortRetentionData[selectedCohortCompanyTwo.value].length > 0 
                                                            ? (
                                                                processedCohortRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                    <tr key={row.year}>
                                                                        <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                        <td>{putCommasInNumber(row.num_people)}</td>
                                                                        {tenureMonths.map((month) => (
                                                                            <td 
                                                                                key={`${row.year}-${month}`}
                                                                                style={{backgroundColor: getHeatmapColor(row.retention[month])}}
                                                                            >
                                                                                    {row.retention[month]}
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))
                                                            )
                                                            : (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data </td> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                            : (
                                                <div> No data </div>
                                            )
                                        }
                                    </div> */}
                                    {/* <div className="chart-container-right-talent-retention-table-1">
                                        <h3 style={{textAlign: 'center', display: 'block'}}> {selectedCohortCompanyTwo.label} {selectedView.label} Retention</h3>
                                        {
                                            processedCohortRetentionData && selectedView
                                            ? (
                                                <table className="company-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Hiring Year</th>
                                                            <th>Num People Hired</th>
                                                            <th colSpan={tenureMonths.length}>Years Retained at {companyName} </th>
                                                        </tr>
                                                        <tr>
                                                        <th></th> 
                                                        <th></th>
                                                            {tenureMonths.map((month) => (
                                                                <th key={month}>
                                                                    {month === 9999 ? "Now" : monthsToYearsMapping[month] }
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            selectedView.id === 1 
                                                            ? (
                                                                inflowAvgRetentionData[selectedCohortCompanyTwo.value].length && (
                                                                    <tr>
                                                                        <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                        <td></td>
                                                                        {
                                                                            inflowAvgRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                                <td 
                                                                                    key={row[0]}
                                                                                    style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                >
                                                                                    {row[1]+'%'}
                                                                                </td>
                                                                            ))
                                                                        }
                                                                        {
                                                                            currentAvgRetentionData[selectedCohortCompanyTwo.value] !== undefined && (
                                                                                <td
                                                                                    style={{backgroundColor: getHeatmapColor(currentAvgRetentionData[selectedCohortCompanyTwo.value]+'%'), fontWeight: 'bold'}}
                                                                                >
                                                                                    {currentAvgRetentionData[selectedCohortCompanyTwo.value]+'%'}
                                                                                </td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                )
                                                            )
                                                            : (
                                                                selectedView.id === 2 
                                                                ? (
                                                                    inflowAvgQualityData[selectedCohortCompanyTwo.value].length && (
                                                                        <tr>
                                                                            <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                            <td></td>
                                                                            {
                                                                                inflowAvgQualityData[selectedCohortCompanyTwo.value].map((row) => (
                                                                                    <td 
                                                                                        key={row[0]}
                                                                                        style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                    >
                                                                                        {row[1]}
                                                                                    </td>
                                                                                ))
                                                                            }
                                                                            {
                                                                                currentAvgQualityData[selectedCohortCompanyTwo.value] !== undefined && (
                                                                                    <td
                                                                                        style={{backgroundColor: getHeatmapColor(currentAvgQualityData[selectedCohortCompanyTwo.value]+'%'), fontWeight: 'bold'}}
                                                                                    >
                                                                                        {currentAvgQualityData[selectedCohortCompanyTwo.value]}
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        </tr>
                                                                    )
                                                                )
                                                                : (
                                                                    selectedView.id == 3 
                                                                    ? (
                                                                        inflowAvgGenderData[selectedCohortCompanyTwo.value].length && (
                                                                            <tr>
                                                                                <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                                <td></td>
                                                                                {
                                                                                    inflowAvgGenderData[selectedCohortCompanyTwo.value].map((row) => (
                                                                                        <td 
                                                                                            key={row[0]}
                                                                                            style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                        >
                                                                                            {row[1]+'%'}
                                                                                        </td>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    currentAvgGenderData[selectedCohortCompanyTwo.value] !== undefined && (
                                                                                        <td
                                                                                            style={{backgroundColor: getHeatmapColor(currentAvgGenderData[selectedCohortCompanyTwo.value]+'%'), fontWeight: 'bold'}}
                                                                                        >
                                                                                            {currentAvgGenderData[selectedCohortCompanyTwo.value]+'%'}
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    )
                                                                    : (
                                                                        selectedView.id === 4
                                                                        ? (
                                                                            inflowAvgAgeData[selectedCohortCompanyTwo.value].length && (
                                                                                <tr>
                                                                                    <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                                    <td></td>
                                                                                    {
                                                                                        inflowAvgAgeData[selectedCohortCompanyTwo.value].map((row) => (
                                                                                            <td 
                                                                                                key={row[0]}
                                                                                                style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                            >
                                                                                                {row[1]}
                                                                                            </td>
                                                                                        ))
                                                                                    }
                                                                                    {
                                                                                        currentAvgAgeData[selectedCohortCompanyTwo.value] !== undefined && (
                                                                                            <td
                                                                                                style={{backgroundColor: getHeatmapColor(currentAvgAgeData[selectedCohortCompanyTwo.value]+'%'), fontWeight: 'bold'}}
                                                                                            >
                                                                                                {currentAvgAgeData[selectedCohortCompanyTwo.value]}
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        )
                                                                        : (
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>No data </td> 
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }
                                                        {
                                                            processedCohortRetentionData[selectedCohortCompanyTwo.value].length > 0 
                                                            ? (
                                                                selectedView.id === 1 
                                                                ? (
                                                                    processedCohortRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                        <tr key={row.year}>
                                                                            <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                            <td>{putCommasInNumber(row.num_people)}</td>
                                                                            {tenureMonths.map((month) => (
                                                                                <td 
                                                                                    key={`${row.year}-${month}`}
                                                                                    style={{backgroundColor: getHeatmapColor(row.retention[month])}}
                                                                                >
                                                                                        {row.retention[month]}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                )
                                                                : (
                                                                    selectedView.id === 2 
                                                                    ? (
                                                                        processedCohortRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                            <tr key={row.year}>
                                                                                <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                                <td>{putCommasInNumber(row.num_people)}</td>
                                                                                {tenureMonths.map((month) => (
                                                                                    <td 
                                                                                        key={`${row.year}-${month}`}
                                                                                        style={{backgroundColor: getHeatmapColor(row.quality[month])}}
                                                                                    >
                                                                                            {row.quality[month]}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        ))
                                                                    )
                                                                    : (
                                                                        selectedView.id === 3 
                                                                        ? (
                                                                            processedCohortRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                                <tr key={row.year}>
                                                                                    <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                                    <td>{putCommasInNumber(row.num_people)}</td>
                                                                                    {tenureMonths.map((month) => (
                                                                                        <td 
                                                                                            key={`${row.year}-${month}`}
                                                                                            style={{backgroundColor: getHeatmapColor(row.gender[month])}}
                                                                                        >
                                                                                                {row.gender[month]}
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            ))
                                                                        )
                                                                        : (
                                                                            selectedView.id === 4 
                                                                            ? (
                                                                                processedCohortRetentionData[selectedCohortCompanyTwo.value].map((row) => (
                                                                                    <tr key={row.year}>
                                                                                        <td style={{backgroundColor: '#f2f2f2'}}>{row.year}</td>
                                                                                        <td>{putCommasInNumber(row.num_people)}</td>
                                                                                        {tenureMonths.map((month) => (
                                                                                            <td 
                                                                                                key={`${row.year}-${month}`}
                                                                                                style={{backgroundColor: getHeatmapColor(row.age[month])}}
                                                                                            >
                                                                                                    {row.age[month]}
                                                                                            </td>
                                                                                        ))}
                                                                                    </tr>
                                                                                ))
                                                                            )
                                                                            : (
                                                                                <tr> 
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>No data </td> 
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                            : (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data </td> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div> No data </div>
                                            )
                                        }
                                    </div> */}
                                </div>
                                </div>
                                )
                            }
                            <hr className="dotted-line"></hr>
                            <div style={{textAlign: 'center', display: 'block', fontSize: '20px', fontWeight: 'bold'}} onClick={() => setIsRetentionByTeamVisible(!isRetentionByTeamVisible)}>
                                {companyName} Retention by Team {isRetentionByTeamVisible ? <FaChevronDown /> : <FaChevronRight />}
                            </div>
                            {
                                isRetentionByTeamVisible  && 
                                (
                                <div>
                                <span style={{textAlign: 'center', display: 'block', fontStyle: 'italic'}}>*Region, Team and Seniority filters do not apply to this view*</span>
                                <br/>
                                <div className="chart-container">
                                    <div className='chart-container-left-talent-retention-table'>
                                        <div id="talent-flow-retention-by-teams-chart-container"></div>
                                    </div>
                                    {/* <div className='chart-container-right-talent-retention-table' >
                                        <div id="talent-flow-retention-by-teams-chart-container-1"></div>
                                    </div> */}
                                </div>
                                <div className="chart-container">
                                    <div className="chart-container-left-talent-retention-table-1">
                                        {
                                            processedCohortRetentionDataByTeams && selectedView
                                            ? (
                                                <table className="company-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Team</th>
                                                            <th>Hired per Year</th>
                                                            <th colSpan={tenureMonths.length - 1}>Years Retained</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th> {/* Empty cell */}
                                                            <th>Overall Avg</th>
                                                            {tenureMonths.map((month) => (
                                                                // skip month === 9999
                                                                month !== 9999 && (
                                                                    <th key={month}>
                                                                        {month === 9999 ? "Now" : monthsToYearsMapping[month] }
                                                                    </th>
                                                                )
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            inflowAvgRetentionData[parseInt(companyId)].length && (
                                                                <tr>
                                                                    <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                    <td>{putCommasInNumber(getAvgNumPeopleHiredPerYear(talentFlowRetentionData))}</td>
                                                                    {
                                                                        inflowAvgRetentionData[parseInt(companyId)].map((row) => (
                                                                            <td 
                                                                                key={row[0]}
                                                                                style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                            >
                                                                                {row[1]+'%'}
                                                                            </td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                        
                                                        {
                                                            processedCohortRetentionDataByTeams[companyId].length > 0 
                                                            ? (
                                                                processedCohortRetentionDataByTeams[companyId].map((row) => (
                                                                    <tr key={row.dept}>
                                                                        <td style={{backgroundColor: '#f2f2f2'}}>{row.dept}</td>
                                                                        <td>{putCommasInNumber(row.num_people)}</td>
                                                                        {tenureMonths.map((month) => (
                                                                            month !== 9999 && (
                                                                                <td 
                                                                                    key={`${row.dept}-${month}`}
                                                                                    style={{backgroundColor: getHeatmapColor(row.retention[month])}}
                                                                                >
                                                                                        {row.retention[month]}
                                                                                </td>
                                                                            )
                                                                        ))}
                                                                    </tr>
                                                                ))
                                                            )
                                                            : (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data </td> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                            : (
                                                <div> No data </div>
                                            )
                                        }
                                    </div>
                                    {/* <div className="chart-container-right-talent-retention-table-1">
                                        {
                                            processedCohortRetentionDataByTeams && selectedView
                                            ? (
                                                <table className="company-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Team</th>
                                                            <th>Hired per Year</th>
                                                            <th colSpan={tenureMonths.length - 1}>Years Retained</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th> 
                                                            <th>Overall Avg</th>
                                                            {tenureMonths.map((month) => (
                                                                // skip month === 9999
                                                                month !== 9999 && (
                                                                    <th key={month}>
                                                                        {month === 9999 ? "Now" : monthsToYearsMapping[month] }
                                                                    </th>
                                                                )
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            selectedView.id === 1 
                                                            ? (
                                                                inflowAvgRetentionData[parseInt(companyId)].length && (
                                                                    <tr>
                                                                        <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                        <td>{putCommasInNumber(getAvgNumPeopleHiredPerYear(talentFlowRetentionData))}</td>
                                                                        {
                                                                            inflowAvgRetentionData[parseInt(companyId)].map((row) => (
                                                                                <td 
                                                                                    key={row[0]}
                                                                                    style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                >
                                                                                    {row[1]+'%'}
                                                                                </td>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                )
                                                            )
                                                            : (
                                                                selectedView.id === 2 
                                                                ? (
                                                                    inflowAvgQualityData[parseInt(companyId)].length && (
                                                                        <tr>
                                                                            <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                            <td>{putCommasInNumber(getAvgNumPeopleHiredPerYear(talentFlowRetentionData))}</td>
                                                                            {
                                                                                inflowAvgQualityData[parseInt(companyId)].map((row) => (
                                                                                    <td 
                                                                                        key={row[0]}
                                                                                        style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                    >
                                                                                        {row[1]}
                                                                                    </td>
                                                                                ))
                                                                            }
                                                                        </tr>
                                                                    )
                                                                )
                                                                : (
                                                                    selectedView.id === 3 
                                                                    ? (
                                                                        inflowAvgGenderData[parseInt(companyId)].length && (
                                                                            <tr>
                                                                                <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                                <td>{putCommasInNumber(getAvgNumPeopleHiredPerYear(talentFlowRetentionData))}</td>
                                                                                {
                                                                                    inflowAvgGenderData[parseInt(companyId)].map((row) => (
                                                                                        <td 
                                                                                            key={row[0]}
                                                                                            style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                        >
                                                                                            {row[1]+'%'}
                                                                                        </td>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    )
                                                                    : (
                                                                        selectedView.id === 4 
                                                                        ? (
                                                                            inflowAvgAgeData[parseInt(companyId)].length && (
                                                                                <tr>
                                                                                    <td style={{backgroundColor: '#f2f2f2', fontWeight: 'bold'}}>Avg</td>
                                                                                    <td>{putCommasInNumber(getAvgNumPeopleHiredPerYear(talentFlowRetentionData))}</td>
                                                                                    {
                                                                                        inflowAvgAgeData[parseInt(companyId)].map((row) => (
                                                                                            <td 
                                                                                                key={row[0]}
                                                                                                style={{backgroundColor: getHeatmapColor(row[1]+'%'), fontWeight: 'bold'}}
                                                                                            >
                                                                                                {row[1]}
                                                                                            </td>
                                                                                        ))
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        )
                                                                        : (
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>No data </td> 
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }
                                                        
                                                        {
                                                            processedCohortRetentionDataByTeams[companyId].length > 0 
                                                            ? (
                                                                selectedView.id === 1 
                                                                ? (
                                                                    processedCohortRetentionDataByTeams[companyId].map((row) => (
                                                                        <tr key={row.dept}>
                                                                            <td style={{backgroundColor: '#f2f2f2'}}>{row.dept}</td>
                                                                            <td>{putCommasInNumber(row.num_people)}</td>
                                                                            {tenureMonths.map((month) => (
                                                                                month !== 9999 && (
                                                                                    <td 
                                                                                        key={`${row.dept}-${month}`}
                                                                                        style={{backgroundColor: getHeatmapColor(row.retention[month])}}
                                                                                    >
                                                                                            {row.retention[month]}
                                                                                    </td>
                                                                                )
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                )
                                                                : (
                                                                    selectedView.id === 2
                                                                    ? (
                                                                        processedCohortRetentionDataByTeams[companyId].map((row) => (
                                                                            <tr key={row.dept}>
                                                                                <td style={{backgroundColor: '#f2f2f2'}}>{row.dept}</td>
                                                                                <td>{putCommasInNumber(row.num_people)}</td>
                                                                                {tenureMonths.map((month) => (
                                                                                    month !== 9999 && (
                                                                                        <td 
                                                                                            key={`${row.dept}-${month}`}
                                                                                            style={{backgroundColor: getHeatmapColor(row.quality[month])}}
                                                                                        >
                                                                                                {row.quality[month]}
                                                                                        </td>
                                                                                    )
                                                                                ))}
                                                                            </tr>
                                                                        ))
                                                                    )
                                                                    : (
                                                                        selectedView.id === 3
                                                                        ? (
                                                                            processedCohortRetentionDataByTeams[companyId].map((row) => (
                                                                                <tr key={row.dept}>
                                                                                    <td style={{backgroundColor: '#f2f2f2'}}>{row.dept}</td>
                                                                                    <td>{putCommasInNumber(row.num_people)}</td>
                                                                                    {tenureMonths.map((month) => (
                                                                                        month !== 9999 && (
                                                                                            <td 
                                                                                                key={`${row.dept}-${month}`}
                                                                                                style={{backgroundColor: getHeatmapColor(row.gender[month])}}
                                                                                            >
                                                                                                    {row.gender[month]}
                                                                                            </td>
                                                                                        )
                                                                                    ))}
                                                                                </tr>
                                                                            ))
                                                                        )
                                                                        : (
                                                                            selectedView.id === 4 
                                                                            ? (
                                                                                processedCohortRetentionDataByTeams[companyId].map((row) => (
                                                                                    <tr key={row.dept}>
                                                                                        <td style={{backgroundColor: '#f2f2f2'}}>{row.dept}</td>
                                                                                        <td>{putCommasInNumber(row.num_people)}</td>
                                                                                        {tenureMonths.map((month) => (
                                                                                            month !== 9999 && (
                                                                                                <td 
                                                                                                    key={`${row.dept}-${month}`}
                                                                                                    style={{backgroundColor: getHeatmapColor(row.age[month])}}
                                                                                                >
                                                                                                        {row.age[month]}
                                                                                                </td>
                                                                                            )
                                                                                        ))}
                                                                                    </tr>
                                                                                ))
                                                                            )
                                                                            : (
                                                                                <tr> 
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>No data </td> 
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>

                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                            : (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data </td> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                            : (
                                                <div> No data </div>
                                            )
                                        }
                                    </div> */}
                                </div>
                                <br/>
                                <br/>
                                </div>
                                )
                            }
                            <br/>
                        </div>
                    )
                    : (
                        <div>
                            <LoadingWithText texts={['Setting up retention chart...']} interval={2500} />
                        </div>
                    )
                }
                </div>
                </div> )}
            </div>
        );
    }
});

export default CompanyTalentRetentionComponent;
