import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";


class DataVersionDateStore {
    loading = false;
    dataVersionDate = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchDataVersionDate(accessToken) {
        // check if data already exists
        if (this.dataVersionDate) {
            console.log(`DataVersionDateStore: Data already exists`);
            return;
        }
        
        this.loading = true;

        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_DATA_VERSION_DATE_ENDPOINT;
            console.log('DataVersionDateStore: Fetching data version date from ' + apiUrl + '...');
            
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                // withCredentials: true,
                decompress: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }
    
            const response = await axios.get(apiUrl, config);
            // convert Fri, 01 Sep 2023 00:00:00 GMT to 2023/09/01

            
            if(response.status === 200) {
                const date = new Date(response.data.data.data_version_date);
                let year = date.getUTCFullYear();
                let month = date.getUTCMonth() + 1;
                let day = date.getUTCDate();

                // add leading zero
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }

                const dataVersionDate = year + '/' + month + '/' + day;
                console.log(`DataVersionDateStore: Fetched data version date ${dataVersionDate}` );
                // set data in store
                this.dataVersionDate = dataVersionDate;
                this.loading = false;
            }

        }
        catch(err) {
            this.error = err;
            this.loading = false;
            console.log(err);
        }
    
    }
}

export const dataVersionDateStore = new DataVersionDateStore();
