import { PageLayout } from "../../components/common/page-layout"
import BugReportForm from "../../components/common/bug_report";

export const BugReportPage = () => {
    return (
        <PageLayout>
            <BugReportForm/>
        </PageLayout>
    );
};
