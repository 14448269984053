
import RegisterUserComponent from "../../components/auth/register";
import { PageLayout } from "../../components/common/page-layout"

export const RegisterUserPage = () => {
    return (
        <PageLayout>
            <RegisterUserComponent/>
        </PageLayout>
    );
};