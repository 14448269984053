import { useEffect, useState } from "react";
import LoadingWithText from "../../common/loading_text";
import { toJS } from "mobx";
import Highcharts from 'highcharts';
import { lineColors } from "../../../utils/constants";
import { standardBenchmarkReportDataStore } from "../../../stores/standard_benchmark_report_data_store";


const EmployeeHiringAndAttritonChart = ({requestData, deptType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [isPercentage, setIsPercentage] = useState(false);

    const togglePercentage = () => {
        setIsPercentage(!isPercentage);
    };

    const title = deptType === null ? `${targetCompanyName}: Employee Hiring and Attrition Over Time` : `${targetCompanyName}: ${deptType} Employee Hiring and Attrition Over Time`;
    const divId = deptType === null ? 'employee-hiring-attrition-chart' : `employee-hiring-attrition-chart-${deptType}`;
    const lineChartDivId = deptType === null ? 'hiring-attrition-line-chart-container' : `hiring-attrition-line-chart-container-${deptType}`;
    const columnChartDivId = deptType === null ? 'hiring-attrition-column-chart-container' : `hiring-attrition-column-chart-container-${deptType}`;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            let headcountHistoryData = [];
            if (deptType !== null) {
                await standardBenchmarkReportDataStore.fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                headcountHistoryData = standardBenchmarkReportDataStore.headcountHistoryByFxData[targetCompanyId];
            }
            else {
                await standardBenchmarkReportDataStore.fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                headcountHistoryData = standardBenchmarkReportDataStore.headcountHistoryData[targetCompanyId];
            }

            if (headcountHistoryData !== null) {
                setData(toJS(headcountHistoryData));
                console.log('headcountHistoryData', toJS(headcountHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawHiringAndAttritionLineChart = (data) => {
            if (deptType !== null) {
                data = data.filter(d => d.derived_department_group === deptType);
            }

            Highcharts.chart(lineChartDivId, {
                chart: {
                    zoomType: 'xy',
                    type: 'spline',
                    height: '25%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                tooltip: {
                    shared: true,
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    labels: {
                        style: {
                            fontSize: '20px',
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: 'Rate (Last 12 months)',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    labels: {
                        format: '{value}%',
                        style: {
                            fontSize: '20px',
                        },
                    },
                },
                legend: {   
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                },
                series: [{
                    name: 'Hiring Rate',
                    data: data.map(d => [d.the_date, parseFloat((d.addition_rate_prev_12_mo * 100).toFixed(1))]),
                    type: 'spline',
                    color: lineColors['Hiring'],
                }, {
                    name: 'Benchmark - Median Hiring Rate',
                    data: data.map(d => [d.the_date, parseFloat((d.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(1))]),
                    type: 'spline',
                    dashStyle: 'Dash',
                    color: lineColors['Hiring'],
                }, {
                    name: 'Attrition Rate',
                    data: data.map(d => [d.the_date, parseFloat((d.attrition_rate_prev_12_mo * 100).toFixed(1))]),
                    type: 'spline',
                    color: lineColors['Attrition'],
                }, {
                    name: 'Benchmark - Median Attrition Rate',
                    data: data.map(d => [d.the_date, parseFloat((d.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(1))]),
                    type: 'spline',
                    dashStyle: 'Dash',
                    color: lineColors['Attrition'],
                }],
                tooltip: {
                    // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    // shared: true,
                    // crosshairs: true, 
                    formatter: function() {
                        var series = this.series.chart.series;
                        var result = '';
                        result += '<b><span style="color:' +
                                    this.point.color + '">\u25CF</span> ' +
                                    this.series.name + '</b>: <b>' + this.point.y +
                                    '%</b><br/>';
                     
                        series.forEach(function(s) {
                            if (s !== this.series) {
                                result += '<span style="color:' +
                                        s.points[this.point.index].color +
                                        '">\u25CF</span><span style="color: #636363"> ' +
                                        s.name + ': ' + s.points[this.point.index].y +
                                        '%</span><br/>';
                            }
                        }, this);
                    
                        return result;
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    },
                },
                credits: {
                    enabled: false
                },
            });

        };

        const drawHiringAndAttritionColumnChart = (data) => {
            if (deptType !== null) {
                data = data.filter(d => d.derived_department_group === deptType);
            }

            const additionData = data.map((item) => {
                if (isPercentage) {
                    let growthPct = item.company_headcount_new/item.company_headcount_total;
                    return [item.the_date, parseFloat((growthPct * 100).toFixed(1))];
                }
                else {
                    return [item.the_date, item.company_headcount_new];
                }
            });
            const attritionData = data.map((item) => {
                if (isPercentage) {
                    let attritionPct = item.company_headcount_lost/item.company_headcount_total;
                    return [item.the_date, parseFloat((attritionPct * 100).toFixed(1))];
                }
                else {
                    return [item.the_date, item.company_headcount_lost];
                }
            });

            Highcharts.chart(columnChartDivId, {
                chart: {
                    zoomType: 'xy',
                    type: 'spline',
                    height: '20%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                tooltip: {
                    shared: true,
                    formatter: function() {
                        let tooltip = '<b>' + Highcharts.dateFormat('%Y', this.x) + '</b><br/>';
                        this.points.forEach(point => {
                            tooltip += point.series.name + ': ' + 
                                      Highcharts.numberFormat(point.y, 0, '.', ',') + '<br/>';
                                      // The parameters are: (number, decimals, decimal point, thousand sep)
                        });
                        return tooltip;
                    }
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null,
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    labels: {
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: isPercentage ? 'Addition vs Attriton Rates' : 'Addition vs Attrition',
                        style: {
                            fontSize: '20px',
                        },
                    },
                    labels: {
                        format: isPercentage ? '{value}%' : '{value}',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    // lineWidth: 2,
                },
    
                legend: {
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                },
                
                series: [
                    {  
                        type: 'column',
                        name: 'Hiring',
                        data: additionData,
                        color: lineColors['Hiring'],
                        pointWidth: 10,
                        tooltip: {
                            pointFormat: isPercentage 
                                            ? '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>'
                                            : '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
                        },
                    },
                    {
                        type: 'column',
                        name: 'Attrition',
                        data: attritionData,
                        color: lineColors['Attrition'],
                        pointWidth: 10,
                        tooltip: {
                            pointFormat: isPercentage
                                            ? '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>'
                                            : '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
                        },
                    },
                ],
    
                credits: {
                    enabled: false
                },
    
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        lineWidth: 2,
                    },
                },
            });

        };

        if (data !== null) {
            const filteredData = data.filter(d => d.linkedin_company_id === targetCompanyId);
            drawHiringAndAttritionLineChart(filteredData);
            drawHiringAndAttritionColumnChart(filteredData);
        }
    }, [data, isPercentage]);


    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting headcount data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <button className="button" onClick={togglePercentage}>
                        Show {isPercentage ? 'Absolute' : 'Percentage'} values
                    </button>
                    <br/>
                    <div id={divId}>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={lineChartDivId} />
                        </div>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={columnChartDivId} />
                        </div>
                    </div>
                </>
                )
            }
        </div>
    );

};

export default EmployeeHiringAndAttritonChart;
