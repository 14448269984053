
import React, { useState, useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { standardBenchmarkReportDataStore } from '../../../stores/standard_benchmark_report_data_store';
import LoadingWithText from '../../common/loading_text';
import { putCommasInNumber } from '../../../utils/utils';


const GeoByDeptTableHC = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [showPercentage, setShowPercentage] = useState(false);
    const [visibleChunks, setVisibleChunks] = useState(1);

    const CHUNK_SIZE = 15;
    const INITIAL_SIZE = 15;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchGeoByDeptTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const geoByDeptData = standardBenchmarkReportDataStore.geoByDeptData[targetCompanyId];

            if (geoByDeptData !== null) {
                setData(toJS(geoByDeptData));
                console.log('geoByDeptData', toJS(geoByDeptData));
            }
            else {
                console.log('geoByDeptData is null');
            }
            setDataLoading(false);
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);


    // Reset visibleChunks when data changes
    useEffect(() => {
        setVisibleChunks(1);
    }, [data]);


    // Define table headers
    const headers = [
        'Sales',
        'Marketing',
        'Product',
        'Engineering',
        'Consulting',
        'Operations',
        'Finance',
        'Human Resources',
        'Administrative',
        'Information Technology',
        'Customer Success',
        'Other',
    ];

    const {
        chunks,
        sortedCountries,
        groupedData,
        targetCompanyData,
        columnTotals,
        grandTotal
    } = useMemo(() => {
        if (!data || data.length === 0) return { 
            chunks: [], 
            sortedCountries: [], 
            groupedData: {}, 
            targetCompanyData: [],
            columnTotals: [],
            grandTotal: [] 
        };

        // filter out the target company
        const targetCompanyData = data.filter(item => item.company_id === targetCompanyId);

        // sort data based on country_rank ascending
        targetCompanyData.sort((a, b) => a.country_rank - b.country_rank);

        // Group data by country and department
        const groupedData = targetCompanyData.reduce((acc, item) => {
            if (!acc[item.geo3]) {
                acc[item.geo3] = {};
            }
            acc[item.geo3][item.dept_group] = item;
            return acc;
        }, {});

        // Sort countries by the target company's employee count
        const sortedCountries = Object.keys(groupedData).sort((a, b) => {
            const totalA = Object.values(groupedData[a]).reduce((sum, item) => sum + item.employee_count, 0);
            const totalB = Object.values(groupedData[b]).reduce((sum, item) => sum + item.employee_count, 0);
            return totalB - totalA;
        });

        // Create chunks
        const initial = sortedCountries.slice(0, INITIAL_SIZE);
        const remaining = sortedCountries.slice(INITIAL_SIZE);
        const chunks = [initial];
        
        for (let i = 0; i < remaining.length; i += CHUNK_SIZE) {
            chunks.push(remaining.slice(i, i + CHUNK_SIZE));
        }
        // Calculate column totals
        const columnTotals = headers.map(header => 
            targetCompanyData.reduce((sum, item) => {
                const deptToCompare = header === 'Customer Success' ? 'Customer Success & Support' : header;
                return item.dept_group === deptToCompare ? sum + (showPercentage ? item.pct_of_dept : item.employee_count) : sum;
            }, 0)
        );
        const grandTotal = showPercentage ? 1 : targetCompanyData.reduce((sum, item) => sum + item.employee_count, 0);

        return { chunks, sortedCountries, groupedData, targetCompanyData, columnTotals, grandTotal };
    }, [data, targetCompanyId, showPercentage]);


    const renderTable = (countries, chunkIndex, isLastTable) => {

        // Function to format cell value
        const formatCellValue = (value, total) => {
            if (showPercentage) {
                return value ? `${(value * 100).toFixed(1)}%` : '-';
            } else {
                return putCommasInNumber(value) || '-';
            }
        };

        const tableData = countries.map((country) => {
            const countryData = groupedData[country];
            const rowData = headers.map((header) => {
                let actualHeader = header;
                if (header === 'Customer Success') {
                    actualHeader = 'Customer Success & Support';
                }
                const item = countryData?.[actualHeader];
                return item ? (showPercentage ? item.pct_of_dept : item.employee_count) : 0;
            });

            const pctOverallDataForCountry = headers.map(header => {
                let actualHeader = header;
                if (header === 'Customer Success') {
                    actualHeader = 'Customer Success & Support';
                }
                const item = countryData[actualHeader];
                return item ? item.pct_of_overall : 0;
            });
            const total = showPercentage 
                ? pctOverallDataForCountry.reduce((sum, pct) => sum + pct, 0)
                : Object.values(countryData).reduce((sum, item) => sum + item.employee_count, 0);
            
            return (
                <tr key={country}>
                    <td className="font-bold">{country}</td>
                    {rowData.map((value, index) => (
                        <td key={index}>{formatCellValue(value, total)}</td>
                    ))}
                    <td>{formatCellValue(total, total)}</td>
                </tr>
            );
        });

        return (
            <>
            <table key={chunkIndex}
                   id={`geo-by-dept-table-hc-${chunkIndex}`}
                   className="company-table">
                <thead>
                    <tr>
                        <th style={{ width: "100px" }}>Countries</th>
                        {headers.map((header) => (
                            <th style={{ width: "7%" }}
                                key={header}>{header}</th>
                        ))}
                        <th style={{ width: "7%" }}>TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData}
                </tbody>
                {isLastTable && (
                    <tfoot>
                        <tr>
                            <td style={{ width: "100px" }}>TOTAL</td>
                            {columnTotals.map((total, index) => (
                                <td style={{ width: "7%" }} key={index}>{formatCellValue(total, grandTotal)}</td>
                            ))}
                            <td style={{ width: "7%" }}>
                                <b>{formatCellValue(grandTotal, grandTotal)}</b>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
            <br/>
            </>
        );
    };

    const tables = chunks.slice(0, visibleChunks).map((chunk, index) =>
        renderTable(chunk, index, index === visibleChunks - 1)
    );

    const title = `${targetCompanyName}: Employee Dispersion by Function as of Current Month - Absolute HC`;

    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting geo data...', 'Setting up table...', 'Wrapping things up...', 'Finished!']} interval={5000} />
                    </div>
                )
                :
                (
                <div id="geo-by-dept-table-hc-div">
                    <div style={{ marginLeft: '2%' }} className="chart-container-left-dbm">
                        {tables}
                        <div>
                            {visibleChunks < chunks.length && (
                                <button
                                    className="button"
                                    onClick={() => setVisibleChunks((prev) => Math.min(prev + 1, chunks.length))}
                                >
                                    Show Next {Math.min(CHUNK_SIZE, chunks[visibleChunks].length)} Countries
                                </button>
                            )}

                            {visibleChunks > 1 && (
                                <button className="button" onClick={() => setVisibleChunks(1)}>
                                    Reset to Top 15
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                )
            }
        </div>
    );

};

export default GeoByDeptTableHC;