import React from 'react';


const Dropdown = (props) => {
    // create items array
    const items = [];
    props.collections.forEach((collection) => {
        items.push({ name: collection.universe_display_name, url: `/universe/summary?universe_id=${collection.universe_id}` });
    });

    // Function to handle item click
    const handleItemClick = (url) => {
        window.open(url, "_blank"); // Opens the link in a new tab
    };

    return (
        <div className="dropdown">
        <button className="dropbtn">Collections</button>
        <div className="dropdown-content">
            { props.collections.length === 0 &&
                <a>No collections</a>
            }

            {items.map((item, index) => (
            <a key={index} onClick={() => handleItemClick(item.url)}>
                {item.name}
            </a>
            ))}
        </div>
        </div>
    );
};

export default Dropdown;
