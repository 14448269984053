import { toJS } from "mobx";
import { useEffect, useMemo, useState } from "react";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils.js";
import { standardBenchmarkReportDataStore } from "../../../stores/standard_benchmark_report_data_store";


const AvgCohortRetentionTable = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const NUM_HIRED_THRESHOLD = 5;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchRetentionHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const retentionHistoryData = standardBenchmarkReportDataStore.retentionHistoryData[targetCompanyId];

            if (retentionHistoryData !== null) {
                setData(toJS(retentionHistoryData));
                console.log('retentionHistoryData', toJS(retentionHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('retentionHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);


    const getProcessedCompanyRetentionData = (data) => {
        // Create a map where each key is a hired_year and the value is an object
        // with keys for each tenure month and values for retention.
        // Also store the num_people where company_exp_tenure_months_normalized is 1.
        const groupedByYear = {};

        data.forEach((item) => {
            const { hired_year, company_exp_tenure_months_normalized, pct_retained, num_people } = item;
            
            if (!groupedByYear[hired_year]) {
                groupedByYear[hired_year] = {
                    retention: {}, // Initialize retention data
                    // Do not initialize num_people here as we only want it for tenure = 1
                };
            }

            // Only store num_people for company_exp_tenure_months_normalized = 1
            if (company_exp_tenure_months_normalized === 1) {
                groupedByYear[hired_year].num_people = num_people;
            }

            // Store the retention data for each tenure month
            groupedByYear[hired_year].retention[company_exp_tenure_months_normalized] = `${(pct_retained * 100).toFixed(0)}%`;

        });

        // Now transform this map into an array of objects for rendering
        const processedData = Object.entries(groupedByYear).map(([year, data]) => {
            // Each entry in the array is an object with a key for the year, num_people, and each tenure month
            return {
                year,
                num_people: Math.round(parseFloat(data.num_people)) || 'N/A', // Use 'N/A' if num_people is undefined
                retention: data.retention,
            };
        });

        return processedData;
    };

    const getHeatmapColor = (percentage) => {
        if (percentage === undefined)
            return 'white';
        // You would define your own logic here to return a color based on the value
        const value = parseFloat(percentage.replace('%', ''));
        // if (value >= 80) return '#2CC0DD';
        // if (value >= 60) return '#25A2BB';
        // if (value >= 40) return '#1F8599';
        // if (value >= 20) return '#186777';
        if (value >= 80) return '#3cdfff';
        if (value >= 60) return '#63e5ff';
        if (value >= 40) return '#8aecff';
        if (value >= 20) return '#b1f2ff';
        return '#d8f9ff'; // Less than 20%
    };

    const tableContent = useMemo(() => {
        if (!data || data.length === 0) return null;

        const filteredData = data.filter(d => d.company_id === targetCompanyId && d.derived_department_group === 'All');
        const processedData = getProcessedCompanyRetentionData(filteredData);
        
        const monthToYear = (month) => {
            if (month === '1') {
                return 0;
            }

            if (month === '9999') {
                return 'current';
            }
            // const years = Math.floor(parseInt(month) / 12);
            // return years === 0 ? '0.5' : years.toString();

            return parseFloat(month) / 12;
        };

        const allYears = new Set();
        processedData.forEach(yearData => {
            Object.keys(yearData.retention).forEach(month => {
                allYears.add(monthToYear(month));
            });
        });
        const columns = [
            'Hiring Year', 
            'Num People Hired', 
            ...Array.from(allYears).sort((a, b) => {
                if (a === 'current') return 1;
                if (b === 'current') return -1;
                return parseFloat(a) - parseFloat(b);
            })
        ];

        const rows = processedData.map(yearData => {
            const row = {
                'Hiring Year': yearData.year,
                'Num People Hired': putCommasInNumber(yearData.num_people),
            };
            Object.entries(yearData.retention).forEach(([month, value]) => {
                if (row['Num People Hired'] < NUM_HIRED_THRESHOLD) {
                    row[monthToYear(month)] = '-';
                    return;
                }

                row[monthToYear(month)] = value;
              });
            return row;
        });


        return (
            <table className="company-table">
                <thead>
                    <tr>
                        <th/>
                        <th/>
                        <th colSpan={columns.length}> Years Retained </th>
                    </tr>
                    <tr>
                        {columns.filter(c => c !== 'current').map(column => (
                            <th key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr key={row['Hiring Year']}>
                            {columns.filter(c => c !== 'current').map(column => (
                                <td 
                                    key={column} 
                                    style={{backgroundColor: column !== 'Hiring Year' && column !== 'Num People Hired' ? getHeatmapColor(row[column] || '0%') : 'transparent'}}
                                >
                                    {row[column] || '-'}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                </table>
        );


    }, [data]);




    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {targetCompanyName}: Avg Cohort Retention By Year </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting retention data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <div id="avg-cohort-retention-table">
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            {tableContent}
                        </div>
                    </div>
                    <p> *Note: Retention data not shown when the Num Hired per Year is less than 5.* </p>
                </>
                )
            }
        </div>
    );


};

export default AvgCohortRetentionTable;
