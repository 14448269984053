// TableOfContents.js
import React from 'react';
import { Link } from 'react-scroll';

const TableOfContents = () => {
  const sections = [
    { id: 'signalScoreTable', name: 'Signal Score Table' },
    { id: 'employeePercentage', name: 'Employee Count' },
    { id: 'employeeHiringAttrition', name: 'Employee Hiring and Attrition' },
    { id: 'deptMakeupByFunction', name: 'Dept Makeup by Function' },
    { id: 'deptMakeupByFunctionTargetPeer', name: 'Dept Makeup by Function - Target vs Peer' },
    // { id: 'percentageDigitalTargetPeer', name: 'Percentage Digital Roles - Target vs Peer' },
    { id: 'deptGrowthRatesByFunction', name: 'Dept Growth Rates by Function' },
    { id: 'deptHiringTrend', name: 'Dept Hiring Trend' },
    { id: 'deptAttritionTrend', name: 'Dept Attrition Trend' },
    { id: 'deptNetHCTargetPeer', name: 'Dept Net HC - Target vs Peer' },
    { id: 'deptHiringTargetPeer', name: 'Dept Hiring - Target vs Peer' },
    { id: 'deptAttritionTargetPeer', name: 'Dept Attrition - Target vs Peer' },
    { id: 'deptSankey', name: 'Dept Talent Inflow/Outflow' },
    { id: 'deptCohortRetentionTargetPeer', name: 'Dept Avg Cohort Retention - Target vs Peer' },
    { id: 'avgCohortRetention', name: 'Dept Avg Cohort Retention' },
    { id: 'avgCohortRetentionByFunction', name: 'Dept Avg Cohort Retention by Function' },
    // // { id: 'cSuiteHires', name: 'C-suite Hires' },
    // // { id: 'cSuiteExits', name: 'C-suite Exits' },
    { id: 'companyCareerTenure', name: 'Company Career Tenure' },
    // { id: 'technicalSkills', name: 'Technical Skills'},
    { id: 'geoMap', name: 'Geo Map' },
    { id: 'geoTableHC', name: 'Geo Table - HC' },
    { id : 'geoTablePct', name: 'Geo Table - Pct' },
    { id: 'geoPeerTable', name: 'Geo Peer Table' },
    { id: 'seniorityMakeup', name: 'Dept Seniority Makeup' },
    { id: 'seniorityMakeupTargetPeer', name: 'Dept Seniority Makeup - Target vs Peer' },
    { id: 'seniorityPeerTable', name: 'Seniority Peer Table' },
    { id: 'detailedAnalysis', name: 'Detailed Analysis' },
    { id: 'appendix', name: 'Appendix' },
    { id: 'downloadReport', name: 'Download Report'},
    // Add more sections as needed
  ];

  return (
    <div className="table-of-contents">
      <h3>Table of Contents</h3>
      <ul>
        {sections.map((section) => (
          <li key={section.id}>
            <Link
              to={section.id}
              smooth={true}
              duration={500}
              spy={true}
              offset={-100} // Adjust this value based on your layout
              activeClass='active'
            >
              {section.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContents;
