import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiServrUrl } from '../../utils/utils';
import LoadingWithText from '../common/loading_text';

const ForgotPasswordComponent = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // API call to request password reset
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_RESET_PASSWORD_REQUEST_ENDPOINT;

            let data = {
                email: email,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                witCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                setMessage('A password reset link has been sent to your email address. Please follow the instructions in the email to reset your password.');
                // wait for 5 seconds and then redirect to login page
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            }
        } catch (error) {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                setMessage('Error processing password reset request. Please try again later.');
            }
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    
    };

    const handleCancel = (e) => {
        e.preventDefault();

        // go back to login page
        navigate('/login');
    };

    return (
        <div className='login-container'>
            <br/>
            <h1 style={{textAlign: 'center'}}> Welcome to Telemetry LVR</h1>
            <br/>
            <br/>
            { isLoading
                ? ( 
                <div>
                    <LoadingWithText texts={['Updating account', 'Sending password reset email', 'Done']} interval={3000}/>
                </div>
                )
                : (
                    <div style={{textAlign: 'center'}}>{message && <p>{message}</p>}</div>
                )
            }
            
            <div className="login-form-container">
                <form className='login-form' onSubmit={handleSubmit}>
                    <h2>Forgot Password</h2>

                    <div className='form-group'>
                        <label className='login-label' htmlFor="email">Email:</label>
                        <input
                            type="text"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <button className='login-button-forgot-password' type="submit">Reset Password</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button className='login-button-forgot-password' onClick={handleCancel}>Back to Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordComponent;
