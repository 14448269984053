import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Highcharts from 'highcharts';
import axios from 'axios';

import { customSort, getApiServrUrl, getLastDateOfMonth, getGoogleAnalyticsId, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { lineColors } from '../../utils/constants';
import { DownloadButton } from '../common/download_button';
import Select from 'react-select';

import { observer } from 'mobx-react';
import { metadataStore } from '../../stores/metadata_store';
import { growthDataStore } from '../../stores/growth_data_store';
import { dataVersionDateStore } from '../../stores/data_version_date_store';
import LoadingWithText from '../common/loading_text';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import Dropdown from '../common/collections_dropdown';
import { collectionsStore } from '../../stores/collections_store';
import { toJS } from 'mobx';
import { apiEventsStore } from '../../stores/api_events_store';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';
import { ExpandableList } from './company_growth_hires_attrits_list';


const CompanyGrowthChartComponent = observer(() => {
    const [data, setData] = useState(null);
    const [universeAvgData, setUniverseAvgData] = useState(null);
    
    const [sortedAdditionData, setSortedAdditionData] = useState(null);
    const [sortedAttritionData, setSortedAttritionData] = useState(null);
    const [totalHires, setTotalHires] = useState(0);
    const [totalAttrits, setTotalAttrits] = useState(0);

    const [additionAttritionDate, setAdditionAttritionDate] = useState('');
    const [collections, setCollections] = useState([]);
    
    const [geo1Values, setGeo1Values] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({value: 'All', label: 'All'});

    const [showUniverseAvgs, setShowUniverseAvgs] = useState(false);
    const [showHiringForGrowthChart, setShowHiringForGrowthChart] = useState(false);
    const [showAttritionForGrowthChart, setShowAttritionForGrowthChart] = useState(false);
    const [showNetGrowthForGrowthChart, setShowNetGrowthForGrowthChart] = useState(true);
    const [showGrowthProdForGrowthChart, setShowGrowthProdForGrowthChart] = useState(false);
    const [showIndustry, setShowIndustry] = useState(true);

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [isAdditionAttritionLoading, setIsAdditionAttritionLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [isUnAuthorized, setIsUnAuthorized] = useState(false);
    const [connectivityError, setConnectivityError] = useState(false);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;

    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`; 
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`;
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top-titles?company_id=${companyId}`;

    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
    }

    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
    }

    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;

        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }
    
    const sortOrder = {
        'owner': 0,
        'executive': 1,
        'vice president': 2,
        'director': 3,
        'manager': 4,
        'individual contributor': 5,
        'assistant': 6,
        'trainee': 7,
        'student / intern': 8,
        'unsure': 9,

        'Executive': 0,
        'Manager / Director': 3,
        'Individual Contributor': 5,
        'Intern': 8,
        'Other': 9,

        // new mappings 2023-03-15
        'pct_executive': 0,
        'pct_advisor_board': 1,
        'pct_leadership': 2,
        'pct_management': 3,
        'pct_individual_contributor': 4,
        'pct_student___intern': 5,
    };

    const deptSortOrder = {
        'Executive': 0,
        'Sales': 1,
        'Product': 2
    }

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };

    const getMetaData = async() => {
        // await both promises in parallel
        await Promise.all([
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ]);
        if (metadataStore.serverConnectionError) {
            // server connection error
            setConnectivityError(true);
            setIsMetaDataLoading(false);
        }
        else if (!metadataStore.isUnAuthorized) {
            setAdditionAttritionDate(new Date(dataVersionDateStore.dataVersionDate));
            
            setCompanyName(metadataStore.data[companyId].companyName);
            if (metadataStore.data[companyId].publicProfileCount !== undefined) {
                setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
            }
        }
        setIsUnAuthorized(metadataStore.isUnAuthorized);
        setIsMetaDataLoading(false);
    };

    const getGrowthData = async() => {
        
        try {
            await growthDataStore.fetchGrowthData(companyId, universeId, accessToken, dataset);
            const jsonData = growthDataStore.data[companyId];
            setData(jsonData);
            let geo1Values = [... new Set(jsonData.map((item) => item.geo1))];
            let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
            setGeo1Values(sortedGeo1Values);

            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                await collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId);
                setCollections(toJS(collectionsStore.collections[companyId]));
            };
           
            setIsUnAuthorized(growthDataStore.setUnAuthorized);
        } catch (err) {
            console.log(err);
            if (err.response !== undefined && (err.response.status == 401 || err.response.status == 403)) {
                setIsUnAuthorized(true);
            }
            
        }
        
        if (universeId !== null && universeId !== undefined) {
            try {
                const universeAvgData = growthDataStore.universeData[universeId];
                setUniverseAvgData(universeAvgData);
                setIsUnAuthorized(growthDataStore.setUnAuthorized);
            } catch (err) {
                console.log(err);
            }
        }

        setIsPageLoading(false);
    };

    const getAdditionsAttritionsData = async() => {
        if (additionAttritionDate !== '') {
            await Promise.all([
                getAdditionsData(dataset),
                getAttritionsData(dataset),
            ]);
        }
    };
    
    const getAdditionsData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ADDITIONS_ENDPOINT;
        console.log(`CompanyGrowth: Fetching growth additions data from ${apiUrl}...`);
        
        let data = {
            "company_ids": [parseInt(companyId)],
            "dataset": dataset,
            "year": additionAttritionDate.getUTCFullYear(),
            "month": additionAttritionDate.getUTCMonth() + 1,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            decompress: true,
            //withCredentials: true,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }

        try {
            setIsAdditionAttritionLoading(true);
            const response = await axios.post(apiUrl, data, config);
            let dateStr =  '';
            dateStr += additionAttritionDate.getUTCFullYear();
            if (additionAttritionDate.getUTCMonth() + 1  < 10) {
                dateStr += '-0' + (additionAttritionDate.getUTCMonth() + 1);
            }
            else {
                dateStr += '-' + (additionAttritionDate.getUTCMonth() + 1);
            }
            if (additionAttritionDate.getUTCDate() < 10) { 
                dateStr += '-0' + additionAttritionDate.getUTCDate();
            }
            else { 
                dateStr += '-' + additionAttritionDate.getUTCDate();
            }
            if (response.status == 200 && response.data.data.headcount_additions[companyId][dateStr] !== undefined) {
                const additionData = response.data.data.headcount_additions[companyId][dateStr];
                const additionDataSortedByDeptGroup = additionData.sort((a, b) => {
                    // Get sort order index, default to a large number if not found
                    const orderA = deptSortOrder[a.department_group] !== undefined ? deptSortOrder[a.department_group] : 1000;
                    const orderB = deptSortOrder[b.department_group] !== undefined ? deptSortOrder[b.department_group] : 1000;

                    if(orderA !== orderB) {
                        return orderA - orderB;
                    }

                    // If not found in deptSortOrder, sort alphabetically
                    return a.department_group.localeCompare(b.department_group);
                });

                let sortedData = {};
                additionDataSortedByDeptGroup.forEach((item) => {
                    const { department_group, seniority_group, title } = item;

                    if (!sortedData[department_group]) {
                        sortedData[department_group] = {};
                    }
                    if (!sortedData[department_group][seniority_group]) {
                        sortedData[department_group][seniority_group] = [];
                    }

                    sortedData[department_group][seniority_group].push({
                        title: item.title,
                        linkedin: item.linkedin_bug,
                    });
                });
           
                const senioritySortOrder = ["Executive", "Advisor / Board", "Leadership", "Management", "Individual Contributor", "Other"];
                
                // Sorting the titles within each seniority group alphabetically and keeping the LinkedIn URLs
                for (const deptGroup in sortedData) {
                    for (const senGroup in sortedData[deptGroup]) {
                        sortedData[deptGroup][senGroup].sort((a, b) => a.title.localeCompare(b.title));
                    }
                }

                // Sorting the seniority groups by the predefined order
                for (const deptGroup in sortedData) {
                    const sortedSeniorityGroups = {};
                    Object.keys(sortedData[deptGroup])
                    .sort((a, b) => {
                        const indexA = senioritySortOrder.indexOf(a);
                        const indexB = senioritySortOrder.indexOf(b);
                        
                        // If a or b is not found in senioritySortOrder, push it to the end
                        if (indexA === -1 && indexB === -1) {
                            return 0; // Preserve the original order
                        } else if (indexA === -1) {
                            return 1; // Push a to the end
                        } else if (indexB === -1) {
                            return -1; // Push b to the end
                        } else {
                            return indexA - indexB; // Normal comparison based on index
                        }
                    })
                    .forEach(senGroup => {
                        sortedSeniorityGroups[senGroup] = sortedData[deptGroup][senGroup];
                    });
                    
                    sortedData[deptGroup] = sortedSeniorityGroups;
                }

                // calculate the total number of titles
                let totalHires = 0;
                for (const deptGroup in sortedData) {
                    for (const senGroup in sortedData[deptGroup]) {
                        totalHires += sortedData[deptGroup][senGroup].length;
                    }
                }

                setSortedAdditionData(sortedData);
                setTotalHires(totalHires);

                if (userAccountDataStore.user !== null) {
                    // track growth event
                    apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                        companyId,
                        eventType: 'growth',
                        eventData: {
                            "url": apiUrl,
                            "path": process.env.REACT_APP_API_ADDITIONS_ENDPOINT,
                            "dataset": dataset,
                            "user_agent": navigator.userAgent,
                        },
                    });
                }
            }
            
        } catch (err) {
            console.log(err);
        }
    };

    const getAttritionsData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ATTRITIONS_ENDPOINT;
        console.log(`CompanyGrowth: Fetching growth attritions data from ${apiUrl}...`);
        
        let data = {
            "company_ids": [parseInt(companyId)],
            "dataset": dataset,
            "year": additionAttritionDate.getUTCFullYear(),
            "month": additionAttritionDate.getUTCMonth() + 1,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            decompress: true,
            // withCredentials: true,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }

        try {
            const response = await axios.post(apiUrl, data, config);
            let dateStr = '';
            dateStr += additionAttritionDate.getUTCFullYear();
            if (additionAttritionDate.getUTCMonth() + 1  < 10) {
                dateStr += '-0' + (additionAttritionDate.getUTCMonth() + 1);
            }
            else {
                dateStr += '-' + (additionAttritionDate.getUTCMonth() + 1);
            }
            if (additionAttritionDate.getUTCDate() < 10) { 
                dateStr += '-0' + additionAttritionDate.getUTCDate();
            }
            else { 
                dateStr += '-' + additionAttritionDate.getUTCDate();
            }
            if (response.status == 200 && response.data.data.headcount_attritions[companyId][dateStr] !== undefined) {
                const attritionData = response.data.data.headcount_attritions[companyId][dateStr];
                const attritionDataSortedByDeptGroup = attritionData.sort((a, b) => {
                    // Get sort order index, default to a large number if not found
                    const orderA = deptSortOrder[a.department_group] !== undefined ? deptSortOrder[a.department_group] : 1000;
                    const orderB = deptSortOrder[b.department_group] !== undefined ? deptSortOrder[b.department_group] : 1000;

                    if(orderA !== orderB) {
                        return orderA - orderB;
                    }

                    // If not found in deptSortOrder, sort alphabetically
                    return a.department_group.localeCompare(b.department_group);
                });
                
                let sortedData = {};
                attritionDataSortedByDeptGroup.forEach((item) => {
                    const { department_group, seniority_group, title } = item;

                    if (!sortedData[department_group]) {
                        sortedData[department_group] = {};
                    }
                    if (!sortedData[department_group][seniority_group]) {
                        sortedData[department_group][seniority_group] = [];
                    }

                    sortedData[department_group][seniority_group].push({
                        title: item.title,
                        linkedin: item.linkedin_bug,
                    });
                });
           
                const senioritySortOrder = ["Executive", "Advisor / Board", "Leadership", "Management", "Individual Contributor", "Other"];

                // Sorting the titles within each seniority group alphabetically and keeping the LinkedIn URLs
                for (const deptGroup in sortedData) {
                    for (const senGroup in sortedData[deptGroup]) {
                        sortedData[deptGroup][senGroup].sort((a, b) => a.title.localeCompare(b.title));
                    }
                }
                
                // Sorting the seniority groups by the predefined order
                for (const deptGroup in sortedData) {
                    const sortedSeniorityGroups = {};
                    Object.keys(sortedData[deptGroup])
                    .sort((a, b) => {
                        const indexA = senioritySortOrder.indexOf(a);
                        const indexB = senioritySortOrder.indexOf(b);
                        
                        // If a or b is not found in senioritySortOrder, push it to the end
                        if (indexA === -1 && indexB === -1) {
                            return 0; // Preserve the original order
                        } else if (indexA === -1) {
                            return 1; // Push a to the end
                        } else if (indexB === -1) {
                            return -1; // Push b to the end
                        } else {
                            return indexA - indexB; // Normal comparison based on index
                        }
                    })
                    .forEach(senGroup => {
                        sortedSeniorityGroups[senGroup] = sortedData[deptGroup][senGroup];
                    });
                    sortedData[deptGroup] = sortedSeniorityGroups;
                }

                // calculate the total number of titles
                let totalAttrits = 0;
                for (const deptGroup in sortedData) {
                    for (const senGroup in sortedData[deptGroup]) {
                        totalAttrits += sortedData[deptGroup][senGroup].length;
                    }
                }

                setSortedAttritionData(sortedData);
                setTotalAttrits(totalAttrits);

                if (userAccountDataStore.user !== null) {
                    // track growth event
                    apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                        companyId,
                        eventType: 'growth',
                        eventData: {
                            "url": apiUrl,
                            "path": process.env.REACT_APP_API_ATTRITIONS_ENDPOINT,
                            "dataset": dataset,
                            "user_agent": navigator.userAgent,
                        },
                    });
                }
            }

        } catch (err) {
            console.log(err);
        }
        setIsAdditionAttritionLoading(false);
    };

    function getOptionsGrowth() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedRegion.value;
        });
        const displayBenchmark = selectedRegion.value === 'All';
        const displayUniverseAvgs = universeAvgData !== null && showUniverseAvgs;

		const ltmHeadcountGrowthData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_net_hc_growth * 100).toFixed(1))];
		});
		const ltmEmployeeAdditionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_addition_rate * 100).toFixed(1))];
		});
		const ltmEmployeeAttritionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_attrition_rate * 100).toFixed(1))];
		});
        const ltmEmployeeGrowthProductivityData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.ltm_growth_productivity * 100).toFixed(1))];
        });
        const ltmAvgNetHCGrowthBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_net_hc_growth_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgAttritionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgAdditionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgGrowthProductivityBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_growth_prod_prev_12_mo_benchmark * 100).toFixed(1))];
        });

        const dataSeries = [];
        if (showNetGrowthForGrowthChart) {
            dataSeries.push(
            {
                type: 'spline',
                name: 'Net Headcount Growth (Last 12 months)',
                data: ltmHeadcountGrowthData,
                color: lineColors['Net Headcount'],
            });
        }
        if (showHiringForGrowthChart) {
            dataSeries.push(
            {
                type: 'spline',
                name: 'Hiring Rate (Last 12 months)',
                data: ltmEmployeeAdditionData,
                color: lineColors['Hiring'],
            });
        }
        if (showAttritionForGrowthChart) {
            dataSeries.push(
            {
                type: 'spline',
                name: 'Attrition Rate (Last 12 months)',
                data: ltmEmployeeAttritionData,
                color: lineColors['Attrition'],
            });
        }
        if (showGrowthProdForGrowthChart) {
            dataSeries.push(
            {
                type: 'spline',
                name: 'Growth Efficiency (Last 12 months)',
                data: ltmEmployeeGrowthProductivityData,
                color: lineColors['Growth Efficiency'],
            });
        }
        
        if (displayBenchmark && showIndustry) {
            if (showNetGrowthForGrowthChart) {
                dataSeries.push(
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Net HC Growth',
                    data: ltmAvgNetHCGrowthBenchmarkData,
                    color: lineColors['Net Headcount'],
                    dashStyle: 'Dash',
                });
            }
            if (showHiringForGrowthChart) {
                dataSeries.push(
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Hiring Rate',
                    data: ltmAvgAdditionRateBenchmarkData,
                    color: lineColors['Hiring'],
                    dashStyle: 'Dash',
                });
            }
            if (showAttritionForGrowthChart) {
                dataSeries.push(
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Attrition Rate',
                    data: ltmAvgAttritionRateBenchmarkData,
                    color: lineColors['Attrition'],
                    dashStyle: 'Dash',
                });
            }
            if (showGrowthProdForGrowthChart) {
                dataSeries.push(
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Growth Efficiency',
                    data: ltmAvgGrowthProductivityBenchmarkData,
                    color: lineColors['Growth Efficiency'],
                    dashStyle: 'Dash',
                });
            }
        }

        if (displayUniverseAvgs) {
            const filteredUniverseAvgData = universeAvgData.filter((item) => {
                if (selectedRegion.value === 'All') {
                    return item.geo1 === "Global";
                }

                return item.geo1 === selectedRegion.value;
            });
            const ltmAvgHeadcountGrowthData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_net_hc_growth * 100).toFixed(1))];
            });
            const ltmAvgEmployeeAdditionData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_addition_rate * 100).toFixed(1))];
            });
            const ltmAvgEmployeeAttritionData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_attrition_rate * 100).toFixed(1))];
            });
            const ltmAvgEmployeeGrowthProductivityData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_growth_productivity * 100).toFixed(1))];
            });

            dataSeries.push(
                {
                    type: 'spline',
                    name: 'Net Headcount Growth (Last 12 months) - Peer Avg',
                    data: ltmAvgHeadcountGrowthData,
                    color: lineColors['Net Headcount'],
                    dashStyle: 'DotDotDash',
                },
                {
                    type: 'spline',
                    name: 'Hiring Rate (Last 12 months) - Peer Avg',
                    data: ltmAvgEmployeeAdditionData,
                    color: lineColors['Hiring'],
                    dashStyle: 'DotDotDash',
                },
                {
                    type: 'spline',
                    name: 'Attrition Rate (Last 12 months) - Peer Avg',
                    data: ltmAvgEmployeeAttritionData,
                    color: lineColors['Attrition'],
                    dashStyle: 'DotDotDash',
                },
                {
                    type: 'spline',
                    name: 'Growth Efficiency (Last 12 months) - Peer Avg',
                    data: ltmAvgEmployeeGrowthProductivityData,
                    color: lineColors['Growth Efficiency'],
                    dashStyle: 'DotDotDash',
                },
            );

        }


		return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Headcount Metrics',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Rate (Last 12 months)'
				},
				labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setAdditionAttritionDate(new Date(dateStr));
                            }
                        }
                    },
                    events: {
                        // afterAnimate: function() {
                        //     if (!displayBenchmark) {
                        //         let chart = this.chart;
                        //         chart.renderer.text('Select All Regions to view benchmark data', 1100, 25)
                        //         .css({
                        //             fontSize: '14px',
                        //             color: 'gray',
                        //         })
                        //         .add();
                        //     }
                        // }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
		
	};

    function getOptionsTotalHeadcount() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedRegion.value;
        });
        const totalHeadcountData = filteredData.map((item) => {
            return [item.the_date, item.total_headcount];
        });
        const additionData = filteredData.map((item) => {
            let growthPct = item.new_headcount/item.total_headcount;
            return [item.the_date, parseFloat((growthPct * 100).toFixed(1))];
        });
        const attritionData = filteredData.map((item) => {
            let attritionPct = item.lost_headcount/item.total_headcount;
            return [item.the_date, parseFloat((-attritionPct * 100).toFixed(1))];
        });
        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            tooltip: {
                shared: true,
            },
			title: {
				text: 'Total Headcount (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: [
                {
				    title: {
					    text: 'Total Headcount (Public Profiles)'
				    },
                    height: '50%',
                    lineWidth: 2,
			    },
                {
                    title: {
                        text: 'Monthly Hiring and Attrition Rates',
                    },
                    top: '55%',
                    height: '45%',
                    offset: 0,
                    lineWidth: 2,
                    labels: {
                        format: '{value}%'
                    },
                },
            ],
			legend: {
				enabled: true,
			},
			
			series: [
				{
					type: 'column',
					name: 'Total Headcount (Public Profiles)',
					data: totalHeadcountData,
                    color: lineColors['Total Headcount'],
                    pointWidth: 10,
                    tooltip: {
                        // format tooltip to show commas in numbers
                        pointFormatter: function() {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                        }
                    },
                    opacity: 0.8,
				},
                {  
                    type: 'column',
                    name: 'Hiring',
                    data: additionData,
                    yAxis: 1,
                    color: lineColors['Hiring'],
                    pointWidth: 10,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'column',
                    name: 'Attrition',
                    data: attritionData,
                    yAxis: 1,
                    color: lineColors['Attrition'],
                    pointWidth: 10,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    function getOptionsGenderHeadcount() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedRegion.value;
        });
        const ltmCompanyPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_total_avg_f_prob * 100).toFixed(1))];
        });
        const ltmHiringPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1))];
        });
        const ltmAttritionPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1))]; 
        });
        const publicProfileData = filteredData.map((item) => {
            return [item.the_date, item.total_headcount];
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Gender Probability (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: [
                {
                    title: {
                        text: 'Percentage Female'
                    },
                    labels: {
                        format: '{value}%'
                    },
			    },
                {   
                    gridLineWidth: 0,
                    title: {
                        text: 'Total Headcount (Public Profiles)'
                    },
                    opposite: true,
                    labels: {
                        formatter: function() {
                            if (this.value >= 1000) {
                                return (this.value/1000) + 'k';
                            }
                            else if ((this.value >= 1000000)) {
                                return (this.value/1000000) + 'M';
                            }
                            return this.value;
                        }
                    },
                    //min: 0,
                },
            ],
			legend: {
				enabled: true,
			},

			tooltip: {
                shared: true,
            },

			series: [
				{
					type: 'column',
					name: 'Public Profiles',
					data: publicProfileData,
                    yAxis: 1,
                    color: lineColors['Public Profiles'],
                    pointWidth: 10,
                    opacity: 0.8,
                    zoomType: 'xy',
                    tooltip: {
                        // format tooltip to show commas in numbers
                        pointFormatter: function() {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                        }
                    },
				},
                {
                    type: 'spline',
                    name: 'Pct Female, Company Avg',
                    data: ltmCompanyPctFemaleData,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'spline',
                    name: 'Pct Female, New Hires (Last 12 months)',
                    data: ltmHiringPctFemaleData,
                    color: lineColors['Hiring'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'spline',
                    name: 'Pct Female, Attrits (Last 12 months)',
                    data: ltmAttritionPctFemaleData,
                    color: lineColors['Attrition'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);


    useEffect(() => {
        try {
            if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
                setIsMetaDataLoading(true);
                getMetaData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                setIsPageLoading(true);
                getGrowthData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [publicProfileCount]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                getAdditionsAttritionsData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [additionAttritionDate]);

    useEffect(() => {
        try {
            if (data !== null
                && document.getElementById('total-headcount-chart-container') !== null
                && document.getElementById('company-gender-chart-container') !== null
            ) {
                let totalHeadcountOptions = getOptionsTotalHeadcount();
                Highcharts.chart('total-headcount-chart-container', totalHeadcountOptions);

                let companyGenderOptions = getOptionsGenderHeadcount();
                Highcharts.chart('company-gender-chart-container', companyGenderOptions);
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [data, universeAvgData, isUnAuthorized, companyName, publicProfileCount, selectedRegion]);

    useEffect(() => {
        try {
            if (data !== null
                && document.getElementById('growth-chart-container') !== null
            ) {
                let growthChartOptions = getOptionsGrowth();
                Highcharts.chart('growth-chart-container', growthChartOptions);
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [data, universeAvgData, isUnAuthorized, companyName, publicProfileCount, selectedRegion, showUniverseAvgs, showIndustry, showHiringForGrowthChart, showAttritionForGrowthChart, showNetGrowthForGrowthChart, showGrowthProdForGrowthChart]);


    const handleGeo1ChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one region must be selected');
            return;
        }
        setSelectedRegion(selectedOption);
    };

    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    function handleTabClick(path) {
        let type = '';
        if (path.includes("team")) {
            type = "Team Growth";
        }
        else if (path.includes("talent")) {
            if (path.includes("retention")) {
                type = "Talent Retention";
            }
            else {
                type = "Talent Flow";
            }
        }
        else if (path.includes("tenure")) {
            type = "Tenure";
        }


        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Growth",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Growth",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
        }
        navigate(path);
    };

    if (connectivityError) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. The server connection is taking too long. Please try again later.</p>
            </div>
        );
    }
    else if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if ( publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={2000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        let loadingTexts = ['Fetching company data...', 'Setting up filters...', 'Adding benchmark data...'];
        if (universeId !== null && universeId !== undefined) {
            loadingTexts.push('Adding universe data...');
        }
        loadingTexts.push('Finishing charts...');
        loadingTexts.push('Adding final touches...');
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px'}}/>
                        <div className="button active">
                            Company
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                           <a href={teamGrowthPath}>Teams</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={talentFlowPath}>Talent Flow</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        {
                            displayTalentRetention() && (
                                <div className="button">
                                    <a href={talentRetentionPath}>Talent Retention</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}}/>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}> Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        <div className="button">
                            <a href={tenurePath}>Tenure</a>
                        </div> 
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={topTitlesPath}> Top Titles </a>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                        {/* { universeId !== null && (
                            <NavLink 
                                style={{border: 'black 2px solid'}}
                                className='button'
                                to={universeSummaryPath}
                                state={{universeId: universeId, accessToken: accessToken}}
                                target="_blank" rel="noopener noreferrer"
                            >
                                View Universe
                            </NavLink>
                        )} */}
                        { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Company Growth: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <LoadingWithText texts={loadingTexts} interval={2000} />
                ) : (
                <div>
                <div className='checkbox-group-container'> 
                    <div className='checkbox-container'>
                        <label htmlFor='regions'>Region:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="regions"
                            options={geo1Values.map((value) => ({value: value, label: value}))}
                            value={selectedRegion}
                            onChange={handleGeo1ChangeWithSelect}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <br/>
                <div style={{textAlign: 'center'}}>
                    {/* { universeId !== null && (
                        <label>
                            <input type="checkbox" checked={showUniverseAvgs} onChange={() => setShowUniverseAvgs(!showUniverseAvgs)} />
                            &nbsp;Compare with Universe Average
                        </label>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp; */}
                    <label>
                        <input type="checkbox" checked={showIndustry} onChange={() => setShowIndustry(!showIndustry)} />
                        &nbsp;Compare with Industry Benchmark
                    </label>
                </div>
                <br/>
                <br/>
                {
                    showIndustry && selectedRegion.value !== 'All'
                    ? (
                        <div style={{right: '450px', marginBottom: '50px', position: 'absolute'}}>
                            Select All Regions to view Benchmark data
                        </div>
                    )   
                    : null
                }
                <div style={{position: 'absolute', right: '25px', maxWidth: '390px', display: 'block', textAlign: 'center'}}>Click on the Headcount Metrics chart to list new hires and attritions for a particular time</div>
                <br/>
                <br/>
                <div className='bordered-box'>
                    <label>
                        <input type="checkbox" checked={showNetGrowthForGrowthChart} onChange={() => setShowNetGrowthForGrowthChart(!showNetGrowthForGrowthChart)} />
                        &nbsp;Net Headcount Growth
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showHiringForGrowthChart} onChange={() => setShowHiringForGrowthChart(!showHiringForGrowthChart)} />
                        &nbsp;Hiring Rate
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showAttritionForGrowthChart} onChange={() => setShowAttritionForGrowthChart(!showAttritionForGrowthChart)} />
                        &nbsp;Attrition Rate
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                        <input type="checkbox" checked={showGrowthProdForGrowthChart} onChange={() => setShowGrowthProdForGrowthChart(!showGrowthProdForGrowthChart)} />
                        &nbsp;Growth Efficiency
                    </label>
                </div>
                   
                <div className='chart-container'>
                    <div className="chart-container-left">
                        <div className="chart-container-left-child" id="growth-chart-container"></div>
                        <br/>
                        <div className="chart-container-left-child" id="total-headcount-chart-container"></div>
                        <br/>
                        <div className='chart-container-left-child' id="company-gender-chart-container"></div>
                        
                    </div>    
                    <div className='chart-container-right'>
                        
                        <div className = 'chart-container-right-child' id="addition-container">
                            <span style={{ fontWeight: 'bold', display: 'block', textAlign: 'center', paddingBottom: '10px'}}> New Hires ({additionAttritionDate.toLocaleString('default',  {month: 'long', year: 'numeric'})}) ({totalHires})</span>
                            { isAdditionAttritionLoading ? (
                                <LoadingWithText texts={['Requesting new hires data...', 'Preparing the list...', 'Finishing up...']} interval={1000} />
                            ) : sortedAdditionData ? (
                                
                                <div>
                                    <ExpandableList data={sortedAdditionData} />
                                </div>
                            ) : (
                                <p> No data </p>
                            )}
                        </div>
                        <br/>
                        <div className = 'chart-container-right-child' id="attrition-container">
                            <span style={{ fontWeight: 'bold', display: 'block', textAlign: 'center', paddingBottom: '10px'}}> Recent Attrits ({additionAttritionDate.toLocaleString('default',  {month: 'long', year: 'numeric'})}) ({totalAttrits})</span>
                            { isAdditionAttritionLoading ? (
                                <LoadingWithText texts={['Requesting new attrits data...', 'Preparing the list...', 'Finishing up...']} interval={1000} />
                            ) : sortedAttritionData ? (
                                <div>
                                    <ExpandableList data={sortedAttritionData} />
                                </div>
                            ) : (
                                <p> No data </p>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data={data} 
                        companyName={companyName} 
                        buttonText={ 'Download Company Data' }
                        fileName={ `${companyName}_company_data.csv` }
                        dataType={'companyGrowth'}
                        accessToken={accessToken}
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }   
});

export default CompanyGrowthChartComponent;
