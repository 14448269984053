import React from 'react';
import axios from 'axios';  
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CompanySearchResultsTable } from './company_search_results_table';
import { getApiServrUrl, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { ClipLoader } from 'react-spinners';
import { NavLink } from 'react-router-dom';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { apiEventsStore } from '../../stores/api_events_store';
import LoadingWithText from '../common/loading_text';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';


const CompanySearchWithCreateUniverseComponent = observer(() => {
	const demoText = 'Enter Linkedin URLs as follows:\nhttps://www.linkedin.com/company/apple/\nhttps://www.linkedin.com/company/microsoft/\nhttps://www.linkedin.com/company/google/\n';
			
    const [inputValue, setInputValue] = useState(demoText);
	const [apiData, setApiData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [createUniverseLoading, setCreateUniverseLoading] = useState(false);
	const [universeName, setUniverseName] = useState('');
	const [universeId, setUniverseId] = useState(''); // This is the universe_id of the newly created universe
	const [universeSummaryPath, setUniverseSummaryPath] = useState(''); // This is the path to the Universe Summary page [used in the "View Universe" button
	const [workflowExecutionName, setWorkflowExecutionName] = useState(''); // This is the workflow execution name [used in the "View Universe" button

	const [isUnAuthorized, setIsUnAuthorized] = useState(false);  
	const [selectedRows, setSelectedRows] = useState({});
	const [selectAll, setSelectAll] = useState(true);
	const [noResults, setNoResults] = useState([]); // This is used to show the "No Results" message

    const [searchParams] = useSearchParams();
	const navigate = useNavigate();
    const location = useLocation();
	
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isTextContainerVisible, setIsTextContainerVisible] = useState(false);

	const [createUniverseModalVisible, setCreateUniverseModalVisible] = useState(false);

	const [isCreateCompositeModalVisible, setIsCreateCompositeModalVisible] = useState(false);
	const [companiesForCompositeAsset, setCompaniesForCompositeAsset] = useState([]);
	const [selectedCompaniesForCompositeAsset, setSelectedCompaniesForCompositeAsset] = useState({});
	const [fromDatesForCompositeAssets, setFromDatesForCompositeAssets] = useState({});
	const [compositeRows, setCompositeRows] = useState([]);
	const [compositeAssetName, setCompositeAssetName] = useState('');
	const [runAlphaBenchmark, setRunAlphaBenchmark] = useState(false);
	const [workflowEnv, setWorkflowEnv] = useState('PROD1');


    const accessToken = searchParams && searchParams.get('access_token') || location && location.state && location.state.accessToken;

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const displayCompositeAssets = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
			const KF_DIGITAL_REPORT_ACCESS_LIST = ['amelia.haynes@kornferry.com', 'andrew.hall@kornferry.com', 'emily.gianunzio@kornferry.com', 'stephen.lams@kornferry.com', 'tessa.misiaszek@kornferry.com'];
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
			if (emailDomain === "kornferry.com" && KF_DIGITAL_REPORT_ACCESS_LIST.includes(userAccountDataStore.user.email)) {
				return true;
			}
            if (emailDomain === "endava.com" 
				|| emailDomain === "bcg.com"
				|| emailDomain === "telemetryllc.com" 
				|| emailDomain === "telemetry-llc.com"
			) {
                return true;
            }
        }
        return false;
    };

	const displayAlphaBenchmarkCheckbox = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com") {
                return true;
            }
        }
        return false;
    };

	const displayWorkflowEnvOptions = () => {
		if (process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging") {
			if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
				let emailDomain = userAccountDataStore.user.email.split('@')[1];
				if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com") {
					return true;
				}
			}
		}

        return false;
    };


	useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
				const refreshUserData = async () => {
					try {
						await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
						if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
					} catch (err) {
						console.log(err);
					}
				};
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);
	
	useEffect(() => {
		if (inputValue.trim() === '') {
			// set demo text with urls that are separated by newlines
			setInputValue(demoText);
		}
		// setSelectedData('');
	}, []);
	
	const handleInputFocus = () => {
		if (inputValue === demoText) {
			setInputValue('');
		}
	};

	const handleInputBlur = () => {
		if (inputValue.trim() === '') {
			setInputValue(demoText);
		}
	};

	const handleSearchClick = async () => {
		if (inputValue.trim() === '' || inputValue.trim() === demoText) {
			return;
		}

		setIsLoading(true);
		setApiData([]);
		setSelectedRows({});
		setNoResults([]);
		setUniverseName('');

		setCompaniesForCompositeAsset([]);
		setSelectedCompaniesForCompositeAsset({});
		setFromDatesForCompositeAssets({});
		setCompositeRows([]);
		setCompositeAssetName('');

		let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_SEARCH_ENDPOINT;
       
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
			decompress: true,
			// withCredentials: true,
        };
		if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }
		
		// trim inputValue to get the shorthand_names
		let linkedin_urls = [];
		if (inputValue.includes('\n')) {
			// split the inputValue by newline
			linkedin_urls = inputValue.trim().split('\n');
		}
		else {
			// inputValue is a single url
			linkedin_urls.push(inputValue);
		}
		// remove any spaces from the linkedin_urls
		linkedin_urls = linkedin_urls.map(url => url.trim());
		// remove any `\n` chars from the linkedin_urls
		linkedin_urls = linkedin_urls.map(url => url.replace(/\n/g, ''));
		// remove empty strings from the linkedin_urls
		linkedin_urls = linkedin_urls.filter(url => url !== '');
		// remove any duplicates from the linkedin_urls
		linkedin_urls = linkedin_urls.filter((url, index, self) => self.indexOf(url) === index);


		let shorthand_names = [];
		for (let i = 0; i < linkedin_urls.length; i++) {
			let url = linkedin_urls[i];
            // check if last character is a slash
            if (url[url.length - 1] === '/') {
                url = url.slice(0, -1);
            }

			// remove the protocol
			url = url.replace(/(^\w+:|^)\/\//, '');
			let parts = url.split('/');

			// the third part is the shorthand_name
			// the third part is the shorthand_name
			let shorthandName = parts[2];
			// first decode and then encode the shorthand_name to handle % encoded characters
			let decodedShorthandName = decodeURIComponent(shorthandName);
			let encodedShorthandName = encodeURIComponent(decodedShorthandName).toLowerCase().replace(/'/g, "%27");
			shorthand_names.push(encodedShorthandName);
		}

		let data = {
			'shorthand_names': shorthand_names
		}

        try {
            const response = await axios.post(apiUrl, data, config)
            if (response.status === 200 & response.data.data.results.length !== 0) {
                const responseData = JSON.parse(response.data.data.results);
				// const shorthandNameToDataMap = new Map(responseData.map((item) => [item.shorthand_name, item]));
				const found = [];
				const notFound = [];
				for (let i = 0; i < responseData.length; i++) {
					const item = responseData[i];
					for (let j = 0; j < shorthand_names.length; j++) {
						if (item.shorthand_name === shorthand_names[j]) {
							found.push(item);
						}
					}
				}

				// sort the data by the order of the shorthand_names
				const orderedData = [];
				for (let i = 0; i < shorthand_names.length; i++) {
					for (let j = 0; j < found.length; j++) {
						if (found[j].shorthand_name === shorthand_names[i]) {
							orderedData.push(found[j]);
						}
					}
				}

				setApiData(orderedData);
				// select all the rows by default
				const newSelectedRows = {};
				const fromDates = {};
				responseData.forEach((item, index) => {
					newSelectedRows[index] = item;
					fromDates[item.company_id] = "1900-01";
				});
				setSelectedRows(newSelectedRows);
				setFromDatesForCompositeAssets(fromDates);
                
				if (responseData.length !== linkedin_urls.length) {
					// search didnt return results for all the companies
					let noResults = [];
					let responseDataLinkedinUrls = [];
					for (let i = 0; i < responseData.length; i++) {
						responseDataLinkedinUrls.push(responseData[i].linkedin_url);
					}

					for (let i = 0; i < linkedin_urls.length; i++) {
						if (!responseDataLinkedinUrls.includes(linkedin_urls[i])) {
							noResults.push(linkedin_urls[i]);
						}
					}

					setNoResults(noResults);
				}
            }
			
			if (userAccountDataStore.user !== null) {

				mixpanelActions.track("Company Search", {
					user: toJS(userAccountDataStore.user),
					linkedinUrls: linkedin_urls,
				});
				amplitudeActions.track("Company Search", {
					user: toJS(userAccountDataStore.user),
					linkedinUrls: linkedin_urls,
				});

				// track search event
				apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
					companyId: null,
					eventType: 'search',
					eventData: {
						"url": apiUrl,
						"path": process.env.REACT_APP_API_SEARCH_ENDPOINT,
						"dataset": null,
						"user_agent": navigator.userAgent,
					},
				});
			}
        }
        catch (error) {
            console.log(error);
			if (error.response && error.response.status === 401 || error.response.status === 403) {
				setIsUnAuthorized(true);
			}
            // setIsLoading(false);
        }
		finally {
			setIsLoading(false);
		}

	};

	const handleClear = () => {
		setInputValue(demoText); // Clear the input field
		setApiData([]);        // Clear the data
		setSelectedRows({}); // Clear the selected rows
		setNoResults([]); // Clear the noResults array
		setUniverseId(''); // Clear the universeId
		setUniverseSummaryPath(''); // Clear the universeSummaryPath
		setWorkflowExecutionName(''); // Clear the workflowExecutionName
		setCompaniesForCompositeAsset([]);
		setSelectedCompaniesForCompositeAsset({});
		setFromDatesForCompositeAssets({});
		setCompositeRows([]);
		setCompositeAssetName('');
		setIsLoading(false); // Clear the loading flag
	};

	const handleCheckboxChange = (index, item) => {
		setSelectedRows(prevState => {
		  const newState = { ...prevState };
	  
		  if (prevState[index]) {
			delete newState[index];
		  } else {
			newState[index] = item;
		  }
	  
		  // Check if all rows are selected after this change
		  if (Object.keys(newState).length === apiData.length) {
			setSelectAll(true);
		  } else {
			setSelectAll(false);
		  }
	  
		  return newState;
		});
	  };

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedRows({});
		} 
		else {
			const newSelectedRows = {};
			apiData.forEach((item, index) => {
				newSelectedRows[index] = item;
			});
			setSelectedRows(newSelectedRows);
		}
		
		setSelectAll(!selectAll);
	};
	  
	const openCreateUniverseModal = () => {
		if (!universeName || universeName.trim() === '') {
			alert('Please provide a Collection Name.');
			return;
		}
		
		if (Object.keys(selectedRows).length === 0 && compositeRows.length === 0) {
			alert('Please select at least one regular company or a composite asset to save to a Collection.');
			return;
		}
		setCreateUniverseModalVisible(true);
	}

	const handleCreateUniverse = async () => {
		if (!universeName || universeName.trim() === '') {
		  alert('Please provide a Collection Name.');
		  return;
		}
		
		if (Object.keys(selectedRows).length === 0 && compositeRows.length === 0) {
			alert('Please select at least one regular/composite company to save to a Collection.');
			return;
		}

		if(compositeRows.length > 0) {
			console.log('Creating universe with composite assets...');
			await handleCreateUniverseWithCompositeAsset();
		}
		else {
			setIsModalVisible(true);
			
			setCreateUniverseLoading(true);
			let data = {
				'companies': [],
				'universe_name': universeName,
				'client_email': toJS(userAccountDataStore.user).email,
			};

			// Add the selected companies to the data object
			for (let key in selectedRows) {
				// check if key is true
				if (selectedRows[key]) {
					data.companies.push({
						'company_id': selectedRows[key].company_id,
						'linkedin_url': selectedRows[key].linkedin_url,
					});
				}
			};

			// remove duplicate companies
			data.companies = data.companies.filter((company, index, self) => {
				// Find the index of the first occurrence of the company with the same company_id
				const firstIndex = self.findIndex((t) => t.company_id === company.company_id);
				// Include the current company only if its index matches the first occurrence's index
				return index === firstIndex;
			});

			let config = { 
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + accessToken,
				},
				decompress: true,
				// withCredentials: true,
			};
			if (accessToken === null || accessToken === undefined || accessToken === '') {
				config['withCredentials'] = true;
			}
			
			try {
				let apiUrl = getApiServrUrl();
				apiUrl += process.env.REACT_APP_API_UNIVERSE_CREATE_ENDPOINT;
				console.log(`CompanySearch: Creating universe at ${apiUrl}...`);
				const response = await axios.post(apiUrl, data, config)
				if (response.status === 200 && response.data.data.universe_id !== '') {
					const universeId = response.data.data.universe_id;
					setUniverseId(universeId);
					if(userAccountDataStore.user !== null) {
						setUniverseSummaryPath(`/universe/summary?universe_id=${universeId}`);
					}
					else {	
						setUniverseSummaryPath(`/universe/summary?universe_id=${universeId}&access_token=${accessToken}`);
					}
				}

				if (userAccountDataStore.user !== null) {
					mixpanelActions.track("Save Collection", {
						user: toJS(userAccountDataStore.user),
						universeName: universeName,
						companies: data.companies,
					});
					amplitudeActions.track("Save Collection", {
						user: toJS(userAccountDataStore.user),
						universeName: universeName,
						companies: data.companies,
					});

					// track create universe event
					apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
						companyId: null,
						eventType: 'universe_create',
						eventData: {
							"url": apiUrl,
							"path": process.env.REACT_APP_API_UNIVERSE_CREATE_ENDPOINT,
							"dataset": null,
							"user_agent": navigator.userAgent,
							"universe_name": universeName,
							"company_ids": data.companies.map(company => company.company_id),
						},
					});
				}
			}
			catch (error) {
				console.log(error);
				if (error.response && error.response.status === 401 || error.response.status === 403) {
					setIsUnAuthorized(true);
				}
			}
			finally {
				setCreateUniverseLoading(false);
				setIsModalVisible(false);
				setCreateUniverseModalVisible(false);
				setIsTextContainerVisible(true);
				// setUniverseName('');
			}
		}
	};

	const handleCreateUniverseWithCompositeAsset = async () => {
		
		let data = {
			'companies': [],
			'universe_name': universeName,
			'client_email': toJS(userAccountDataStore.user).email,
			'composite_assets': compositeRows,
			'run_alpha': runAlphaBenchmark,
			'workflow_env': workflowEnv,
		};

		// get the company ids of the composite assets
		const compositeCompanies = [];
		compositeRows.map((composite) => {
			compositeCompanies.push(...composite.data.companyIds);
		});

		// Add the selected companies to the data object but only if they are not part of a composite asset
		for (let key in selectedRows) {
			// check if key is true
			if (selectedRows[key]) {
				if (compositeCompanies.includes(parseInt(selectedRows[key].company_id))) {
					// do nothing
				}
				else {
					data.companies.push({
						'company_id': selectedRows[key].company_id,
						'linkedin_url': selectedRows[key].linkedin_url,
					});
				}
			}
		};

		let config = { 
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + accessToken,
			},
			decompress: true,
			// withCredentials: true,
		};
		if (accessToken === null || accessToken === undefined || accessToken === '') {
			config['withCredentials'] = true;
		}
		
		try {
			setIsModalVisible(true);
			
			setCreateUniverseLoading(true);

			let apiUrl = getApiServrUrl();
			apiUrl += process.env.REACT_APP_API_UNIVERSE_CREATE_ENDPOINT;
			console.log(`CompanySearch: Creating universe with composite assets at ${apiUrl}...`);
			const response = await axios.post(apiUrl, data, config)
			if (response.status === 200 && response.data.data.universe_id !== '') {
				const universeId = response.data.data.universe_id;
				const workflowExecutionName = response.data.data.execution_name;
				setUniverseId(universeId);
				setWorkflowExecutionName(workflowExecutionName);
				if(userAccountDataStore.user !== null) {
					setUniverseSummaryPath(`/universe/summary?universe_id=${universeId}`);
				}
				else {	
					setUniverseSummaryPath(`/universe/summary?universe_id=${universeId}&access_token=${accessToken}`);
				}
			}

			if (userAccountDataStore.user !== null) {
				mixpanelActions.track("Save Collection", {
					user: toJS(userAccountDataStore.user),
					universeName: universeName,
					companies: data.companies,
					compositeCompanies: compositeRows,
				});
				amplitudeActions.track("Save Collection", {
					user: toJS(userAccountDataStore.user),
					universeName: universeName,
					companies: data.companies,
					compositeCompanies: compositeRows,
				});

				// track create universe event
				apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
					companyId: null,
					eventType: 'universe_create_composite',
					eventData: {
						"url": apiUrl,
						"path": process.env.REACT_APP_API_UNIVERSE_CREATE_ENDPOINT,
						"dataset": null,
						"user_agent": navigator.userAgent,
						"universe_name": universeName,
						"company_ids": data.companies.map(company => company.company_id),
						"composite_assets": compositeRows.map(composite => composite.name),
					},
				});
			}
		}
		catch (error) {
			console.log(error);
			if (error.response && error.response.status === 401 || error.response.status === 403) {
				setIsUnAuthorized(true);
			}
		}
		finally {

			setIsModalVisible(false);
			setCreateUniverseLoading(false);
			document.getElementById('saveCollectionText').style.display = 'none';
			document.getElementById('compositeCollectionRequest').style.display = 'block';

			//use timeout and then redirect to home
			setTimeout(() => {
				navigate('/home');
			}, 5000);
		}
		
	};
	
	const handleOkClick = () => {
		setIsTextContainerVisible(false);
		navigate(universeSummaryPath, { state: { universeId: universeId, accessToken: accessToken }});
	};

	const openCompositeModal = () => {
		setIsCreateCompositeModalVisible(true);
	};

	const closeCompositeAssetModal = () => {
		setIsCreateCompositeModalVisible(false);
		setCompositeAssetName('');
		// setCompaniesForCompositeAsset([]);
		setSelectedCompaniesForCompositeAsset({});
		// setFromDatesForCompositeAssets([]);
	};

	const handleFromDateForCompositeAsset = (value, company_id, index) => {
		const newFromDates = {...fromDatesForCompositeAssets};
		newFromDates[company_id] = value;
		setFromDatesForCompositeAssets(newFromDates);
	};

	const handleDeleteCompositeRow = (index) => {
		const toDeleteCompanies = compositeRows[index].data.companyIds;
		const updatedRows = [...compositeRows];
		updatedRows.splice(index, 1);
		setCompositeRows(updatedRows);

		const newCompaniesForCompositeAsset = companiesForCompositeAsset.filter((company) => !toDeleteCompanies.includes(company.company_id));
		setCompaniesForCompositeAsset(newCompaniesForCompositeAsset);
	};

	const handleCompositeAssetCheckboxChange = (company) => {
		// if (companiesForCompositeAsset.includes(company)) {
		// 	setCompaniesForCompositeAssets(companiesForCompositeAsset.filter((c) => c !== company));
		// } else {
		// 	setCompaniesForCompositeAssets([...companiesForCompositeAsset, company]);
		// }
		setSelectedCompaniesForCompositeAsset(prevCheckedCompanies => ({
			...prevCheckedCompanies,
			[company.company_id]: !prevCheckedCompanies[company.company_id]
		}));
		
	};

	const getComapniesForCompositeAsset = () => {
		return apiData.filter(company => !companiesForCompositeAsset.includes(company));
	};

	const getCompaniesForCreateUniverseModalDisplay = () => {
		const companies = {};
		for (let key in selectedRows) {
			const companyName = selectedRows[key].name;
			let isIncludedInACompositeAsset = false;
			for (let i=0; i<compositeRows.length; i++) {
				const compositeCompanies = compositeRows[i].display.companies;
				if (compositeCompanies.includes(companyName)) {
					isIncludedInACompositeAsset = true;
					break;
				}
			}
			if(!isIncludedInACompositeAsset) {
				companies[key] = selectedRows[key];
			}
		}

		return companies;
	}

	const handleCreateCompositeAsset = () => {
		if (Object.keys(selectedCompaniesForCompositeAsset).length < 2) {
			// Handle validation or show error message
			alert('Need to select atleast 2 companies to create a composite asset!');
			return;
		}

		if (!compositeAssetName) {
			alert('Please provide a name for the composite asset!');
			return;
		}
		
		const companies = [];
		const companyIds = [];
		const companyShorthandNames = [];
		const asOfDates = [];
		const linkedinUrls = [];
		const newCompaniesForCompositeAsset = [...companiesForCompositeAsset];
		apiData.map((item) => {
			if (item.company_id in selectedCompaniesForCompositeAsset) {
				if (selectedCompaniesForCompositeAsset[item.company_id] === false) {
					return;
				}
				// companies.push(`${item.name}(as of ${fromDatesForCompositeAssets[item.company_id]})`);
				companies.push(item.name);
				companyIds.push(item.company_id);
				companyShorthandNames.push(item.company_shorthand_name);
				linkedinUrls.push(item.linkedin_url);
				asOfDates.push(fromDatesForCompositeAssets[item.company_id]);
				newCompaniesForCompositeAsset.push(item);
			}
		})

		let companiesStr = '';
		companies.forEach((item, index) => {
			if (index > 0) {
				companiesStr += ', '
			}
			companiesStr += `${item} (as of ${asOfDates[index]})`;
		});

		const newRow = {
			name: compositeAssetName,
			display: {
				companies: companies.join(', '),
				asOfDates: companiesStr,
				linkedin: linkedinUrls.join(', '),
			},
			data: {
				companyIds: companyIds,
				asOfDates: asOfDates,
				linkedin: linkedinUrls,
			}
		};

		setCompositeRows([...compositeRows, newRow]);
		setCompaniesForCompositeAsset(newCompaniesForCompositeAsset);
		closeCompositeAssetModal();
	};

	const handleRunAlphaCheckbox = () => {
		setRunAlphaBenchmark(!runAlphaBenchmark);
	};

	useEffect(() => {
		if (apiData.length > 0) {
		  setTimeout(() => {
			window.scrollTo({
			  top: document.documentElement.scrollHeight,
			  behavior: 'smooth'
			});
		  }, 100);
		}
	}, [apiData]);


	if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
	else {
		return (
			<div style={{textAlign: 'center'}}>
				{isModalVisible && <div className="modalOverlay"></div>}
				<h1 id="page-title">
					Create a Collection
				</h1>
				<div>
					<h3>
						Step 1: Company Search
					</h3>
					<p>
						<span>
							Please enter a list of LinkedIn URLs to search for companies.
						</span>
					</p>
				</div>
				<div style={{alignItems: 'center'}}>
					<textarea
						id='linkedin-urls'
						value={inputValue} 
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						style={{ width: '75%', height: '100px', fontSize: '16px', padding: '10px', borderRadius: '20px' }} 
					/>
					<br/>
					<button 
						className='button'
						onClick={handleSearchClick}
					>
						Search
					</button>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<button 
						className='button'
						onClick={handleClear}
					>
						Clear
					</button>
				</div>
				{ apiData.length === 0
					// check if isLoading is true
					? (isLoading
						// if isLoading is true, show Loading...
						? (<div style={{textAlign: 'center'}}> <LoadingWithText texts={["Searching the database...", "Setting up the results table...", "Adding final touches...", "Finished!"]} interval={8000}/> </div>)
						// if isLoading is false, show nothing
						: (<div></div>)
					)
					// if apiData is not empty, show the table
					: (
						<div style={{alignItems: 'center', margin: '20px', textAlign: 'center'}}>
							{/* <hr className='dotted-line'/> */}
							{/* <p><b>Search query URLs: {inputValue.split(',').length}</b> &nbsp; <b>Results: {apiData.length}</b> &nbsp; <b>No results: {noResults.length}</b></p>
							{ noResults.length > 0 && (
								<p><b>Not Found: </b>{noResults.join(', ')}</p>
							)} */}
							{/* <h3> Search Results </h3> */}
							<p>Select one or more companies you wish to save to your Collection</p>
							{/* <p>You can also view company insights by clicking on any of the Insights buttons</p> */}
							<CompanySearchResultsTable 
								searchDataItems={apiData} 
								selectedRows={selectedRows} 
								handleSelect={handleCheckboxChange} 
								handleSelectAll={handleSelectAllChange} 
								selectAll={selectAll} 
								accessToken={accessToken}
								onlySearch={false}
							/>
							
							<hr className='dotted-line'/>
							{ displayCompositeAssets()  && (
								<div>
								<div style={{alignItems: 'center', margin: '20px', textAlign: 'center'}}>
									<h3> Step 2: Create Composite Assets (as needed)</h3>
									<p> Combine two or more companies from above to create a composite asset</p>
								</div>
								<div style={{height: '150px', width: '100%', overflowY: 'auto',  border: '1px solid black', display: 'block'}}>
									<table className="home-table">
										<thead>
										<tr>
											
											<th>Composite Asset Name</th>
											<th>Companies</th>
											<th>Merge Date</th>
											<th>Action</th>
											{/* Add more column headers here if needed */}
										</tr>
										</thead>
										<tbody>
										{compositeRows.map((row, index) => (
											<tr key={index}>
											
											<td>{row.name}</td>
											<td>{row.display.companies}</td>
											<td>{row.display.asOfDates}</td>
											<td>
												<button className="button" onClick={() => handleDeleteCompositeRow(index)}>Discard</button>
											</td>
											{/* Add more cells here if needed */}
											</tr>
										))}
										<tr>
											<td colSpan="4">
											<button className="button" onClick={openCompositeModal}>New Composite Asset</button>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								</div>
							)}
							{/* Modal for creating composite asset */}
							{isCreateCompositeModalVisible && (
								<div className="modal">
									<div className="modal-content">
										<span className="close" onClick={closeCompositeAssetModal}>&times;</span>
										<h2>Composite Asset</h2>
										<p>Select atleast 2 companies to create a composite asset.
											<br/>
											Note: please set at least 1 company merge date to Jan 1900.
										</p>

										{
											getComapniesForCompositeAsset().length > 0
											? (
												getComapniesForCompositeAsset().map((item, index) => (
													<label key={index}>
														<input 
															type="checkbox" 
															// value={item} 
															onChange={() => handleCompositeAssetCheckboxChange(item)} 
															checked={selectedCompaniesForCompositeAsset[item.company_id]}
														/>
														<b>{item.name}</b>
														<span style={{margin: '2px'}} />
														<label htmlFor={`date_${index}`} >as of </label>
														{/* set max to today for the input type=date */}
														<input 
															id={`date_${index}`} 
															type="month" 
															style={{width: '180px', marginLeft: '5px'}} 
															value={fromDatesForCompositeAssets[item.company_id]} 
															onChange={(e) => handleFromDateForCompositeAsset(e.target.value, item.company_id, index)} 
															//max={new Date().toISOString().split('T')[0]} // Set max date to today
															max={new Date().toISOString().substring(0, 7)}
															defaultValue={"1900-01"} // Set default to Jan 1st 1900
														/>
														<br/>
														<br/>
													</label>
												))
											)
											:
											(
												<p> No selections available</p>
											)
										}
										<br/>
										<input type="text" placeholder="Enter Composite Asset Name" value={compositeAssetName} onChange={(e) => setCompositeAssetName(e.target.value)} />
										
										<br/>
										<button onClick={handleCreateCompositeAsset}>Create</button>
									</div>
								</div>
							)}
							<hr className='dotted-line'/>
						</div>
					)
				}
				{ apiData.length > 0 && (
					<div style={{textAlign: 'center'}}>
						<h3> Step 3: Save Collection </h3>
						<p> Save your collection to create benchmark reports</p>
						<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<label>
								Collection Name: &nbsp;
								<input 
									type="text" 
									value={universeName}
									style={{ fontSize: '16px', padding: '10px', width: '200px', borderRadius: '10px', marginTop: '10px' }} 
									onChange={(e) => setUniverseName(e.target.value)} 
									
								/>
							</label>
							<span style={{marginLeft: '100px'}} />
							<button 
								className='button'
								// onClick={handleCreateUniverse}
								onClick={openCreateUniverseModal}
							>
								Save Collection
							</button>
						</div>
					</div>
				)}
				<br/>
				{
					createUniverseModalVisible && (
						<div className="modal">
							<div className="modal-content">
								<span className="close" onClick={() => setCreateUniverseModalVisible(!createUniverseModalVisible)}>&times;</span>
								<h2>Save Collection</h2>
								<div id = 'saveCollectionText' style={{display: 'block'}}>
									<p> Are you sure you want to save the following assets to the collection - <b>{universeName}</b>?</p>
									{/* Loop thru selectedRows display the names */}
									<p><b>Regular Assets:</b></p>
									{
										Object.keys(getCompaniesForCreateUniverseModalDisplay()).length === 0
										? (
											<p>No selections</p>
										)
										: (
											<ul className="sublist">
											{
												Object.keys(getCompaniesForCreateUniverseModalDisplay()).map((k) => (
													<li key={k}>{getCompaniesForCreateUniverseModalDisplay()[k].name}</li>
												))
											}
											</ul>
										)
									}

									<p><b>Composite Assets:</b></p>
									{ compositeRows.length === 0 
										? (
											<p> No selections </p>
										)
										: (
											<>
											<ul className="sublist">
											{
												compositeRows.map((item, index) => (
													<li key={index}>{item.name} ({item.display.companies})</li>
												))
											}
											</ul>
											<br/>
											<br/>
											<br/>
											{/* checkbox to enable/disable running alpha computations only for composite assets*/}
											{/* only display for internal team */}
											{ 
												displayAlphaBenchmarkCheckbox() && (
												<label>
													<input type="checkbox" id="runAlpha" checked={runAlphaBenchmark} onChange={handleRunAlphaCheckbox}/>
													&nbsp; <b>Quality Index Benchmarking</b>
												</label>
											)}
											{
												displayWorkflowEnvOptions() && (
													<>
														<br/>
														<br/>
														<label>Workflow Environment:</label>
														<select id="workflowEnv" onChange={(e) => setWorkflowEnv(e.target.value)}>
															<option value="PROD1">Prod1 (default)</option>
															<option value="PROD2">Prod2 (new candidate)</option>
															<option value="TEST">Test (dev)</option>
														</select>
													</>
											)}
											
											
											</>
										)
									}
									
									<br/>
									<button id="confirmCreateUniverse" onClick={handleCreateUniverse}>Confirm</button>
								</div>
								<br/>
								<div id = "compositeCollectionRequest" style={{display: 'none'}}>
									<p>Your request to save this collection with a composite asset is currently being processsed.</p>
									<p>You will be notified via email once the collection is ready to view!</p>
									
								</div>
								<br/>
								{/* Conditionally display spinner based on createUniverseLoading */}
								{ createUniverseLoading && (
									<div style={{textAlign: 'center'}}> 
										{
											compositeRows.length > 0 
											? (
												<LoadingWithText 
													texts={["Processing create collection request...", 
															"Creating workflows for composite assets...",
															"Almost finished..."]} 
													interval={4000}
												/>
											)
											: (
												<LoadingWithText 
													texts={["Creating collection...", 
															"Adding metrics...",
															"Saving to the database...", 
															"Setting up the summary page...", 
															"Almost finished..."]} 
													interval={8000}
												/>
											)
										}
									</div>
								) }
							</div>
						</div>
					)
				}
				{universeId !== '' && isTextContainerVisible && (
				<div className="textContainer">
					<div className='textHeader'>Success</div>
					<div className='textContent'>
						Collection: {universeName} created successfully!
						<br/>
						<br/>
						Click <a href={universeSummaryPath} target="_blank" rel="noopener noreferrer">here</a> to view the Collection.
					</div>
					<button className='button' onClick={handleOkClick}>OK</button>
				</div>
				)}
			</div>
		);
	}
});

export default CompanySearchWithCreateUniverseComponent;
