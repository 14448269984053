import mixpanel from 'mixpanel-browser';

// check env
const apiKey = process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging"
    ? "cfb3bb34b51a23656043f958349ed1d1"
    : "bb81851242970f1ef37f7b6d4bf01dbe"

mixpanel.init(apiKey, { debug: true, track_pageview: true });

export const mixpanelActions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
};
