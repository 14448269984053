import ResetPasswordComponent from "../../components/auth/reset-password";
import { PageLayout } from "../../components/common/page-layout";

export const ResetPasswordPage = () => {
    return (
        <PageLayout>
            <ResetPasswordComponent/>
        </PageLayout>
    );
};
