import React from "react";
import NavbarComponent from "../navigation/navbar";
import FooterComponent from "./footer";
import { TopBanner } from "./top_banner";


export const PageLayout = ({ children }) => {
  return (
    <div className="page-layout" >
      {/* <NavbarComponent /> */}
      <TopBanner />
      <div className="page-layout__content" style={{minHeight: '87vh'}}>{children}</div>
      <footer className="footer">
        © 2024 Telemetry LLC. All rights reserved.
      </footer>
    </div>
  );
};
