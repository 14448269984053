import { useEffect, useMemo, useState } from "react";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import { toJS } from "mobx";
import LoadingWithText from "../../common/loading_text";
import { putCommasInNumber } from "../../../utils/utils.js";

const DigitalGrowthRatesByFunctionTable = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const getHeatmapColor = (value) => {
        // if value is less than 0, return red
        if (value < 0) {
          return '#FF0000';
        }
        else {
          // if value is greater than 0, return shades of green depending on value
          if (value < 1) {
            return '#E0FFE0';
          }
          else if (value < 2) {
            return '#C0FFC0';
          }
          else if (value < 3) {
            return '#A0FFA0';
          }
          else if (value < 4) {
            return '#80FF80';
          }
          else if (value < 5) {
            return '#60FF60';
          }
          else if (value < 6) {
            return '#40FF40';
          }
          else if (value < 7) {
            return '#20FF20';
          }
          else {
            return '#00FF00';
          }
          
        }
    };

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await digitalBenchmarkReportDataStore.fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const headcountHistoryByFxData = digitalBenchmarkReportDataStore.headcountHistoryByFxData[targetCompanyId];

            if (headcountHistoryByFxData !== null) {
                setData(toJS(headcountHistoryByFxData));
                console.log('headcountHistorybyFxData', toJS(headcountHistoryByFxData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryByFxData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);


    const tableContent = useMemo(() => {
        if (!data || data.length === 0) return null;

        const filteredData = data.filter(d => d.linkedin_company_id === targetCompanyId && d.cagr_date_flag === 1);
        const processedData = {};
        filteredData.forEach((item) => {
            const type = item.digital_transformation_type_name;
            const year = new Date(item.the_date).getFullYear();
            const headcount = item.company_headcount_total;
        
            if (!processedData[type]) {
              processedData[type] = {};
            }
        
            processedData[type][year] = headcount;
        });

        const calculateCAGR = (start, end, years) => {
            return (Math.pow((end / start), 1 / years) - 1) * 100 ;
        };

        // Helper function to calculate totals
        const calculateTotals = (processedData) => {
            const totals = {
            2014: 0,
            2019: 0,
            2024: 0,
            delta10Year: 0,
            delta5Year: 0
            };
        
            Object.values(processedData).forEach((values) => {
            totals[2014] += values[2014] || 0;
            totals[2019] += values[2019] || 0;
            totals[2024] += values[2024] || 0;
            });
        
            totals.delta10Year = totals[2024] - totals[2014];
            totals.delta5Year = totals[2024] - totals[2019];
        
            return totals;
        };
        const totals = calculateTotals(processedData);
  

        return (
            <table className="company-table">
              <thead>
                <tr>
                  <th>Function</th>
                  <th>10 Years Ago HC</th>
                  <th>5 Years Ago HC </th>
                  <th>Current HC </th>
                  <th>Delta (10 Years)</th>
                  <th>Delta (5 Years)</th>
                  <th>CAGR (10 Years)</th>
                  <th>CAGR (5 Years)</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(processedData).map(([type, values]) => (
                  <tr key={type}>
                    <td style={{ width: '200px'}}>{type}</td>
                    <td style={{ width: '100px'}}>{(putCommasInNumber(values[2014])) || 'N/A'}</td>
                    <td style={{ width: '100px'}}>{(putCommasInNumber(values[2019])) || 'N/A'}</td>
                    <td style={{ width: '100px'}}>{(putCommasInNumber(values[2024])) || 'N/A'}</td>
                    <td style={{ width: '100px'}}>{values[2024] && values[2014] ? putCommasInNumber(values[2024] - values[2014]) : 'N/A'}</td>
                    <td style={{ width: '100px'}}>{values[2024] && values[2019] ? putCommasInNumber(values[2024] - values[2019]) : 'N/A'}</td>
                    <td style={{ width: '100px', backgroundColor: getHeatmapColor(calculateCAGR(values[2014], values[2024], 10).toFixed(1)) }}>
                      {values[2024] && values[2014]
                        ? `${calculateCAGR(values[2014], values[2024], 10).toFixed(1)}%`
                        : 'N/A'}
                    </td>
                    <td style= {{ width: '100px', backgroundColor: getHeatmapColor(calculateCAGR(values[2019], values[2024], 5).toFixed(1)) }}>
                      {values[2024] && values[2019]
                        ? `${calculateCAGR(values[2019], values[2024], 5).toFixed(1)}%`
                        : 'N/A'}
                    </td>
                    
                  </tr>
              ))}
                <tr>
                    <td><strong>Total</strong></td>
                    <td><strong>{putCommasInNumber(totals[2014])}</strong></td>
                    <td><strong>{putCommasInNumber(totals[2019])}</strong></td>
                    <td><strong>{putCommasInNumber(totals[2024])}</strong></td>
                    <td><strong>{putCommasInNumber(totals.delta10Year)}</strong></td>
                    <td><strong>{putCommasInNumber(totals.delta5Year)}</strong></td>
                    <td style={{ backgroundColor: getHeatmapColor(calculateCAGR(totals[2014], totals[2024], 10).toFixed(1)) }}>
                      <strong>{`${calculateCAGR(totals[2014], totals[2024], 10).toFixed(1)}%`}</strong>
                    </td>
                    <td style={{ backgroundColor: getHeatmapColor(calculateCAGR(totals[2019], totals[2024], 5).toFixed(1)) }}>
                      <strong>{`${calculateCAGR(totals[2019], totals[2024], 5).toFixed(1)}%`}</strong>
                    </td>
                </tr>
              </tbody>
            </table>
          );
        
    }, [data]);

    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {targetCompanyName}: Digital Growth Rates by Function </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting headcount by fx data...', 'Setting up table...', 'Wrapping things up...', 'Finished!']} interval={5000} />
                    </div>
                )
                :
                (
                <div id="digital-growth-rates-by-fx-table">
                  <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                      {tableContent}
                  </div>
                </div>
                )
            }
        </div>
    );

};

export default DigitalGrowthRatesByFunctionTable;
