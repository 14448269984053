
import LoginComponent from "../../components/auth/login";
import { PageLayout } from "../../components/common/page-layout"

export const LoginPage = () => {
    return (
        <PageLayout>
            <LoginComponent/>
        </PageLayout>
    );
};