import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";


class StandardBenchmarkReportDataStore {
    loading = false;
    signalScoreData = null;
    industryDistributionData = null;
    headcountHistoryData = null;
    headcountHistoryByDigitalTypeData = null;
    headcountHistoryByFxData = null;
    headcountHistoryBySeniorityData = null;
    headcountHistoryByTeamSeniorityData = null;
    retentionHistoryData = null;
    talentFlowData = null;
    tenureHistoryData = null;
    tenureHistoryByDeptTypeData = null;
    cSuiteHiresData = null;
    cSuiteExitsData = null;
    topTitlesData = null;
    geoByDeptData = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchSignalScoreData(targetCompanyId, companyList, endDate, dataset) {
        if (this.signalScoreData !== null && this.signalScoreData[targetCompanyId] !== undefined) {
            return;
        }

        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let signalScoreUrl = apiUrl + process.env.REACT_APP_API_SBM_SIGNAL_SCORE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching signal score data from ${signalScoreUrl}...`);

            const response = await axios.post(signalScoreUrl, data, config);
            if (response.status === 200) {
                let signalScoreData = JSON.parse(response.data.data.signal_score);
                console.log(`StandardBenchmarkReportDataStore: Fetched signal score data from ${signalScoreUrl}, ${signalScoreData.length} records`);
                if (this.signalScoreData === null) {
                    this.signalScoreData = {};
                }
                this.signalScoreData[targetCompanyId] = signalScoreData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_signal_score',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_SIGNAL_SCORE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };


    async fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryData !== null && this.headcountHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryUrl = apiUrl + process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching headcount history data from ${headcountHistoryUrl}...`);

            const response = await axios.post(headcountHistoryUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryData = JSON.parse(response.data.data.headcount_history);
                console.log(`StandardBenchmarkReportDataStore: Fetched headcount history data from ${headcountHistoryUrl}, ${headcountHistoryData.length} records`);
                if (this.headcountHistoryData === null) {
                    this.headcountHistoryData = {};
                }
                
                this.headcountHistoryData[targetCompanyId] = headcountHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_headcount_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };


    async fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryByFxData !== null && this.headcountHistoryByFxData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryByFxUrl = apiUrl + process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_BY_FX_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };
            console.log('data', data);

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching headcount history by fx data from ${headcountHistoryByFxUrl}...`);

            const response = await axios.post(headcountHistoryByFxUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryByFxData = JSON.parse(response.data.data.headcount_history_by_fx);
                console.log(`StandardBenchmarkReportDataStore: Fetched headcount history by fx data from ${headcountHistoryByFxUrl}, ${headcountHistoryByFxData.length} records`);
                if (this.headcountHistoryByFxData === null) {
                    this.headcountHistoryByFxData = {};
                }
                
                this.headcountHistoryByFxData[targetCompanyId] = headcountHistoryByFxData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_headcount_history_by_fx',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_BY_FX_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchHeadcountHistoryBySeniorityForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryBySeniorityData !== null && this.headcountHistoryBySeniorityData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryBySenUrl = apiUrl + process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_BY_SENIORITY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };
            console.log('data', data);

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching headcount history by seniority data from ${headcountHistoryBySenUrl}...`);

            const response = await axios.post(headcountHistoryBySenUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryBySenData = JSON.parse(response.data.data.headcount_history_by_seniority);
                console.log(`StandardBenchmarkReportDataStore: Fetched headcount history by seniority data from ${headcountHistoryBySenUrl}, ${headcountHistoryBySenData.length} records`);
                if (this.headcountHistoryBySeniorityData === null) {
                    this.headcountHistoryBySeniorityData = {};
                }
                
                this.headcountHistoryBySeniorityData[targetCompanyId] = headcountHistoryBySenData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_headcount_history_by_seniority',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_BY_SENIORITY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchHeadcountHistoryByTeamSeniorityForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryByTeamSeniorityData !== null && this.headcountHistoryByTeamSeniorityData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryByTeamSenUrl = apiUrl + process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_BY_TEAM_SENIORITY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };
            console.log('data', data);

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching headcount history by team-sen data from ${headcountHistoryByTeamSenUrl}...`);

            const response = await axios.post(headcountHistoryByTeamSenUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryByTeamSenData = JSON.parse(response.data.data.headcount_history_by_team_seniority);
                console.log(`StandardBenchmarkReportDataStore: Fetched headcount history by team-sen data from ${headcountHistoryByTeamSenUrl}, ${headcountHistoryByTeamSenData.length} records`);
                if (this.headcountHistoryByTeamSeniorityData === null) {
                    this.headcountHistoryByTeamSeniorityData = {};
                }
                
                this.headcountHistoryByTeamSeniorityData[targetCompanyId] = headcountHistoryByTeamSenData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_headcount_history_by_team_seniority',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_HEADCOUNT_HISTORY_BY_TEAM_SENIORITY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchRetentionHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset, deptType) {
        if (deptType === null && this.retentionHistoryData !== null && this.retentionHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let retentionHistoryUrl = apiUrl + process.env.REACT_APP_API_SBM_RETENTION_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
                "filter": deptType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching retention history data from ${retentionHistoryUrl}...`);

            const response = await axios.post(retentionHistoryUrl, data, config);
            if (response.status === 200) {
                let retentionHistoryData = JSON.parse(response.data.data.retention_history);
                console.log(`StandardBenchmarkReportDataStore: Fetched retention history data from ${retentionHistoryUrl}, ${retentionHistoryData.length} records`);
                if (this.retentionHistoryData === null) {
                    this.retentionHistoryData = {};
                }
                
                this.retentionHistoryData[targetCompanyId] = retentionHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_retention_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_RETENTION_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "filter": deptType,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }


    };

    async fetchTalentFlowData(targetCompanyId, duration, dataset, deptType) {
        if (deptType === null && this.talentFlowData !== null && this.talentFlowData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let talentFlowUrl = apiUrl + process.env.REACT_APP_API_SBM_TALENT_FLOW_HISTORY_ENDPOINT;

            let data = {
                "company_id": targetCompanyId,
                "duration": duration,
                "dataset": dataset,
                "filter": deptType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching talent flow history data from ${talentFlowUrl}...`);

            const response = await axios.post(talentFlowUrl, data, config);
            if (response.status === 200) {
                let talentflowHistoryData = JSON.parse(response.data.data.talent_flow);
                console.log(`StandardBenchmarkReportDataStore: Fetched talent flow history data from ${talentFlowUrl}, ${talentflowHistoryData.length} records`);
                if (this.talentFlowData === null) {
                    this.talentFlowData = {};
                }
                
                this.talentFlowData[targetCompanyId] = talentflowHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_talent_flow_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_TALENT_FLOW_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "duration": duration,
                        "target_company_id": targetCompanyId,
                        "filter": deptType,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }


    };

    async fetchTenureHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.tenureHistoryData !== null && this.tenureHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureHistoryUrl = apiUrl + process.env.REACT_APP_API_SBM_TENURE_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching tenure history data from ${tenureHistoryUrl}...`);

            const response = await axios.post(tenureHistoryUrl, data, config);
            if (response.status === 200) {
                let tenureHistoryData = JSON.parse(response.data.data.tenure_history);
                console.log(`StandardBenchmarkReportDataStore: Fetched tenure history data from ${tenureHistoryUrl}, ${tenureHistoryData.length} records`);
                if (this.tenureHistoryData === null) {
                    this.tenureHistoryData = {};
                }
                
                this.tenureHistoryData[targetCompanyId] = tenureHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_tennure_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_TENURE_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchTenureHistoryByDeptTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.tenureHistoryByDeptTypeData !== null && this.tenureHistoryByDeptTypeData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureHistoryByDeptTypeUrl = apiUrl + process.env.REACT_APP_API_SBM_TENURE_HISTORY_BY_DEPT_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching tenure history by dept type data from ${tenureHistoryByDeptTypeUrl}...`);

            const response = await axios.post(tenureHistoryByDeptTypeUrl, data, config);
            if (response.status === 200) {
                let tenureHistoryByDeptTypeData = JSON.parse(response.data.data.tenure_history);
                console.log(`StandardBenchmarkReportDataStore: Fetched tenure history by dept type data from ${tenureHistoryByDeptTypeUrl}, ${tenureHistoryByDeptTypeData.length} records`);
                if (this.tenureHistoryByDeptTypeData === null) {
                    this.tenureHistoryByDeptTypeData = {};
                }
                
                this.tenureHistoryByDeptTypeData[targetCompanyId] = tenureHistoryByDeptTypeData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_tennure_history_by_digital_type',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_TENURE_HISTORY_BY_DEPT_TYPE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchCSuiteHiresForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.cSuiteHiresData !== null && this.cSuiteHiresData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let cSuiteHiresUrl = apiUrl + process.env.REACT_APP_API_DBM_CSUITE_HIRES_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching csuite hires data from ${cSuiteHiresUrl}...`);

            const response = await axios.post(cSuiteHiresUrl, data, config);
            if (response.status === 200) {
                let cSuiteHiresData = JSON.parse(response.data.data.c_suite_hires);
                console.log(`StandardBenchmarkReportDataStore: Fetched csuite hires data from ${cSuiteHiresUrl}, ${cSuiteHiresData.length} records`);
                if (this.cSuiteHiresData === null) {
                    this.cSuiteHiresData = {};
                }
                
                this.cSuiteHiresData[targetCompanyId] = cSuiteHiresData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_csuite_hires_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_CSUITES_HIRES_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchCSuiteExitsForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.cSuiteExitsData !== null && this.cSuiteExitsData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let cSuiteExitsUrl = apiUrl + process.env.REACT_APP_API_DBM_CSUITE_EXITS_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching csuite exits data from ${cSuiteExitsUrl}...`);

            const response = await axios.post(cSuiteExitsUrl, data, config);
            if (response.status === 200) {
                let cSuiteExitsData = JSON.parse(response.data.data.c_suite_exits);
                console.log(`StandardBenchmarkReportDataStore: Fetched csuite exits data from ${cSuiteExitsUrl}, ${cSuiteExitsData.length} records`);
                if (this.cSuiteExitsData === null) {
                    this.cSuiteExitsData = {};
                }
                
                this.cSuiteExitsData[targetCompanyId] = cSuiteExitsData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_csuite_exits_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_CSUITES_EXITS_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchTopTitlesForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.topTitlesData !== null && this.topTitlesData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let topTitlesUrl = apiUrl + process.env.REACT_APP_API_SBM_TOP_TITLES_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching top titles data from ${topTitlesUrl}...`);

            const response = await axios.post(topTitlesUrl, data, config);
            if (response.status === 200) {
                let topTitlesData = JSON.parse(response.data.data.top_titles);
                console.log(`StandardBenchmarkReportDataStore: Fetched top titles data from ${topTitlesUrl}, ${topTitlesData.length} records`);
                if (this.topTitlesData === null) {
                    this.topTitlesData = {};
                }
                
                this.topTitlesData[targetCompanyId] = topTitlesData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_top_titles_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_TOP_TITLES_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchGeoByDeptTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.geoByDeptData !== null && this.geoByDeptData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let geoUrl = apiUrl + process.env.REACT_APP_API_SBM_GEO_BY_DEPT_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`StandardBenchmarkReportDataStore: Fetching geo by dept data from ${geoUrl}...`);

            const response = await axios.post(geoUrl, data, config);
            if (response.status === 200) {
                let geoData = JSON.parse(response.data.data.geo_by_dept);
                console.log(`StandardBenchmarkReportDataStore: Fetched top titles data from ${geoUrl}, ${geoData.length} records`);
                if (this.geoByDeptData === null) {
                    this.geoByDeptData = {};
                }
                
                this.geoByDeptData[targetCompanyId] = geoData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'sbm_geo_by_dept',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_SBM_GEO_BY_DEPT_TYPE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };
    
}

export const standardBenchmarkReportDataStore = new StandardBenchmarkReportDataStore();
