import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";


class CollectionsStore {
    error = null;
    unAuthorized = false;
    collections = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchCollections(email, companyId) {
        // check if data already exists
        if (this.collections && companyId in this.collections && this.collections[companyId] !== undefined) {
            console.log(`CollectionsStore: Data already exists`);
            return;
        }

        try{
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_UNIVERSE_LIST_FOR_COMPANY_ENDPOINT;

            console.log(`CollectionsStore: Fetching collections from ${apiUrl}...`);

            let data = {
                "email": email,
                "company_id": companyId,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200 && response.data.data.universe_list.length !== 0) {
                const jsonData = JSON.parse(response.data.data.universe_list);
                console.log(`CollectionsStore: Fetched collections from ${apiUrl}, ${jsonData.length} records`);
                
                // set data in store
                if (this.collections === null) {
                    this.collections = {};
                }
                this.collections[companyId] = jsonData;
            }

        }
        catch(err) {
            console.log(err);
            if(err.response !== undefined && err.response.status === 401) {
                this.error = 'Unauthorized';
                this.unAuthorized = true;
            }
            else {
                this.error = "Oops! Something went wrong. Please try again later."
            }
        }
    
    }
}

export const collectionsStore = new CollectionsStore();
