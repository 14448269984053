import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import LoadingWithText from "../common/loading_text";
import { ProgressModal } from "../common/progress_modal";
import { set, toJS } from "mobx";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";
import pptxgen from "pptxgenjs";
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { dbm_add_appendix_company_slide, dbm_add_chart_slide, dbm_add_contacts_slide, dbm_add_initial_slide, dbm_add_section_slide, dbm_add_table_slide } from "../../utils/ppt_gen_report";
import { observer } from "mobx-react";

import { talentTypeMapping } from "./utils";
import ErrorBoundary from "./common/error_boundary";
import ErrorSafeComponent from "./common/error_safe";

import Appendix from "./talent-mgmt/appendix";
import PercentageTalentTable from "./talent-mgmt/percentage_talent_table";
import IndustryDistributionChart from "./talent-mgmt/industry_distribution_chart";
import EmployeePercentageChart from "./talent-mgmt/employee_percentage_chart";
import EmployeeHiringAndAttritonChart from "./talent-mgmt/employee_hiring_attrition_chart";
import TalentMakeupByFunctionChart from "./talent-mgmt/talent_makeup_by_fx_chart";
import TargetToPeerTalentMakeupByFunctionChart from "./talent-mgmt/talent_makeup_by_fx_target_peer_chart";
import TargetToPeerPercentageTalentRoleChart from "./talent-mgmt/percentage_talent_peer_chart";
import TalentGrowthRatesByFunctionTable from "./talent-mgmt/talent_growth_rates_by_fx_table";
import TalentAttritionTrendChart from "./talent-mgmt/talent_attrition_trend_chart";
import TalentHiringTrendChart from "./talent-mgmt/talent_hiring_trend_chart";
import TargetToPeerTalentNetHCGrowthChart from "./talent-mgmt/talent_employee_net_hc_growth_peer_chart";
import TargetToPeerTalentHiringChart from "./talent-mgmt/talent_employee_hiring_peer_chart";
import TargetToPeerTalentAttritionChart from "./talent-mgmt/talent_employee_attrition_peer_chart";
import TalentSankeyChart from "./talent-mgmt/talent_strategy_talent_inflow_outflow_chart";
import TargetToPeerTalentAvgCohortRetentionChart from "./talent-mgmt/cohort_retention_peer_chart";
import AvgCohortRetentionTable from "./talent-mgmt/cohort_retention_table";
import AvgCohortRetentionByFunctionChart from "./talent-mgmt/cohort_retention_by_fx_chart"; 
import CSuiteHiresTable from "./talent-mgmt/c_suite_hires_table";
import CSuiteExitsTable from "./talent-mgmt/c_suite_exits_table";
import CompanyAndCareerTenureChart from "./talent-mgmt/company_career_tenure_chart";
import DetailedAnalysisSection from "./talent-mgmt/detailed_analysis_section";
import TableOfContents from "./talent-mgmt/table_of_contents";
import Highcharts, { chart } from 'highcharts';
import ChartThemeSwitcher from "./common/chart_color_theme";



const TalentMgmtReport = observer(() => {
    const tmReportData = JSON.parse(sessionStorage.getItem('tmReportData')); 
    if (tmReportData === undefined || tmReportData === null) {
        return (
            <div>
                <p> Oops, something went wrong, please try again later.</p>
            </div>
        );
    }


    const universeData = tmReportData && tmReportData.universeData ? tmReportData.universeData : null;
    const competitorCompanies = tmReportData && tmReportData.competitorCompanies ? tmReportData.competitorCompanies : null;
    const orderedCompetitors = tmReportData && tmReportData.orderedCompetitors ? tmReportData.orderedCompetitors : null;
    const targetCompanyName = tmReportData && tmReportData.targetCompanyName ? tmReportData.targetCompanyName : null;
    const targetCompanyId = tmReportData && tmReportData.targetCompanyId ? tmReportData.targetCompanyId : null;
    const dataset = tmReportData && tmReportData.datasetName ? tmReportData.datasetName : null;
    const accessToken = tmReportData && tmReportData.accessToken ? tmReportData.accessToken : null;
    const dataVersionDate = tmReportData && tmReportData.dataVersionDate ? tmReportData.dataVersionDate : null;


    if (universeData === null || 
        competitorCompanies === null || 
        orderedCompetitors === null || 
        targetCompanyName === null || 
        targetCompanyId === null ||
        dataset === null ||
        dataVersionDate === null
    ) {
        return (
            <div>
                <p>Oops, something went wrong, please try again later.</p>
            </div>
        );
    }

    const [dataLoading, setDataLoading] = useState(true);
    const [companyDataList, setCompanyDataList] = useState([]);
    const [requestData, setRequestData] = useState({});

    const [industryDistributionData, setIndustryDistributionData] = useState([]);
    const [headcountHistoryData, setHeadcountHistoryData] = useState([]);
    const [headcountHistoryByFxData, setHeadcountHistoryByFxData] = useState([]);
    const [retentionHistoryData, setRetentionHistoryData] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');

    const FULL_OPTIONS_SET = ['Change Management', 'Culture', 'DEI', 'Coaching', 'Compensation & Benefits', 'Talent Acquisition', 'Learning & Development', 'Talent Management', 'EHS', 'Payroll', 'HR Generalists'];
    const [detailedAnalysisSections, setDetailedAnalysisSections] = useState([]);
    const [availableOptions, setAvailableOptions] = useState(FULL_OPTIONS_SET);
    const [activeDetailedAnalysisComponentIds, setActiveDetailedAnalysisComponentIds] = useState([]);

    const handleCreateDetailedAnalysis = (selectedOption) => {
        const sectionId = `section-${Date.now()}`;
        const componentIds = [
            `${sectionId}-employee-percentage-chart`,
            `${sectionId}-employee-hiring-attrition-chart`,
            `${sectionId}-target-peer-percentage-digital-role-chart`,
            `${sectionId}-target-peer-digital-net-hc-growth-chart`,
            `${sectionId}-target-peer-digital-hiring-chart`,
            `${sectionId}-target-peer-digital-attrition-chart`,
            `${sectionId}-digital-sankey-chart`,
            `${sectionId}-target-peer-cohort-retention-chart`,
            `${sectionId}-company-career-tenure-chart`,
        ];
        const newSection = {
            id: sectionId,
            option: selectedOption,
            components: [
                {
                    id: `${sectionId}-employee-percentage-chart`,
                    component: <>
                                <EmployeePercentageChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-employee-hiring-attrition-chart`,
                    component: <>
                                <EmployeeHiringAndAttritonChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-target-peer-percentage-digital-role-chart`,
                    component: <>
                                <TargetToPeerPercentageTalentRoleChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-target-peer-digital-net-hc-growth-chart`,
                    component: <>
                                <TargetToPeerTalentNetHCGrowthChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-target-peer-digital-hiring-chart`,
                    component: <>
                                <TargetToPeerTalentHiringChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-target-peer-digital-attrition-chart`,
                    component: <>
                                <TargetToPeerTalentAttritionChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-digital-sankey-chart`,
                    component: <>
                                <TalentSankeyChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-target-peer-cohort-retention-chart`,
                    component: <>
                                <TargetToPeerTalentAvgCohortRetentionChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-company-career-tenure-chart`,
                    component: <>
                            <CompanyAndCareerTenureChart requestData={requestData} talentType={talentTypeMapping[selectedOption]} />
                            <hr className="dotted-lined" />
                        </>
                },
            ],
        };

        setDetailedAnalysisSections([...detailedAnalysisSections, newSection]);
        setAvailableOptions(availableOptions.filter(option => option !== selectedOption));
        setActiveDetailedAnalysisComponentIds([...activeDetailedAnalysisComponentIds, ...componentIds]);
    };

    const handleDiscardDetailedAnalysis = (sectionId) => {
        const discardedSection = detailedAnalysisSections.find(section => section.id === sectionId);
        setDetailedAnalysisSections(detailedAnalysisSections.filter(section => section.id !== sectionId));

        setActiveDetailedAnalysisComponentIds(activeDetailedAnalysisComponentIds.filter(id => !id.startsWith(sectionId)));

        const newAvailableOptions = [...FULL_OPTIONS_SET].filter(option => 
            availableOptions.includes(option) || option === discardedSection.option
        );
        setAvailableOptions(newAvailableOptions);
    };


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                // check if access is expired
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
    
        // check if any of the required parameters are missing
        if (!universeData || !targetCompanyName || !targetCompanyId || !dataset) {
            console.log("Missing required parameters");
            alert("Missing required parameters, redirecting to home page...")
            // redirect to the home page
            navigate("/home");
            
            return;
        }
        else {
            if ((userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
                // get data from the server
                getAndSetData();
            }
        }
    }, []);


    const getAndSetData = async () => {
        setDataLoading(true);

        let companyList = [];
        companyList.push({
            'linkedin_company_id': targetCompanyId,
            'name': targetCompanyName,
        });

        // loop thru the ordered competitors and add them to the company list
        for (let i = 0; i < orderedCompetitors.length; i++) {
            const competitor = orderedCompetitors[i];
            companyList.push({
                'linkedin_company_id': parseInt(competitor.id),
                'name': competitor.name,
            });
        }

        setCompanyDataList(companyList);

        const dataVersionDateFormat = new Date(dataVersionDate);
        const endDate = `${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;

        setRequestData({
            targetCompanyId,
            targetCompanyName,
            companyList,
            endDate,
            dataset,
            duration: 5,
            filter: null,
        });

        // fetch data 
        // const promises = [
        //     digitalBenchmarkReportDataStore.fetchPercentageDigitalData(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchIndustryDistribtuionForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchTalentFlowData(targetCompanyId, 5, dataset, null),
        //     digitalBenchmarkReportDataStore.fetchTenureHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchCSuiteHiresForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchCSuiteExitsForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchTopTitlesForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        // ];

        // await Promise.all(promises);

        // set data
        // setIndustryDistributionData(digitalBenchmarkReportDataStore.industryDistributionData[targetCompanyId]);
        // setHeadcountHistoryData(digitalBenchmarkReportDataStore.headcountHistoryData[targetCompanyId]);
        // setHeadcountHistoryByFxData(digitalBenchmarkReportDataStore.headcountHistoryByFxData[targetCompanyId]);
        // setRetentionHistoryData(digitalBenchmarkReportDataStore.retentionHistoryData[targetCompanyId]);

        setDataLoading(false);
    };
    
    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handleDownloadReport = async () => {
        console.log('Download report clicked');
        setIsModalVisible(true);
        setProgressMessage('Initializing report generation...');

        // defer 
        setTimeout(() => {
            generatePPT()
            .then(() => {
                setProgressMessage('Report generated successfully!');
                // Close the modal after a short delay or on user acknowledgment
                if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                    mixpanelActions.track("Download", { type: 'talentManagementReport', user: toJS(userAccountDataStore.user) });
                    amplitudeActions.track("Download", { type: 'talentManagementReport', user: toJS(userAccountDataStore.user) });
                }
                setTimeout(() => setIsModalVisible(false), 2000);
            })
            .catch(error => {
                setProgressMessage('Error generating report');
                console.error('Error generating report:', error);
            });
        }, 1000);
    };

    const delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    };

    const exportChartAsImage = async (containerId, chartContainer, fixedWidth) => {

        const tableContainerIds = ['percentage-digital-table',
                                   'digital-growth-rates-by-fx-table',
                                   'avg-cohort-retention-table'];
        
        const cSuiteTableContainerIds = ['c-suite-hires-table',
                                         'c-suite-exits-table']

        const highResolutionContainerIds = ['digital-cohort-retention-target-peer-chart',
        'avg-cohort-retention-by-function-chart'];

        let originalWidth;
        let originalHeight;
        let innerAspectRatio;

        // Store the original size
        if (cSuiteTableContainerIds.includes(containerId)) {
            innerAspectRatio = chartContainer.style.width / chartContainer.style.height;
            originalWidth = chartContainer.parentNode.parentNode.parentNode.style.width;
            originalHeight = chartContainer.parentNode.parentNode.parentNode.style.height;
            chartContainer.style.paddingLeft = '5%';
            chartContainer.style.paddingRight = '5%';
        } else {
            originalWidth = chartContainer.style.width;
            originalHeight = chartContainer.style.height;
        }

        const aspectRatio = originalWidth / originalHeight;

        // check if containerId is in tableContainerIds
        if (tableContainerIds.includes(containerId)) {
            fixedWidth = 1350; // Set fixed width for tables
            // Resize the container while maintaining aspect ratio
            chartContainer.style.width = `${fixedWidth}px`;
            chartContainer.style.height = `${fixedWidth / aspectRatio}px`;

        } else if (cSuiteTableContainerIds.includes(containerId)) {
            fixedWidth = 1350; // Set fixed width for tables
            chartContainer.style.width = `${fixedWidth}px`;
            chartContainer.style.height = `${fixedWidth / aspectRatio}px`;

            // For c-suite tables the parent container needs to be bigger
            // because we are working with the inner table container
            // to avoid printing the 'x' buttons
            chartContainer.parentNode.parentNode.parentNode.style.width = `1500px`;
            chartContainer.parentNode.parentNode.parentNode.style.height = `${1500 / aspectRatio}px`;

        } else if (containerId.includes('appendix-')) {
            fixedWidth = 1850; // Set fixed width for appendix tables
            // Resize the container while maintaining aspect ratio
            chartContainer.style.width = `${fixedWidth}px`;
            chartContainer.style.height = `${fixedWidth / aspectRatio}px`;

        } else {
            // Set to fixed size for export
            chartContainer.style.width = `${fixedWidth}px`;
        }

        // create a dictionary of chart titles and their sizes
        const chartHeights = {};
        const containerStyles = {};

        // percentage-digital-table

        // industry-distribution-charts
        chartHeights['chart-container-0'] = '100%';
        chartHeights['chart-container-1'] = '100%';
        chartHeights['chart-container-2'] = '100%';

        // employee-percentage-chart
        chartHeights['percentage-digital-chart-container'] = '20%';
        chartHeights['total-headcount-chart-container'] = '17.2%';
        containerStyles['total-headcount-chart-container'] = {'padding-bottom': '48px'};

        // employee-hiring-attrition-chart
        chartHeights['hiring-attrition-line-chart-container'] = '20%';
        chartHeights['hiring-attrition-column-chart-container'] = '20%';
        
        // digital-makeup-by-fx-chart
        chartHeights['digital-makeup-by-fx-chart-container'] = '41.2%';
        containerStyles['digital-makeup-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // digital-makeup-by-fx-target-peer-chart
        chartHeights['digital-makeup-by-fx-target-to-peer-chart-container'] = '41.2%';
        containerStyles['digital-makeup-by-fx-target-to-peer-chart-container'] = {'padding-bottom': '9px'};

        // pct-digital-role-target-peer-chart
        chartHeights['pct-digital-chart-container'] = '37.7%';
        containerStyles['pct-digital-chart-container'] = {'padding-bottom': '47px'};

        // digital-growth-rates-by-fx-table

        // digital-hiring-trend-chart
        chartHeights['digital-hiring-by-fx-chart-container'] = '41.2%';
        containerStyles['digital-hiring-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // digital-attrition-trend-chart
        chartHeights['digital-attrition-by-fx-chart-container'] = '41.2%';
        containerStyles['digital-attrition-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // digital-net-hc-growth-target-peer-chart
        chartHeights['net-hc-growth-chart-container'] = '37.7%';
        containerStyles['net-hc-growth-chart-container'] = {'padding-bottom': '47px'};

        // digital-hiring-target-peer-chart
        chartHeights['hiring-chart-container'] = '37.7%';
        containerStyles['hiring-chart-container'] = {'padding-bottom': '47px'};

        // digital-attrition-target-peer-chart
        chartHeights['attrition-chart-container'] = '37.7%';
        containerStyles['attrition-chart-container'] = {'padding-bottom': '47px'};

        // digital-sankey-chart
        chartHeights['talent-flow-chart-container'] = '37%';
        containerStyles['talent-flow-chart-container'] = {'padding-top': '30px'};

        // digital-cohort-retention-target-peer-chart
        chartHeights['cohort-retention-chart-container'] = '25%';
        // containerStyles['cohort-retention-chart-container'] = {'padding-bottom': '40px'};
        
        // avg-cohort-retention-table
        
        // avg-cohort-retention-by-function-chart
        chartHeights['cohort-retention-teams-chart-container'] = '20%';
        
        // c-suite-hires-table

        // c-suite-exits-table

        // company-career-tenure-chart
        chartHeights['company-tenure-chart-container'] = '18%';
        chartHeights['career-tenure-chart-container'] = '18%';


        const originalChartHeights = {};
        const originalChartBackGroundColor = {};
        const originalContainerStyles = {};

        // Function to wait until chart reflow finishes by monitoring changes in size
        const waitForReflowCompletion = (chart) => {
            return new Promise((resolve) => {
                const checkInterval = 500; // Interval to check size (in ms)
                let previousWidth = chart.chartWidth;
                let previousHeight = chart.chartHeight;

                const checkSize = setInterval(() => {
                    const currentWidth = chart.chartWidth;
                    const currentHeight = chart.chartHeight;

                    // If size hasn't changed for an interval, assume reflow is complete
                    if (currentWidth === previousWidth && currentHeight === previousHeight) {
                        clearInterval(checkSize);
                        resolve();
                    } else {
                        // Update the previous dimensions for the next check
                        previousWidth = currentWidth;
                        previousHeight = currentHeight;
                    }
                }, checkInterval);
            });
        };

        // Reflow charts in the container to adjust to the new size
        for (const chart of Highcharts.charts) {
        // Highcharts.charts.forEach(chart => {
            if (chart && chart.container.parentNode.parentNode.parentNode.id === containerId) {

                // Check if the parentNode.id contains the substring 'container-' (for detailed analysis charts)
                const isDetailedAnalysis = chart.container.parentNode.id.includes('container-');
                let baseName = chart.container.parentNode.id;
                
                if (isDetailedAnalysis) {
                    // Keep only the base name of the parentNode.id, discarding everything after the last - e.g. chart-container-flag_0 -> chart-container
                    baseName = chart.container.parentNode.id.substring(0, chart.container.parentNode.id.lastIndexOf('container-')) + 'container';
                }
                
                // Store original height and background color
                originalChartHeights[chart.container.parentNode.id] = chart.options.chart.height;
                originalChartBackGroundColor[chart.container.parentNode.id] = chart.options.chart.backgroundColor;
                
                // Set new height and background color
                chart.options.chart.height = chartHeights[baseName];
                chart.options.chart.backgroundColor= 'rgba(255, 255, 255, 0)';

                // If container has a style, store original and set the new
                if (containerStyles[baseName]) {
                    originalContainerStyles[chart.container.parentNode.id] = chart.container.parentNode.style;
                    Object.keys(containerStyles[baseName]).forEach(key => {
                        chart.container.parentNode.style[key] = containerStyles[baseName][key];
                    });
                };

                chart.reflow();

                // Wait for the redraw to complete
                await waitForReflowCompletion(chart);
            }
        }
        // });
        // await new Promise(resolve => setTimeout(resolve, 200)); // Adjust the delay time as needed

    
        // Get the dimensions of the HTML element
        const elementWidth = chartContainer.offsetWidth;
        const elementHeight = chartContainer.offsetHeight;

        // Export the image
        let pixelRatio = 1;
        // Check if containerId contains a substring in highResolutionContainerIds
        if (highResolutionContainerIds.some(substring => containerId.includes(substring))) {
            pixelRatio = 2;
        }

        const imgData = await htmlToImage.toPng(chartContainer, {
            width: fixedWidth,
            backgroundColor: null,
            pixelRatio: pixelRatio,  // You can adjust this based on your needs
        });

        // Restore the original size
        if (cSuiteTableContainerIds.includes(containerId)) {
            chartContainer.parentNode.parentNode.parentNode.style.width = originalWidth;
            chartContainer.parentNode.parentNode.parentNode.style.height = originalHeight;
            chartContainer.style.paddingLeft = '0';
            chartContainer.style.paddingRight = '0';
        } else {
            chartContainer.style.width = originalWidth;
            chartContainer.style.height = originalHeight;
        }
    
        // Reflow charts to restore the original size
        for (const chart of Highcharts.charts) {
        // Highcharts.charts.forEach(chart => {
            if (chart && chart.container.parentNode.parentNode.parentNode.id === containerId) {

                // Restore original height and background color
                chart.options.chart.height = originalChartHeights[chart.container.parentNode.id];
                chart.options.chart.backgroundColor = originalChartBackGroundColor[chart.container.parentNode.id];
                
                // If container has a style, restore original
                if (originalContainerStyles[chart.container.parentNode.id]) {
                    Object.keys(originalContainerStyles[chart.container.parentNode.id]).forEach(key => {
                        chart.container.parentNode.style = originalContainerStyles[chart.container.parentNode.id];
                    });
                };
                chart.reflow();
                // Wait for the redraw to complete
                await waitForReflowCompletion(chart);
            }
        }
        // });
        // await new Promise(resolve => setTimeout(resolve, 200)); // Adjust the delay time as needed
    
        return { imgData, elementWidth, elementHeight };
    };
 
    const generatePPT = async() => {
        setIsModalVisible(true);
        setProgressMessage('Initializing report generation...');
    
        let pptx = new pptxgen();
        let today = new Date();
        let monthInWords = today.toLocaleString('default', { month: 'long' });
        let dateLabel = `${monthInWords} ${today.getUTCDate()} ${today.getUTCFullYear()}`;
        pptx = dbm_add_initial_slide(pptx, 'Telemetry Talent Strategy Report', `Target: ${targetCompanyName}`, dateLabel);
        console.log('Initial slide created');
        pptx = dbm_add_section_slide(pptx, 'Talent Strategy Overview');
    
        const containerIds = [
            'percentage-digital-table', 
            'industry-distribution-charts', 
            'employee-percentage-chart',
            'employee-hiring-attrition-chart',
            'digital-makeup-by-fx-chart',
            'digital-makeup-by-fx-target-peer-chart',
            'pct-digital-role-target-peer-chart',
            'digital-growth-rates-by-fx-table',
            'digital-hiring-trend-chart',
            'digital-attrition-trend-chart',
            'digital-net-hc-growth-target-peer-chart',
            'digital-hiring-target-peer-chart',
            'digital-attrition-target-peer-chart',
            'digital-sankey-chart',
            'digital-cohort-retention-target-peer-chart',
            'avg-cohort-retention-table',
            'avg-cohort-retention-by-function-chart',
            // 'c-suite-hires-table',
            // 'c-suite-exits-table',
            'company-career-tenure-chart',

        ];

        const detailedAnalysisSectionIds = [
            `employee-percentage-chart-selectedOption`,
            `employee-hiring-attrition-chart-selectedOption`,
            `pct-digital-role-target-peer-chart-selectedOption`,
            `digital-net-hc-growth-target-peer-chart-selectedOption`,
            `digital-hiring-target-peer-chart-selectedOption`,
            `digital-attrition-target-peer-chart-selectedOption`,
            `digital-sankey-chart-selectedOption`,
            `digital-cohort-retention-target-peer-chart-selectedOption`,
            `company-career-tenure-chart-selectedOption`,
        ];

        const slideTitles = {
            'percentage-digital-table': `Percentage Talent Strategy`,
            'industry-distribution-charts': `Percentage Talent Strategy - Industry Distribution & Target Company`,
            'employee-percentage-chart': `${targetCompanyName}: Talent Strategy Employee Percentage and Count Over Time`,
            'employee-hiring-attrition-chart':  `${targetCompanyName}: Talent Strategy Employee Hiring and Attrition (Rolling Last 12 Months) Over Time`,
            'digital-makeup-by-fx-chart': `${targetCompanyName}: Makeup by Talent Strategy Function Over Time`,
            'digital-makeup-by-fx-target-peer-chart': `Target-to-Peer Set Comparison: Talent Strategy Makeup by Function as of current month`,
            'pct-digital-role-target-peer-chart': `Target-to-Peer Set Comparison: Percentage of Company in a Talent Strategy Role`,
            'digital-growth-rates-by-fx-table': `${targetCompanyName}: Talent Strategy Growth Rates by Function`,
            'digital-hiring-trend-chart': `${targetCompanyName}: Talent Strategy Hiring Trends Over Time - Change in Headcount by Talent Strategy Function`,
            'digital-attrition-trend-chart': `${targetCompanyName}: Talent Strategy Attrition Trends Over Time - Change in Headcount by Talent Strategy Function`,
            'digital-net-hc-growth-target-peer-chart': `Target-to-Peer Set Comparison: Talent Strategy Employee Net HC Growth (Rolling Last 12 months) Over Time`,
            'digital-hiring-target-peer-chart': `Target-to-Peer Set Comparison: Talent Strategy Employee Hiring (Rolling Last 12 months) Over Time`,
            'digital-attrition-target-peer-chart': `Target-to-Peer Set Comparison: Talent Strategy Employee Attrition (Rolling Last 12 months) Over Time`,
            'digital-sankey-chart': `${targetCompanyName}: Talent Strategy Talent inflow/outflow in the last 5 years`,
            'digital-cohort-retention-target-peer-chart': `Target-to-Peer Set Comparison: Talent Strategy Employee Avg Cohort Retention`,
            'avg-cohort-retention-table': `${targetCompanyName}: Avg Cohort Retention By Year`,
            'avg-cohort-retention-by-function-chart': `${targetCompanyName}: Avg Cohort Retention by Talent Strategy Function`,
            // 'c-suite-hires-table': `${targetCompanyName}: Talent Strategy C-suite hires in the last 5 years`,
            // 'c-suite-exits-table': `${targetCompanyName}: Talent Strategy C-suite exits in the last 5 years`,
            'company-career-tenure-chart': `${targetCompanyName}: Company and Career Tenure for Talent Strategy Roles`,
        };

        const detailedAnalysisSlideTitles = [
            `${targetCompanyName}: selectedOption Employee Percentage and Count Over Time`,
            `${targetCompanyName}: selectedOption Employee Hiring and Attrition (Rolling Last 12 Months) Over Time`,
            `Target-to-Peer Set Comparison: Percentage of Company in a selectedOption Role`,
            `Target-to-Peer Set Comparison: selectedOption Employee Net HC Growth (Rolling Last 12 months) Over Time`,
            `Target-to-Peer Set Comparison: selectedOption Employee Hiring (Rolling Last 12 months) Over Time`,
            `Target-to-Peer Set Comparison: selectedOption Employee Attrition (Rolling Last 12 months) Over Time`,
            `${targetCompanyName}: selectedOption Talent inflow/outflow in the last 5 years`,
            `Target-to-Peer Set Comparison: selectedOption Employee Avg Cohort Retention`,
            `${targetCompanyName}: Company and Career Tenure for selectedOption Roles`,
        ];
    
        // Process each chart sequentially to allow UI updates
        for (let i = 0; i < containerIds.length; i++) {
            const id = containerIds[i];
            setProgressMessage(`Processing chart ${i + 1} of ${containerIds.length}...`);

            const chartContainer = document.getElementById(id);
            if (!chartContainer) {
                console.error(`Chart container not found: ${id}`);
                continue;
            }

            const { imgData, elementWidth, elementHeight } = await exportChartAsImage(id, chartContainer, 1920); // or any other fixed size
            
            if (imgData) {
                const slideTitle = slideTitles[id];
                if (id === 'percentage-digital-table') {
                    pptx = dbm_add_table_slide(pptx, slideTitle, imgData, elementWidth, elementHeight);;
                }
                else {
                    pptx = dbm_add_chart_slide(pptx, slideTitle, imgData, elementWidth, elementHeight);;
                }
            }

            delay(100);
        }

        // add Detailed Section slides
        for (let i = 0; i < detailedAnalysisSections.length; i++) {
            const section = detailedAnalysisSections[i];
            const sectionTitle = section.option;

            pptx = dbm_add_section_slide(pptx, `Detailed Analysis: ${sectionTitle}`);

            for (let j = 0; j < section.components.length; j++) {
                const componentId = detailedAnalysisSectionIds[j].replace('selectedOption', talentTypeMapping[section.option]);
                const chartContainer = document.getElementById(componentId);
                setProgressMessage(`Processing detailed analysis slide ${j + 1} of ${section.components.length}...`);
                if (!chartContainer) {
                    console.error(`Chart container not found: ${componentId}`);
                    continue;
                }

                const { imgData, elementWidth, elementHeight } = await exportChartAsImage(componentId, chartContainer, 1920); // or any other fixed size
            
                if (imgData) {
                    const slideTitle = detailedAnalysisSlideTitles[j].replace('selectedOption', sectionTitle);
                    pptx = dbm_add_chart_slide(pptx, slideTitle, imgData, elementWidth, elementHeight);;
                }

                delay(100);
            }
        }
                

    
        setProgressMessage('Adding final touches...');

        pptx = dbm_add_section_slide(pptx, 'Appendix (Talent Strategy)');
        const companyList = requestData.companyList;
        const companyNames = companyList.map(company => company.name);
        const appendixDivIds = companyNames.map(name => `appendix-${name}`);
        // create a map of appendixDivIds to company names
        const appendixDivIdToCompanyMap = {};
        for (let i = 0; i < companyNames.length; i++) {
            appendixDivIdToCompanyMap[appendixDivIds[i]] = companyNames[i];
        }
        for (let i = 0; i < appendixDivIds.length; i++) {
            const id = appendixDivIds[i];
            const chartContainer = document.getElementById(id);
            setProgressMessage(`Processing appendix slide ${i + 1} of ${appendixDivIds.length}...`);
            if (!chartContainer) {
                console.error(`Chart container not found: ${id}`);
                continue;
            }
            const { imgData, elementWidth, elementHeight } = await exportChartAsImage(id, chartContainer, 1920); // or any other fixed size
            
            if (imgData) {
                const slideTitle = appendixDivIdToCompanyMap[id];
                pptx = dbm_add_appendix_company_slide(pptx, imgData, elementWidth, elementHeight);
            }

            delay(100);
        }

        pptx = dbm_add_contacts_slide(pptx);
    
        // Save the PowerPoint
        await pptx.writeFile({ fileName: `Telemetry_Talent Strategy_Benchmarking_Report_${targetCompanyName}`, compression: true });
        console.log('PPTX file written');
        setProgressMessage('Report generated successfully!');
    }

    const chartIdsToUpdate = [
        'digital-makeup-by-fx-chart-container', 
        'digital-makeup-by-fx-target-to-peer-chart-container',
        'digital-hiring-by-fx-chart-container',
        'digital-attrition-by-fx-chart-container',
        'company-tenure-chart-container',
        'career-tenure-chart-container',
    ];

    return (
        <div>
        {
            dataLoading ?
            <div style={{ textAlign: "center" }} >
                <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...']} interval={3000} />
            </div>
            : 
            <div className="report-container">
                <TableOfContents />

                <div className="report-content">
                    <h1>Talent Strategy Report Preview: {targetCompanyName}</h1>
                    <h4>
                        Scroll through the preview, make any adjustments as needed and then click Download Report at the bottom to get the full report.
                    </h4>
                    
                    <div style={{ 
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'sticky',
                        top: '0',
                        backgroundColor: 'white',
                        zIndex: 100,
                        padding: '10px 0',
                        borderBottom: '1px solid #e5e7eb'
                    }}>
                        <ChartThemeSwitcher  targetChartIds={chartIdsToUpdate}/>
                    </div>
                    <br/>
                    <ErrorSafeComponent>
                        <div id="percentageDigitalTable">
                            <PercentageTalentTable requestData={requestData} />
                            <br/>
                            <br/>
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="industryDistribution">
                            <IndustryDistributionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="employeePercentage">
                            <EmployeePercentageChart requestData={requestData}/>
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="employeeHiringAttrition">
                            <EmployeeHiringAndAttritonChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalMakeupByFunction">
                            <TalentMakeupByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalMakeupByFunctionTargetPeer">
                            <TargetToPeerTalentMakeupByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    
                    <ErrorSafeComponent>
                        <div id="percentageDigitalTargetPeer">
                            <TargetToPeerPercentageTalentRoleChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalGrowthRatesByFunction">
                            <TalentGrowthRatesByFunctionTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalHiringTrend">
                            <TalentHiringTrendChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalAttritionTrend">
                            <TalentAttritionTrendChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    
                    <ErrorSafeComponent>
                        <div id="digitalNetHCTargetPeer">
                            <TargetToPeerTalentNetHCGrowthChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalHiringTargetPeer">
                            <TargetToPeerTalentHiringChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalAttritionTargetPeer">
                            <TargetToPeerTalentAttritionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/> 
                    <hr className="dotted-line"/>
                    
                    <ErrorSafeComponent>
                        <div id="digitalSankey">
                            <TalentSankeyChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="digitalCohortRetentionTargetPeer">
                            <TargetToPeerTalentAvgCohortRetentionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="avgCohortRetention">
                            <AvgCohortRetentionTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="avgCohortRetentionByFunction">
                            <AvgCohortRetentionByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>

                    {/* <ErrorSafeComponent>
                        <div id="cSuiteHires">
                            <CSuiteHiresTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="cSuiteExits">
                            <CSuiteExitsTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/> */}

                    <ErrorSafeComponent>
                        <div id="companyCareerTenure">
                            <CompanyAndCareerTenureChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    
                    {
                        detailedAnalysisSections.map((section) => (
                            <ErrorSafeComponent>
                                <DetailedAnalysisSection 
                                    key={section.id}
                                    section={section}
                                    onDiscard={() => handleDiscardDetailedAnalysis(section.id)}
                                />
                            </ErrorSafeComponent>
                        ))
                    }
                    <ErrorSafeComponent>
                        <div id="detailedAnalysis">
                            <DetailedAnalysisSection 
                                requestData={requestData}
                                availableOptions={availableOptions}
                                onCreate={handleCreateDetailedAnalysis}
                            />
                        </div>
                    </ErrorSafeComponent>
                    <hr className="dotted-line"/>

                    <ErrorSafeComponent>
                        <div id="appendix">
                            <Appendix requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>

                    <ProgressModal isVisible={isModalVisible} onClose={closeModal} title={`Download Talent Strategy Report`} progressMessage={progressMessage} />
                    <hr className="dotted-line"/>
                    <br/>
                    <br/>
                    
                    <div  id="downloadReport">
                        <button className='button' onClick={handleDownloadReport}> Download Report </button>
                        <p> * For best performance, please use the Google Chrome browser *</p>
                    </div> 

                </div>
            </div>
        }
        </div>
    );
});

export default TalentMgmtReport;
