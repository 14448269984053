import { useEffect, useMemo, useState } from "react";
import LoadingWithText from "../../common/loading_text.js";
import { toJS } from "mobx";
import { putCommasInNumber } from "../../../utils/utils.js"
import { standardBenchmarkReportDataStore } from "../../../stores/standard_benchmark_report_data_store.js";


const SignalScoreTable = (props) => {
    const { targetCompanyId, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const columnConfig = [
        { key: 'official_name', displayName: 'Company' },
        { key: 'employees_count', displayName: 'LinkedIn HC' },
        { key: 'num_valid_public_profiles', displayName: 'Total Public Profiles' },
        { key: 'signal_score', displayName: 'Signal Score' },
    ];

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await standardBenchmarkReportDataStore.fetchSignalScoreData(targetCompanyId, companyList, endDate, dataset);
            const signalScoreData = standardBenchmarkReportDataStore.signalScoreData[targetCompanyId];

            if (signalScoreData !== null) {
                setData(toJS(signalScoreData));
                console.log('signalScoreData', toJS(signalScoreData));
                setDataLoading(false);
            }
            else {
                console.log('signalScoreData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    const tableContent = useMemo(() => {
        if (!data || data.length === 0) return null;

        const firstRowStyle = {
            // fontWeight: 'bold',
            border: '5px solid orange',
        };

        const tableHeader = Object.keys(data[0]);
        
        return (
            <table className="company-table" >
                <thead>
                    <tr>
                        {columnConfig.map(column => (
                            <th key={column.key} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                {column.displayName}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} style={rowIndex === 0 ? firstRowStyle : {}}>
                            {columnConfig.map(column => (
                                <td 
                                    key={`${rowIndex}-${column.key}`} 
                                    style={{ 
                                        border: '1px solid #ddd', 
                                        padding: '8px',
                                        ...(rowIndex === 0 ? firstRowStyle : {})
                                    }}
                                >
                                    {column.key === 'signal_score' ? parseFloat((row[column.key] *100).toFixed(1)) + '%' : putCommasInNumber(row[column.key])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }, [data]);


    return (
        <div>
        {/* <div id="signal-score-table" style={{position: "relative"}}> */}
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> Signal Score </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting signal score data...', 'Setting up table...', 'Wrapping things up...', 'Finished!']} interval={5000} />
                    </div>
                )
                :
                (
                <div id="signal-score-table" style={{position: "relative"}}>
                    <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                        {tableContent}
                    </div>
                    
                    <div style={{
                            border: '5px solid orange', 
                            width: '60px', 
                            padding: '5px', 
                            position: 'absolute', 
                            right: '200px', 
                        }}
                    >
                        Target
                    </div>
                    
                </div>
                )
            }
        </div>
    );

};

export default SignalScoreTable;
