import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/components/verify-email.css'
import LoadingWithText from '../common/loading_text';
import axios from 'axios';
import { getApiServrUrl } from '../../utils/utils';
import { observer } from 'mobx-react-lite';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';


const VerfiyEmailComponent = observer(() => {
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [isVerifying, setIsVerifying] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    
    const handleChange = (index, value) => {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Focus next input box if the value is not empty
        if (value && index < 5) {
            inputRefs[index + 1].current.focus();
        }

        // handle backspace
        if (!value && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    const handleResendCode = async (e) => {
        e.preventDefault();
        setIsModalVisible(true);

        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_RESEND_VERIFY_EMAIL_ENDPOINT;
            
            const email = location.state && location.state.email;
            const password = location.state && location.state.password;
            const fromLogin = location.state && location.state.fromLogin;

            let data = {
                "email": email,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
            };

            console.log(`VerfiyEmailComponent: Fetching resend verify email data from ${apiUrl}...`);
            const response = await axios.post(apiUrl, data, config);
            console.log(response);
            if (response.status === 200) {
                console.log(`Resend verify email successful`);
            }
        }
        catch(error) {
            console.log(error);
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                console.log(`Resend verify email failed`);
                document.getElementById("failed-verification").innerHTML = "<p>Resend verify email failed.</p>"
            }
            else if (error.response.status === 500) { 
                console.log(`Internal server error`);
                document.getElementById("failed-verification").innerHTML = "<p>Oops, something went wrong. Please try again later.</p>"
            }
        }   
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // check if any of the input is empty
        for (let i = 0; i < code.length; i++) {
            if (code[i] === "") {
                document.getElementById("failed-verification").innerHTML = "<p>Please enter a valid 6-digit code.</p>"
                return;
            }
        }
        
        setIsVerifying(true);

        document.getElementById("failed-verification").innerHTML = "";

        try{
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_VERIFY_EMAIL_ENDPOINT;
                        
            const email = location.state && location.state.email;
            const password = location.state && location.state.password;
            const fromLogin = location.state && location.state.fromLogin;

            let data = {
                "email": email,
                "verification_code": code.join(''),
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
            };

            console.log(`VerfiyEmailComponent: Fetching verify email data from ${apiUrl}...`);
            const response = await axios.post(apiUrl, data, config);
            console.log(response);
            if (response.status === 200) {
                console.log(`Verify email successful`);

                // perform login if the user is coming from login page
                if (fromLogin) {
                    await userAccountDataStore.loginUser(email, password);
                    if (userAccountDataStore.user !== null) {
                        // redirect to home page
                        console.log("Login successful");
                        navigate('/home', { state: { user: userAccountDataStore.user }});
                    }   
                }
                else {
                    // redirect to login page by default
                    alert('Registration complete! Please login to continue.');
                    navigate('/login');
                }

                mixpanelActions.track("Email Verification", { status: "success", email: email });
                amplitudeActions.track("Email Verification", { status: "success", email: email });
            }

        }
        catch(error) {
            let errorMsg = "";
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                console.log(`Verify email failed here`);
                console.log(error.response.data.error);
                errorMsg = error.response.data.error;
                // set error message on the failed-verification div
                let errorElement = document.getElementById("failed-verification");
                errorElement.style.color = 'red';
                errorElement.innerHTML = "<p>" + errorMsg + "</p>";
            }
            else if (error.response.status === 500) {
                console.log(`Internal server error`);
                document.getElementById("failed-verification").innerHTML = "<p>Oops, something went wrong. Please try again later.</p>"
            }

            mixpanelActions.track("Email Verification", { status: "failure", email: location.state.email, error: errorMsg});
            amplitudeActions.track("Email Verification", { status: "failure", email: location.state.email, error: errorMsg});
            console.log(error);
        }
        setIsVerifying(false);
    };

    return (
        <div>
        {/* <div style={{textAlign: 'center', margin: '50px'}}>
            <p> An email with the verification code has been sent to you from notifications@telemetry-llc.com.
                If you do not see the email, please check your spam folder.
            </p>
            <p>
                Please input the 6-digit code below to verify your email.
            </p>
        </div> */}
        <div className='email-verification-page-container'>
            {isModalVisible && 
                (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalVisible(false)}>&times;</span>
                        <h2>Resend Code</h2>
                        <div>
                            <p> 
                                An email with a new verification code has been sent to you from
                                <a href="mailto:notifications@telemetry-llc.com"> Telemetry LLC </a>. 
                                Please check your spam folder if you do not see the email.
                            </p>
                        </div>
                        <br/>
                        <br/>
                        <button onClick={() => setIsModalVisible(false)}>OK</button>
                    </div>
                </div>
                )
            }

            <div className="email-verification-container">
                <div id="failed-verification" style={{ textAlign: 'center'}} />
                
                <h2>Verify Your Email</h2>
                <p>Please enter the 6-digit code sent to your email.</p>
                <form onSubmit={handleSubmit}>
                    {code.map((_, index) => (
                        <input
                            key={index}
                            ref={inputRefs[index]}
                            type="text"
                            value={code[index]}
                            onChange={(e) => handleChange(index, e.target.value)}
                            maxLength="1"
                            placeholder="_"
                            className="digit-input"
                        />
                    ))}
                    <br/>
                    <br/>
                    <button type="submit">Verify</button>
                    <br/>
                    <br/>
                    {
                        isVerifying && (
                            <LoadingWithText texts={["Verifying..."]} interval={2000} />
                        )
                    }
                    <div className="form-links">
                        <a href="#resend-verify-email" onClick={handleResendCode}>Resend code</a>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
});

export default VerfiyEmailComponent;
