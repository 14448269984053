import CompanyTeamGrowthChartComponent from "../../components/company/company_teams_growth_chart";
import { PageLayout } from "../../components/common/page-layout";

export const CompanyTeamGrowthPage = () => {
    return (
        <PageLayout>
            <CompanyTeamGrowthChartComponent />
        </PageLayout>
    );
};
