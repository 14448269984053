import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiServrUrl } from '../../utils/utils';
import LoadingWithText from '../common/loading_text';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';

// import login style file
import '../../styles/components/login.css';


const RegisterUserComponent = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // for confirm password
    const [isRegistering, setIsRegistering] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };

    const openModal = (e) => 
    {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);
    
    const navigate = useNavigate();
    
    const passwordConstraints = [
        { regex: /.{8,}/, message: "Password must be at least 8 characters long." },
        { regex: /[A-Z]/, message: "Password must contain at least one uppercase letter." },
        { regex: /[a-z]/, message: "Password must contain at least one lowercase letter." },
        { regex: /[0-9]/, message: "Password must contain at least one number." },
        { regex: /[^A-Za-z0-9]/, message: "Password must contain at least one special character." }
    ];

    const generateErrorList = (inputValue) => {
        const errors = passwordConstraints
            .filter(constraint => !constraint.regex.test(inputValue))
            .map(constraint => `<li>${constraint.message}</li>`)
            .join('');
        
        return errors ? `<ul>${errors}</ul>` : '';
    };
      
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            const errorElement = document.getElementById("password-error");
            errorElement.style.color = "red";
            errorElement.textContent = "Passwords do not match.";
            return;
        }

        // check if email domain is in the allowed list
        // convert env var to a list first
        // const allowedEmails = process.env.REACT_APP_EMAIL_DOMAIN_WHITELIST.split(',');
        // const emailDomain = username.split('@')[1];
        // if (!allowedEmails.includes(emailDomain)) {
        //     const errorElement = document.getElementById("password-error");
        //     errorElement.style.color = "red";
        //     errorElement.innerHTML = "<p>The email address you've entered is currently not eligible to register.<br/> Please contact support@telemetry-llc.com for further assistance.</p>";
        //     return;
        // }


        setIsRegistering(true);

        // check if its a valid email
        if (!username.includes('@')) {
            alert('Please enter a valid email address');
            return;
        }

        document.getElementById("failed-login").innerHTML = "";
        
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_REGISTER_USER_ENDPOINT;
            console.log(`RegisterUserComponent: Fetching registration data from ${apiUrl}...`);

            let data = {
                "email": username.toLowerCase(),
                "password": password,
            }

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            console.log(response);
            if (response.status === 200) {
                console.log(`Registration successful`);
                // redirect to home page using React Router
                mixpanelActions.track("Register", {
                    email: username,
                    status: "success",
                    verified: false,
                });

                amplitudeActions.track("Register", {
                    email: username,
                    status: "success",
                    verified: false,
                });

                navigate('/verify-email', { state: { email: username } });
            }

        }
        catch (error) {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                // set error message on the failed-login div
                let errorMsg = '';
                if (error.response.status === 400) {
                    errorMsg = error.response.data.error;
                }
                else {
                    errorMsg = 'Email already in use. Please use a different email to register or <a href="/login">login here</a>.';
                }
                document.getElementById("failed-login").innerHTML = "<p>" + errorMsg + "</p>";
                setPassword('');
                setConfirmPassword('');
                mixpanelActions.track("Register", {
                    email: username,
                    status: "failure",
                    verified: false,
                    error: errorMsg,
                });
                amplitudeActions.track("Register", {
                    email: username,
                    status: "failure",
                    verified: false,
                    error: errorMsg,
                });
            }
            else if (error.response.status === 500) { 
                // set error message on the failed-login div
                let errorMsg = 'Oops, something went wrong. Please try again later.';
                document.getElementById("failed-login").innerHTML = "<p>" + errorMsg + "</p>";
                setPassword('');
                setConfirmPassword('');
                mixpanelActions.track("Register", {
                    email: username,
                    status: "failure",
                    verified: false,
                    error: errorMsg,
                });
                amplitudeActions.track("Register", {
                    email: username,
                    status: "failure",
                    verified: false,
                    error: errorMsg,
                });
            }  
            console.log(error);
        }

        setIsRegistering(false);
    };

    const handleInput = (e) => {
        const input = e.target;
        const errorElement = document.getElementById("password-error");
        const errorHTML = generateErrorList(input.value);
        errorElement.innerHTML = errorHTML;
    };

    const handleBlur = (e) => {
        const input = e.target;
        const errorElement = document.getElementById("password-error");

        if (input.validity.patternMismatch || input.validity.tooShort) {
            errorElement.textContent = input.title; // Show the error message
        } else {
            errorElement.textContent = ''; // Clear the error message when valid
        }
    };

    const handleConfirmPassword = (inputValue) => {
        const errorElement = document.getElementById("password-error");
        if (inputValue !== password) {
            errorElement.style.color = "red";
            errorElement.textContent = "Passwords do not match.";
        } else {
            errorElement.textContent = '';
        }
        setConfirmPassword(inputValue);
    };


    return (
        <div className="login-container">
            <br/>
            <h1 style={{textAlign: 'center'}}> Welcome to Telemetry LVR</h1>
            <div id="failed-login" style={{ color: 'red', textAlign: 'center', display: 'block'}}></div>

            <div className="login-form-container"> {/* Login Form */}
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>Create Account</h2>
               
                    <div className="form-group">
                        <label className="login-label" htmlFor="username">Email:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className='password-container'>
                        <label className="login-label"  htmlFor="password">Password:</label>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            minLength="8"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title=""
                            required
                            onInput={handleInput} // Event listener for real-time feedback
                            onBlur={handleBlur}   // Event listener for feedback on blur
                        />
                        <img
                            src={passwordShown ? `${process.env.PUBLIC_URL}/eye.png` : `${process.env.PUBLIC_URL}/closed_eye.png`}
                            alt="Toggle password visibility"
                            onClick={togglePasswordVisibility}
                            className="password-icon"
                        />
                        <label className="login-label-confirm-password"  htmlFor="confirm-password">Confirm Password:</label>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            id="confirm-password"
                            value={confirmPassword}
                            onChange={(e) => handleConfirmPassword(e.target.value)}
                        />
                    </div>
                    <br/>
                    <button className="login-button" type="submit">Register</button>
                    <br/>
                    <div id="password-error" className="error-message"></div>
                    { isRegistering
                        ?
                        <div>
                            <LoadingWithText texts={["Registering user...", "Creating account...", "Verifying email...", "Sending verification code..."]} interval={3000} />
                        </div>
                        : null
                    }
                    <div className="form-links">
                        <a href="/forgot-password">Forgot password</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="/login">Existing User</a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterUserComponent;
