import React, { useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom'; // If you're using React Router
import { getApiServrUrl } from '../../utils/utils';
import LoadingWithText from '../common/loading_text';

const ResetPasswordComponent = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
   
    const [searchParams] = useSearchParams();
    const token = searchParams && searchParams.get('token');
    const email = searchParams && searchParams.get('email');
    
    const [passwordShown, setPasswordShown] = useState(false);

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (newPassword !== confirmNewPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        try {
            // API call to reset the password
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_RESET_PASSWORD_ENDPOINT;

            let data = {
                email: email,
                password: newPassword,
                verification_token: token,
            };
            
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                witCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                setMessage('Password reset successful. Please login with your new password.');
                // wait for 5 seconds and then redirect to login page
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            };

        } catch (error) {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                setMessage('Oops something went wrong. Please try again later.');
            }
            console.log(error);

        }
        finally {
            setIsLoading(false);
        }
    };

    const passwordConstraints = [
        { regex: /.{8,}/, message: "Password must be at least 8 characters long." },
        { regex: /[A-Z]/, message: "Password must contain at least one uppercase letter." },
        { regex: /[a-z]/, message: "Password must contain at least one lowercase letter." },
        { regex: /[0-9]/, message: "Password must contain at least one number." },
        { regex: /[^A-Za-z0-9]/, message: "Password must contain at least one special character." }
    ];

    const generateErrorList = (inputValue) => {
        const errors = passwordConstraints
            .filter(constraint => !constraint.regex.test(inputValue))
            .map(constraint => `<li>${constraint.message}</li>`)
            .join('');
        
        return errors ? `<ul>${errors}</ul>` : '';
    };

    const handleInput = (e) => {
        const input = e.target;
        const errorElement = document.getElementById("password-error");
        const errorHTML = generateErrorList(input.value);
        errorElement.innerHTML = errorHTML;
    };

    const handleBlur = (e) => {
        const input = e.target;
        const errorElement = document.getElementById("password-error");

        if (input.validity.patternMismatch || input.validity.tooShort) {
            errorElement.textContent = input.title; // Show the error message
        } else {
            errorElement.textContent = ''; // Clear the error message when valid
        }
    };

    const handleConfirmPassword = (inputValue) => {
        const errorElement = document.getElementById("password-error");
        if (inputValue !== newPassword) {
            errorElement.style.color = "red";
            errorElement.textContent = "Passwords do not match.";
        } else {
            errorElement.textContent = '';
        }
        setConfirmNewPassword(inputValue);
    };

    return (
        <div className='login-container'>
            <br/>
            <h1 style={{textAlign: 'center'}}> Welcome to Telemetry LVR</h1>
            <br/>
            <br/>
            { isLoading
                ? ( 
                <div>
                    <LoadingWithText texts={['Setting new account password...',]} interval={3000}/>
                </div>
                )
                : (
                    <div style={{textAlign: 'center'}}>{message && <p>{message}</p>}</div>
                )
            }
            <div className='login-form-container'>
                <form className='login-form' onSubmit={handleSubmit}>
                    <h2> Reset Password </h2>
                    <div className='form-group'>
                        <label className='login-label' htmlFor="email">Email:</label>
                        <input
                            type="text"
                            id="email"
                            value={email}
                            disabled
                        />
                    </div>

                    <div className='password-container'>
                        <label className="login-label"  htmlFor="newPassword">New Password:</label>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            minLength="8"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title=""
                            onInput={handleInput} // Event listener for real-time feedback
                            onBlur={handleBlur}   // Event listener for feedback on blur
                        />
                        <img
                            src={passwordShown ? `${process.env.PUBLIC_URL}/eye.png` : `${process.env.PUBLIC_URL}/closed_eye.png`}
                            alt="Toggle password visibility"
                            onClick={togglePasswordVisibility}
                            className="password-icon"
                        />
                        <label className="login-label-confirm-password"  htmlFor="confirmNewPassword">Confirm New Password:</label>
                        <input
                            type={passwordShown ? "text" : "password"}
                            id="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={(e) => handleConfirmPassword(e.target.value)}
                            required
                        />
                    </div>

                    <br/>
                    <button className='login-button' type="submit">Confirm</button>
                    <br/>
                    <div id="password-error" className="error-message"></div>
                    <br/>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordComponent;
