export const DIGITAL_TRANSFORMATION_TYPES_ORDER = ['Transformation', 'Data Science & Analytics', 'Data Engineering', 'Software', 'Cloud Infrastructure', 'General Infrastructure', 'Network', 'Security', 'Support'];
export const DEPT_TYPES_ORDER = ['Sales', 'Marketing', 'Product', 'Engineering', 'Consulting', 'Operations', 'Finance', 'Human Resources', 'Administrative', 'Information Technology', 'Customer Success & Support', 'Other'];
export const SEN_TYPES_ORDER = ['Executive', 'Leadership', 'Management', 'Individual Contributor'];


export const palette1 = {
    'Transformation': '#dbc9f8',
    'Data Science & Analytics': '#b89ccc',
    'Data Engineering': '#cad0fc',
    'Software': '#6884d9',
    'Cloud Infrastructure': '#3563d0',
    'General Infrastructure': '#75b9dc',
    'Network': '#3893b8',
    'Security': '#73a8ab',
    'Support': '#0b4c4d',

    'Other': '#dbc9f8',
    'Customer Success & Support': '#b89cec',
    'Information Technology': '#cad0fc',
    'Administrative': '#9ba7df',
    'Human Resources': '#6884d9',
    'Finance': '#3563d0',
    'Operations': '#75b9dc',
    'Consulting': '#3893b8',
    'Engineering': '#a7cacd',
    'Product': '#73a8ab',
    'Marketing': '#3a8083',
    'Sales': '#0b4c4d',

    'Change Management': '#dbc9f8',
    'Culture': '#b89cec',
    'DEI': '#cad0fc',
    'Coaching': '#9ba7df',
    'Compensation & Benefits': '#3563d0',
    'Talent Acquisition': '#75b9dc',
    'Learning & Development': '#3893b8',
    'Talent Management': '#a7cacd',
    'EHS': '#73a8ab',
    'Payroll': '#3a8083',
    'HR Generalists': '#0b4c4d',

    'Executive': '#0b4c4d',
    'Leadership': '#3a8083',
    'Management': '#3893b8',
    'Individual Contributor': '#75b9dc',
    'Other': '#9ba7df',
    'Advisor / Board': '#cad0fc',

    '0-1 year': '#cad0fc',
    '1-3 years': '#9ba7df',
    '3-5 years': '#75b9dc',
    '5-10 years': '#3893b8',
    '10-20 years': '#3a8083',
    '20+ years': '#0b4c4d',

    'North America': '#0b4c4d',
    'Europe, Middle East & Africa': '#3893b8',
    'Asia/Pacific': '#3563d0',
    'Latin America': '#b89cec',
    'Other': '#9ba7df',
    'N/A': '#cad0fc',
};

export const palette2 = {
    'Transformation': '#fcd9df',
    'Data Science & Analytics': '#c66c8a',
    'Data Engineering': '#a13a61',
    'Software': '#bd97ce',
    'Cloud Infrastructure': '#6e4c8f',
    'General Infrastructure': '#93a4d7',
    'Network': '#436bad',
    'Security': '#88c0c0',
    'Support': '#5c7b70',

    'Other': '#fce4b3',
    'Customer Success & Support': '#e99358',
    'Information Technology': '#fcd9df',
    'Administrative': '#f1b1c1',
    'Human Resources': '#c66c8a',
    'Finance': '#a13a61',
    'Operations': '#bd97ce',
    'Consulting': '#6e4c8f',
    'Engineering': '#93a4d7',
    'Product': '#436bad',
    'Marketing': '#88c0c0',
    'Sales': '#5c7b70',

    'Change Management': '#fce4b3',
    'Culture': '#e99358',
    'DEI': '#fcd9df',
    'Coaching': '#c66c8a',
    'Compensation & Benefits': '#a13a61',
    'Talent Acquisition': '#bd97ce',
    'Learning & Development': '#6e4c8f',
    'Talent Management': '#93a4d7',
    'EHS': '#436bad',
    'Payroll': '#88c0c0',
    'HR Generalists': '#5c7b70',

    'Executive': '#5c7b70',
    'Leadership': '#88c0c0',
    'Management': '#93a4d7',
    'Individual Contributor': '#bd97ce',
    'Other': '#f1b1c1',
    'Advisor / Board': '#fcd9df',

    '0-1 year': '#fcd9df',
    '1-3 years': '#f1b1c1',
    '3-5 years': '#bd97ce',
    '5-10 years': '#93a4d7',
    '10-20 years': '#88c0c0',
    '20+ years': '#5c7b70',

    'North America': '#88c0c0',
    'Europe, Middle East & Africa': '#93a4d7',
    'Asia/Pacific': '#fce4b3',
    'Latin America': '#e99358',
    'Other': '#f1b1c1',
    'N/A': '#fcd9df',
};

export const digitalTranformationTypeMapping = {
    "Transformation": "flag_digital_innovation",
    "Data Science & Analytics": "flag_digital_data_science_and_analytics",
    "Software": "flag_digital_SW",
    "Data Engineering": "flag_digital_data",
    "Cloud Infrastructure": "flag_digital_cloud_infrastructure",
    "General Infrastructure": "flag_digital_infrastructure",
    "Network": "flag_digital_network",
    "Security": "flag_digital_security",
    "Support": "flag_digital_support",
};

// do a reverse mapping
export const digitalTranformationTypeMappingReverse = {
    "flag_digital_innovation": "Transformation",
    "flag_digital_data_science_and_analytics": "Data Science & Analytics",
    "flag_digital_SW": "Software",
    "flag_digital_data": "Data Engineering",
    "flag_digital_cloud_infrastructure": "Cloud Infrastructure",
    "flag_digital_infrastructure": "General Infrastructure",
    "flag_digital_network": "Network",
    "flag_digital_security": "Security",
    "flag_digital_support": "Support",
};

export const talentTypeMapping = {
    "Change Management": "flag_hr_change_mgmt",
    "Culture": "flag_hr_culture",
    "DEI": "dei_core",
    "Coaching": "flag_coach",
    "Compensation & Benefits": "flag_hr_comp_benefits",
    "Talent Acquisition": "flag_hr_talent_acquis",
    "Learning & Development": "flag_hr_learning_and_dev",
    "Talent Management": "flag_hr_talent_mgmt",
    "EHS": "flag_hr_ehs",
    "Payroll": "flag_hr_payroll",
    "HR Generalist": "flag_hr_general",
};

export const talentTypeMappingReverse = {
    "flag_hr_change_mgmt": "Change Management",
    "flag_hr_culture": "Culture",
    "dei_core": "DEI",
    "flag_coach": "Coaching",
    "flag_hr_comp_benefits": "Compensation & Benefits",
    "flag_hr_talent_acquis": "Talent Acquisition",
    "flag_hr_learning_and_dev": "Learning & Development",
    "flag_hr_talent_mgmt": "Talent Management",
    "flag_hr_ehs": "EHS",
    "flag_hr_payroll": "Payroll",
    "flag_hr_general": "HR Generalist",
};


export const countryCodesISO = {
    "Heard and McDonald Islands": "HMD",
    "Heard Island and McDonald Islands": "HMD",
    "Not Specified": null,
    "Bouvet Island": "BVT",
    "Saint Pierre and Miquelon": "SPM",
    "Antarctica": "ATA",
    "French Southern Territories": "ATF",
    "Other": null,
    "nan": null,
    "Japan": "JPN",
    "Macao": "MAC",
    "Taiwan": "TWN",
    "China": "CHN",
    "Hong Kong": "HKG",
    "Australia": "AUS",
    "Korea": "KOR",
    "Singapore": "SGP",
    "South Korea": "KOR",
    "New Zealand": "NZL",
    "Palau": "PLW",
    "Nauru": "NRU",
    "Myanmar": "MMR",
    "Solomon Islands": "SLB",
    "Cambodia": "KHM",
    "Bhutan": "BTN",
    "Niue": "NIU",
    "Tonga": "TON",
    "U.S. Outlying Islands": "UMI",
    "Pakistan": "PAK",
    "Afghanistan": "AFG",
    "India": "IND",
    "Maldives": "MDV",
    "Sri Lanka": "LKA",
    "Brunei": "BRN",
    "Northern Mariana Islands": "MNP",
    "Korea (North)": "PRK",
    "Vietnam": "VNM",
    "Papua New Guinea": "PNG",
    "Philippines": "PHL",
    "Bangladesh": "BGD",
    "Timor-Leste": "TLS",
    "Tuvalu": "TUV",
    "American Samoa": "ASM",
    "Vanuatu": "VUT",
    "Fiji": "FJI",
    "Pitcairn": "PCN",
    "Brunei Darussalam": "BRN",
    "Laos": "LAO",
    "North Korea": "PRK",
    "Federated States of Micronesia": "FSM",
    "Thailand": "THA",
    "Malaysia": "MYS",
    "Kiribati": "KIR",
    "Mongolia": "MNG",
    "Samoa": "WSM",
    "Indonesia": "IDN",
    "Guam": "GUM",
    "New Caledonia": "NCL",
    "Cook Islands": "COK",
    "Micronesia": "FSM",
    "British Indian Ocean Territory": "IOT",
    "Nepal": "NPL",
    "Marshall Islands": "MHL",
    "French Polynesia": "PYF",
    "Christmas Island": "CXR",
    "Wallis and Futuna": "WLF",
    "Cocos (Keeling) Islands": "CCK",
    "Peru": "PER",
    "Netherlands Antilles": "ANT",
    "Nicaragua": "NIC",
    "Antigua and Barbuda": "ATG",
    "Virgin Islands (U.S.)": "VIR",
    "South Georgia and South Sandwich Islands": "SGS",
    "Caribbean Nations": null,
    "Bolivia": "BOL",
    "Dominican Republic": "DOM",
    "Anguilla": "AIA",
    "Paraguay": "PRY",
    "Barbados": "BRB",
    "Montserrat": "MSR",
    "Mexico": "MEX",
    "Cayman Islands": "CYM",
    "Bermuda": "BMU",
    "Grenada": "GRD",
    "Belize": "BLZ",
    "Jamaica": "JAM",
    "Aruba": "ABW",
    "Colombia": "COL",
    "Bahamas": "BHS",
    "Honduras": "HND",
    "Saint Lucia": "LCA",
    "Chile": "CHL",
    "Saint Kitts and Nevis": "KNA",
    "Guatemala": "GTM",
    "Dominica": "DMA",
    "S. Georgia and S. Sandwich Islands": "SGS",
    "Suriname": "SUR",
    "Turks and Caicos Islands": "TCA",
    "Virgin Islands (British)": "VGB",
    "Ecuador": "ECU",
    "Falkland Islands (Malvinas)": "FLK",
    "Brazil": "BRA",
    "Venezuela": "VEN",
    "Argentina": "ARG",
    "Cuba": "CUB",
    "Puerto Rico": "PRI",
    "Guyana": "GUY",
    "Bonaire, Sint Eustatius, and Saba": "BES",
    "French Guiana": "GUF",
    "Saint Vincent and the Grenadines": "VCT",
    "Uruguay": "URY",
    "Costa Rica": "CRI",
    "Panama": "PAN",
    "El Salvador": "SLV",
    "Martinique": "MTQ",
    "Trinidad and Tobago": "TTO",
    "Haiti": "HTI",
    "Guadeloupe": "GLP",
    "Canada": "CAN",
    "United States": "USA",
    "Kazakhstan": "KAZ",
    "Uzbekistan": "UZB",
    "Kyrgyzstan": "KGZ",
    "Turkmenistan": "TKM",
    "Azerbaijan": "AZE",
    "Lithuania": "LTU",
    "Ukraine": "UKR",
    "Russian Federation": "RUS",
    "Georgia": "GEO",
    "Armenia": "ARM",
    "Russia": "RUS",
    "Tajikistan": "TJK",
    "Moldova": "MDA",
    "Belarus": "BLR",
    "Latvia": "LVA",
    "Serbia": "SRB",
    "North Macedonia": "MKD",
    "Hungary": "HUN",
    "Bosnia and Herzegovina": "BIH",
    "Czech Republic": "CZE",
    "Poland": "POL",
    "Albania": "ALB",
    "Czechia": "CZE",
    "Slovenia": "SVN",
    "Bulgaria": "BGR",
    "Kosovo": "XKX",
    "Montenegro": "MNE",
    "Cyprus": "CYP",
    "Slovakia": "SVK",
    "Slovak Republic": "SVK",
    "Croatia": "HRV",
    "Romania": "ROU",
    "Estonia": "EST",
    "Serbia and Montenegro": "SCG",
    "Macedonia": "MKD",
    "France": "FRA",
    "Andorra": "AND",
    "Faroe Islands": "FRO",
    "Germany": "DEU",
    "Isle of Man": "IMN",
    "Aland Islands": "ALA",
    "Sweden": "SWE",
    "Greenland": "GRL",
    "Svalbard and Jan Mayen": "SJM",
    "Luxembourg": "LUX",
    "Norway": "NOR",
    "Greece": "GRC",
    "Belgium": "BEL",
    "Portugal": "PRT",
    "Netherlands": "NLD",
    "Spain": "ESP",
    "Italy": "ITA",
    "Austria": "AUT",
    "Malta": "MLT",
    "Vatican City State (Holy See)": "VAT",
    "Finland": "FIN",
    "United Kingdom": "GBR",
    "Denmark": "DNK",
    "Monaco": "MCO",
    "Guernsey": "GGY",
    "Switzerland": "CHE",
    "Liechtenstein": "LIE",
    "Ireland": "IRL",
    "Holy See": "VAT",
    "Iceland": "ISL",
    "Ãland": "ALA",
    "San Marino": "SMR",
    "Jersey": "JEY",
    "Angola": "AGO",
    "Malawi": "MWI",
    "Gabon": "GAB",
    "South Africa": "ZAF",
    "Mauritius": "MUS",
    "Saint Helena": "SHN",
    "Benin": "BEN",
    "Mali": "MLI",
    "Namibia": "NAM",
    "Mozambique": "MOZ",
    "Eritrea": "ERI",
    "Eswatini": "SWZ",
    "Mayotte": "MYT",
    "Uganda": "UGA",
    "Cameroon": "CMR",
    "Zimbabwe": "ZWE",
    "Burkina Faso": "BFA",
    "Cabo Verde": "CPV",
    "Cote Dâ€™Ivoire (Ivory Coast)": "CIV",
    "Burundi": "BDI",
    "Tanzania": "TZA",
    "Niger": "NER",
    "Gambia": "GMB",
    "Madagascar": "MDG",
    "Liberia": "LBR",
    "Senegal": "SEN",
    "Lesotho": "LSO",
    "Equatorial Guinea": "GNQ",
    "Reunion": "REU",
    "Swaziland": "SWZ",
    "Côte d'Ivoire": "CIV",
    "Cape Verde": "CPV",
    "Seychelles": "SYC",
    "Central African Republic": "CAF",
    "Comoros": "COM",
    "Zambia": "ZMB",
    "Togo": "TGO",
    "Democratic Republic of the Congo": "COD",
    "Mauritania": "MRT",
    "Sierra Leone": "SLE",
    "Guinea": "GIN",
    "Botswana": "BWA",
    "Ghana": "GHA",
    "Congo": "COG",
    "Ethiopia": "ETH",
    "Rwanda": "RWA",
    "Sudan": "SDN",
    "Kenya": "KEN",
    "Somalia": "SOM",
    "Guinea-Bissau": "GNB",
    "Chad": "TCD",
    "Sao Tome and Principe": "STP",
    "Nigeria": "NGA",
    "South Sudan": "SSD",
    "Oman": "OMN",
    "Turkey": "TUR",
    "Libya": "LBY",
    "Kuwait": "KWT",
    "Sultanate of Oman": "OMN",
    "Egypt": "EGY",
    "Syria": "SYR",
    "Saudi Arabia": "SAU",
    "Morocco": "MAR",
    "United Arab Emirates": "ARE",
    "Palestinian Territory": "PSE",
    "Iran": "IRN",
    "Jordan": "JOR",
    "Algeria": "DZA",
    "Gibraltar": "GIB",
    "Qatar": "QAT",
    "Yemen": "YEM",
    "Western Sahara": "ESH",
    "Bahrain": "BHR",
    "Iraq": "IRQ",
    "Djibouti": "DJI",
    "Israel": "ISR",
    "Tunisia": "TUN",
    "Lebanon": "LBN",
    "Palestine State": "PSE"
  };