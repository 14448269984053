export const universeDisplayNames = {
    'Employees': 'employees_count_public',
    'Net Headcount Growth': 'ltm_net_hc_growth',
    'Employee Attrition': 'ltm_attrition_rate',
    'Employee Hiring': 'ltm_hiring_rate',
    'Employee Retention': 'ltm_retention_rate',
    'Growth Efficiency': 'ltm_growth_productivity',
};

export const universeDisplayNamesForColumns = Object.entries(universeDisplayNames)
    .reduce((universeDisplayNames, [key, value]) => ({ ...universeDisplayNames, [value]: key }), {});

export const lineColors = {
    // department
    'All': 'black',

    'Sales': '#0b4c4d', // Dark Teal
    'Marketing': '#3a8083', // Teal
    'Product': '#73a8ab', // Light Teal
    'Engineering': '#a7cacd', // Very Light Teal
    'Consulting': '#3893b8', // Sky Blue
    'Operations': '#75b9dc', // Light Blue
    'Finance': '#3563d0', // Dark Blue
    'Human Resources': '#6884d9', // Medium Blue
    'Administrative': '#9ba7df', // Light Blue
    'Information Technology': '#cad0fc', // Light Purple
    'Customer Success & Support': '#b89ccc', // Light Purple
    'Other': '#dbc9f8', // Very Light Purple

    'Executive': '#0b4c4d', // Dark Teal
    'Leadership': '#3a8083',
    'Management': '#3893b8',
    'Individual Contributor': '#75b9dc',
    'Advisor / Board': '#cad0fc', // Light Purple

    // not used anymore
    'Customer Support': '#128012', // Darker Green
    'Delivery': '#AF71E8', // Light Violet
    'R&D': '#6B21B0', // Medium Dark Violet
    'IT': '#4169E1', // Medium Blue
    'Legal': '#1E90FF', // Sky Blue
    'Quality Assurance': '#CC919A', // Dark Pink
    'Intern': '#A9A9A9', // DarkGray
    'Non-Work': '#808080', // Gray
    'Student': '#C0C0C0', // Silver
    'Unsure': '#E8E8E8', // VeryLightGray


    // headcount
    'Net Headcount': '#3c78d8', // Blue
    'Growth Efficiency': '#a64d79', //Magenta
    'Hiring' : '#34a853', // Green
    'Attrition' : '#f1c232', // Orange/Yellow

    // geo
    'Global': 'black',
    'North America': '#0b4c4d', // Dark Teal
    'Europe, Middle East & Africa': '#3893b8', // Sky Blue
    'Asia/Pacific': '#3563d0', // Dark Blue'
    'Latin America': '#b89cec', // Light Purple'
    'N/A': '#cad0fc', // Light Purple

    //Gender
    'Public Profiles': 'lightgray',
    'Total Headcount': 'lightgray',

    //Tenure
    'Company Tenure 0-1': '#cad0fc', // Light Purple
    'Company Tenure 1-3': '#9ba7df', // Light Blue
    'Company Tenure 3-5': '#75b9dc', // Light Blue
    'Company Tenure 5-10': '#3893b8', // Sky Blue
    'Company Tenure 10-20': '#3a8083', // Teal
    'Company Tenure 20+': '#0b4c4d', // Dark Teal

    'Career Tenure 0-1': '#cad0fc', // Light Purple
    'Career Tenure 1-3': '#9ba7df', // Light Blue
    'Career Tenure 3-5': '#75b9dc', // Light Blue
    'Career Tenure 5-10': '#3893b8', // Sky Blue
    'Career Tenure 10-20': '#3a8083', // Teal
    'Career Tenure 20+': '#0b4c4d',

    'Company Tenure': '#3c78d8', // Blue
    'Career Tenure': '#a64d79', //Magenta

};

export const benchmarkLineColors = {
    'All': '#800542', // Dark Red
    
    'Sales': '#CC317E', // Dark Pink
};


export const lineStyles = {
    'Net Headcount Growth (Last 12 Months)': 'Solid',
    'Hiring rate (Last 12 Months)': 'ShortDash',
    'Attrition rate (Last 12 Months)': 'ShortDot',
    'Growth Efficiency (Last 12 Months)': 'ShortDashDotDot',
    'Pct Female, New Hires (Last 12 months)': 'ShortDash',
    'Pct Female, Attrits (Last 12 months)': 'ShortDot',
    'Pct Female, Company Avg': 'Solid',
    'Benchmark Avg Net Growth (Last 12 Months)': 'LongDashDot',
    'Benchmark Avg Hiring (Last 12 Months)': 'LongDash',
    'Benchmark Avg Attrition (Last 12 Months)': 'LongDot',
    'Benchmark Avg Growth Efficiency (Last 12 Months)': 'LongDashDotDot',
    'Company Tenure Female': 'ShortDash',
    'Company Tenure Male': 'ShortDot',
    'Career Tenure Female': 'ShortDash',
    'Career Tenure Male': 'ShortDot',
};
        