import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";

class TenureDataStore {
    loading = false;
    data = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchTenureData(companyId, universeId, accessToken, startDate, endDate, geo1,dept, seniority, dataset) {  
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureUrl = apiUrl + process.env.REACT_APP_API_TENURE_ENDPOINT;

            let data = {
                "company_id": parseInt(companyId),
                "start_date": startDate,
                "end_date": endDate, 
                "geo1": geo1,
                "dept": dept,
                "seniority": seniority,
                "dataset": dataset,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                decompress: true,
                // withCredentials: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            console.log(`TenureDataStore: Fetching tenure data from ${tenureUrl}...`);
            const response = await axios.post(tenureUrl, data, config);
            if (response.status === 200) {
                let tenureData = JSON.parse(response.data.data.tenure_history);
                console.log(`TenureDataStore: Fetched tenure data from ${tenureUrl},  ${tenureData.length} records`);
                if (this.data === null) {
                    this.data = {};
                }
                this.data[companyId] = tenureData;
            }
            
            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId,
                    eventType: 'tenure',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TENURE_ENDPOINT,
                        "dataset": dataset,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    }

}

export const tenureDataStore = new TenureDataStore();
