import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import LoadingWithText from "../common/loading_text";
import { ProgressModal } from "../common/progress_modal";
import { set, toJS } from "mobx";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";
import pptxgen from "pptxgenjs";
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { dbm_add_appendix_company_slide_kf, dbm_add_chart_slide_kf, dbm_add_initial_slide_kf, dbm_add_ending_slide_kf, dbm_add_section_slide_kf, dbm_add_table_slide_kf } from "../../utils/ppt_gen_report_kf";
import { observer } from "mobx-react";
import { DEPT_TYPES_ORDER, digitalTranformationTypeMapping } from "./utils";
import ErrorBoundary from "./common/error_boundary";
import ErrorSafeComponent from "./common/error_safe";
import Highcharts, { chart } from 'highcharts';

import TableOfContents from "./standard-benchmark/table_of_contents";
import SignalScoreTable from "./standard-benchmark/signal_score_table";
import EmployeePercentageChart from "./standard-benchmark/employee_percentage_chart";
import EmployeeHiringAndAttritonChart from "./standard-benchmark/employee_hiring_attrition_chart";
import DeptMakeupByFunctionChart from "./standard-benchmark/dept_makeup_by_fx_chart";
import TargetToPeerDeptMakeupByFunctionChart from "./standard-benchmark/dept_makeup_by_fx_target_peer_chart";
import DeptGrowthRatesByFunctionTable from "./standard-benchmark/dept_growth_rates_by_fx_table";
import DeptHiringTrendChart from "./standard-benchmark/dept_hiring_trend_chart";
import DeptAttritionTrendChart from "./standard-benchmark/dept_attrition_trend_chart";
import TargetToPeerDeptNetHCGrowthChart from "./standard-benchmark/dept_employee_net_hc_growth_peer_chart";
import TargetToPeerDeptHiringChart from "./standard-benchmark/dept_employee_hiring_peer_chart";
import TargetToPeerDeptAttritionChart from "./standard-benchmark/dept_employee_attrition_peer_chart";
import DeptSankeyChart from "./standard-benchmark/dept_talent_inflow_outflow_chart";
import TargetToPeerDeptAvgCohortRetentionChart from "./standard-benchmark/cohort_retention_peer_chart";
import AvgCohortRetentionTable from "./standard-benchmark/cohort_retention_table";
import AvgCohortRetentionByFunctionChart from "./standard-benchmark/cohort_retention_by_fx_chart";
import CompanyAndCareerTenureChart from "./standard-benchmark/company_career_tenure_chart";
import Appendix from "./standard-benchmark/appendix";
import DetailedAnalysisSection from "./standard-benchmark/detailed_analysis_section";
import GeoMap from "./standard-benchmark/geo_map";
import GeoByDeptTableHC from "./standard-benchmark/geo_table_hc";
import GeoByDeptTablePct from "./standard-benchmark/geo_table_pct";
import GeoByDeptPeerTable from "./standard-benchmark/geo_peer_table";
import SeniorityMakeupByFunctionChart from "./standard-benchmark/sen_makeup_by_fx_chart";
import TargetToPeerSeniorityMakeupByFunctionChart from "./standard-benchmark/sen_makeup_by_fx_target_peer_chart";
import SeniorityByDeptPeerTable from "./standard-benchmark/sen_dept_peer_table";
import ChartThemeSwitcher from "./common/chart_color_theme";


const StandardBenchmarkReport = observer(() => {
    const sbmReportData = JSON.parse(sessionStorage.getItem('sbmReportData'));
    if (sbmReportData === undefined || sbmReportData === null) {
        return (
            <div>
                <p> Oops, something went wrong, please try again later.</p>
            </div>
        );
    }


    const universeData = sbmReportData && sbmReportData.universeData ? sbmReportData.universeData : null;
    const competitorCompanies = sbmReportData && sbmReportData.competitorCompanies ? sbmReportData.competitorCompanies : null;
    const orderedCompetitors = sbmReportData && sbmReportData.orderedCompetitors ? sbmReportData.orderedCompetitors : null;
    const targetCompanyName = sbmReportData && sbmReportData.targetCompanyName ? sbmReportData.targetCompanyName : null;
    const targetCompanyId = sbmReportData && sbmReportData.targetCompanyId ? sbmReportData.targetCompanyId : null;
    const dataset = sbmReportData && sbmReportData.datasetName ? sbmReportData.datasetName : null;
    const accessToken = sbmReportData && sbmReportData.accessToken ? sbmReportData.accessToken : null;
    const dataVersionDate = sbmReportData && sbmReportData.dataVersionDate ? sbmReportData.dataVersionDate : null;


    if (universeData === null || 
        competitorCompanies === null || 
        orderedCompetitors === null || 
        targetCompanyName === null || 
        targetCompanyId === null ||
        dataset === null ||
        dataVersionDate === null
    ) {
        return (
            <div>
                <p>Oops, something went wrong, please try again later.</p>
            </div>
        );
    }

    const [dataLoading, setDataLoading] = useState(true);
    const [companyDataList, setCompanyDataList] = useState([]);
    const [requestData, setRequestData] = useState({});

    const [headcountHistoryData, setHeadcountHistoryData] = useState([]);
    const [headcountHistoryByFxData, setHeadcountHistoryByFxData] = useState([]);
    const [retentionHistoryData, setRetentionHistoryData] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');

    const [detailedAnalysisSections, setDetailedAnalysisSections] = useState([]);
    const [availableOptions, setAvailableOptions] = useState(DEPT_TYPES_ORDER);
    const [activeDetailedAnalysisComponentIds, setActiveDetailedAnalysisComponentIds] = useState([]);

    const handleCreateDetailedAnalysis = (selectedOption) => {
        const sectionId = `section-${Date.now()}`;
        const componentIds = [
            `${sectionId}-employee-percentage-chart`,
            `${sectionId}-employee-hiring-attrition-chart`,
            `${sectionId}-dept-net-hc-growth-target-peer-chart`,
            `${sectionId}-dept-hiring-target-peer-chart`,
            `${sectionId}-dept-attrition-target-peer-chart`,
            `${sectionId}-sankey-chart`,
            `${sectionId}-dept-cohort-retention-target-peer-chart`,
            `${sectionId}-company-career-tenure-chart`,
            `${sectionId}-geo-peer-table-container`,
            `${sectionId}-sen-make-up-by-fx-chart`,
            `${sectionId}-sen-make-up-by-fx-target-peer-chart`,
        ];
        const newSection = {
            id: sectionId,
            option: selectedOption,
            components: [
                {
                    id: `${sectionId}-employee-percentage-chart`,
                    component: <>
                                <EmployeePercentageChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-employee-hiring-attrition-chart`,
                    component: <>
                                <EmployeeHiringAndAttritonChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-dept-net-hc-growth-target-peer-chart`,
                    component: <>
                                <TargetToPeerDeptNetHCGrowthChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-dept-hiring-target-peer-chart`,
                    component: <>
                                <TargetToPeerDeptHiringChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-dept-attrition-target-peer-chart`,
                    component: <>
                                <TargetToPeerDeptAttritionChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-sankey-chart`,
                    component: <>
                                <DeptSankeyChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-dept-cohort-retention-target-peer-chart`,
                    component: <>
                                <TargetToPeerDeptAvgCohortRetentionChart requestData={requestData} deptType={selectedOption} />
                                <hr className="dotted-lined" />
                            </>,
                },
                {
                    id: `${sectionId}-company-career-tenure-chart`,
                    component: <>
                            <CompanyAndCareerTenureChart requestData={requestData} deptType={selectedOption} />
                            <hr className="dotted-lined" />
                        </>
                },
                {
                    id: `${sectionId}-geo-peer-table-container`,
                    component: <>
                            <GeoByDeptPeerTable requestData={requestData} deptType={selectedOption} />
                            <hr className="dotted-lined" />
                        </>
                },
                {
                    id: `${sectionId}-sen-make-up-by-fx-chart`,
                    component: <>
                            <SeniorityMakeupByFunctionChart requestData={requestData} deptType={selectedOption} />
                            <hr className="dotted-lined" />
                        </>
                },
                {
                    id: `${sectionId}-sen-make-up-by-fx-target-peer-chart`,
                    component: <>
                            <TargetToPeerSeniorityMakeupByFunctionChart requestData={requestData} deptType={selectedOption} />
                            <hr className="dotted-lined" />
                        </>
                }
            ],
        };

        setDetailedAnalysisSections([...detailedAnalysisSections, newSection]);
        setAvailableOptions(availableOptions.filter(option => option !== selectedOption));
        setActiveDetailedAnalysisComponentIds([...activeDetailedAnalysisComponentIds, ...componentIds]);
    };

    const handleDiscardDetailedAnalysis = (sectionId) => {
        const discardedSection = detailedAnalysisSections.find(section => section.id === sectionId);
        setDetailedAnalysisSections(detailedAnalysisSections.filter(section => section.id !== sectionId));

        setActiveDetailedAnalysisComponentIds(activeDetailedAnalysisComponentIds.filter(id => !id.startsWith(sectionId)));

        const newAvailableOptions = [...DEPT_TYPES_ORDER].filter(option => 
            availableOptions.includes(option) || option === discardedSection.option
        );
        setAvailableOptions(newAvailableOptions);
    };


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                // check if access is expired
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
    
        // check if any of the required parameters are missing
        if (!universeData || !targetCompanyName || !targetCompanyId || !dataset) {
            console.log("Missing required parameters");
            alert("Missing required parameters, redirecting to home page...")
            // redirect to the home page
            navigate("/home");
            
            return;
        }
        else {
            if ((userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
                // get data from the server
                getAndSetData();
            }
        }
    }, []);


    const getAndSetData = async () => {
        setDataLoading(true);

        let companyList = [];
        companyList.push({
            'linkedin_company_id': targetCompanyId,
            'name': targetCompanyName,
        });

        // loop thru the ordered competitors and add them to the company list
        for (let i = 0; i < orderedCompetitors.length; i++) {
            const competitor = orderedCompetitors[i];
            companyList.push({
                'linkedin_company_id': parseInt(competitor.id),
                'name': competitor.name,
            });
        }

        setCompanyDataList(companyList);

        const dataVersionDateFormat = new Date(dataVersionDate);
        const endDate = `${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;

        setRequestData({
            targetCompanyId,
            targetCompanyName,
            companyList,
            endDate,
            dataset,
            duration: 5,
            filter: null,
        });

        // fetch data 
        // const promises = [
        //     digitalBenchmarkReportDataStore.fetchPercentageDigitalData(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchIndustryDistribtuionForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchTalentFlowData(targetCompanyId, 5, dataset, null),
        //     digitalBenchmarkReportDataStore.fetchTenureHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchCSuiteHiresForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchCSuiteExitsForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        //     digitalBenchmarkReportDataStore.fetchTopTitlesForAllCompanies(targetCompanyId, companyList, endDate, dataset),
        // ];

        // await Promise.all(promises);

        // set data
        // setIndustryDistributionData(digitalBenchmarkReportDataStore.industryDistributionData[targetCompanyId]);
        // setHeadcountHistoryData(digitalBenchmarkReportDataStore.headcountHistoryData[targetCompanyId]);
        // setHeadcountHistoryByFxData(digitalBenchmarkReportDataStore.headcountHistoryByFxData[targetCompanyId]);
        // setRetentionHistoryData(digitalBenchmarkReportDataStore.retentionHistoryData[targetCompanyId]);

        setDataLoading(false);
    };
    
    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handleDownloadReport = async () => {
        console.log('Download report clicked');
        setIsModalVisible(true);
        setProgressMessage('Initializing report generation...');

        // defer 
        setTimeout(() => {
            generatePPT()
            .then(() => {
                setProgressMessage('Report generated successfully!');
                // Close the modal after a short delay or on user acknowledgment
                if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                    mixpanelActions.track("Download", { type: 'standardBenchmarkingReport', user: toJS(userAccountDataStore.user) });
                    amplitudeActions.track("Download", { type: 'standardBenchmarkingReport', user: toJS(userAccountDataStore.user) });
                }
                setTimeout(() => setIsModalVisible(false), 2000);
            })
            .catch(error => {
                setProgressMessage('Error generating report');
                console.error('Error generating report:', error);
            });
        }, 1000);
    };

    const delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    };
 
    const exportChartAsImage = async (containerId, chartContainer, fixedWidth) => {

        const tableContainerIds = ['signal-score-table',
                                   'dept-growth-rates-by-fx-table',
                                   'avg-cohort-retention-table'
                                ];
        
        const wideTableContainerIds = ['geo-by-dept-table-hc',
                                       'geo-by-dept-table-pct',
                                       'inner-sen-by-dept-peer-table',
                                       'inner-geo-by-dept-peer-table'
                                    ];

        const highResolutionContainerIds = ['dept-cohort-retention-target-peer-chart',
                                            'avg-cohort-retention-by-function-chart'];

        let originalWidth;
        let originalHeight;
        let innerAspectRatio;

        // Store the original size
        originalWidth = chartContainer.style.width;
        originalHeight = chartContainer.style.height;

        const aspectRatio = originalWidth / originalHeight;

        // check if containerId is in tableContainerIds
        if (tableContainerIds.includes(containerId)) {
            fixedWidth = 1350; // Set fixed width for tables
            // Resize the container while maintaining aspect ratio
            chartContainer.style.width = `${fixedWidth}px`;
            chartContainer.style.height = `${fixedWidth / aspectRatio}px`;

        } 
        // check if containerId starts with any of the ids in wideTableContainerIds.
        else if (wideTableContainerIds.some(substring => containerId.startsWith(substring))) {
            fixedWidth = 1600; // Set fixed width for tables
            chartContainer.style.width = `${fixedWidth}px`;
            chartContainer.style.height = `${fixedWidth / aspectRatio}px`;
            chartContainer.style.paddingBottom = '10px';
        }
         else if (containerId.includes('appendix-')) {
            fixedWidth = 1850; // Set fixed width for appendix tables
            // Resize the container while maintaining aspect ratio
            chartContainer.style.width = `${fixedWidth}px`;
            chartContainer.style.height = `${fixedWidth / aspectRatio}px`;

        } else {
            // Set to fixed size for export
            chartContainer.style.width = `${fixedWidth}px`;
        }

        // create a dictionary of chart titles and their sizes
        const chartHeights = {};
        const containerStyles = {};

        // signal-score-table

        // employee-percentage-chart
        // chartHeights['percentage-chart-container'] = '20%';
        chartHeights['total-headcount-chart-container'] = '37.5%';
        containerStyles['total-headcount-chart-container'] = {'padding-bottom': '55px'};

        // employee-hiring-attrition-chart
        chartHeights['hiring-attrition-line-chart-container'] = '20%';
        chartHeights['hiring-attrition-column-chart-container'] = '20%';
        
        // dept-makeup-by-fx-chart
        chartHeights['dept-makeup-by-fx-chart-container'] = '41.2%';
        containerStyles['dept-makeup-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // dept-makeup-by-fx-target-to-peer-chart
        chartHeights['dept-makeup-by-fx-target-to-peer-chart-container'] = '41.2%';
        containerStyles['dept-makeup-by-fx-target-to-peer-chart-container'] = {'padding-bottom': '9px'};

        // dept-hiring-trend-chart
        chartHeights['dept-hiring-by-fx-chart-container'] = '41.2%';
        containerStyles['dept-hiring-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // dept-attrition-trend-chart
        chartHeights['dept-attrition-by-fx-chart-container'] = '41.2%';
        containerStyles['dept-attrition-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // dept-net-hc-growth-target-peer-chart
        chartHeights['dept-net-hc-growth-target-peer-chart-container'] = '37.7%';
        containerStyles['dept-net-hc-growth-target-peer-chart-container'] = {'padding-bottom': '47px'};

        // dept-hiring-target-peer-chart
        chartHeights['dept-hiring-target-peer-chart-container'] = '37.7%';
        containerStyles['dept-hiring-target-peer-chart-container'] = {'padding-bottom': '47px'};

        // dept-attrition-target-peer-chart
        chartHeights['dept-attrition-target-peer-chart-container'] = '37.7%';
        containerStyles['dept-attrition-target-peer-chart-container'] = {'padding-bottom': '47px'};

        // sankey-chart
        chartHeights['sankey-chart-container'] = '37%';
        containerStyles['sankey-chart-container'] = {'padding-top': '30px'};

        // dept-cohort-retention-target-peer-chart
        chartHeights['dept-cohort-retention-target-peer-chart-container'] = '25%';
        containerStyles['dept-cohort-retention-target-peer-chart-container'] = {'padding-bottom': '40px'};
        
        // avg-cohort-retention-by-function-chart
        chartHeights['avg-cohort-retention-by-function-chart-container'] = '20%';
        
        // company-career-tenure-chart
        chartHeights['company-tenure-chart-container'] = '25%';
        chartHeights['career-tenure-chart-container'] = '18%';
        
        // geo-map-chart
        chartHeights['geo-map-chart-container'] = '47%';
        containerStyles['geo-map-chart-container'] = {'padding-top': '20px'};
        
        // sen-makeup-by-fx-chart
        chartHeights['sen-makeup-by-fx-chart-container'] = '41.25%';
        containerStyles['sen-makeup-by-fx-chart-container'] = {'padding-bottom': '9px'};

        // sen-makeup-by-fx-target-to-peer-chart
        chartHeights['sen-makeup-by-fx-target-to-peer-chart-container'] = '41.25%';
        containerStyles['sen-makeup-by-fx-target-to-peer-chart-container'] = {'padding-bottom': '9px'};


        const originalChartHeights = {};
        const originalChartBackGroundColor = {};
        const originalContainerStyles = {};

        // Function to wait until chart reflow finishes by monitoring changes in size
        const waitForReflowCompletion = (chart) => {
            return new Promise((resolve) => {
                const checkInterval = 500; // Interval to check size (in ms)
                let previousWidth = chart.chartWidth;
                let previousHeight = chart.chartHeight;

                const checkSize = setInterval(() => {
                    const currentWidth = chart.chartWidth;
                    const currentHeight = chart.chartHeight;

                    // If size hasn't changed for an interval, assume reflow is complete
                    if (currentWidth === previousWidth && currentHeight === previousHeight) {
                        clearInterval(checkSize);
                        resolve();
                    } else {
                        // Update the previous dimensions for the next check
                        previousWidth = currentWidth;
                        previousHeight = currentHeight;
                    }
                }, checkInterval);
            });
        };

        // Reflow charts in the container to adjust to the new size
        for (const chart of Highcharts.charts) {
        // Highcharts.charts.forEach(chart => {
            if (chart && chart.container.parentNode.parentNode.parentNode.id === containerId) {

                // Check if the parentNode.id contains the substring 'container-' (for detailed analysis charts)
                const isDetailedAnalysis = chart.container.parentNode.id.includes('container-');
                let baseName = chart.container.parentNode.id;
                
                if (isDetailedAnalysis) {
                    // Keep only the base name of the parentNode.id, discarding everything after the last - e.g. chart-container-flag_0 -> chart-container
                    baseName = chart.container.parentNode.id.substring(0, chart.container.parentNode.id.lastIndexOf('container-')) + 'container';
                }
                
                // Store original height and background color
                originalChartHeights[chart.container.parentNode.id] = chart.options.chart.height;
                originalChartBackGroundColor[chart.container.parentNode.id] = chart.options.chart.backgroundColor;
                
                // Set new height and background color
                chart.options.chart.height = chartHeights[baseName];
                chart.options.chart.backgroundColor= 'rgba(255, 255, 255, 0)';

                // If container has a style, store original and set the new
                if (containerStyles[baseName]) {
                    originalContainerStyles[chart.container.parentNode.id] = chart.container.parentNode.style;
                    Object.keys(containerStyles[baseName]).forEach(key => {
                        chart.container.parentNode.style[key] = containerStyles[baseName][key];
                    });
                };

                chart.reflow();

                // Wait for the redraw to complete
                await waitForReflowCompletion(chart);
            }
        }
        // });
        // await new Promise(resolve => setTimeout(resolve, 200)); // Adjust the delay time as needed

    
        // Get the dimensions of the HTML element
        const elementWidth = chartContainer.offsetWidth;
        const elementHeight = chartContainer.offsetHeight;

        // Export the image
        let pixelRatio = 1;
        // Check if containerId contains a substring in highResolutionContainerIds
        if (highResolutionContainerIds.some(substring => containerId.includes(substring))) {
            pixelRatio = 2;
        }

        const imgData = await htmlToImage.toPng(chartContainer, {
            width: fixedWidth,
            backgroundColor: null,
            pixelRatio: pixelRatio,  // You can adjust this based on your needs
        });

        // Restore the original size
        chartContainer.style.width = originalWidth;
        chartContainer.style.height = originalHeight;
    
        // Reflow charts to restore the original size
        for (const chart of Highcharts.charts) {
        // Highcharts.charts.forEach(chart => {
            if (chart && chart.container.parentNode.parentNode.parentNode.id === containerId) {

                // Restore original height and background color
                chart.options.chart.height = originalChartHeights[chart.container.parentNode.id];
                chart.options.chart.backgroundColor = originalChartBackGroundColor[chart.container.parentNode.id];
                
                // If container has a style, restore original
                if (originalContainerStyles[chart.container.parentNode.id]) {
                    Object.keys(originalContainerStyles[chart.container.parentNode.id]).forEach(key => {
                        chart.container.parentNode.style = originalContainerStyles[chart.container.parentNode.id];
                    });
                };
                chart.reflow();
                // Wait for the redraw to complete
                await waitForReflowCompletion(chart);
            }
        }
        // });
        // await new Promise(resolve => setTimeout(resolve, 200)); // Adjust the delay time as needed
    
        return { imgData, elementWidth, elementHeight };
    };


    async function generatePPT() {
        setIsModalVisible(true);
        setProgressMessage('Initializing report generation...');
    
        let pptx = new pptxgen();
        let today = new Date();
        let monthInWords = today.toLocaleString('default', { month: 'long' });
        let dateLabel = `${monthInWords} ${today.getUTCDate()} ${today.getUTCFullYear()}`;
        pptx = dbm_add_initial_slide_kf(pptx, targetCompanyName, dateLabel, 'standard');
        console.log('Initial slide created');
        pptx = dbm_add_section_slide_kf(pptx, 'Target Company Overview');
    
        const containerIds = [
            // Update the containerIds array with the ids of the charts you want to export
            'signal-score-table', 
            'employee-percentage-chart',
            'employee-hiring-attrition-chart',
            'dept-makeup-by-fx-chart',
            'dept-makeup-by-fx-target-to-peer-chart',
            'dept-growth-rates-by-fx-table',
            'dept-hiring-trend-chart',
            'dept-attrition-trend-chart',
            'dept-net-hc-growth-target-peer-chart',
            'dept-hiring-target-peer-chart',
            'dept-attrition-target-peer-chart',
            'sankey-chart',
            'dept-cohort-retention-target-peer-chart',
            'avg-cohort-retention-table',
            'avg-cohort-retention-by-function-chart',
            'company-career-tenure-chart',
            'geo-map-chart',
            'geo-by-dept-table-hc',
            'geo-by-dept-table-pct',
            'geo-by-dept-peer-table',
            'sen-makeup-by-fx-chart',
            'sen-makeup-by-fx-target-to-peer-chart',
            'sen-by-dept-peer-table',
        ];

        const detailedAnalysisSectionIds = [
            `employee-percentage-chart-selectedOption`,
            `employee-hiring-attrition-chart-selectedOption`,
            `dept-net-hc-growth-target-peer-chart-selectedOption`,
            `dept-hiring-target-peer-chart-selectedOption`,
            `dept-attrition-target-peer-chart-selectedOption`,
            `sankey-chart-selectedOption`,
            `dept-cohort-retention-target-peer-chart-selectedOption`,
            `company-career-tenure-chart-selectedOption`,

            `geo-by-dept-peer-table-selectedOption`,
            `sen-makeup-by-fx-chart-selectedOption`,
            `sen-makeup-by-fx-target-to-peer-chart-selectedOption`,
        ];

        const slideTitles = {
            'signal-score-table': `Signal Score`,
            'employee-percentage-chart': `Employee Count Over Time`,
            'employee-hiring-attrition-chart':  `Employee Hiring and Attrition Over Time`,
            'dept-makeup-by-fx-chart': `Makeup by Dept Function Over Time`,
            'dept-makeup-by-fx-target-to-peer-chart': `Target-to-Peer Set Comparison: Dept Makeup by Function as of current month`,
            'dept-growth-rates-by-fx-table': `Dept Growth Rates by Function`,
            'dept-hiring-trend-chart': `Dept Hiring Trends Over Time - Change in Headcount by Dept Function`,
            'dept-attrition-trend-chart': `Dept Attrition Trends Over Time - Change in Headcount by Dept Function`,
            'dept-net-hc-growth-target-peer-chart': `Target-to-Peer Set Comparison: Dept Employee Net HC Growth (Rolling Last 12 months) Over Time`,
            'dept-hiring-target-peer-chart': `Target-to-Peer Set Comparison: Dept Employee Hiring (Rolling Last 12 months) Over Time`,
            'dept-attrition-target-peer-chart': `Target-to-Peer Set Comparison: Dept Employee Attrition (Rolling Last 12 months) Over Time`,
            'sankey-chart': `Dept Talent Inflow/Outflow in the Last 5 Years`,
            'dept-cohort-retention-target-peer-chart': `Target-to-Peer Set Comparison: Dept Employee Avg Cohort Retention`,
            'avg-cohort-retention-table': `Avg Cohort Retention By Year`,
            'avg-cohort-retention-by-function-chart': `Avg Cohort Retention by Dept Function`,
            'company-career-tenure-chart': `Company and Career Tenure for Dept Roles`,
            'geo-map-chart': `Employee Dispersion by Geo as of Current Month`,
            'geo-by-dept-table-hc': `Employee Dispersion by Function as of Current Month - Absolute HC`,
            'geo-by-dept-table-pct': `Employee Dispersion by Function as of Current Month - Pct of Company`,
            'geo-by-dept-peer-table': `Target-to-Peer Set Comparison: Employee Dispersion by Geo as of Current Month`,
            'geo-map-chart': `Employee Dispersion as of Current Month`,
            'sen-makeup-by-fx-chart': `Makeup by Seniority over Time`,
            'sen-makeup-by-fx-target-to-peer-chart': `Target-to-Peer Set Comparison: Employee Seniority as of Current Month`,
            'sen-by-dept-peer-table': `Target-to-Peer Set Comparison: Employee Dispersion by Dept and Seniority as of Current Month`,
        };

        const detailedAnalysisSlideTitles = [
            `selectedOption Employee Percentage and Count Over Time`,
            `selectedOption Employee Hiring and Attrition (Rolling Last 12 Months) Over Time`,
            `Target-to-Peer Set Comparison: selectedOption Employee Net HC Growth (Rolling Last 12 months) Over Time`,
            `Target-to-Peer Set Comparison: selectedOption Employee Hiring (Rolling Last 12 months) Over Time`,
            `Target-to-Peer Set Comparison: selectedOption Employee Attrition (Rolling Last 12 months) Over Time`,
            `selectedOption Talent Inflow/Outflow in the Last 5 Years`,
            `Target-to-Peer Set Comparison: selectedOption Employee Avg Cohort Retention`,
            `Company and Career Tenure for selectedOption Roles`,
            `Target-to-Peer Set Comparison: selectedOption Employee Dispersion by Geo as of Current Month`,
            `Makeup by selectedOption Seniority over Time`,
            `Target-to-Peer Set Comparison: selectedOption Employee by Dept and Seniority as of Current Month`,
        ];
    
        // Process each chart sequentially to allow UI updates
        for (let i = 0; i < containerIds.length; i++) {
            const id = containerIds[i];
            setProgressMessage(`Processing chart ${i + 1} of ${containerIds.length}...`);

            // check if id is in tableContainerIds
            if (['geo-by-dept-table-hc', 'geo-by-dept-table-pct', 'sen-by-dept-peer-table', 'geo-by-dept-peer-table'].includes(id)){
                // Iterate over all tables with id starting with 'geo-by-dept-table-hc' or 'geo-by-dept-table-pct' and export them as images
                let tables = []
                if (['sen-by-dept-peer-table', 'geo-by-dept-peer-table'].includes(id)) {
                    tables = document.querySelectorAll(`div[id^=${'inner-' + id }]`);

                    const regex = new RegExp(`^inner-${id}-\\d+$`); // Match optional category and trailing number
                    tables = [...document.querySelectorAll('div[id]')].filter(table => regex.test(table.id));
                } else {
                    tables = document.querySelectorAll(`table[id^=${id}]`);
                }
                for (let j = 0; j < tables.length; j++) {
                    const table = tables[j];
                    const { imgData, elementWidth, elementHeight } = await exportChartAsImage(table.id, table, 1920); // or any other fixed size
                    if (imgData) {
                        let slideTitle = slideTitles[id];
                        // If is not the first slide, add suffix (...contd)
                        if (j > 0) {
                            slideTitle += ` (...contd)`;
                        }
                        pptx = dbm_add_table_slide_kf(pptx, targetCompanyName, slideTitle, imgData, elementWidth, elementHeight);
                    }
                }


            } else {

                const chartContainer = document.getElementById(id);
                if (!chartContainer) {
                    console.error(`Chart container not found: ${id}`);
                    continue;
                }
                const { imgData, elementWidth, elementHeight } = await exportChartAsImage(id, chartContainer, 1920); // or any other fixed size
                
                if (imgData) {
                    const slideTitle = slideTitles[id];
                    if (id === 'signal-score-table') {
                        pptx = dbm_add_table_slide_kf(pptx, targetCompanyName, slideTitle, imgData, elementWidth, elementHeight);;
                    }
                    else {
                        pptx = dbm_add_chart_slide_kf(pptx, targetCompanyName, slideTitle, imgData, elementWidth, elementHeight);;
                    }
                }
            }

            delay(100);
        }

        // add Detailed Section slides
        for (let i = 0; i < detailedAnalysisSections.length; i++) {
            const section = detailedAnalysisSections[i];
            const sectionTitle = section.option;

            pptx = dbm_add_section_slide_kf(pptx, `Detailed Analysis: ${sectionTitle}`);

            for (let j = 0; j < section.components.length; j++) {
                const componentId = detailedAnalysisSectionIds[j].replace('selectedOption', section.option);
                setProgressMessage(`Processing detailed analysis slide ${j + 1} of ${section.components.length}...`);


                // check if componentId starts with 'geo-by-dept-peer-table'
                
                // if (componentId === 'geo-by-dept-peer-table'){
                if (componentId.startsWith('geo-by-dept-peer-table')){
                    // Iterate over all tables with id starting with 'inner-geo-by-dept-peer-table' and export them as images
                    let tables = []
                    const regex = new RegExp(`^inner-${componentId}-\\d+$`); // Require a category before the trailing index
                    tables = [...document.querySelectorAll('div[id]')].filter(table => regex.test(table.id));

                    for (let k = 0; k < tables.length; k++) {
                        const table = tables[k];
                        const { imgData, elementWidth, elementHeight } = await exportChartAsImage(table.id, table, 1920); // or any other fixed size
                        if (imgData) {
                            let slideTitle = detailedAnalysisSlideTitles[j].replace('selectedOption', sectionTitle);
                            // If is not the first slide, add suffix (...contd)
                            if (k > 0) {
                                slideTitle += ` (...contd)`;
                            }
                            pptx = dbm_add_table_slide_kf(pptx, targetCompanyName, slideTitle, imgData, elementWidth, elementHeight);
                        }
                    }


                } else {
                    const chartContainer = document.getElementById(componentId);
                    if (!chartContainer) {
                        console.error(`Chart container not found: ${componentId}`);
                        continue;
                    }

                    const { imgData, elementWidth, elementHeight } = await exportChartAsImage(componentId, chartContainer, 1920); // or any other fixed size
                
                    if (imgData) {
                        const slideTitle = detailedAnalysisSlideTitles[j].replace('selectedOption', sectionTitle);
                        pptx = dbm_add_chart_slide_kf(pptx, targetCompanyName, slideTitle, imgData, elementWidth, elementHeight);;
                    }
                }

                delay(100);
            }
        }

    
        setProgressMessage('Adding final touches...');

        pptx = dbm_add_section_slide_kf(pptx, 'Appendix');
        const companyList = requestData.companyList;
        const companyNames = companyList.map(company => company.name);
        const appendixDivIds = companyNames.map(name => `appendix-${name}`);
        // create a map of appendixDivIds to company names
        const appendixDivIdToCompanyMap = {};
        for (let i = 0; i < companyNames.length; i++) {
            appendixDivIdToCompanyMap[appendixDivIds[i]] = companyNames[i];
        }
        for (let i = 0; i < appendixDivIds.length; i++) {
            const id = appendixDivIds[i];
            const chartContainer = document.getElementById(id);
            setProgressMessage(`Processing appendix slide ${i + 1} of ${appendixDivIds.length}...`);
            if (!chartContainer) {
                console.error(`Chart container not found: ${id}`);
                continue;
            }

            const { imgData, elementWidth, elementHeight } = await exportChartAsImage(id, chartContainer, 1920); // or any other fixed size
            
            if (imgData) {
                const slideTitle = appendixDivIdToCompanyMap[id];
                pptx = dbm_add_appendix_company_slide_kf(pptx, imgData, elementWidth, elementHeight);
            }

            delay(100);
        }

        pptx = dbm_add_ending_slide_kf(pptx);
    
        // Save the PowerPoint
        await pptx.writeFile({ fileName: `Telemetry_KF_Benchmarking_Report_${targetCompanyName}`, compression: true });
        console.log('PPTX file written');
        setProgressMessage('Report generated successfully!');
    }

    const chartIdsToUpdate = [
        'dept-makeup-by-fx-chart-container', 
        'dept-makeup-by-fx-target-to-peer-chart-container',
        'dept-hiring-by-fx-chart-container',
        'dept-attrition-by-fx-chart-container',
        'company-tenure-chart-container',
        'career-tenure-chart-container',
        'sen-makeup-by-fx-chart-container',
        'sen-makeup-by-fx-target-to-peer-chart-container'
    ];

    return (
        <div>
        {
            dataLoading ?
            <div style={{ textAlign: "center" }} >
                <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...']} interval={3000} />
            </div>
            : 
            <div className="report-container">
                <TableOfContents />
                <div className="report-content">
                    <h1>Standard Benchmark Report Preview: {targetCompanyName}</h1>
                    <h4>
                        Scroll through the preview, make any adjustments as needed and then click Download Report at the bottom to get the full report.
                    </h4>
                    <div style={{ 
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'sticky',
                        top: '0',
                        backgroundColor: 'white',
                        zIndex: 100,
                        padding: '10px 0',
                        borderBottom: '1px solid #e5e7eb'
                    }}>
                        <ChartThemeSwitcher targetChartIds={chartIdsToUpdate}/>
                    </div>
                    <br/>
                    <ErrorSafeComponent>
                        <div id="signalScoreTable">
                            <SignalScoreTable requestData={requestData} />
                            <br/>
                            <br/>
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="employeePercentage">
                            <EmployeePercentageChart requestData={requestData}/>
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="employeeHiringAttrition">
                            <EmployeeHiringAndAttritonChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptMakeupByFunction">
                            <DeptMakeupByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptMakeupByFunctionTargetPeer">
                            <TargetToPeerDeptMakeupByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptGrowthRatesByFunction">
                            <DeptGrowthRatesByFunctionTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptHiringTrend">
                            <DeptHiringTrendChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptAttritionTrend">
                            <DeptAttritionTrendChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptNetHCTargetPeer">
                            <TargetToPeerDeptNetHCGrowthChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptHiringTargetPeer">
                            <TargetToPeerDeptHiringChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptAttritionTargetPeer">
                            <TargetToPeerDeptAttritionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptSankey">
                            <DeptSankeyChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="deptCohortRetentionTargetPeer">
                            <TargetToPeerDeptAvgCohortRetentionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="avgCohortRetention">
                            <AvgCohortRetentionTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="avgCohortRetentionByFunction">
                            <AvgCohortRetentionByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="companyCareerTenure">
                            <CompanyAndCareerTenureChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="geoMap">
                            <GeoMap requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="geoTableHC">
                            <GeoByDeptTableHC requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="geoTablePct">
                            <GeoByDeptTablePct requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="geoPeerTable">
                            <GeoByDeptPeerTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="seniorityMakeup">
                            <SeniorityMakeupByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="seniorityMakeupTargetPeer">
                            <TargetToPeerSeniorityMakeupByFunctionChart requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="seniorityPeerTable">
                            <SeniorityByDeptPeerTable requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <br/>

                    <hr className="dotted-line"/>
                    {
                        detailedAnalysisSections.map((section) => (
                            <ErrorSafeComponent>
                                <DetailedAnalysisSection 
                                    key={section.id}
                                    section={section}
                                    onDiscard={() => handleDiscardDetailedAnalysis(section.id)}
                                />
                            </ErrorSafeComponent>
                        ))
                    }
                    <ErrorSafeComponent>
                        <div id="detailedAnalysis">
                            <DetailedAnalysisSection
                                requestData={requestData}
                                availableOptions={availableOptions}
                                onCreate={handleCreateDetailedAnalysis}
                            />
                        </div>
                    </ErrorSafeComponent>
                    <hr className="dotted-line"/>
                    <ErrorSafeComponent>
                        <div id="appendix">
                            <Appendix requestData={requestData} />
                        </div>
                    </ErrorSafeComponent>
                    <ProgressModal isVisible={isModalVisible} onClose={closeModal} title={`Download Benchmarking Report`} progressMessage={progressMessage} />
                    <hr className="dotted-line"/>
                    <br/>
                    <br/>
                    <div style={{textAlign: 'center'}} id="downloadReport">
                        <button className='button' onClick={handleDownloadReport}> Download Report </button>
                        <p> * For best performance, please use the Google Chrome browser *</p>
                    </div>

                </div>
            </div>
        }
        </div>
    );
});

export default StandardBenchmarkReport;
