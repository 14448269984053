import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";


class TeamsGrowthDataStore {
    loading = false;
    data = {};
    defaultData = {};
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchTeamsGrowthData(companyId, accessToken, loadDefault, dataset, callback) {
        // check if data already exists
        // and company id is present in data
        if (this.data[companyId] && this.data[companyId].length > 0) {
            console.log(`TeamsGrowthDataStore: Data already exists for company ${companyId}`);
            return;
        }

        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_TEAM_GROWTH_ENDPOINT;
            console.log(`TeamsGrowthDataStore: Fetching team growth data from ${apiUrl}...`);

            let data = {
                "company_ids": [parseInt(companyId)],
                "load_default": loadDefault,
                "dataset": dataset,
            }
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                decompress: true,
                // withCredentials: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            const response = await axios.post(apiUrl, data, config);

            if (response.data.data.headcount.length !== 0) {
                const jsonData = JSON.parse(response.data.data.headcount);
                console.log(`TeamsGrowthDataStore: Fetched team growth data from ${apiUrl}, ${jsonData.length} records`);
                // set data in store
                if (loadDefault) {
                    this.defaultData[companyId] = jsonData;
                }
                else {
                    this.data[companyId] = jsonData;
                    if (callback) {
                        callback(this.data, this.error);
                    }
                }
                this.loading = false;
            }

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId,
                    eventType: 'team_growth',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TEAM_GROWTH_ENDPOINT,
                        "dataset": dataset,
                        "user_agent": navigator.userAgent,
                    },
                });
            }

        } catch (err) {
            console.log(err);
            this.error = err;
            this.loading = false;
            if (!loadDefault)
                if (callback)
                    callback(this.data, this.error);
        }
    }
}

export const teamsGrowthDataStore = new TeamsGrowthDataStore();
