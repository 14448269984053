import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";


class UniverseListStore {
    loading = false;
    universes = null;
    sharedUniverses = null;
    error = null;
    unAuthorized = false;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchUniverseList(email) {
        // check if data already exists
        if (this.universes && email in this.universes && this.universes[email] !== undefined) {
            console.log(`UniverseListStore: Data already exists`);
            return;
        }
        
        this.loading = true;

        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_UNIVERSE_LIST_ENDPOINT;

            console.log(`UniverseListStore: Fetching universe list from ${apiUrl}...`);

            let data = {
                "email": email, //'admin@telemetryllc.com',
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200 && response.data.data.universe_list.length !== 0) {
                const jsonData = JSON.parse(response.data.data.universe_list);
                console.log(`UniverseListStore: Fetched universe list from ${apiUrl}, ${jsonData.length} records`);
                
                // set data in store
                if (this.universes === null) {
                    this.universes = {};
                }
                this.universes[email] = jsonData;
                this.unAuthorized = false;
            }

        }
        catch(err) {
            console.log(err.response);
            if(err.response !== undefined  && err.response.status === 401) {
                this.error = 'Unauthorized';
                this.unAuthorized = true;
            }
            else {
                this.error = "Oops! Something went wrong. Please try again later."
            }
        }
        finally {
            this.loading = false;
        }

    }

    async fetchSharedUniverseList(email) {
        // check if data already exists
        if (this.sharedUniverses && email in this.sharedUniverses && this.sharedUniverses[email] !== undefined) {
            console.log(`UniverseListStore: Data already exists`);
            return;
        }
        
        this.loading = true;

        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_SHARED_UNIVERSE_LIST_ENDPOINT;

            console.log(`UniverseListStore: Fetching shared universe list from ${apiUrl}...`);

            let data = {
                "email": email, //'admin@telemetryllc.com',
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200 && response.data.data.universe_list.length !== 0) {
                const jsonData = JSON.parse(response.data.data.universe_list);
                console.log(`UniverseListStore: Fetched shared universe list from ${apiUrl}, ${jsonData.length} records`);
                
                // set data in store
                if (this.sharedUniverses === null) {
                    this.sharedUniverses = {};
                }
                this.sharedUniverses[email] = jsonData;
            }

        }
        catch(err) {
            console.log(err);
            if(err.response !== undefined && err.response.status === 401) {
                this.error = 'Unauthorized';
                this.unAuthorized = true;
            }
            else {
                this.error = "Oops! Something went wrong. Please try again later."
            }
        }
        finally {
            this.loading = false;
        }
    }
}

export const universeListStore = new UniverseListStore();
