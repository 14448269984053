import { PageLayout } from "../../components/common/page-layout";
import CompanyTalentFlowComponent from "../../components/company/company_talent_flow_chart";

export const CompanyTalentPage = () => {
    return (
        <PageLayout>
            <CompanyTalentFlowComponent />
        </PageLayout>
    );
};
