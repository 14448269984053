import React, { useState } from 'react';
import Select from 'react-select';


const DetailedAnalysisSection = ({ requestData, availableOptions=[], onCreate, section, onDiscard }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    const handleCreate = () => {
        if (selectedValue) {
            onCreate(selectedValue.value);
            setSelectedValue(null);
        }
    };
    // const options = availableOptions.map(option => ({ value: option, label: option }));
    const options = Array.isArray(availableOptions) 
        ? availableOptions.map(option => ({ value: option, label: option }))
        : [];

    if (section) {
        return (
            <div>
                <h2>Detailed Analysis: {section.option}</h2>
                <button className="button" onClick={onDiscard}>Discard</button>
                {
                    section.components.map(({ id, component }) => (
                        <div key={id} id={id}>
                            {component}
                        </div>
                    ))
                }
            </div>
        );
    }

    return (
        <>
            <h2> Detailed Analysis </h2>
            <h4 id="header" style={{display: 'block'}}> Select any of the dept types to create a detailed analysis section </h4>
            <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Select
                    value={selectedValue}
                    onChange={setSelectedValue}
                    options={options}
                    placeholder="Select an option"
                    styles={{
                        container: (provided) => ({
                        ...provided,
                        width: '270px',
                        marginRight: '30px',
                        })
                    }}
                />
                <button className="button" style={{ marginTop: '0px' }} onClick={handleCreate}>Create</button>
            </div>
        </>
    );

    // return (
    //     <div>
            
    //         {selectedOption ? (
    //             <>
    //                 <h2>Detailed Analysis: {selectedOption}</h2>
    //                 <button className="button" onClick={() => onDiscard(selectedOption)}>Discard</button>
    //                 {selectedOption.components}
    //             </>
    //         ) : (
    //             <>
    //             <h4 id="header" style={{display: 'block'}}> Select any of the digital transformation types to create a detailed analysis section </h4>
    //             <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //                 <Select
    //                     value={selectedValue}
    //                     onChange={setSelectedValue}
    //                     options={options}
    //                     placeholder="Select an option"
    //                     styles={{
    //                         container: (provided) => ({
    //                         ...provided,
    //                         width: '270px',
    //                         marginRight: '30px',
    //                         })
    //                     }}
    //                 />
    //                 <button className="button" style={{ marginTop: '0px' }} onClick={handleCreate}>Create</button>
    //             </div>
    //             </>
    //         )}
    //     </div>
    // );
};

export default DetailedAnalysisSection;
