import { PageLayout } from "../../components/common/page-layout"
import UniverseEditComponent from "../../components/universe/universe_edit";

export const UniverseEditPage = () => {
    return (
        <PageLayout>
            <UniverseEditComponent />
        </PageLayout>
    );
};
